/**
 * Formats a number as a currency string.
 * @param amount The number to format
 * @param currency The currency code (e.g., 'USD', 'EUR')
 * @param locale The locale to use for formatting (default: 'en-US')
 * @returns Formatted currency string
 */
export function formatCurrency({
  amount,
  currency,
  locale = "en-US",
}: {
  amount: number;
  currency: string;
  locale?: string;
}): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}
