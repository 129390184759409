import { z } from "zod";

export const websiteFormSchema = z.object({
  website: z
    .string({
      invalid_type_error: "Website is required",
    })
    .min(1, "Website is required")
    .url("Invalid URL"),
});

export type WebsiteForm = z.infer<typeof websiteFormSchema>;
