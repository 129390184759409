import { env } from "@dashboard/env.ts";
import { useEffect, useState } from "react";
import { ChannelType } from "@db";
import { Route } from "@dashboard/routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId";
import { ConversationCard } from "../ConversationCard";

interface RecentConversationLinkProps {
  publicId: string;
  isClosed: boolean;
  createdAt: Date;
  channel: ChannelType;
  orderId: string | null;
}

export function RecentConversationLink({
  publicId,
  isClosed,
  createdAt,
  channel,
  orderId,
}: RecentConversationLinkProps) {
  const { organizationSlug } = Route.useParams();

  const [, setTick] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <a
      href={`${env.DASHBOARD_BASE_URL}/organization/${organizationSlug}/conversation/${publicId}`}
      target="_blank"
      rel="noreferrer"
    >
      <ConversationCard
        isClosed={isClosed}
        createdAt={createdAt}
        channel={channel}
        orderId={orderId}
      />
    </a>
  );
}
