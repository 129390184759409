import { createFileRoute } from "@tanstack/react-router";

import { Table } from "@dashboard/common/Table/Table";
import { UploadDocumentDialogContent } from "./-components/UploadDocumentDialogContent";
import { useDeleteDocuments } from "./-hooks/useDeleteDocuments";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/documents/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.documents.getAll.ensureData({
      businessSlug,
    });
  },

  component: DocumentsPage,
});

function DocumentsPage() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [documents] = trpc.dashboard.documents.getAll.useSuspenseQuery({
    businessSlug,
  });

  const { deleteDocuments } = useDeleteDocuments();

  const records = documents.map((document) => ({
    id: document.id,
    name: document.name,
  }));

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col">
        <Table
          title="Documents"
          records={records}
          columns={[
            {
              header: "Document",
              accessor: "name",
            },
          ]}
          addRecordDialogContent={(setOpen) => (
            <UploadDocumentDialogContent setOpen={setOpen} />
          )}
          deleteRecords={deleteDocuments}
          recordName={{ singular: "Document", plural: "Documents" }}
          noRecordsText="No documents available"
        />
      </div>
    </div>
  );
}
