import { Route } from "..";
import { toast } from "sonner";
import { ArticleForm } from "../-utils/articleForm";

export function useEditArticle({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.articles.upsert.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.articles.getAll.invalidate({ businessSlug });

      setOpen(false);
      toast.success("Article updated");
    },
    onError: () => {
      toast.error("Failed to update article");
    },
  });

  return {
    onSubmit: ({
      id,
      title,
      content,
      categoryId,
      isPublic,
    }: ArticleForm & { id: string }) => {
      updateMutation.mutate({
        businessSlug,
        id,
        title,
        content,
        categoryId,
        isPublic,
      });
    },
  };
}
