import { useRouter } from "@tanstack/react-router";
import { Route } from "..";
import { toast } from "sonner";

export function useDeleteWebsites() {
  const router = useRouter();
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.scraper.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.scraper.getAll.invalidate({ businessSlug });
      await router.invalidate();

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Websites removed successfully."
          : "Website removed successfully.";

      toast.success(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to delete websites."
          : "Failed to delete website.";

      toast.error(message);
      console.error(error);
    },
  });

  return {
    deleteWebsites: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids });
    },
  };
}
