import { createFileRoute } from "@tanstack/react-router";
import { ConversationTopBar } from "../../-components/ConversationTopBar";
import { MessageInput } from "./-components/MessageInput";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/new/",
)({
  component: NewConversationPage,
});

function NewConversationPage() {
  return (
    <>
      <div className="flex h-full flex-grow flex-col">
        <ConversationTopBar>
          <h1 className="text-xl font-medium text-black">New conversation</h1>
        </ConversationTopBar>
        <MessageInput />
      </div>
      <div className="flex h-full w-[22%] shrink-0 flex-col border-l 2xl:w-[25%]">
        <ConversationTopBar />
      </div>
    </>
  );
}
