import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { DixaForm, dixaFormSchema } from "../-utils/dixaFormSchema";
import { useIntegration } from "../-hooks/useIntegration";
import { useSubmitForm } from "../-hooks/useSubmitForm";

export function DixaSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "dixa" });

  const defaultValues = {
    apiKey: "",
    requesterId: "",
    emailIntegrationId: "",
  };

  const form = useForm<DixaForm>({
    resolver: zodResolver(dixaFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "dixa",
  });

  function Description() {
    return (
      <div className="flex flex-col gap-4 leading-normal">
        <h1 className="text-2xl font-semibold">Dixa Integration Settings</h1>
        <h2 className="text-xl font-medium">
          Enter your Dixa account API credentials
        </h2>
        <p>
          Instructions on generating a Dixa API token can be found here:
          <div className="py-2">
            <Button variant="link" className="p-0 font-semibold">
              <a
                href="https://docs.dixa.io/docs/tutorial-create-an-api-token"
                target="_blank"
                rel="noreferrer"
              >
                Creating an API token
              </a>
            </Button>
          </div>
        </p>
      </div>
    );
  }

  return (
    <>
      <Description />
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="apiKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>API Key</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter the API Key" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="emailIntegrationId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Integration ID</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter the Email Integration ID"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="requesterId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Requester ID</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter the Requester ID" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex w-full justify-end pt-4">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Form>
    </>
  );
}
