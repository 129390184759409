import { Route } from "..";

export function useConversation() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const [conversation] = trpc.dashboard.conversations.get.useSuspenseQuery({
    organizationSlug,
    publicId,
  });
  return conversation;
}
