import { Table } from "@dashboard/common/Table/Table";
import { Button } from "@dashboard/common/ui/button";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import { FlowLink } from "./-components/FlowLink";
import { Live } from "./-components/Live";
import { Draft } from "./-components/Draft";
import { useDeleteFlows } from "./-hooks/useDeleteFlows";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/flows/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.flows.getAll.ensureData({
      businessSlug,
    });
  },

  component: FlowsPage,
});

function FlowsPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [flows] = trpc.dashboard.flows.getAll.useSuspenseQuery({
    businessSlug,
  });

  const records = flows.map((flow) => ({
    id: flow.id,
    title: <FlowLink title={flow.title} flowId={flow.id} />,
    isLive: flow.isLive ? <Live /> : <Draft />,
  }));

  const { deleteFlows } = useDeleteFlows();

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">Flows</h1>
        <div className="flex items-center gap-2">
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/flows/templates"
            params={{ organizationSlug, businessSlug }}
          >
            <Button
              className="flex items-center justify-between gap-2"
              type="button"
              variant="secondary"
            >
              Flow Templates
            </Button>
          </Link>
          <Link
            to="/organization/$organizationSlug/settings/$businessSlug/flows/new"
            params={{ organizationSlug, businessSlug }}
          >
            <Button
              className="flex items-center justify-between gap-2"
              type="button"
            >
              <Plus className="h-4 w-4" strokeWidth={3} />
              Create a new flow
            </Button>
          </Link>
        </div>
      </div>
      <Table
        records={records}
        columns={[
          {
            header: "Title",
            accessor: "title",
          },
          {
            header: "Status",
            accessor: "isLive",
          },
        ]}
        recordName={{ singular: "Flow", plural: "Flows" }}
        deleteRecords={deleteFlows}
        noRecordsText="No flows available"
      />
    </div>
  );
}
