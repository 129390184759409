import { z } from "zod";

export const emailFormSchema = z.object({
  fromEmail: z
    .string({
      required_error: "Sender Email is required",
      invalid_type_error: "Sender Email is required",
    })
    .trim()
    .email("Sender Email is required"),

  smtpHost: z
    .string({
      required_error: "SMTP Host is required",
      invalid_type_error: "SMTP Host is required",
    })
    .trim()
    .min(1, "SMTP Host is required"),
  smtpPort: z.coerce
    .number({
      required_error: "SMTP Port is required",
      invalid_type_error: "SMTP Port is required",
    })
    .int({
      message: "SMTP Port is required",
    })
    .refine((val) => val !== 0, {
      message: "SMTP Port is required",
    }),
  smtpUsername: z
    .string({
      required_error: "SMTP Username is required",
      invalid_type_error: "SMTP Username is required",
    })
    .trim()
    .min(1, "SMTP Username is required"),
  smtpPassword: z
    .string({
      required_error: "SMTP Password is required",
      invalid_type_error: "SMTP Password is required",
    })
    .trim()
    .min(1, "SMTP Password is required"),

  imapHost: z
    .string({
      required_error: "IMAP Host is required",
      invalid_type_error: "IMAP Host is required",
    })
    .trim()
    .min(1, "IMAP Host is required"),
  imapPort: z.coerce
    .number({
      required_error: "IMAP Port is required",
      invalid_type_error: "IMAP Port is required",
    })
    .int({
      message: "IMAP Port is required",
    })
    .refine((val) => val !== 0, {
      message: "IMAP Port is required",
    }),
  imapUsername: z
    .string({
      required_error: "IMAP Username is required",
      invalid_type_error: "IMAP Username is required",
    })
    .trim()
    .min(1, "IMAP Username is required"),
  imapPassword: z
    .string({
      required_error: "IMAP Password is required",
      invalid_type_error: "IMAP Password is required",
    })
    .trim()
    .min(1, "IMAP Password is required"),
});

export type EmailForm = z.infer<typeof emailFormSchema>;
