import { createFileRoute } from "@tanstack/react-router";
import {
  AlertCircle,
  CreditCard,
  BarChart3,
  Clock,
  DollarSign,
} from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@dashboard/common/ui/alert";
import { Button } from "@dashboard/common/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@dashboard/common/ui/card";
import { Progress } from "@dashboard/common/ui/progress";
import { differenceInDays } from "date-fns";
import {
  BASIC_PLAN,
  TRIAL_DAYS,
} from "@be/modules/integrations/thirdParty/shopify/shopify.const";
import { addDays } from "date-fns";
import { MetricCard } from "./-components/MetricCard";
import { format } from "date-fns";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";
import { useCancelSubscription } from "./-hooks/useCancelSubscription";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/billing/",
)({
  component: BillingPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.shopify.getSubscription.ensureData({
      businessSlug,
    });
  },
});

const plan = BASIC_PLAN;

function formatCurrency(amount: number | string) {
  const locale = navigator.language || "en-US";
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  }).format(Number(amount));
}

function formatDate(date: Date, dateFormat = "PP") {
  return format(date, dateFormat);
}

function BillingLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col">
      <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
        <h1 className="text-lg font-semibold">Billing</h1>
      </div>
      <div className="max-w-screen-lg flex-1 space-y-5 overflow-y-auto px-10 py-5">
        {children}
      </div>
    </div>
  );
}

export default function BillingPage() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const { cancelSubscription } = useCancelSubscription();

  const [subscription] =
    trpc.dashboard.shopify.getSubscription.useSuspenseQuery({
      businessSlug,
    });

  if (!subscription) {
    return (
      <BillingLayout>
        <Card className="w-full">
          <CardHeader>
            <CardTitle className="text-lg">No Active Subscription</CardTitle>
            <CardDescription>
              You currently don&apos;t have an active subscription plan
            </CardDescription>
          </CardHeader>
        </Card>
      </BillingLayout>
    );
  }

  const freeTrialEndDate = addDays(
    new Date(subscription.subscriptionCreatedAt),
    TRIAL_DAYS,
  );
  const freeTrialDaysRemaining = differenceInDays(freeTrialEndDate, new Date());

  const isFreeTrialActive = freeTrialDaysRemaining > 0;

  const billingPeriodStartDate = new Date(subscription.billingPeriodStartDate);
  const billingPeriodEndDate = new Date(subscription.billingPeriodEndDate);

  const planPrice = plan.price;
  const overageRate = plan.overageFee;
  const baseConversations = plan.maxConversations;

  const conversationsThisPeriod = subscription.conversationCount;
  const remainingConversations = Math.max(
    0,
    baseConversations - conversationsThisPeriod,
  );
  const overageConversations = Math.max(
    0,
    conversationsThisPeriod - baseConversations,
  );

  const usagePercentage = (conversationsThisPeriod / baseConversations) * 100;

  const overageFees = Number(overageRate) * subscription.usageChargeCount;

  return (
    <BillingLayout>
      {isFreeTrialActive && (
        <Alert className="bg-blue-light border-blue-200">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Free Trial Status</AlertTitle>
          <AlertDescription>
            You have {freeTrialDaysRemaining} days remaining in your free trial.
            Your trial ends on {formatDate(freeTrialEndDate)}.
          </AlertDescription>
        </Alert>
      )}

      <div className="grid gap-5 lg:grid-cols-4">
        <MetricCard
          icon={CreditCard}
          title="Next Payment"
          value={formatCurrency(planPrice)}
          description={`Due on ${formatDate(billingPeriodEndDate)}`}
        />
        <MetricCard
          icon={BarChart3}
          title="Usage"
          value={`${conversationsThisPeriod}/${baseConversations}`}
          description="Conversations this period"
        />
        <MetricCard
          icon={DollarSign}
          title="Overage Fees"
          value={formatCurrency(overageFees)}
          description="Current billing period"
        />
        <MetricCard
          icon={Clock}
          title="Current Period"
          value={`${differenceInDays(
            billingPeriodEndDate,
            billingPeriodStartDate,
          )} days`}
          description="Remaining in billing period"
        />
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Current Plan</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-baseline space-x-2">
            <span className="text-3xl font-bold">
              {formatCurrency(planPrice)}
            </span>
            <span className="text-gray-500">/month</span>
          </div>
          <div className="mt-6 space-y-4">
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Included Conversations</span>
              <span>{baseConversations} conversations</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Overage Rate</span>
              <span>{formatCurrency(overageRate)}/100 conversations</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Next Payment</span>
              <span>{formatDate(billingPeriodEndDate)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Next Amount</span>
              <span>{formatCurrency(planPrice)}</span>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Dialog>
            <DialogTrigger asChild>
              <Button
                variant="ghost"
                className="w-full text-red-500 hover:bg-red-50 hover:text-red-600"
              >
                Cancel Plan
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Cancel Subscription</DialogTitle>
                <DialogDescription>
                  Are you sure you want to cancel your subscription?
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button variant="ghost">Keep subscription</Button>
                <Button variant="destructive" onClick={cancelSubscription}>
                  Yes, cancel subscription
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Usage This Period</CardTitle>
          <CardDescription>
            Billing period: {formatDate(billingPeriodStartDate)} -{" "}
            {formatDate(billingPeriodEndDate)}
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Conversations</span>
              <span>
                {conversationsThisPeriod}/{baseConversations} included
              </span>
            </div>
            <Progress value={usagePercentage} className="h-2" />
            <p className="text-xs text-gray-500">
              You have used {usagePercentage.toFixed(2)}% of your included
              conversations
            </p>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">
                Base Plan Conversations
              </span>
              <span className="font-medium">{baseConversations}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">Used Conversations</span>
              <span className="font-medium">{conversationsThisPeriod}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-500">
                Remaining Conversations
              </span>
              <span className="font-medium">{remainingConversations}</span>
            </div>
          </div>

          <div className="border-t pt-4">
            <h4 className="mb-2 font-semibold">Overage Charges</h4>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">Current Overage</span>
                <span className="font-medium">
                  {overageConversations} conversations
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">Overage Rate</span>
                <span className="font-medium">
                  {formatCurrency(overageRate)} / 100 conversations
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">
                  Total Overage Charges
                </span>
                <span className="font-medium">
                  {formatCurrency(overageFees)}
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </BillingLayout>
  );
}
