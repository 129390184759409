import { useEffect, useRef, useCallback } from "react";
import { Route } from "../_conversationSidebar";

export const useTabNotificationEffect = () => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const search = Route.useSearch();
  const businessId = search.filters?.businessId;
  const [count] = trpc.dashboard.conversations.getCount.useSuspenseQuery({
    organizationSlug,
    filters: { businessId, replyChannel: "web", assignee: null },
  });

  const originalTitle = "Octocom Dashboard";
  const notificationTitle = `${count} ${
    count === 1 ? "person" : "people"
  } waiting | Octocom Live Chat`;

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const updateTitle = useCallback(() => {
    document.title =
      document.title === originalTitle ? notificationTitle : originalTitle;
  }, [originalTitle, notificationTitle]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (count > 0) {
      intervalRef.current = setInterval(() => {
        console.log("interval");
        updateTitle();
      }, 3000);
    } else {
      document.title = originalTitle;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [count, updateTitle, originalTitle]);
};
