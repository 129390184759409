import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { OrderDetails } from "./OrderDetails";
import { Separator } from "@dashboard/common/ui/separator";
import { Loader2 } from "lucide-react";
import { TrackedOrder } from "@be/modules/orders/orders.types";
import { useOrders } from "../../../-hooks/orders/useOrders";
import { Route } from "../../..";

export function Orders() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();
  const { data: orderDetails, isLoading } = useOrders();
  const [hasOrderConfig] =
    trpc.dashboard.conversations.orders.hasConfig.useSuspenseQuery({
      publicId,
      organizationSlug,
    });

  if (!hasOrderConfig) {
    return null;
  }

  return (
    <Accordion
      type="multiple"
      defaultValue={["order-details"]}
      className="w-full"
    >
      <AccordionItem value="order-details" className="border-none !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Orders {!isLoading && `(${orderDetails?.length ?? 0})`}
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {isLoading ? (
            <div className="flex items-center justify-center p-4 pb-0">
              <Loader2
                className="h-8 w-8 animate-spin text-gray-500"
                strokeWidth={1.5}
              />
              <span className="ml-2 text-sm text-gray-500">
                Loading order details...
              </span>
            </div>
          ) : !orderDetails || orderDetails.length === 0 ? (
            <div className="px-7 pt-4 text-sm text-gray-500">
              No order details found for this customer.
            </div>
          ) : (
            <Accordion
              type="single"
              collapsible
              className="max-h-160 mt-5 flex flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4"
            >
              {orderDetails.map((order, index) => (
                <div key={order.orderId}>
                  <OrderDetails order={order as TrackedOrder} />
                  {index !== orderDetails.length - 1 && (
                    <Separator className="bg-offWhite mx-3 w-auto" />
                  )}
                </div>
              ))}
            </Accordion>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
