import { BriefcaseBusiness } from "lucide-react";
import { FilterSelect } from "./FilterSelect";
import { Route } from "../../..";

export function Business({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const { businesses } = Route.useRouteContext();

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.id,
  }));

  return (
    <FilterSelect
      filterName="Business"
      valueIcon={BriefcaseBusiness}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
