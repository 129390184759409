import { createFileRoute } from "@tanstack/react-router";
import { Check } from "lucide-react";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/demo-success",
)({
  component: DemoSuccessPage,
});

function DemoSuccessPage() {
  return (
    <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
      <div className="flex flex-col items-center">
        <h1 className="pb-3 text-center text-2xl font-medium leading-8">
          Demo Request Received!
        </h1>
        <h2 className="text-center leading-6 text-gray-800">
          Our team will contact you shortly to schedule your personalized demo
        </h2>
      </div>

      <div className="flex w-full max-w-2xl flex-col gap-4 py-8">
        <div className="rounded-lg border p-6">
          <h3 className="mb-4 font-medium">What happens next?</h3>
          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Demo Scheduling</span>
                <p className="text-sm text-gray-500">
                  We&apos;ll reach out within 24 hours to find a time that works
                  for you
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Personalized Walkthrough</span>
                <p className="text-sm text-gray-500">
                  Get a detailed tour of Octocom&apos;s powerful automation and
                  AI features
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Custom Solution Planning</span>
                <p className="text-sm text-gray-500">
                  We&apos;ll help design and implement a solution that fits your
                  specific needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
