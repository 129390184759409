import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";
import { Separator } from "@dashboard/common/ui/separator";
import { StaticFilterComponent } from "./Filters/StaticFilterComponent";

interface ViewDialogProps {
  title: string;
  filters: { key: FilterKeys; value: string }[];
}

export function ViewDialog({ title, filters }: ViewDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="pl-0" variant="link">
          {title}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="mx-auto">{title}</DialogTitle>
        </DialogHeader>

        {filters.length > 0 && (
          <>
            <Separator />

            <div className="flex flex-col items-start justify-start gap-2">
              {filters.map((filter, index) => (
                <StaticFilterComponent key={index} filter={filter} />
              ))}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
