import * as React from "react";
import { cn } from "@dashboard/utils/ui";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useFormContext } from "react-hook-form";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon = false, name, ...props }, ref) => {
    const inputClasses = cn(
      "border-input placeholder:text-muted-foreground focus-visible:ring-ring block h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
      {
        "pl-10": icon,
      },
      className,
    );

    let inputProps = props;

    const formContext = useFormContext();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (name && formContext) {
      const { register } = formContext;
      if (type === "number") {
        inputProps = {
          ...register(name, {
            setValueAs: (v) => (v === "" ? null : parseFloat(v as string)),
          }),
          ...props,
        };
      } else {
        inputProps = {
          ...register(name, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            setValueAs: (v) => (v === "" ? null : v),
          }),
          ...props,
        };
      }
    }

    // ! The icon is fixed for now - generalize it in the future
    return (
      <div className="relative flex w-full items-center">
        {icon && ( // Render the icon and its container only if icon prop is true
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          </div>
        )}
        <input type={type} className={inputClasses} ref={ref} {...inputProps} />
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
