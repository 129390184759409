import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { Select } from "@dashboard/common/ui/select";
import { z } from "zod";
import { Accordion } from "@dashboard/common/ui/accordion";
import { AccordionItemComponent } from "./-components/AccordionItemComponent";
import { TestYourBot } from "./-components/TestYourBot";
import { AddKnowledge } from "./-components/AddKnowledge";
import { StyleBot } from "./-components/StyleBot";
import { SetupChannels } from "./-components/SetupChannels";
import { BookText, Bot, BotMessageSquare, Split, Users } from "lucide-react";
import { MessageCircle } from "lucide-react";
import { AddTeamMembers } from "./-components/AddTeamMembers";
import { ImplementFlows } from "./-components/ImplementFlows";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@dashboard/common/ui/tabs";
import { App } from "@chatbot/App";
import { LaunchBot } from "./-components/LaunchBot";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/setup/",
)({
  validateSearch: z.object({
    businessSlug: z.string().optional(),
  }),
  beforeLoad: ({
    context: { businesses },
    search: { businessSlug },
    params: { organizationSlug },
  }) => {
    if (!businessSlug) {
      const business = businesses[0];
      return { business };
    } else {
      const business = businesses.find(
        (business) => business.slug === businessSlug,
      );

      if (!business) {
        throw redirect({
          to: `/organization/$organizationSlug/setup`,
          params: { organizationSlug },
          search: { businessSlug: undefined },
        });
      }

      return { business };
    }
  },
  loader: async ({ context: { trpcClientUtils, businesses } }) => {
    // TODO: this query is too big for organizations with many businesses, handle data fetching better
    await Promise.all(
      businesses.map((business) =>
        Promise.all([
          trpcClientUtils.dashboard.webChat.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.business.getOrThrow.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.integrations.getInstalledIntegrations.ensureData(
            {
              businessSlug: business.slug,
            },
          ),
          trpcClientUtils.dashboard.bots.getKnowledgeSummary.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.products.getConfig.ensureData({
            businessSlug: business.slug,
          }),
          trpcClientUtils.dashboard.products.getCount.ensureData({
            businessSlug: business.slug,
          }),
        ]),
      ),
    );
  },
  component: SetupPage,
});

function SetupPage() {
  const { trpc, business, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const navigate = useNavigate();

  const [webChatConfig] = trpc.dashboard.webChat.getConfig.useSuspenseQuery({
    businessSlug: business.slug,
  });

  const [{ completedOnboardingSteps }] =
    trpc.dashboard.business.getOrThrow.useSuspenseQuery({
      businessSlug: business.slug,
    });

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.slug,
  }));

  const setBusiness = (value: string) => {
    void navigate({
      search: (prev) => ({ ...prev, businessSlug: value }),
    });
  };

  const setupSteps = [
    {
      value: "add-knowledge",
      title: "Add Knowledge Base",
      subtitle:
        "Train your bot with your business information and documentation for more accurate responses.",
      icon: BookText,
      component: (
        <AddKnowledge
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("knowledge")}
        />
      ),
      completed: completedOnboardingSteps.includes("knowledge"),
    },
    {
      value: "flows",
      title: "Implement Customer Support Flows",
      subtitle:
        "Create automated workflows and response patterns to streamline your customer support process.",
      icon: Split,
      component: (
        <ImplementFlows
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("flows")}
        />
      ),
      completed: completedOnboardingSteps.includes("flows"),
    },
    {
      value: "style-bot",
      title: "Style Your Chatbot",
      subtitle:
        "Customize your chatbot's appearance to match your brand and business.",
      icon: Bot,
      component: (
        <StyleBot
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("style")}
        />
      ),
      completed: completedOnboardingSteps.includes("style"),
    },
    {
      value: "team",
      title: "Add Team Members",
      subtitle:
        "Invite and manage team members to collaborate on customer support and bot training.",
      icon: Users,
      component: (
        <AddTeamMembers
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("team")}
        />
      ),
      completed: completedOnboardingSteps.includes("team"),
    },
    {
      value: "launch",
      title: "Launch Your Chatbot",
      subtitle:
        "Deploy your chatbot and make it live for your customers to start getting automated support 24/7.",
      icon: BotMessageSquare,
      component: (
        <LaunchBot completed={completedOnboardingSteps.includes("launch")} />
      ),
      completed: completedOnboardingSteps.includes("launch"),
    },
    {
      value: "channels",
      title: "Set up Additional Channels",
      subtitle:
        "Connect your bot to various platforms where your customers can interact with it.",
      icon: MessageCircle,
      component: (
        <SetupChannels
          businessSlug={business.slug}
          organizationSlug={organizationSlug}
          completed={completedOnboardingSteps.includes("channels")}
        />
      ),
      completed: completedOnboardingSteps.includes("channels"),
    },
  ];

  return (
    <div className="flex h-full w-full flex-col">
      <div className="border-b-gray-light flex w-full items-center justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium">Welcome to Octocom</h1>
        {businesses.length > 1 && (
          <Select
            variant="dropdown"
            value={business.slug}
            onChange={setBusiness}
            options={options}
            placeholder="Select a business"
          />
        )}
      </div>
      <Tabs
        defaultValue="test"
        className="flex w-full flex-1 flex-col overflow-hidden"
      >
        <TabsList
          variant="underline"
          className="border-b-gray-light h-11 w-full shrink-0 justify-start gap-4 border-b px-10"
        >
          <TabsTrigger variant="underline" value="test">
            Test Your Bot
          </TabsTrigger>
          <TabsTrigger variant="underline" value="setup">
            Setup
          </TabsTrigger>
        </TabsList>
        <TabsContent value="test" className="mt-0 flex-1 overflow-hidden">
          <div className="flex h-full w-full overflow-hidden">
            <div className="flex h-full w-full justify-center overflow-auto px-10 py-6">
              <TestYourBot
                organizationSlug={organizationSlug}
                businessSlug={business.slug}
              />
            </div>
            <div className="border-l-gray-light flex h-full w-[50%] items-center justify-center overflow-auto border-l p-4">
              <div className="h-full w-full" id="octocom-container">
                <App presetConfigId={webChatConfig.id} />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="setup" className="mt-0 flex-1 overflow-auto">
          <Accordion
            type="single"
            collapsible
            className="flex w-full flex-col gap-4 px-10 py-6"
          >
            {setupSteps.map((step) => (
              <AccordionItemComponent
                key={step.value}
                value={step.value}
                title={step.title}
                subtitle={step.subtitle}
                icon={step.icon}
                completed={step.completed}
              >
                {step.component}
              </AccordionItemComponent>
            ))}
          </Accordion>
        </TabsContent>
      </Tabs>
    </div>
  );
}
