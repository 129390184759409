import { Route } from "..";

export function useFeedback() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const [feedback] = trpc.dashboard.conversations.feedback.get.useSuspenseQuery(
    {
      organizationSlug,
      publicId,
    },
  );
  return feedback;
}
