import { createFileRoute, useNavigate } from "@tanstack/react-router";

import octocomLogo from "@dashboard/assets/octocom-logo.svg";
import gorgiasLogo from "@dashboard/assets/gorgias.svg";
import zendeskLogo from "@dashboard/assets/zendesk.svg";
import { Check, Mail, CircleHelp } from "lucide-react";
import { Button } from "@dashboard/common/ui/button";
import { useUpdateOnboardingStatus } from "../-hooks/useUpdateOnboardingStatus";
import { useOrganization } from "../-hooks/useOrganization";
import { useExternalTicketConfig } from "../-hooks/useExternalTicketConfig";
import { useRemoveExternalTicketConfig } from "../-hooks/useRemoveExternalTicketConfig";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { cn } from "@dashboard/utils/ui";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/help-desk/",
)({
  component: HelpDeskPage,
});

function HelpDeskPage() {
  const { organizationSlug } = Route.useParams();

  const organization = useOrganization();
  const externalTicketConfig = useExternalTicketConfig();
  const stepCompleted = organization.onboardingStatus !== "HELP-DESK";

  let selectedOption: string | null = null;

  if (externalTicketConfig) {
    selectedOption = externalTicketConfig.system;
  } else if (stepCompleted) {
    selectedOption = "octocom";
  }

  const removeConfiguration = useRemoveExternalTicketConfig();
  const navigate = useNavigate();

  const { update } = useUpdateOnboardingStatus({
    onSuccess: async () => {
      await removeConfiguration();
      void navigate({
        to: "/organization/$organizationSlug/onboarding/knowledge",
        params: { organizationSlug },
      });
    },
  });

  const options = [
    {
      name: "octocom",
      label: "Octocom",
      logo: octocomLogo,
      onClick: () => {
        update("KNOWLEDGE");
      },
    },
    {
      name: "gorgias",
      label: "Gorgias",
      logo: gorgiasLogo,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/gorgias",
          params: { organizationSlug },
        });
      },
    },
    {
      name: "zendesk",
      label: "Zendesk",
      logo: zendeskLogo,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/zendesk",
          params: { organizationSlug },
        });
      },
    },
    {
      name: "email",
      label: "Email",
      logo: Mail,
      onClick: () => {
        void navigate({
          to: "/organization/$organizationSlug/onboarding/help-desk/email",
          params: { organizationSlug },
        });
      },
    },
  ];

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Next, select your help desk
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Any unhandled tickets will be routed to the help desk you select.
        <br />
        Octocom also offers a built-in help desk you can use for managing
        tickets.
      </h2>
      <div className="flex w-full max-w-3xl flex-col gap-4 py-8">
        {options.map((option) => (
          <div key={option.name} className="relative">
            <Button
              onClick={option.onClick}
              variant="outline"
              className="h-10 w-full justify-between border-[#041627] font-semibold"
            >
              <div className="flex items-center">
                {option.name === "email" ? (
                  <option.logo className="mr-4 h-6 w-6" strokeWidth={1.5} />
                ) : (
                  <img
                    src={option.logo as string}
                    alt={`${option.label} Logo`}
                    className="mr-4 h-6 w-6"
                  />
                )}
                {option.label}
              </div>
              {option.name === selectedOption && (
                <Check className="h-6 w-6" strokeWidth={1.5} />
              )}
            </Button>
            {option.name === "email" && (
              <Tooltip
                disableHoverableContent={true}
                triggerAsChild
                side="bottom"
                content={
                  <p className="max-w-[400px] text-sm leading-relaxed text-gray-800">
                    With email forwarding, you will receive:
                    <br />
                    • Complete conversation transcripts for any unhandled chats
                    <br />
                    • Customer contact details for direct follow-up
                    <br />
                    • Everything delivered straight to your specified email
                    address
                    <br />
                    <br />
                    This lets you manage customer support through your email
                    service.
                  </p>
                }
                trigger={
                  <CircleHelp
                    className={cn(
                      "absolute top-1/2 -translate-y-1/2 transform cursor-pointer",
                      option.name === selectedOption ? "right-12" : "right-3",
                    )}
                    strokeWidth={1.5}
                  />
                }
              />
            )}
          </div>
        ))}
        <p className="text-center text-sm text-gray-600">
          You can change this later in the settings.
        </p>

        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/platform"
          nextPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={stepCompleted}
        />
      </div>
    </div>
  );
}
