import { IntegrationBlock } from "./IntegrationBlock";
import telegramIcon from "@dashboard/assets/telegram.svg";

import { Separator } from "@dashboard/common/ui/separator";
import { useRemoveIntegration } from "../-hooks/useRemoveIntegration";
import { TelegramSettingsForm } from "./TelegramSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Telegram Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Enhance your Telegram communication capabilities by connecting your
        account with Octocom. With this integration, you can seamlessly automate
        and manage your Telegram messages to elevate your customer interactions
        and streamline your business processes.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Seamless Connection:</span> Effortlessly
          link your Telegram account to Octocom, allowing you to manage your
          Telegram conversations directly from the Octocom platform.
        </li>
        <li>
          <span className="font-medium">Automated Conversations:</span> Leverage
          the power of the Octocom bot to handle your Telegram chats. The bot
          can automatically respond to messages, resolve common issues, and
          provide 24/7 support, ensuring that your customers receive prompt and
          accurate assistance.
        </li>
        <li>
          <span className="font-medium">Centralized Management:</span> View and
          manage all your Telegram conversations within the Octocom dashboard.
          This centralized approach allows you to track and respond to messages
          without switching between platforms, improving efficiency and reducing
          response times.
        </li>
        <li>
          <span className="font-medium">Helpdesk Integration:</span> Utilize
          Octocom&apos;s helpdesk tools to respond to customer inquiries on
          Telegram. Your support team can reply to messages directly from the
          Octocom dashboard, ensuring that customer issues are resolved quickly
          and effectively.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your Telegram communications
        more efficiently, offering both automation and direct management tools
        to ensure a smooth and responsive customer service experience.
      </p>
    </div>
  );
}

export function TelegramIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  const removeIntegration = useRemoveIntegration();

  return (
    <IntegrationBlock
      integrationName="telegram"
      title="Telegram"
      shortDescription="Automate your Telegram messages with Octocom."
      longDescription={<Description />}
      imageSrc={telegramIcon}
      installed={installed}
      onRemove={() =>
        removeIntegration({
          integrationServiceName: "telegram",
        })
      }
      settingsForm={<TelegramSettingsForm />}
    />
  );
}
