import { z } from "zod";

export const recommendationSettingsFormSchema = z.object({
  collections: z.string().nullish(),
  metafields: z.string().nullish(),
  nameHints: z.array(
    z.object({
      hint: z
        .string({
          required_error: "Empty name hint",
          invalid_type_error: "Empty name hint",
        })
        .min(1, "Empty name hint"),
    }),
  ),
  productPickingHints: z.array(
    z.object({
      hint: z
        .string({
          required_error: "Empty product picking hint",
          invalid_type_error: "Empty product picking hint",
        })
        .min(1, "Empty product picking hint"),
    }),
  ),
  collectionPickingHints: z.array(
    z.object({
      hint: z
        .string({
          required_error: "Empty collection picking hint",
          invalid_type_error: "Empty collection picking hint",
        })
        .min(1, "Empty collection picking hint"),
    }),
  ),
});

export type RecommendationSettingsForm = z.infer<
  typeof recommendationSettingsFormSchema
>;
