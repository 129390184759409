import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { AddressDetailsSection } from "./AddressDetailsSection";
import { EditAddressForm } from "./EditAddressForm";
import { useConversation } from "../../../-hooks/useConversation.hook";

export function ShippingAddressSection({ order }: { order: TrackedOrder }) {
  const { availableOrderServices } = useConversation();
  const updateAvailable = availableOrderServices.updates;

  if (!order.recipient) return null;

  return (
    <AccordionItem
      value="shipping-address"
      className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
    >
      <AccordionTrigger className="py-2 text-xs font-medium">
        Shipping Address
      </AccordionTrigger>
      <AccordionContent className="pb-2 text-xs">
        {updateAvailable && (
          <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
            <EditAddressForm order={order} />
          </div>
        )}
        <AddressDetailsSection
          address={order.recipient.address}
          name={order.recipient.name}
        />
      </AccordionContent>
    </AccordionItem>
  );
}
