import { createFileRoute } from "@tanstack/react-router";
import { SignUpForm } from "./-components/SignUpForm";

export const Route = createFileRoute("/_auth/sign-up/")({
  component: SignUpPage,
});

function SignUpPage() {
  return (
    <>
      <h1 className="pb-8 text-2xl font-medium lg:text-3xl">
        Get started today
      </h1>
      <SignUpForm />
    </>
  );
}
