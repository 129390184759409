import { Languages as LanguagesIcon } from "lucide-react";
import { FilterSelect } from "./FilterSelect";
import { Route } from "../../..";

export function Language({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [languages] =
    trpc.dashboard.conversations.getLanguages.useSuspenseQuery({
      organizationSlug,
    });

  const options = languages.map((language) => ({
    label: language.name,
    value: language.code,
  }));

  return (
    <FilterSelect
      filterName="Language"
      valueIcon={LanguagesIcon}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
