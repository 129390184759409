import { createContext, useContext, useState } from "react";
import { MacroAction } from "@be/modules/macros/macros.types";

interface DraftState {
  current: {
    text: string;
    originalText: string; // used to keep the original text after a translation to allow for undo
    attachments: { fileId: string; filename: string }[];
    actions: MacroAction[];
  };
  newThread: {
    text: string;
    originalText: string; // used to keep the original text after a translation to allow for undo
    attachments: { fileId: string; filename: string }[];
    actions: MacroAction[];
    email: string;
    subject: string;
  };
}

// key is the publicId of the conversation
type DraftMessages = Record<string, DraftState>;

interface DraftMessagesContextValue {
  getDraft: (publicId: string) => DraftState;
  updateCurrentDraft: (
    publicId: string,
    draft: Partial<DraftState["current"]>,
  ) => void;
  updateNewThreadDraft: (
    publicId: string,
    draft: Partial<DraftState["newThread"]>,
  ) => void;
  clearDraft: (publicId: string) => void;
  clearNewThreadDraft: (publicId: string) => void;
}

const DraftMessagesContext = createContext<DraftMessagesContextValue | null>(
  null,
);

const defaultDraftState: DraftState = {
  current: {
    text: "",
    originalText: "",
    attachments: [],
    actions: [],
  },
  newThread: {
    text: "",
    originalText: "",
    attachments: [],
    actions: [],
    email: "",
    subject: "",
  },
};

export function DraftMessagesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [drafts, setDrafts] = useState<DraftMessages>({});

  const initiateDraftState = ({ publicId }: { publicId: string }) => {
    setDrafts((prev) => ({
      ...prev,
      [publicId]: defaultDraftState,
    }));
  };

  const getDraft = (publicId: string): DraftState => {
    const draft = drafts[publicId] as DraftState | undefined;

    if (!draft) {
      initiateDraftState({ publicId });

      return defaultDraftState;
    }

    return draft;
  };

  const updateCurrentDraft = (
    publicId: string,
    draft: Partial<DraftState["current"]>,
  ) => {
    setDrafts((prev) => ({
      ...prev,
      [publicId]: {
        ...prev[publicId],
        current: {
          ...prev[publicId].current,
          ...draft,
        },
      },
    }));
  };

  const updateNewThreadDraft = (
    publicId: string,
    draft: Partial<DraftState["newThread"]>,
  ) => {
    setDrafts((prev) => ({
      ...prev,
      [publicId]: {
        ...prev[publicId],
        newThread: {
          ...prev[publicId].newThread,
          ...draft,
        },
      },
    }));
  };

  const clearDraft = (publicId: string) => {
    setDrafts((prev) => ({
      ...prev,
      [publicId]: {
        ...prev[publicId],
        current: defaultDraftState.current,
      },
    }));
  };

  const clearNewThreadDraft = (publicId: string) => {
    setDrafts((prev) => ({
      ...prev,
      [publicId]: {
        ...prev[publicId],
        newThread: defaultDraftState.newThread,
      },
    }));
  };

  return (
    <DraftMessagesContext.Provider
      value={{
        getDraft,
        updateCurrentDraft,
        updateNewThreadDraft,
        clearDraft,
        clearNewThreadDraft,
      }}
    >
      {children}
    </DraftMessagesContext.Provider>
  );
}

export function useDraftMessages() {
  const context = useContext(DraftMessagesContext);
  if (!context) {
    throw new Error(
      "useDraftMessages must be used within a DraftMessagesProvider",
    );
  }
  return context;
}
