import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import ConnectContainer from "./-components/ConnectContainer";
import { Button } from "@dashboard/common/ui/button";
import { useConnect } from "./-hooks/useConnect";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";

export const Route = createFileRoute("/connect/selection")({
  validateSearch: z.object({
    integrationData: z.unknown(),
    integrationServiceName: integrationServiceNameSchema,
  }),
  component: ConnectSelection,
});

function ConnectSelection() {
  const { trpc } = Route.useRouteContext();
  const { integrationServiceName } = Route.useSearch();

  const [user] = trpc.dashboard.users.getCurrent.useSuspenseQuery();
  const [businesses] = trpc.dashboard.business.getAll.useSuspenseQuery({
    organizationSlug: user.organizations[0].slug,
  });

  const [selectedBusinessSlug, setSelectedBusinessSlug] = useState(
    businesses[0].slug,
  );

  const navigate = useNavigate();

  const connect = useConnect({
    onDataSuccess: () => {
      if (integrationServiceName === "shopify") {
        void navigate({
          to: "/organization/$organizationSlug/conversation",
          params: {
            organizationSlug: user.organizations[0].slug,
          },
          search: {
            tutorial: "shopifySubscription",
            businessSlug: selectedBusinessSlug,
          },
        });
      } else {
        void navigate({ to: "/connect/success" });
      }
    },
  });

  return (
    <ConnectContainer>
      <h1 className="text-2xl font-bold">Connect</h1>
      <p className="text-wrap text-center" style={{ marginTop: "30px" }}>
        Choose a business to connect with{" "}
        {integrationServiceName.charAt(0).toUpperCase() +
          integrationServiceName.slice(1)}
      </p>
      <div style={{ marginTop: "30px", marginBottom: "40px" }}>
        <select
          onChange={(e) => {
            setSelectedBusinessSlug(e.target.value);
          }}
        >
          {businesses.map((b) => (
            <option key={b.id} value={b.slug}>
              {b.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <Button
          onClick={() => {
            connect({ selectedBusinessSlug });
          }}
          className="w-full"
          style={{ height: "40px" }}
        >
          Connect
        </Button>
      </div>
    </ConnectContainer>
  );
}
