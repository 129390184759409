import { z } from "zod";

export const articleFormSchema = z.object({
  title: z
    .string({
      invalid_type_error: "Title is required",
    })
    .min(1, { message: "Title is required" }),
  content: z
    .string({
      invalid_type_error: "Content is required",
    })
    .min(1, { message: "Content is required" }),
  categoryId: z.string().uuid().optional(),
  isPublic: z.boolean(),
});

export type ArticleForm = z.infer<typeof articleFormSchema>;
