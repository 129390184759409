import {
  addHours,
  addMonths,
  format,
  isBefore,
  nextMonday,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfTomorrow,
} from "date-fns";

interface TimeOptionsProps {
  onSelect: (date: Date) => void;
}

export function TimeOptions({ onSelect }: TimeOptionsProps) {
  const now = new Date();

  const dates = {
    evening: addHours(startOfToday(), 18),
    tomorrow: addHours(startOfTomorrow(), 9),
    nextWeek: addHours(startOfDay(nextMonday(now)), 9),
    nextMonth: addHours(startOfMonth(addMonths(now, 1)), 9),
  };

  return (
    <div className="flex flex-col">
      {isBefore(now, dates.evening) && (
        <Option label="This evening" date={dates.evening} onSelect={onSelect} />
      )}
      <Option label="Tomorrow" date={dates.tomorrow} onSelect={onSelect} />
      <Option label="Next week" date={dates.nextWeek} onSelect={onSelect} />
      <Option label="Next month" date={dates.nextMonth} onSelect={onSelect} />
    </div>
  );
}

interface OptionProps {
  label: string;
  date: Date;
  onSelect: (date: Date) => void;
}

function Option({ label, date, onSelect }: OptionProps) {
  const handleOnClick = () => {
    console.log("date", date);
    onSelect(date);
  };

  return (
    <div
      onClick={handleOnClick}
      className="hover:bg-accent flex w-full cursor-pointer items-center justify-between px-3 py-2 text-sm"
    >
      <div className="w-40 font-medium text-gray-700">{label}</div>
      <div className="font-normal text-gray-500">
        {format(date, "EEE, d MMM yyyy, HH:mm")}
      </div>
    </div>
  );
}
