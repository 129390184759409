import { sumProp } from "@dashboard/utils/arrays";
import { useData } from "./useData";
import { toPercent } from "@dashboard/utils/numbers";

export function useHandedOffPercentage() {
  const { cur, prev } = useData();

  const conversationCountCur = sumProp(cur.conversationsPerDay, "count");
  const conversationCountPrev = sumProp(prev.conversationsPerDay, "count");

  const handedOffCountCur = sumProp(cur.handoffs, "count");
  const handedOffCountPrev = sumProp(prev.handoffs, "count");

  console.log("conversationCountCur", conversationCountCur);
  console.log("handoffsCur", handedOffCountCur);

  const handedOffPercentageCur = toPercent(
    conversationCountCur,
    handedOffCountCur,
  );
  const handedOffPercentagePrev = toPercent(
    conversationCountPrev,
    handedOffCountPrev,
  );

  return {
    value: handedOffPercentageCur,
    diff: handedOffPercentageCur - handedOffPercentagePrev,
  };
}
