import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/bigcommerce.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        BigCommerce Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Supercharge your eCommerce operations by integrating your BigCommerce
        store with Octocom. This comprehensive integration allows you to
        effortlessly automate and manage your BigCommerce orders, customer
        interactions, and store analytics, ensuring a more efficient and
        seamless shopping experience for your customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Hassle-Free Integration:</span> Easily
          connect your BigCommerce store to Octocom, enabling you to manage all
          aspects of your eCommerce business directly from the Octocom platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Management:</span>{" "}
          Streamline your order processing by automating critical tasks such as
          order confirmations, shipment tracking, and inventory updates. This
          automation minimizes manual work and improves the accuracy of your
          order fulfillment process.
        </li>
        <li>
          <span className="font-medium">All-in-One Dashboard:</span> Manage and
          monitor all your BigCommerce store activities from a single,
          user-friendly dashboard. Keep track of orders, customer inquiries, and
          store performance metrics without the need to navigate multiple
          platforms.
        </li>
        <li>
          <span className="font-medium">Integrated Customer Support:</span> Take
          advantage of Octocom&apos;s customer support tools to handle inquiries
          related to your BigCommerce store. Respond to questions, resolve
          issues, and manage returns directly from the Octocom dashboard,
          ensuring a smooth and efficient customer service experience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your BigCommerce store more
        efficiently, offering both automation and direct management tools to
        enhance your eCommerce operations and elevate customer satisfaction.
      </p>
    </div>
  );
}

export function BigCommerceIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        integrationName="bigCommerce"
        title="BigCommerce"
        shortDescription="Automate the customer support for your BigCommerce store with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
