import botIcon from "@dashboard/assets/bot.svg";

interface MessageSender {
  sender: "customer" | "bot" | "agent" | "external";
  agentName?: string;
}

export function MessageAvatar({ sender, agentName }: MessageSender) {
  switch (sender) {
    case "customer":
      return <CustomerAvatar />;
    case "bot":
      return <BotAvatar />;
    case "agent":
      return <AgentAvatar name={agentName ?? ""} />;
    case "external":
      return <ExternalAvatar />;
  }
}

function CustomerAvatar() {
  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-[#ec4662]">
      <div className="h-0.5 w-[40%] rounded-full bg-white"></div>
    </div>
  );
}

function BotAvatar() {
  return (
    <img
      alt="logo"
      className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center"
      src={botIcon}
    />
  );
}

function AgentAvatar({ name }: { name: string }) {
  const initials = name
    .split(" ")
    .map((s) => s[0].toUpperCase())
    .join("")
    .slice(0, 2);

  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-black text-xs font-medium text-white">
      {initials}
    </div>
  );
}

function ExternalAvatar() {
  return (
    <div className="relative mt-auto flex h-[23px] w-[23px] shrink-0 items-center justify-center rounded-full bg-[#ec4662]">
      <div className="h-0.5 w-[40%] rounded-full bg-white"></div>
    </div>
  );
}
