import { Route } from "..";
import { toast } from "sonner";

export function useDeleteArticles() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.articles.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.articles.getAll.invalidate({ businessSlug });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Articles deleted successfully."
          : "Article deleted successfully.";

      toast(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to delete articles."
          : "Failed to delete article.";

      toast(message);
      console.error(error);
    },
  });

  return {
    deleteArticles: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids: ids });
    },
  };
}
