import { Route } from "../../onboarding";

export function useOrganization() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [organization] =
    trpc.dashboard.business.getOrganizationOrThrow.useSuspenseQuery({
      organizationSlug,
    });

  return organization;
}
