import { Route } from "..";
import { toast } from "sonner";

import { MacroFormPayload } from "../-utils/macroFormSchema";

export function useEditMacro({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.macros.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.macros.getAvailable.invalidate({
        organizationSlug,
      });

      setOpen(false);
      toast.success("Macro updated");
    },
    onError: () => {
      toast.error("Failed to update macro");
    },
  });

  return {
    onSubmit: (data: MacroFormPayload & { id: string }) => {
      updateMutation.mutate({ organizationSlug, data });
    },
  };
}
