import { toPercent } from "@dashboard/utils/numbers";
import { useData } from "./useData";

export function useTopicHandoffData() {
  const { cur, prev } = useData();

  return cur.topics
    .map((totalDataCur) => {
      const handedOffDataCur = cur.handedOffTopics.find(
        (x) => x.topic === totalDataCur.topic,
      );

      const totalDataPrev = prev.topics.find(
        (x) => x.topic === totalDataCur.topic,
      );

      const handedOffDataPrev = prev.handedOffTopics.find(
        (x) => x.topic === totalDataCur.topic,
      );

      const percentageCur = toPercent(
        totalDataCur.count,
        handedOffDataCur?.count ?? 0,
      );
      const percentagePrev = toPercent(
        totalDataPrev?.count ?? 0,
        handedOffDataPrev?.count ?? 0,
      );

      let topic = totalDataCur.topic;
      if (topic === "unclassified") {
        topic = "informational-queries";
      }

      return {
        topic,
        percentage: percentageCur,
        diff: percentageCur - percentagePrev,
      };
    })
    .sort((a, b) => b.percentage - a.percentage)
    .filter(
      ({ topic }) =>
        !["asked-to-talk-to-a-human", "asked-to-talk-to-a-supervisor"].includes(
          topic,
        ),
    );
}
