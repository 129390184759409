import { toast } from "sonner";
import { Route } from "..";
import { ChannelName } from "@be/modules/conversations/conversations.types";
import { MacroAction } from "@be/modules/macros/macros.types";

export function useSendMessage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const sendMessageMutation =
    trpc.dashboard.conversations.sendAgentMessage.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.conversations.get.invalidate({
          organizationSlug,
          publicId,
        });
      },
      onError: (error) => {
        console.error("Error sending message", error);
        toast.error("Error sending message");
      },
    });

  return ({
    text,
    channel,
    actions,
    fileIds,
  }: {
    text: string;
    channel: ChannelName;
    actions: MacroAction[];
    fileIds: string[];
  }) =>
    sendMessageMutation.mutate({
      organizationSlug,
      publicId,
      text,
      channel,
      actions,
      fileIds,
    });
}
