import { createFileRoute, Link } from "@tanstack/react-router";
import { format } from "date-fns";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/feedback",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.feedback.getAll.ensureData({
      businessSlug,
    });
  },
  component: Feedback,
});

function Feedback() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [feedbacks] = trpc.dashboard.feedback.getAll.useSuspenseQuery({
    businessSlug,
  });

  return (
    <div className="flex flex-col items-center p-4">
      {feedbacks.length === 0 ? (
        <p>No feedback yet</p>
      ) : (
        feedbacks.map((feedback) => (
          <div
            key={feedback.conversationId}
            className="mb-4 w-full max-w-2xl rounded-lg border p-4 shadow-md"
          >
            <p className="text-gray-500">
              {format(new Date(feedback.createdAt), "PPpp")}
            </p>
            <p
              className={
                feedback.correctAction ? "text-green-500" : "text-red-500"
              }
            >
              {feedback.correctAction ? "Correct Action" : "Incorrect Action"}
            </p>
            <p>{feedback.comment ?? "No comment"}</p>
            <p className="text-gray-700">
              Conversation ID: {feedback.conversationId}
            </p>
            <Link
              to={`/organization/$organizationSlug/conversation/${feedback.conversationId}`}
              className="text-blue-500 underline"
            >
              View Conversation
            </Link>
          </div>
        ))
      )}
    </div>
  );
}
