import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { z } from "zod";
import { useState } from "react";
import { isPast } from "date-fns";

interface DatetimeInputProps {
  setMainSection: (section: "calendar" | "time") => void;
  time: string;
  date: string;
  setTime: (time: string) => void;
  setDate: (date: string) => void;
  setCalendarDate: (date: Date) => void;
  onSelect: (date: Date) => void;
  actionLabel: string;
}

export function DatetimeInput({
  setMainSection,
  time,
  date,
  setTime,
  setDate,
  setCalendarDate,
  onSelect,
  actionLabel,
}: DatetimeInputProps) {
  const [error, setError] = useState<string | null>(null);

  const handleDateChange = (date: string) => {
    setError(null);
    setDate(date);
    setCalendarDate(new Date(date));
  };

  const handleTimeChange = (time: string) => {
    setError(null);
    setTime(time);
  };

  const handleSave = () => {
    const datetime = new Date(`${date} ${time}`);
    const result = z.date().safeParse(datetime);
    if (result.success) {
      if (isPast(result.data)) {
        setError("Date must be in the future");
      } else {
        onSelect(result.data);
      }
    } else {
      setError("Invalid input");
    }
  };

  return (
    <div className="text-gray flex flex-col gap-2 px-3 py-3">
      <div className="flex items-center justify-between gap-4">
        <div className="flex w-32 flex-col gap-2">
          <span className="text-sm font-medium">Date</span>
          <Input
            type="text"
            value={date}
            onChange={(e) => handleDateChange(e.target.value)}
            onFocus={() => setMainSection("calendar")}
            className="rounded-lg text-sm font-medium"
          />
        </div>
        <div className="flex w-32 flex-col gap-2">
          <span className="text-sm font-medium">Time</span>
          <Input
            type="text"
            value={time}
            onChange={(e) => handleTimeChange(e.target.value)}
            onFocus={() => setMainSection("time")}
            className="rounded-lg text-sm font-medium"
          />
        </div>
      </div>
      {error && <span className="text-sm text-red-500">{error}</span>}
      <div className="flex items-center justify-center">
        <Button
          onClick={handleSave}
          type="button"
          variant="default"
          className="w-full rounded-lg"
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
}
