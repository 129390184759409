import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { TrendIndicator } from "./TrendIndicator/TrendIndicator";

const COLORS = ["#0056B3", "#007FFF", "#33CCFF", "#ADD8E6"];

interface CustomTooltipPieProps {
  active?: boolean;
  payload?: {
    payload: {
      x: string;
      y: number | string; // Depends on your data structure
    };
  }[];
}

const CustomTooltipPie: React.FC<CustomTooltipPieProps> = ({
  active,
  payload,
}) => {
  if (active && payload?.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        <p className="font-medium text-blue-500">{data.x}</p>
        <p className="text-gray-700">{data.y}</p>
      </div>
    );
  }
  return null;
};

interface ChartProps {
  trendingIndicator?: {
    value: number;
    higherIsBetter: boolean;
    comparedTo: {
      days: number;
      from: string;
      to: string;
    };
  };
  data: {
    x: string;
    y: number;
  }[];
}

export const ChartPie: React.FC<ChartProps> = ({ data, trendingIndicator }) => {
  return (
    <div className="w-full">
      <div className="mt-4 flex flex-row items-center">
        {trendingIndicator && (
          <TrendIndicator showPreviousDays={true} {...trendingIndicator} />
        )}
      </div>
      <div className="h-[280px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            width={500}
            height={500}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Pie
              data={data}
              cx={250} // Half of the width
              cy={150} // Half of the height
              outerRadius={100}
              innerRadius={70}
              label={true}
              paddingAngle={0} // Ensures that there are no gaps
              fill="#8884d8"
              dataKey="y"
            >
              {data.map((_entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  strokeWidth={0} // increase this as needed
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltipPie />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
