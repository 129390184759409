import { createFileRoute } from "@tanstack/react-router";
import { MetafieldLink } from "./-components/MetafieldLink";
import { Table } from "@dashboard/common/Table/Table";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/metafields/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.metafields.getAllKeys.ensureData({
      businessSlug,
    });
  },
  component: MetafieldListPage,
});

export function MetafieldListPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [metafields] = trpc.dashboard.metafields.getAllKeys.useSuspenseQuery({
    businessSlug,
  });

  const records = metafields.map((metafield) => ({
    id: metafield.key,
    key: metafield.key,
    count: metafield.count,
    link: MetafieldLink({
      organizationSlug,
      businessSlug,
      metafieldKey: metafield.key,
    }),
  }));

  return (
    <div className="flex h-screen flex-col">
      <Table
        title="Metafield Keys"
        records={records}
        columns={[
          {
            header: "Key",
            accessor: "key",
          },
          {
            header: "Product Count",
            accessor: "count",
          },
          {
            header: "Details",
            accessor: "link",
          },
        ]}
        recordName={{ singular: "Metafield Key", plural: "Metafield Keys" }}
        noRecordsText="No metafield keys available"
      />
    </div>
  );
}
