import { Route } from "../_conversationList";

import { Select } from "@dashboard/common/ui/select";

export function ConversationsFilters() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const { organizationSlug } = Route.useParams();
  const { trpc, hasExternalTicketConfig } = Route.useRouteContext();

  const { view, filters, status, sortBy, searchQuery } = search;

  const [total] = trpc.dashboard.conversations.getCount.useSuspenseQuery({
    organizationSlug,
    view,
    filters,
    status,
    searchQuery,
  });

  const setSearch = (key: "status" | "sortBy", value: string | undefined) => {
    if (key === "status") {
      if (value === "all") {
        value = undefined;
      }

      void navigate({
        to: "/organization/$organizationSlug/conversation",
        params: { organizationSlug },
        search: {
          ...search,
          status: value as "open" | "closed" | "snoozed" | undefined,
        },
      });
    } else {
      void navigate({
        search: { ...search, sortBy: value as "newest" | "oldest" },
      });
    }
  };

  const statusOptions = [
    {
      label: "Open",
      value: "open",
    },
    {
      label: "Closed",
      value: "closed",
    },
    {
      label: "Snoozed",
      value: "snoozed",
    },
  ];

  let defaultStatus = "open";

  if (view === "bot" || view === "search") {
    statusOptions.push({
      label: "All",
      value: "all",
    });
    defaultStatus = "all";
  }

  if (hasExternalTicketConfig && view === "handed-off") {
    defaultStatus = "closed";
  }

  return (
    <div className="flex w-full shrink-0 flex-row justify-between px-[16px] py-2">
      <Select
        variant="dropdown"
        value={status ?? defaultStatus}
        defaultValue={defaultStatus}
        onChange={(value) => setSearch("status", value)}
        renderValue={(value) => (
          <>
            <span className="mr-1">{total}</span>
            {value}
          </>
        )}
        options={statusOptions}
      />

      <Select
        variant="dropdown"
        value={sortBy ?? "newest"}
        defaultValue="newest"
        onChange={(value) => setSearch("sortBy", value)}
        groups={[
          {
            title: "Sort by",
            options: [
              {
                label: "Newest",
                value: "newest",
              },
              {
                label: "Oldest",
                value: "oldest",
              },
            ],
          },
        ]}
      />
    </div>
  );
}
