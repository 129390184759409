import {
  addDays,
  differenceInDays,
  format,
  startOfDay,
  subDays,
} from "date-fns";
import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";
import { sumProp } from "@dashboard/utils/arrays";

export function useAssignedConversationsChartData() {
  const {
    period,
    businessSlug,
    assignedAssignee: assignee,
  } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const range = periodToRange(period);
  const days = differenceInDays(range.to, range.from);

  const [cur] =
    trpc.dashboard.metrics.getAssignedConversationCounts.useSuspenseQuery({
      organizationSlug,
      businessSlug,
      assignee,
      from: range.from,
      to: range.to,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  const prevRange = {
    from: subDays(range.from, days),
    to: range.from,
  };

  const [prev] =
    trpc.dashboard.metrics.getAssignedConversationCounts.useSuspenseQuery({
      organizationSlug,
      businessSlug,
      assignee,
      from: prevRange.from,
      to: prevRange.to,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  let dataIndex = 0;
  const assignedConversationsPerDay = Array.from({ length: days }, (_, i) => {
    const dataTime =
      dataIndex < cur.length
        ? startOfDay(new Date(cur[dataIndex].date)).getTime()
        : undefined;
    const targetDate = startOfDay(addDays(range.from, i));

    let count = 0;
    if (targetDate.getTime() === dataTime) {
      count = cur[dataIndex].count;
      dataIndex += 1;
    }

    return {
      x: format(targetDate, "PPp"),
      y: count,
    };
  });

  const assignedConversationCountCur = sumProp(cur, "count");
  const assignedConversationCountPrev = sumProp(prev, "count");

  return {
    count: assignedConversationCountCur,
    countDiff: assignedConversationCountCur - assignedConversationCountPrev,
    perDay: assignedConversationsPerDay,
  };
}
