import { TrackedOrder } from "@be/modules/orders/orders.types";
import { formatCurrency } from "@dashboard/utils/currency";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@dashboard/common/ui/hover-card";
import { Separator } from "@dashboard/common/ui/separator";
import { HelpCircle } from "lucide-react"; // Import the HelpCircle icon from Lucide

interface OrderCostHoverCardProps {
  order: TrackedOrder;
}

export function OrderCostHoverCard({ order }: OrderCostHoverCardProps) {
  const totalCost = order.totalCost
    ? formatCurrency({
        amount: parseFloat(order.totalCost.amount),
        currency: order.totalCost.currencyCode,
      })
    : "N/A";

  return (
    <HoverCard openDelay={100}>
      <div className="flex items-center gap-1">
        <span>{totalCost}</span>
        <HoverCardTrigger asChild>
          <div className="hover:bg-accent cursor-pointer rounded-md p-1">
            <HelpCircle className="h-4 w-4" strokeWidth={1.5} />
          </div>
        </HoverCardTrigger>
      </div>
      <HoverCardContent>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span>Subtotal:</span>
            <span>
              {order.subtotalCost
                ? formatCurrency({
                    amount: parseFloat(order.subtotalCost.amount),
                    currency: order.subtotalCost.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Discounts:</span>
            <span>
              -
              {order.discounts
                ? formatCurrency({
                    amount: parseFloat(order.discounts.amount),
                    currency: order.discounts.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Taxes:</span>
            <span>
              {order.taxes
                ? formatCurrency({
                    amount: parseFloat(order.taxes.amount),
                    currency: order.taxes.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Shipping:</span>
            <span>
              {order.shippingCost
                ? formatCurrency({
                    amount: parseFloat(order.shippingCost.amount),
                    currency: order.shippingCost.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <Separator />
          <div className="flex justify-between font-semibold">
            <span>Total cost:</span>
            <span>{totalCost}</span>
          </div>
          <div className="flex justify-between">
            <span>Total paid:</span>
            <span>
              {order.totalReceived
                ? formatCurrency({
                    amount: parseFloat(order.totalReceived.amount),
                    currency: order.totalReceived.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Total refunded:</span>
            <span>
              -
              {order.totalRefunded
                ? formatCurrency({
                    amount: parseFloat(order.totalRefunded.amount),
                    currency: order.totalRefunded.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
          <Separator />
          <div className="flex justify-between font-semibold">
            <span>Net payment:</span>
            <span>
              {order.netPayment
                ? formatCurrency({
                    amount: parseFloat(order.netPayment.amount),
                    currency: order.netPayment.currencyCode,
                  })
                : "N/A"}
            </span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
