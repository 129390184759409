import { TagIcon } from "@heroicons/react/24/outline";
import { FilterSelect } from "./FilterSelect";
import { Route } from "../../..";
export function Tag({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  const options = tags.map((tag) => ({
    label: tag.title,
    value: tag.id,
  }));

  return (
    <FilterSelect
      filterName="Tag"
      valueIcon={TagIcon}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
