import { Variable } from "lucide-react";

export function MacroVariable({ content }: { content: string }) {
  return (
    <div className="inline-flex items-center justify-between gap-1 rounded bg-gray-200 px-2 py-0.5">
      <Variable className="h-4 w-4" />
      <span>{content}</span>
    </div>
  );
}
