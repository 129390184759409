import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { CreateMacroForm } from "./CreateMacroForm";

export function CreateMacroDialogContent({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Create a new Macro</DialogTitle>
      </DialogHeader>
      <CreateMacroForm setOpen={setOpen} />
    </>
  );
}
