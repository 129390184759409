import { trpc } from "@chatbot/utils/trpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { wsLink, createWSClient } from "@trpc/client/links/wsLink";
import { env } from "@chatbot/env";
import { useEffect, useState } from "react";
import { useResetCount } from "./hooks/reset.hooks";
import { BrowserSessionProvider } from "./components/BrowserSessionProvider";
import CustomErrorBoundary from "./components/CustomErrorBoundary";

export function App({ presetConfigId }: { presetConfigId?: string }) {
  const resetCount = useResetCount();

  const [queryClient, setQueryClient] = useState(new QueryClient());
  const [trpcClient, setTrpcClient] = useState(
    trpc.createClient({
      links: [
        wsLink({
          client: createWSClient({
            url: env.CHATBOT_TRPC_WS_URL,
          }),
        }),
      ],
    }),
  );

  useEffect(() => {
    setQueryClient(new QueryClient());
    setTrpcClient(
      trpc.createClient({
        links: [
          wsLink({
            client: createWSClient({
              url: env.CHATBOT_TRPC_WS_URL,
            }),
          }),
        ],
      }),
    );
  }, [resetCount]);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <CustomErrorBoundary>
          <BrowserSessionProvider presetConfigId={presetConfigId} />
        </CustomErrorBoundary>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
