import { ExternalTicketsSystemName } from "@be/modules/externalTickets/externalTickets.types";

export const systemLabels: Record<ExternalTicketsSystemName | "none", string> =
  {
    none: "None",
    email: "Email",
    zendesk: "Zendesk",
    gorgias: "Gorgias",
    hubspot: "HubSpot",
    richpanel: "Richpanel",
    console: "Console",
    dixa: "Dixa",
    freshdesk: "Freshdesk",
  };
