import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { useRouter } from "@tanstack/react-router";
import { Route } from "..";
import { toast } from "sonner";

const formSchema = z.object({
  file: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload a file",
    })
    .transform((value) => value as File),
});

type Form = z.infer<typeof formSchema>;

export function UplodDocumentForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<Form>({
    resolver: zodResolver(formSchema),
  });

  const { onSubmit: onSubmitMutation } = useUploadDocument({ setOpen });

  const onSubmit = (data: Form) => {
    onSubmitMutation(data);

    form.reset();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="file"
          render={({ field: { onChange, ...fieldProps } }) => (
            <FormItem>
              <FormLabel>Document</FormLabel>
              <FormControl>
                {/* https://github.com/shadcn-ui/ui/discussions/2137 */}
                <Input
                  {...fieldProps}
                  value={undefined}
                  type="file"
                  placeholder="Picture"
                  className="text-base text-black"
                  accept=".docx,.csv,.txt,.md"
                  onChange={(e) => onChange(e.target.files?.[0])}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export const useUploadDocument = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const router = useRouter();
  const { trpc, business } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug, organizationSlug } = Route.useParams();

  const businessId = business.id;

  const fileUploadMutation =
    trpc.dashboard.documents.getFileUploadUrl.useMutation();

  const creationMutation = trpc.dashboard.documents.create.useMutation();

  return {
    onSubmit: (data: Form) => {
      const file = data.file;
      setOpen(false);
      void fileUploadMutation
        .mutateAsync({
          filename: file.name,
          contentType: file.type,
          businessId,
          organizationSlug,
        })
        .then(async ({ id: fileId, presignedUrl }) => {
          const r = await fetch(presignedUrl, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
              "x-ms-blob-type": "BlockBlob",
              "x-ms-blob-content-disposition": `attachment; filename="${file.name}"`,
            },
          });

          if (!r.ok) {
            throw new Error("Failed to upload file");
          }

          return fileId;
        })
        .then(async (fileId) => {
          return await creationMutation.mutateAsync({
            businessSlug,
            fileId,
          });
        })
        .then(async () => {
          await trpcUtils.dashboard.documents.getAll.invalidate({
            businessSlug,
          });
          await router.invalidate();

          toast.success("Document added");
        })
        .catch(() => {
          toast.error("Failed to add document");
        });
    },
  };
};
