import { GiftCard } from "@be/modules/giftCards/giftCards.types";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import { ItemDetailSection } from "../Orders/OrderDetailSection";
import { format } from "date-fns";

export function GiftCardDetails({
  giftCard,
  index,
}: {
  giftCard: GiftCard;
  index: number;
}) {
  return (
    <AccordionItem
      value={giftCard.externalId}
      className="data-[state=open]:bg-offWhite rounded-xl border-none !shadow-none"
    >
      <AccordionTrigger className="hover:bg-offWhite flex rounded-xl px-3 py-2 text-left">
        <span className="text-xs font-medium">Gift card #{index + 1}</span>
      </AccordionTrigger>
      <AccordionContent className="px-3 pb-1 pt-1 text-xs">
        <div>
          <ItemDetailSection
            details={[
              {
                label: "Amount:",
                value: `$${giftCard.amount}`,
              },
              {
                label: giftCard.expiresOn ? "Expires on:" : "Expires:",
                value: giftCard.expiresOn
                  ? format(giftCard.expiresOn, "MM/dd/yyyy")
                  : "Never",
              },
            ]}
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
