import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/",
)({
  loader: ({ context: { businesses }, params: { organizationSlug } }) => {
    if (businesses.length > 0) {
      throw redirect({
        to: "/organization/$organizationSlug/settings/$businessSlug/business",
        params: { organizationSlug, businessSlug: businesses[0].slug },
      });
    }
  },
  component: () => <h1>There are no businesses assigned to this account.</h1>,
});
