import { Switch } from "@dashboard/common/ui/switch";
import { useUpdateVisibility } from "../-hooks/useUpdateVisibility";
import { useEffect, useState } from "react";

interface ArticleVisibilityProps {
  articleId: string;
  isPublic: boolean;
}

export function ArticleVisibility({
  articleId,
  isPublic,
}: ArticleVisibilityProps) {
  const [isVisible, setIsVisible] = useState(isPublic);

  const { updateVisibility } = useUpdateVisibility();

  const handleToggle = async (checked: boolean) => {
    await updateVisibility({ id: articleId, isPublic: checked });
    setIsVisible(checked);
  };

  useEffect(() => {
    setIsVisible(isPublic);
  }, [isPublic]);

  return (
    <div className="flex flex-row items-center justify-center">
      <Switch
        checked={isVisible}
        onCheckedChange={(value) => void handleToggle(value)}
      />
      <span className="ml-2">{isVisible ? "Public" : "Private"}</span>
    </div>
  );
}
