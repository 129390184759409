import { Select } from "@dashboard/common/ui/select";
import { cn } from "@dashboard/utils/ui";
import { ElementType, useState } from "react";

export function FilterSelect({
  filterName,
  valueIcon,
  options,
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  filterName: string;
  valueIcon?: ElementType;
  options: { label: string; value: string }[];
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const [value, setValue] = useState(data ?? "");

  const handleSelectValue = (data: string) => {
    setValue(data);
    setFormValue(data);
    clearErrors();
  };

  const errorClass = error
    ? "bg-red-300 group-hover:bg-red-200 group-hover:text-red-700 data-[state=open]:bg-red-200 data-[state=open]:text-red-700"
    : "";

  function Value() {
    const valueClass = error
      ? "text-red-600 group-hover:text-red-600 data-[state=open]:text-red-600"
      : "";

    const label = options.find((option) => option.value === value)?.label;

    return (
      <div className="font-medium">
        {filterName}:{" "}
        <span className={valueClass}>
          {label ? label : error ? "Value is Missing*" : "Choose Value"}
        </span>
      </div>
    );
  }

  return (
    <>
      <Select
        value={value}
        variant="no-icon"
        className={cn(
          "group-hover:text-blue group-hover:bg-blue-light bg-gray-light gap-2 rounded-lg",
          errorClass,
        )}
        valueIcon={valueIcon}
        defaultValue={""}
        placeholder={filterName}
        onChange={(value) => handleSelectValue(value)}
        options={options}
        renderValue={() => <Value />}
      />
    </>
  );
}
