import { z } from "zod";

export const telegramFormSchema = z.object({
  botToken: z
    .string({
      required_error: "Bot token is required",
      invalid_type_error: "Bot token is required",
    })
    .min(1, "Bot token is required"),
});

export type TelegramForm = z.infer<typeof telegramFormSchema>;
