import { Link } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginFormSchema, loginFormSchema } from "../-utils/loginFormSchema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { useLogin } from "../-hooks/useLogin";

export function LoginForm() {
  const form = useForm<LoginFormSchema>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const setCustomError = (error: string) => {
    form.setError("root", { message: error });
  };

  const { login } = useLogin({ setError: setCustomError });

  async function onSubmit(values: LoginFormSchema) {
    await login(values);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="flex w-full max-w-xl flex-col gap-2"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  placeholder="name@example.com"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  className="h-11 py-2"
                  type="password"
                  placeholder="Enter your password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="mt-4 h-11 bg-[#041627] text-white hover:bg-gray-800"
        >
          Log in
        </Button>
      </form>

      {form.formState.errors.root && (
        <div className="mt-4 text-center text-sm text-red-500">
          {form.formState.errors.root.message}
        </div>
      )}

      <div className="mt-8 text-center text-sm">
        Don&apos;t have an account?{" "}
        <Link
          to="/sign-up"
          className="text-primary font-medium hover:underline"
        >
          Sign up
        </Link>
      </div>
    </Form>
  );
}
