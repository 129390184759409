import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/outlook.svg";
import { Separator } from "@dashboard/common/ui/separator";
import { env } from "@dashboard/env";
import { Route } from "..";
import { Button } from "@dashboard/common/ui/button";

export function OutlookIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  const { business } = Route.useRouteContext();

  const installUrl = new URL(
    `${env.DASHBOARD_BASE_API_URL}/microsoft/email/install`,
  );
  const consentUrl = new URL(
    `${env.DASHBOARD_BASE_API_URL}/microsoft/email/admin-consent`,
  );

  const params = new URLSearchParams({
    businessId: business.id,
  });

  installUrl.search = params.toString();
  consentUrl.search = params.toString();

  function Description() {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-semibold">
          Microsoft Outlook Integration with Octocom
        </h2>

        <div className="bg-accent flex flex-col gap-4 rounded-lg p-4">
          <p className="text-justify text-gray-800">
            <span className="font-medium">Note:</span> Some Outlook accounts may
            require admin consent before installing this integration. If you
            encounter an error requesting admin consent, please use this link
            with an admin account. Afterward, you can proceed with installing
            the integration.
          </p>

          <Button type="button" variant="publish" className="w-full">
            <a href={consentUrl.toString()} target="_blank" rel="noreferrer">
              Grant Admin Consent
            </a>
          </Button>
        </div>

        <p className="text-justify text-gray-800">
          Elevate your customer support experience by integrating Microsoft
          Outlook with Octocom.
        </p>
        <h3 className="text-xl font-semibold">Key Features:</h3>
        <ul className="space-y-2 text-justify text-gray-800">
          <li>
            <span className="font-medium">Seamless Integration:</span>{" "}
            Effortlessly connect your Microsoft Outlook account to Octocom,
            enabling a unified platform for managing your customer emails and
            interactions.
          </li>
          <li>
            <span className="font-medium">Automated Workflow:</span> Leverage
            Octocom&apos;s automation tools to streamline the handling of your
            Outlook emails, reducing manual effort and improving efficiency.
          </li>
          <li>
            <span className="font-medium">Centralized Support Dashboard:</span>{" "}
            Access and manage all your Outlook emails directly from the Octocom
            dashboard. This central view allows your team to address customer
            inquiries across multiple channels without the need to switch
            platforms, optimizing your support processes.
          </li>
          <li>
            <span className="font-medium">Improved Customer Engagement:</span>{" "}
            Utilize Octocom to deliver personalized, timely, and relevant
            responses through Outlook. Enhance customer satisfaction by ensuring
            that each interaction is managed effectively and with care.
          </li>
        </ul>
        <Separator />
        <p className="text-justify">
          This integration empowers your support team to manage Outlook Emails
          more effectively, combining automation and centralized management
          tools to improve your customer service operations and achieve better
          outcomes.
        </p>
      </div>
    );
  }

  return (
    <IntegrationBlock
      integrationName="outlook"
      title="Outlook"
      shortDescription="Send and receive Emails from your Outlook account."
      longDescription={<Description />}
      imageSrc={icon}
      installed={installed}
      customButton={
        <Button type="button" variant="publish" className="w-40">
          <a href={installUrl.toString()} target="_blank" rel="noreferrer">
            Install
          </a>
        </Button>
      }
    />
  );
}
