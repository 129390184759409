import { format } from "date-fns";
import { Languages } from "lucide-react";
import { Toggle } from "@dashboard/common/ui/toggle";
import { usePreviewTranslation } from "../../-hooks/usePreviewTranslation";
import { useConversation } from "../../-hooks/useConversation.hook";

type ConversationPreview = ReturnType<
  typeof useConversation
>["relatedConversations"][number];

interface ConversationPreviewTopBarProps {
  createdAt: string;
  useTranslation: boolean;
  setUseTranslation: (useTranslation: boolean) => void;
  showTranslation: boolean;
  conversation: ConversationPreview;
  mergingEnabled: boolean;
  mergeButton?: React.ReactNode;
}

export function ConversationPreviewTopBar({
  createdAt,
  useTranslation,
  setUseTranslation,
  showTranslation,
  conversation,
  mergingEnabled,
  mergeButton,
}: ConversationPreviewTopBarProps) {
  const { translate } = usePreviewTranslation({
    setUseTranslation,
    messages: conversation.parts.filter(
      (p): p is Extract<typeof p, { type: "message" }> => p.type === "message",
    ),
  });

  const handleTranslation = (enabled: boolean) => {
    if (enabled) {
      translate(conversation.publicId);
    }
    setUseTranslation(enabled);
  };

  return (
    <div className="flex items-center justify-between border-b px-4 py-2">
      <span className="text-sm font-medium">
        Started {format(new Date(createdAt), "PPpp")}
      </span>
      <div className="flex items-center gap-2">
        {showTranslation && (
          <Toggle
            pressed={useTranslation}
            onPressedChange={handleTranslation}
            aria-label="Toggle translation"
            variant="outline"
          >
            <Languages
              height={18}
              width={18}
              color={useTranslation ? "#3b82f6" : "black"}
              className={!useTranslation ? "opacity-80" : undefined}
            />
          </Toggle>
        )}
        {mergingEnabled && mergeButton}
      </div>
    </div>
  );
}
