import { Route } from "../_conversationList";
import { useRemoveConversation } from "./useRemoveConversation";

export function useHandleDeleteEvent({
  pageParams,
}: {
  pageParams: (string | null)[];
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const removeConversation = useRemoveConversation({
    pageParams,
  });

  return async (data: { conversationId: string }) => {
    removeConversation({ conversationId: data.conversationId });

    await trpcUtils.dashboard.conversations.getCount.invalidate({
      organizationSlug,
    });
  };
}
