import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";

interface BotRuleDialogProps {
  title: string;
  rule: string;
}

export function BotRuleDialog({ title, rule }: BotRuleDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="pl-0" variant="link">
          {title}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="mx-auto">{title}</DialogTitle>
        </DialogHeader>
        <MarkdownContent content={rule} />
      </DialogContent>
    </Dialog>
  );
}
