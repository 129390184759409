/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export function recordGtmEvent({
  eventAction,
}: {
  eventAction: "Open" | "Close" | "Send Message";
}) {
  console.log("recordGtmEvent", eventAction);
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: "UserAction",
    eventCategory: "Octocom Chat Widget",
    eventAction,
  });
}
