import { Route } from "..";
import { toast } from "sonner";

export function useCreateTag() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const createMutation = trpc.dashboard.conversations.tags.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.tags.getConversationTags.invalidate(
        {
          organizationSlug,
          publicId,
        },
      );

      await trpcUtils.dashboard.conversations.tags.getOrganizationTags.invalidate(
        {
          organizationSlug,
        },
      );
    },

    onError: (error) => {
      console.error(error);
      toast.error("Failed to create tag");
    },
  });

  return (title: string) => {
    createMutation.mutate({
      organizationSlug,
      publicId,
      title,
    });
  };
}
