import { Route } from "../_navbar";

export function useSetAgentAsUnavailable() {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const setAsUnavailableMutation =
    trpc.dashboard.users.setAgentAsUnavailable.useMutation();

  return () => {
    if (user.isManager) return;

    setAsUnavailableMutation.mutate({ organizationSlug });
  };
}
