import { toast } from "sonner";
import { Route } from "..";
import { MacroAction } from "@be/modules/macros/macros.types";

export function useCreateConversations() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const navigate = Route.useNavigate();

  const createMutation = trpc.dashboard.conversations.create.useMutation({
    onSuccess: (data, args) => {
      if (args.customerEmails.length < 2) {
        toast.success("Conversation created");
      } else {
        toast.success("Conversations created");
      }

      void navigate({
        to: "/organization/$organizationSlug/conversation/$publicId",
        params: { organizationSlug, publicId: data.publicId },
        search: { view: "your-inbox" },
      });
    },
    onError: (error, args) => {
      if (args.customerEmails.length < 2) {
        toast.error("Failed to create conversation");
      } else {
        toast.error("Failed to create conversations");
      }
      console.error(error);
    },
  });

  return ({
    businessId,
    customerEmails,
    text,
    subject,
    actions,
    fileIds,
  }: {
    businessId: string;
    customerEmails: string[];
    text: string;
    subject: string;
    actions: MacroAction[];
    fileIds: string[];
  }) => {
    createMutation.mutate({
      organizationSlug,
      businessId,
      customerEmails,
      text,
      subject,
      actions,
      fileIds,
    });
  };
}
