import { Route } from "..";
import { LoginFormSchema } from "../-utils/loginFormSchema";

export function useLogin({ setError }: { setError: (error: string) => void }) {
  const { trpc } = Route.useRouteContext();

  const loginMutation = trpc.dashboard.auth.login.useMutation({
    onSuccess: (data) => {
      if (data.success) {
        const redirectPath =
          sessionStorage.getItem("redirectAfterLogin") ?? "/organization";
        sessionStorage.removeItem("redirectAfterLogin");
        window.location.href = redirectPath;
      } else {
        setError(data.error);
      }
    },
    onError: () => {
      setError("Failed to login. Please try again.");
    },
  });

  return {
    login: async (values: LoginFormSchema) => {
      await loginMutation.mutateAsync(values);
    },
  };
}
