import { Table } from "@dashboard/common/Table/Table";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/collections",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.collections.getAll.ensureData({
      businessSlug,
    });
  },
  component: CollectionListPage,
});

function CollectionListPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [collections] = trpc.dashboard.collections.getAll.useSuspenseQuery({
    businessSlug,
  });

  const records = collections.map((collection) => ({
    id: collection.id,
    name: collection.name,
    slug: collection.slug,
    url: collection.url ?? "N/A",
    productCount: collection.products.length,
  }));

  return (
    <div className="flex h-screen flex-col">
      <Table
        title="Collections"
        records={records}
        columns={[
          {
            header: "Name",
            accessor: "name",
          },
          {
            header: "Slug",
            accessor: "slug",
          },
          {
            header: "URL",
            accessor: "url",
          },
          {
            header: "Product Count",
            accessor: "productCount",
          },
        ]}
        recordName={{ singular: "Collection", plural: "Collections" }}
        noRecordsText="No collections available"
      />
    </div>
  );
}
