import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LauncherProps } from "./Launcher";

export default function IconLauncher(props: LauncherProps) {
  return (
    <>
      {props.launcherIconSrc ? (
        <img
          style={{
            height: props.launcherIconSize,
            width: props.launcherIconSize,
          }}
          className="rounded"
          src={props.launcherIconSrc}
        />
      ) : props.isChatWindowOpen ? (
        <ChevronDownIcon
          style={{
            color: props.launcherIconColor,
            width: props.launcherIconSize,
            height: props.launcherIconSize,
          }}
        />
      ) : (
        <ChatBubbleOvalLeftIcon
          style={{
            color: props.launcherIconColor,
            width: props.launcherIconSize,
            height: props.launcherIconSize,
          }}
        />
      )}
    </>
  );
}
