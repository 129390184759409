import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@dashboard/common/ui/dialog";

import { useState } from "react";
import { CreateUserForm } from "./CreateUserForm";

export function AddUserDialogContent() {
  const [password, setPassword] = useState<string>("");

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          {password === ""
            ? "Add a new team member"
            : "Member added successfully!"}
        </DialogTitle>
      </DialogHeader>
      <DialogDescription>
        {password === ""
          ? "Enter the details of the user you want to add to your team."
          : "Here is the password, make sure to save it."}
      </DialogDescription>
      {password === "" ? (
        <CreateUserForm setPassword={setPassword} />
      ) : (
        <p className="w-full rounded-md border-2 border-black bg-gray-200 p-2 text-center">
          {password}
        </p>
      )}
    </>
  );
}
