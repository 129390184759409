import { Route } from "..";
import { toast } from "sonner";
import { ViewFormPayload } from "../-utils/viewFormSchema";

export const useCreateView = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.views.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.views.getAll.invalidate({
        organizationSlug,
      });

      setOpen(false);
      toast.success("View created");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to create view");
    },
  });

  return {
    onSubmit: (data: ViewFormPayload) => {
      creationMutation.mutate({ organizationSlug, ...data });
    },
  };
};
