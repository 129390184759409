import { User } from "lucide-react";
import { FilterSelect } from "./FilterSelect";
import { Route } from "../../..";

export function Assignee({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const options = [
    { label: "Anyone", value: "anyone" },
    ...users.map((user) => ({ label: user.fullName, value: user.id })),
    { label: "Unassigned", value: "unassigned" },
  ];

  return (
    <FilterSelect
      filterName="Assignee"
      valueIcon={User}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
