import { useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { KeyboardEvent } from "react";

export function ImageLauncher() {
  const config = useConfig();
  const { displayChatWindow, openChatWindow, closeChatWindow } = useUiState();

  const imageFileUrl = config.launcherImageFileUrl;

  if (!imageFileUrl) {
    throw new Error("Image file URL is missing");
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (displayChatWindow) {
        closeChatWindow();
      } else {
        openChatWindow();
      }
    }
  };

  return (
    <div
      // aria-label={displayChatWindow ? "Close chat widget" : "Open chat widget"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={() => (displayChatWindow ? closeChatWindow() : openChatWindow())}
      className="cursor-pointer hover:scale-105"
      style={{
        height: config.launcherSize ?? "48px",
      }}
    >
      <img src={imageFileUrl} className="h-full rounded" />
    </div>
  );
}
