import { IntegrationBlock } from "./IntegrationBlock";
import gorgiasIcon from "@dashboard/assets/gorgias.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Gorgias Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Supercharge your customer support efforts by integrating Gorgias with
        Octocom. This seamless integration allows you to automate and manage
        your Gorgias support tickets, ensuring that your customer service team
        can deliver timely and effective responses across multiple channels.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Smooth Integration:</span> Effortlessly
          link your Gorgias account to Octocom, enabling centralized management
          of your support tickets and customer interactions from a single
          platform.
        </li>
        <li>
          <span className="font-medium">Automated Ticket Handling:</span>{" "}
          Utilize Octocom&apos;s automation capabilities to manage your Gorgias
          support tickets more efficiently. Automatically categorize,
          prioritize, and assign tickets, ensuring that customer inquiries are
          resolved quickly and accurately.
        </li>
        <li>
          <span className="font-medium">Unified Support Dashboard:</span> Manage
          all your Gorgias tickets within the Octocom dashboard. This unified
          view allows your team to respond to customer inquiries across
          different channels without switching between platforms, streamlining
          your support operations.
        </li>
        <li>
          <span className="font-medium">Enhanced Customer Communication:</span>{" "}
          Leverage the power of Octocom to provide personalized responses and
          proactive support through Gorgias. Improve customer satisfaction by
          ensuring that every interaction is timely, relevant, and handled with
          care.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to manage Gorgias tickets
        more efficiently, combining automation and centralized management tools
        to enhance your customer service operations and drive better outcomes.
      </p>
    </div>
  );
}

export function GorgiasIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        integrationName="gorgias"
        title="Gorgias"
        shortDescription="Pass unhandled customer support queries to Gorgias."
        longDescription={<Description />}
        imageSrc={gorgiasIcon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
