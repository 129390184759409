import { toast } from "sonner";
import { Route } from "..";

export function useFileUpload() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const fileUploadMutation = trpc.dashboard.files.getUploadUrl.useMutation();

  async function uploadFile({ file }: { file: File }) {
    const { id, presignedUrl } = await fileUploadMutation.mutateAsync({
      businessSlug,
      filename: file.name,
      contentType: file.type,
    });

    const response = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });

    if (!response.ok) {
      toast.error(`Could not upload file ${file.name}. Please try again.`);
      throw new Error("Could not upload file");
    }

    return { fileId: id, filename: file.name };
  }

  async function uploadFiles({ files }: { files: File[] }) {
    return Promise.all(files.map((file) => uploadFile({ file })));
  }

  return { uploadFile, uploadFiles };
}
