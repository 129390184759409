import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { Sidebar } from "@dashboard/common/Sidebar/Sidebar";
import { useState } from "react";
import { SidebarHeader } from "./-components/SidebarHeader";
import { searchParamsSchema } from "./-utils/searchParamSchema";
import { SidebarSearch } from "./-components/SidebarSearch";
import { SidebarViews } from "./-components/SidebarViews";
import Tutorial from "./-components/Tutorial";
import { useTabNotificationEffect } from "./-hooks/useTabNotificationEffect";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar",
)({
  validateSearch: searchParamsSchema,
  loaderDeps: ({ search: { filters, view } }) => ({ filters, view }),
  loader: async ({
    context: { trpcClientUtils, user, hasExternalTicketConfig },
    params: { organizationSlug },
    deps: { filters, view },
  }) => {
    if (!view) {
      view = "your-inbox";

      if (user.isAdmin || hasExternalTicketConfig) {
        view = "bot";
      }

      const currentPath = window.location.pathname;
      const conversationPath = currentPath.split("/conversation/")[1] || "";
      const endpoint = conversationPath.split("?")[0];

      const search = new URLSearchParams(window.location.search);

      const parsedSearch = searchParamsSchema.parse(
        Object.fromEntries(search.entries()),
      );

      throw redirect({
        to: `/organization/$organizationSlug/conversation/${endpoint}`,
        params: { organizationSlug },
        search: { ...parsedSearch, view },
      });
    }

    const baseViews = [
      "your-inbox",
      "unassigned",
      "handed-off",
      "bot",
      "threads",
    ];

    const customViews = await trpcClientUtils.dashboard.views.getAll.ensureData(
      {
        organizationSlug,
      },
    );

    const businessId = filters?.businessId;

    await Promise.all([
      trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
        organizationSlug,
      }),

      trpcClientUtils.dashboard.conversations.getLanguages.ensureData({
        organizationSlug,
      }),

      ...baseViews.map((view) =>
        trpcClientUtils.dashboard.conversations.getCount.ensureData({
          organizationSlug,
          view,
          filters: { businessId },
          status: "open",
        }),
      ),

      ...customViews.map((view) =>
        trpcClientUtils.dashboard.conversations.getCount.ensureData({
          organizationSlug,
          view: view.id,
          status: "open",
        }),
      ),

      trpcClientUtils.dashboard.conversations.getCount.ensureData({
        organizationSlug,
        filters: { businessId, replyChannel: "web", assignee: null },
      }),
    ]);
  },
  component: ConversationSidebarLayout,
});

function ConversationSidebarLayout() {
  const search = Route.useSearch();

  const [searchActive, setSearchActive] = useState(search.view === "search");

  useTabNotificationEffect();

  return (
    <div className="flex h-full w-full">
      <Sidebar
        header={
          <SidebarHeader
            setSearchActive={setSearchActive}
            searchActive={searchActive}
          />
        }
      >
        {searchActive ? <SidebarSearch /> : <SidebarViews />}
      </Sidebar>
      <Tutorial tutorialType={search.tutorial} />
      <Outlet />
    </div>
  );
}
