import { RouterOutput } from "@be/exports";
import { Route } from "../_conversationList";
import { useEffect, useRef } from "react";

type ConversationCard =
  RouterOutput["dashboard"]["conversations"]["getAll"]["conversations"][0];
type DashboardConversation = RouterOutput["dashboard"]["conversations"]["get"];

export function useInsertConversation({
  pageParams,
}: {
  pageParams: (string | null)[];
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, sortBy, status } = Route.useSearch();
  const trpcUtils = trpc.useUtils();

  const viewRef = useRef(view);
  const filtersRef = useRef(filters);
  const sortByRef = useRef(sortBy);
  const statusRef = useRef(status);

  useEffect(() => {
    viewRef.current = view;
    filtersRef.current = filters;
    sortByRef.current = sortBy;
    statusRef.current = status;
  }, [view, filters, sortBy, status]);

  return (updatedConversation: DashboardConversation) => {
    trpcUtils.dashboard.conversations.getAll.setInfiniteData(
      {
        organizationSlug,
        view: viewRef.current,
        filters: filtersRef.current,
        sortBy: sortByRef.current,
        status: statusRef.current,
      },
      (oldData) => {
        if (oldData === undefined) {
          return oldData;
        }

        let conversationInserted = false;

        const pagesWithInsertedConversation = oldData.pages.map((page) => {
          const conversationCards = [...page.conversations];
          const existingIndex = conversationCards.findIndex(
            (conversation) => conversation.id === updatedConversation.id,
          );

          if (existingIndex !== -1) {
            conversationCards.splice(existingIndex, 1);
          }

          if (conversationInserted) {
            return {
              ...page,
              conversations: conversationCards,
            };
          }

          let insertIndex = -1;

          if (sortByRef.current === "oldest") {
            insertIndex = conversationCards.findIndex(
              (conversation) =>
                conversation.timestamp >
                updatedConversation.lastMessageCreatedAt,
            );
          } else {
            insertIndex = conversationCards.findIndex(
              (conversation) =>
                conversation.timestamp <
                updatedConversation.lastMessageCreatedAt,
            );
          }

          const lastMessage =
            updatedConversation.messages[
              updatedConversation.messages.length - 1
            ];

          const updatedConversationCard: ConversationCard = {
            ...updatedConversation,
            customerEmail: updatedConversation.customer?.email ?? null,
            timestamp: updatedConversation.lastMessageCreatedAt,
            lastMessageText: lastMessage.text,
            lastMessageChannel: lastMessage.channel,
          };

          if (insertIndex !== -1) {
            conversationCards.splice(insertIndex, 0, updatedConversationCard);
            conversationInserted = true;
          } else if (page.nextCursor === null) {
            conversationCards.push(updatedConversationCard);
            conversationInserted = true;
          }

          return {
            ...page,
            conversations: conversationCards,
          };
        });

        return {
          pages: pagesWithInsertedConversation,
          pageParams,
        };
      },
    );
  };
}
