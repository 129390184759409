import { Dispatch, SetStateAction } from "react";
import { useGetEmails } from "./useGetEmails";
import { z } from "zod";

export function useProcessRecipients({
  allowOrderIds,
  businessSlug,
  setErrors,
  setProcessedEmails,
}: {
  allowOrderIds: boolean;
  businessSlug: string;
  setErrors: Dispatch<SetStateAction<{ key: string; error: string }[]>>;
  setProcessedEmails: Dispatch<SetStateAction<string[]>>;
}) {
  const getEmails = useGetEmails();

  return async ({ orderIds, emails }: { orderIds: string; emails: string }) => {
    const finalEmails: string[] = [];

    setErrors([]);

    if (allowOrderIds && orderIds !== "") {
      try {
        const data = await getEmails({ businessSlug, orderIds });

        for (const item of data) {
          if (item.email) {
            const result = z.string().email().safeParse(item.email);

            if (result.success) {
              finalEmails.push(item.email);
            } else {
              const formatted = result.error.format();
              setErrors((prev) => [
                ...prev,
                {
                  key: `OrderID: ${item.orderId}`,
                  error: `${item.email}: ${formatted._errors[0]}`,
                },
              ]);
            }
          } else {
            setErrors((prev) => [
              ...prev,
              { key: `OrderId: ${item.orderId}`, error: "Email not found" },
            ]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (emails !== "") {
      const splitEmails = emails.split(",").map((email) => email.trim());
      for (const email of splitEmails) {
        const result = z.string().email().safeParse(email);

        if (result.success) {
          finalEmails.push(email);
        } else {
          const formatted = result.error.format();
          setErrors((prev) => [
            ...prev,
            { key: `Email: ${email}`, error: formatted._errors[0] },
          ]);
        }
      }
    }

    setProcessedEmails(
      finalEmails.filter((value, index, self) => self.indexOf(value) === index),
    );
  };
}
