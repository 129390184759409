import { ArrowUpRight } from "lucide-react";
import { FilterSelect } from "./FilterSelect";

export function HandedOff({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];

  return (
    <FilterSelect
      filterName="Handed Off"
      valueIcon={ArrowUpRight}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
