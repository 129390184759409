import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { EditViewForm } from "./EditViewForm";

interface EditViewDialogContentProps {
  id: string;
  title: string;
  filters: { key: FilterKeys; value: string }[];
  setOpen: (opened: boolean) => void;
}

export function EditViewDialogContent({
  id,
  title,
  filters,
  setOpen,
}: EditViewDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle className="mx-auto">Edit View</DialogTitle>
      </DialogHeader>
      <EditViewForm id={id} title={title} filters={filters} setOpen={setOpen} />
    </>
  );
}
