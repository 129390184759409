import { ChangeEvent } from "react";
import { Input } from "@dashboard/common/ui/input";
import { useFormContext } from "react-hook-form";
import { FormColorKey, WebChatConfigForm } from "../-utils/formSchema";

function ColorPicker({
  name,
  defaultValue,
}: {
  name: FormColorKey;
  defaultValue: string;
}) {
  const { watch, setValue } = useFormContext<WebChatConfigForm>();

  const color = watch(name) ?? defaultValue;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    value === "" ? setValue(name, null) : setValue(name, value);
  };

  return (
    <div>
      <div className="relative flex w-min flex-row items-center">
        <Input
          className="flex h-8 w-[120px] flex-row pr-5 text-black"
          defaultValue={defaultValue}
          name={name}
        />
        <input
          type="color"
          defaultValue={defaultValue}
          onChange={handleInputChange}
          className="color-input border-gray-light absolute right-3 h-5 w-5 rounded-full border"
          style={{ backgroundColor: color }}
        />
      </div>
    </div>
  );
}

export default ColorPicker;
