import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { File } from "lucide-react";

interface FileComponentProps {
  file: {
    id: string;
    name: string;
  };
}

export function FileComponent({ file }: FileComponentProps) {
  return (
    <>
      <File className="h-4 w-4 shrink-0" strokeWidth={1.5} />
      <TruncatedTooltip text={file.name} className="max-w-[400px]" />
    </>
  );
}
