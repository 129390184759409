import { Tooltip } from "@dashboard/common/ui/tooltip";
import { MacroActionType } from "@db";
import { X } from "lucide-react";
import { SetTag } from "./SetTag";
import { AssignUser } from "./AssignMember";
import { cn } from "@dashboard/utils/ui";
import { CloseConversation } from "./CloseConversation";
import { Snooze } from "./Snooze";

function ActionComponent({
  type,
  setFormValue,
  data,
  error,
  clearErrors,
}: {
  type: MacroActionType;
  setFormValue: (value: string) => void;
  data: string | null;
  error: boolean;
  clearErrors: () => void;
}) {
  switch (type) {
    case "close":
      return <CloseConversation />;
    case "assignMember":
      return (
        <AssignUser
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "setTag":
      return (
        <SetTag
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "snooze":
      return (
        <Snooze
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
  }
}
export function Action({
  type,
  data,
  remove,
  setFormValue,
  error,
  clearErrors,
}: {
  type: MacroActionType;
  data: string | null;
  remove: () => void;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const errorClass = error
    ? "group-hover:bg-red-200 group-hover:text-red-700"
    : "";

  return (
    <div className="group relative flex items-center justify-between gap-2">
      <div
        className={cn(
          "group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm",
          errorClass,
        )}
      />
      <Tooltip
        triggerAsChild
        content="Remove action"
        side="top"
        trigger={
          <X
            className={cn(
              "group-hover:bg-accent group-hover:text-blue absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors",
              errorClass,
            )}
            onClick={remove}
          />
        }
      />
      <ActionComponent
        type={type}
        setFormValue={setFormValue}
        data={data}
        error={error}
        clearErrors={clearErrors}
      />
    </div>
  );
}
