import { Link } from "@tanstack/react-router";

export function MetafieldLink({
  organizationSlug,
  businessSlug,
  metafieldKey,
}: {
  organizationSlug: string;
  businessSlug: string;
  metafieldKey: string;
}) {
  return (
    <Link
      className="text-blue-600 hover:underline"
      to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products/metafields/$metafieldKey"
      params={{ organizationSlug, businessSlug, metafieldKey }}
    >
      View details
    </Link>
  );
}
