import { Link } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { useCompleteOnboardingStep } from "../-hooks/useCompleteOnboardingStep";
import { Check } from "lucide-react";

export function ImplementFlows({
  businessSlug,
  organizationSlug,
  completed,
}: {
  businessSlug: string;
  organizationSlug: string;
  completed: boolean;
}) {
  const completeStep = useCompleteOnboardingStep();

  return (
    <div className="space-y-6">
      <p className="text-justify">
        Set up automated workflows to handle common customer requests
        efficiently. Create flows for order tracking, returns, product
        inquiries, and other repetitive tasks to provide faster service while
        maintaining consistency.
      </p>

      <div className="flex flex-col gap-4">
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/flows"
          params={{
            organizationSlug,
            businessSlug,
          }}
          className="w-full"
        >
          <Button className="w-full">Configure Flows</Button>
        </Link>

        {!completed ? (
          <Button
            onClick={() => completeStep({ step: "flows" })}
            variant="secondary"
            className="w-full"
          >
            Complete
          </Button>
        ) : (
          <Button className="w-full">
            <Check className="mr-2 h-4 w-4" />
            Completed
          </Button>
        )}
      </div>
    </div>
  );
}
