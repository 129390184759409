import { createTRPCClient, createWSClient, wsLink } from "@trpc/client";
import { env } from "@dashboard/env";
import { AppRouter } from "@be/exports";

// TODO: Use split link so that we can use devtools for debugging and keep it neat
export const wsTrpcClient = createTRPCClient<AppRouter>({
  links: [
    wsLink({
      client: createWSClient({ url: env.DASHBOARD_TRPC_WS_URL }),
    }),
  ],
});
