import { ReactNode } from "react";
import logo from "../../../assets/side-navbar-logo.svg";

interface ConnectContainerProps {
  children: ReactNode;
}

function ConnectContainer({ children }: ConnectContainerProps) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className="flex h-auto justify-center rounded-xl border shadow-md shadow-gray-300"
        style={{
          paddingTop: "50px",
          paddingBottom: "30px",
          width: "420px",
          paddingInline: "30px",
        }}
      >
        <div className="block">
          <div className="flex w-full justify-center">
            <img alt="Octocom logo" className="h-14 w-14" src={logo} />
          </div>
          <div
            className="flex w-full justify-center"
            style={{ marginTop: "30px" }}
          >
            <div className="text-center">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectContainer;
