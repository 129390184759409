import { z } from "zod";

export const botRuleFormSchema = z.object({
  title: z
    .string({
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  rule: z
    .string({
      invalid_type_error: "Rule is required",
    })
    .min(1, "Rule is required"),
});

export type BotRuleForm = z.infer<typeof botRuleFormSchema>;
