import { z } from "zod";

export const filtersSchema = z
  .object({
    timeRange: z
      .union([
        z.literal("today"),
        z.literal("this-week"),
        z.literal("this-month"),
        z.literal("last-90-days"),
      ])
      .nullish(),
    channel: z
      .union([
        z.literal("email"),
        z.literal("web"),
        z.literal("instagram"),
        z.literal("instagramComment"),
        z.literal("messenger"),
        z.literal("facebookComment"),
      ])
      .nullish(),
    replyChannel: z
      .union([
        z.literal("email"),
        z.literal("web"),
        z.literal("instagram"),
        z.literal("instagramComment"),
        z.literal("messenger"),
        z.literal("facebookComment"),
      ])
      .nullish(),
    handedOff: z.union([z.literal("yes"), z.literal("no")]).nullish(),
    language: z.string().nullish(),
    assignee: z.string().nullish(),
    businessId: z.string().nullish(),
    tag: z.string().nullish(),
    status: z
      .union([z.literal("open"), z.literal("closed"), z.literal("snoozed")])
      .nullish(),
    type: z.union([z.literal("external"), z.literal("all")]).nullish(),
  })
  .optional();

export const viewSchema = z
  .union([
    z.literal("search"),
    z.literal("your-inbox"),
    z.literal("unassigned"),
    z.literal("handed-off"),
    z.literal("bot"),
    z.literal("threads"),
    z.literal("spam"),
    z.string().regex(/^(language-|user-).*/),
    z.string().uuid(),
  ])
  .optional();

export const sortBySchema = z
  .union([z.literal("newest"), z.literal("oldest")])
  .optional()
  .catch(undefined);

export const statusSchema = z
  .union([z.literal("closed"), z.literal("open"), z.literal("snoozed")])
  .optional();

export const subscriptionTokenPayloadSchema = z.union([
  z.object({
    organizationId: z.string().uuid(),
    userId: z.string(),
    view: viewSchema,
    filters: filtersSchema,
    status: statusSchema,
  }),
  z.object({
    organizationId: z.string().uuid(),
    publicId: z.string(),
  }),
]);
