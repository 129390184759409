import { env } from "@dashboard/env";
import { useEffect } from "react";
import { useCreateSubscription } from "./useCreateSubscription";
import { Route } from "../_navbar";

interface NotificationMessageEventData {
  type: string;
  volume?: number;
}

export const useNotificationEffect = ({
  settings,
  submitCount,
}: {
  settings?: {
    enableNotifications: boolean;
    enableNotificationSound: boolean;
  };
  submitCount?: number;
}) => {
  const { user } = Route.useRouteContext();

  // When notification effect is triggered on page load (navbar), use the user object,
  // when it is triggered by the account settings form submission, use the form values.
  const usedSettings = settings ?? {
    enableNotifications: user.enableNotifications,
    enableNotificationSound: user.enableNotificationSound,
  };

  const createMutation = useCreateSubscription();

  async function subscribeUser() {
    if ("serviceWorker" in navigator) {
      try {
        await navigator.serviceWorker.register("/service-worker.js");

        await navigator.serviceWorker.ready.then(async (registration) => {
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope,
          );

          const existingSubscription =
            await registration.pushManager.getSubscription();

          if (!existingSubscription) {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: env.DASHBOARD_VAPID_PUBLIC_KEY,
            });

            createMutation(subscription);
          }
        });

        navigator.serviceWorker.removeEventListener("message", handleMessage);
        if (usedSettings.enableNotificationSound) {
          navigator.serviceWorker.addEventListener("message", handleMessage);
        }
      } catch (error) {
        console.error(
          "Service Worker registration or subscription failed:",
          error,
        );
      }
    }
  }

  async function subscribeToNotifications() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      await subscribeUser();
    } else {
      console.error("Notification permission denied.");
    }
  }

  async function unsubscribeFromNotifications() {
    if ("serviceWorker" in navigator) {
      try {
        await navigator.serviceWorker.ready.then(async (registration) => {
          const subscription = await registration.pushManager.getSubscription();

          if (subscription) {
            await subscription.unsubscribe();
          }
        });

        navigator.serviceWorker.removeEventListener("message", handleMessage);
      } catch (error) {
        console.error("Unsubscribe failed:", error);
      }
    }
  }

  useEffect(() => {
    if (usedSettings.enableNotifications) {
      void subscribeToNotifications();
    } else {
      void unsubscribeFromNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);
};

function handleMessage(event: MessageEvent<NotificationMessageEventData>) {
  if (event.data.type === "PLAY_NOTIFICATION_SOUND") {
    playNotificationSound({ volume: event.data.volume }).catch((error) => {
      console.error("Error playing notification sound:", error);
    });
  }
}

async function playNotificationSound({ volume }: { volume?: number }) {
  const audio = new Audio(`/notification-${volume ?? 3}.wav`);
  await audio.play();
}
