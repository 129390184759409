import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { format, formatDistanceToNow } from "date-fns";
import { ItemDetailSection } from "./OrderDetailSection";
import { ShippingAddressSection } from "./ShippingAddressSection";
import { BillingAddressSection } from "./BillingAddressSection";
import { FulfillmentsSection } from "./FulfillmentsSection";
import { ShippingCostSection } from "./ShippingCostSection";
import { OrderActions } from "./OrderActions";
import {
  getFinancialStatusString,
  getOrderStatusString,
} from "../../../-utils/orderStatusUtils";
import { OrderCostHoverCard } from "./OrderCostHoverCard";
import { CustomFieldsSection } from "./CustomFieldsSection";
import { Link } from "@tanstack/react-router";

export function OrderDetails({ order }: { order: TrackedOrder }) {
  return (
    <AccordionItem
      value={order.orderId}
      className="data-[state=open]:bg-offWhite rounded-xl border-none !shadow-none"
    >
      <AccordionTrigger className="hover:bg-offWhite flex justify-between rounded-xl px-3 py-2">
        <div className="flex w-full flex-col items-start gap-1 pr-4">
          <div className="flex w-full items-center justify-between gap-4">
            {order.adminPageUrl ? (
              <Link
                to={order.adminPageUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs font-medium hover:underline"
              >
                {order.orderId}
              </Link>
            ) : (
              <span className="text-xs font-medium">{order.orderId}</span>
            )}
            {order.status && (
              <span className="ml-auto rounded-full bg-gray-200 px-3 py-0.5 text-[10px] font-semibold text-gray-800">
                {getOrderStatusString(order.status)}
              </span>
            )}
          </div>
          <div className="flex w-full items-center justify-between gap-4 text-left">
            <span className="text-gray text-xs font-normal">
              {order.orderPlacedAt
                ? `(Created ${formatDistanceToNow(
                    new Date(order.orderPlacedAt),
                    {
                      addSuffix: true,
                    },
                  )})`
                : ""}
            </span>
            {order.financialStatus && (
              <span className="ml-auto rounded-full bg-blue-100 px-3 py-0.5 text-[10px] font-semibold text-blue-800">
                {getFinancialStatusString(order.financialStatus)}
              </span>
            )}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-3 pb-1 pt-1 text-xs">
        <OrderActions order={order} />
        <div
          className={`${
            order.shippingCost
              ? "data-[state=open]:border-gray-light border-gray-light border-b pb-2"
              : ""
          }`}
        >
          <ItemDetailSection
            details={[
              {
                label: "External ID:",
                value: order.externalId,
              },
              {
                label: "Created at:",
                value: order.orderPlacedAt
                  ? format(new Date(order.orderPlacedAt), "MMM d, yyyy HH:mm")
                  : "N/A",
              },
              {
                label: "Total cost:",
                value: <OrderCostHoverCard order={order} />,
              },
            ]}
          />
        </div>
        <Accordion
          type="multiple"
          className="w-full"
          defaultValue={[
            "shipping-cost",
            "shipping-address",
            "billing-address",
            "fulfillments",
          ]}
        >
          <ShippingCostSection order={order} />
          <ShippingAddressSection order={order} />
          <BillingAddressSection order={order} />
          <FulfillmentsSection order={order} />
          <CustomFieldsSection order={order} />
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
}
