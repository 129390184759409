import { Button } from "@dashboard/common/ui/button";
import { Merge } from "lucide-react";

interface MergeButtonProps {
  onClick: () => void;
}

export function MergeButton({ onClick }: MergeButtonProps) {
  return (
    <Button variant="outline" className="h-9 px-3" onClick={onClick}>
      <Merge className="h-4 w-4" />
    </Button>
  );
}
