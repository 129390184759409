import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

export function useMarketId() {
  const { data } = useQuery({
    queryKey: ["getMarketId"],
    queryFn: async () => {
      const r = await fetch("https://be.green/webservices/vendors/octocom/dc", {
        credentials: "include",
      });

      if (!r.ok) {
        throw new Error("Failed to get market id");
      }

      const { delivery_country: marketId } = z
        .object({ delivery_country: z.string() })
        .parse(await r.json());

      console.log("Got market ID", marketId);

      return marketId;
    },
    enabled:
      new URL(window.location.href).hostname === "be.green" &&
      !new URL(window.location.href).pathname.endsWith("plant-doctor"),
  });

  return data;
}

export function useSuntouchedHack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const styleEl = document.createElement("style");
    styleEl.innerHTML = ".richpanel-micro { display: none !important; }";
    document.head.appendChild(styleEl);

    const removeElement = () => {
      const elements = document.getElementsByClassName("richpanel-micro");
      if (elements.length > 0) {
        console.log("Removed Richpanel widget");
        elements[0].remove();
        return true;
      }
      return false;
    };

    if (!removeElement()) {
      const intervalId = setInterval(() => {
        if (removeElement()) {
          clearInterval(intervalId);
        }
      }, 50);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [enabled]);
}

export function useZendeskHack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const removeElement = () => {
      const element = document.getElementById("launcher");
      if (element?.parentElement?.parentElement) {
        console.log("Hidden Zendesk widget");
        element.parentElement.parentElement.style.visibility = "hidden";
        return true;
      }
      return false;
    };

    if (!removeElement()) {
      const intervalId = setInterval(() => {
        if (removeElement()) {
          clearInterval(intervalId);
        }
      }, 50);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [enabled]);
}

export function useFurniture1Hack({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) return;

    const styleEl = document.createElement("style");
    styleEl.innerHTML = "#messageus_button { display: none !important; }";
    document.head.appendChild(styleEl);

    const removeElement = () => {
      const element = document.getElementById("messageus_button");
      if (element) {
        console.log("Removed Furniture1 widget");
        element.remove();
        return true;
      }
      return false;
    };

    if (!removeElement()) {
      const intervalId = setInterval(() => {
        if (removeElement()) {
          clearInterval(intervalId);
        }
      }, 50);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [enabled]);
}
