import { toast } from "sonner";
import { Route } from "..";

export function useDelete() {
  const { trpc } = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const { organizationSlug, publicId } = Route.useParams();
  const search = Route.useSearch();

  const deleteMutation = trpc.dashboard.conversations.delete.useMutation({
    onSuccess: () => {
      toast.success(`Conversation ${publicId} was deleted`);
      void navigate({
        to: "/organization/$organizationSlug/conversation",
        params: { organizationSlug },
        search,
      });
    },
    onError: (error) => {
      console.error("Error deleting conversation", error);
      toast.error("Error deleting conversation");
    },
  });

  return () => deleteMutation.mutate({ organizationSlug, publicId });
}
