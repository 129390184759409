import { LucideIcon } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@dashboard/common/ui/card";

interface MetricCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  description: string;
}

export function MetricCard({
  icon: Icon,
  title,
  value,
  description,
}: MetricCardProps) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center space-x-2">
        <Icon className="h-4 w-4" />
        <CardTitle className="!mt-0">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      </CardContent>
    </Card>
  );
}
