import { toast } from "sonner";
import { Route } from "..";

export function useArchiveUsers() {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const archiveMutation = trpc.dashboard.users.archive.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.users.getOrgUsers.invalidate({
        organizationSlug,
      });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Users archived successfully."
          : "User archived successfully.";

      toast(message);
    },

    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1 ? "Failed to archive users." : "Failed to archive user.";

      toast(message);
      console.error(error);
    },
  });

  return {
    archiveUsers: (ids: string[]) => {
      archiveMutation.mutate({ organizationSlug, ids: ids });
    },
  };
}
