import { Route } from "..";

export function useMacros() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [macros] = trpc.dashboard.macros.getAvailable.useSuspenseQuery({
    organizationSlug,
  });

  return macros;
}
