import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";
import { Route } from "../_navbar";

export function ChooseOrganizationDialog({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const {
    user: { organizations },
  } = Route.useRouteContext();
  const navigate = Route.useNavigate();

  return (
    <CommandDialog open={open} onOpenChange={onOpenChange}>
      <CommandInput placeholder="Search for organization" />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Organizations">
          {organizations.map((org) => (
            <CommandItem
              key={org.id}
              onSelect={() => {
                void navigate({
                  to: "/organization/$organizationSlug/conversation",
                  params: { organizationSlug: org.slug },
                });
                onOpenChange(false);
              }}
            >
              {org.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
