import { InboxIcon } from "@heroicons/react/24/outline";
import { FilterSelect } from "./FilterSelect";

export function Status({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    { label: "Open", value: "open" },
    { label: "Closed", value: "closed" },
    { label: "Snoozed", value: "snoozed" },
  ];

  return (
    <FilterSelect
      filterName="Status"
      valueIcon={InboxIcon}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
