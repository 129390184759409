import { toast } from "sonner";
import { pushSubscriptionSchema } from "../-utils/pushSubscriptionSchema";
import { Route } from "../_navbar";

export function useCreateSubscription() {
  const { trpc, user } = Route.useRouteContext();

  const createSubscription =
    trpc.dashboard.notifications.createSubscription.useMutation({
      onSuccess: () => {
        toast("This browser is now subscribed to notifications.");
      },
    });

  return (subscription: PushSubscription) => {
    const parsedSubscription = pushSubscriptionSchema.parse(
      subscription.toJSON(),
    );

    createSubscription.mutate({ userId: user.id, ...parsedSubscription });
  };
}
