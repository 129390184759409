import { useState, useRef } from "react";
import { Button } from "@dashboard/common/ui/button";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";
import { Check, ChevronsUpDown, FolderPlus, Trash2 } from "lucide-react";
import { cn } from "@dashboard/utils/ui";
import { UseFormReturn } from "react-hook-form";
import { ArticleForm } from "../-utils/articleForm";
import { useCategories } from "../-hooks/useCategories";
import { useCreateCategory } from "../-hooks/useCreateCategory";
import { useDeleteCategory } from "../-hooks/useDeleteCategory";
import { Tooltip } from "@dashboard/common/ui/tooltip";

interface CategoryFormFieldProps {
  form: UseFormReturn<ArticleForm>;
}

export function CategoryFormField({ form }: CategoryFormFieldProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const triggerRef = useRef<HTMLButtonElement>(null);

  const categories = useCategories();
  const createCategory = useCreateCategory();
  const deleteCategory = useDeleteCategory();

  const handleCreateCategory = async ({
    name,
    onChange,
  }: {
    name: string;
    onChange: (value: string) => void;
  }) => {
    const result = await createCategory({ name });
    setValue(result.name);
    onChange(result.id);
    setOpen(false);
  };

  return (
    <FormField
      control={form.control}
      name="categoryId"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Category</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  ref={triggerRef}
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className={cn(
                    "h-9 w-full justify-between",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  {field.value
                    ? categories.find((category) => category.id === field.value)
                        ?.name
                    : "Select category (optional)"}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              sideOffset={5}
              className="w-[var(--radix-popover-trigger-width)] max-w-[var(--radix-popover-content-available-width)] p-0"
              style={
                {
                  "--radix-popover-trigger-width":
                    triggerRef.current?.offsetWidth + "px",
                } as React.CSSProperties
              }
            >
              <Command className="w-full">
                <CommandInput
                  placeholder="Search categories..."
                  value={value}
                  onValueChange={(search) => setValue(search)}
                />
                <CommandList>
                  <CommandEmpty>
                    {value.trim() !== "" &&
                      !categories.some(
                        (category) =>
                          category.name.toLowerCase() ===
                          value.trim().toLowerCase(),
                      ) && (
                        <Button
                          variant="ghost"
                          className="w-full justify-start"
                          onClick={() =>
                            void handleCreateCategory({
                              name: value.trim(),
                              onChange: field.onChange,
                            })
                          }
                        >
                          <FolderPlus className="mr-2 h-4 w-4" />
                          Create &quot;{value.trim()}&quot;
                        </Button>
                      )}
                  </CommandEmpty>
                  <CommandGroup>
                    {value.trim() !== "" &&
                      !categories.some(
                        (category) =>
                          category.name.toLowerCase() ===
                          value.trim().toLowerCase(),
                      ) && (
                        <Button
                          variant="ghost"
                          className="w-full justify-start"
                          onClick={() =>
                            void handleCreateCategory({
                              name: value.trim(),
                              onChange: field.onChange,
                            })
                          }
                        >
                          <FolderPlus className="mr-2 h-4 w-4" />
                          Create &quot;{value.trim()}&quot;
                        </Button>
                      )}
                    {categories.map((category) => (
                      <CommandItem
                        key={category.id}
                        value={category.name}
                        onSelect={() => {
                          field.onChange(category.id);
                          setOpen(false);
                        }}
                        className="group relative flex h-9 cursor-pointer items-center justify-between"
                      >
                        <div className="flex items-center">
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              category.id === field.value
                                ? "opacity-100"
                                : "opacity-0",
                            )}
                          />
                          {category.name}
                        </div>
                        <div className="group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
                        <Tooltip
                          triggerAsChild
                          content="Delete category"
                          side="top"
                          trigger={
                            <Trash2
                              className="group-hover:bg-accent absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors group-hover:text-black"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteCategory({ id: category.id });
                              }}
                            />
                          }
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormDescription>
            Select a category or create a new one.
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
