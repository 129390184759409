import { z } from "zod";

export const threatFormSchema = z.object({
  title: z
    .string({
      invalid_type_error: "Title is required",
    })
    .min(1, { message: "Title is required" }),
  description: z
    .string({
      invalid_type_error: "Description is required",
    })
    .min(1, { message: "Description is required" }),
  sampleStatements: z.array(
    z.object({
      statement: z.string().min(1, { message: "Statement is required" }),
    }),
  ),
});

export type ThreatForm = z.infer<typeof threatFormSchema>;
