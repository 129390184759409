import { toast } from "sonner";
import { Route } from "..";

export function useUnsnooze() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const unsnoozeMutation = trpc.dashboard.conversations.unsnooze.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.getCount.invalidate({
        organizationSlug,
      });
      await trpcUtils.dashboard.conversations.get.invalidate({
        organizationSlug,
        publicId,
      });

      toast.success("Conversation unsnoozed", {
        position: "bottom-right",
      });
    },
    onError: (error) => {
      console.error("Error unsnoozing conversation", error);
      toast.error("Error unsnoozing conversation");
    },
  });

  return () => unsnoozeMutation.mutate({ organizationSlug, publicId });
}
