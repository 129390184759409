import { Link, createFileRoute } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { Template } from "./-components/Template";
import { flowTemplates } from "./-utils/flowTemplates";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/flows/templates",
)({
  component: FlowTemplatesPage,
});

function FlowTemplatesPage() {
  const { organizationSlug, businessSlug } = Route.useParams();

  return (
    <div className="relative py-0">
      <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
        <h1 className="text-xl font-medium text-black">Flow Templates</h1>
        <Link
          to="/organization/$organizationSlug/settings/$businessSlug/flows"
          params={{ organizationSlug, businessSlug }}
        >
          <Button
            variant="secondary"
            className="flex items-center"
            type="button"
          >
            Back to flows
          </Button>
        </Link>
      </div>

      <div className="grid w-full grid-cols-3 gap-4 px-10 py-5">
        {Object.keys(flowTemplates).map((key) => (
          <Link
            key={key}
            to="/organization/$organizationSlug/settings/$businessSlug/flows/new"
            params={{ organizationSlug, businessSlug }}
            search={{ template: key }}
          >
            <Template
              title={flowTemplates[key as keyof typeof flowTemplates].title}
              description={
                flowTemplates[key as keyof typeof flowTemplates].description
              }
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
