import { toast } from "sonner";
import { Route } from "..";

export function useDeleteProducts() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.products.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.products.getAll.invalidate({ businessSlug });
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Products deleted successfully."
          : "Product deleted successfully.";
      toast(message);
    },
    onError: () => {
      toast("Failed to delete products.");
    },
  });

  return {
    deleteProducts: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids });
    },
  };
}
