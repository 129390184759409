export const MAX_BASIC_CONVOS = 800;
export const BASIC_PLAN_PRICE = "100";
export const BASIC_PLAN_OVERAGE_FEE = "40";

export const MAX_ADVANCED_CONVOS = 1200;
export const ADVANCED_PLAN_PRICE = "300";
export const ADVANCED_PLAN_OVERAGE_FEE = "36";

export const MAX_ENTERPRISE_CONVOS = 3200;
export const ENTERPRISE_PLAN_PRICE = "800";
export const ENTERPRISE_PLAN_OVERAGE_FEE = "30";

export const TRIAL_DAYS = 14;

export const BASIC_PLAN = {
  id: "Basic",
  name: "Basic",
  price: BASIC_PLAN_PRICE,
  maxConversations: MAX_BASIC_CONVOS,
  overageFee: BASIC_PLAN_OVERAGE_FEE,
};
