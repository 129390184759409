import { Outlet, createFileRoute, Link } from "@tanstack/react-router";
import octocomLogoSmall from "@dashboard/assets/octocom-logo.svg";
import octocomLogo from "@dashboard/assets/octocom-white.svg";
import slogo from "@dashboard/assets/auth-slogo.svg";

export const Route = createFileRoute("/_auth")({
  component: AuthLayout,
  beforeLoad: async ({ context: { trpcClientUtils } }) => {
    try {
      await trpcClientUtils.dashboard.users.getCurrent.ensureData();
      window.location.href = "/organization";
    } catch (e) {
      // noop
    }
  },
});

function AuthLayout() {
  return (
    <div className="flex min-h-screen w-full flex-col lg:flex-row">
      <div className="relative flex w-full items-center justify-center bg-white px-10 pt-10 lg:bg-[#041627] lg:py-10">
        <Link
          to="/"
          className="flex items-center lg:absolute lg:left-10 lg:top-10"
          aria-label="Octocom home page"
        >
          <img
            className="block lg:hidden lg:w-[152px]"
            src={octocomLogoSmall}
            alt="Octocom logo dark"
          />
          <img
            className="hidden lg:block lg:w-[152px]"
            src={octocomLogo}
            alt="Octocom logo white"
          />
        </Link>
        <img
          className="hidden lg:block lg:w-[80%]"
          src={slogo}
          alt="The new age of AI-first customer experience starts here."
        />
      </div>
      <div className="flex w-full flex-grow flex-col items-center justify-center bg-white px-10 py-20 pt-10 lg:py-10">
        <Outlet />
      </div>
    </div>
  );
}
