import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCreateUser } from "../-hooks/useCreateUser";
import { UserForm, userFormSchema } from "../-utils/userFormSchema";
import { toast } from "sonner";
import { UserFormBody } from "./UserFormBody";

export function CreateUserForm({
  setPassword,
}: {
  setPassword: (password: string) => void;
}) {
  const form = useForm<UserForm>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      fullName: "",
      email: "",
    },
  });

  const { setError } = form;

  const { onSubmit: onSubmitMutation } = useCreateUser({
    setPassword,
    setError,
  });

  const onSubmit = (data: UserForm) => {
    onSubmitMutation(data);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to create user");
  };

  return <UserFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
