export function getColorForUuid(uuid: string, colorsArray: string[]) {
  const index = hashStringToIndex(uuid, colorsArray.length);
  return colorsArray[index];
}

function hashStringToIndex(str: string, arrayLength: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash % arrayLength);
}
