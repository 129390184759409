import { ReactNode } from "@tanstack/react-router";
import { MacroVariable } from "./MacroVariable";

export function MacroContent({ input }: { input: string }) {
  const regex = /{{(.*?)}}/g;
  const parts: ReactNode[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(input)) !== null) {
    const [fullMatch, content] = match;
    const matchIndex = match.index;

    if (lastIndex < matchIndex) {
      parts.push(input.substring(lastIndex, matchIndex));
    }

    parts.push(<MacroVariable key={matchIndex} content={content.trim()} />);

    lastIndex = matchIndex + fullMatch.length;
  }

  if (lastIndex < input.length) {
    parts.push(input.substring(lastIndex));
  }

  return <>{parts}</>;
}
