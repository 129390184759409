import { Route } from "..";
import { Tabs, TabsTrigger, TabsList } from "@dashboard/common/ui/tabs";
import {
  SelectRoot as Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@dashboard/common/ui/select";
import { useConversation } from "../-hooks/useConversation.hook";
import { useEffect } from "react";
import { useDraftMessages } from "../../../-context/DraftMessagesContext";

interface ConversationTabsProps {
  activeTab: "customer" | "external";
  setActiveTab: (value: "customer" | "external") => void;
  externalTabValue: string | null;
  setExternalTabValue: (value: string | null) => void;
}

export function ConversationTabs({
  activeTab,
  setActiveTab,
  externalTabValue,
  setExternalTabValue,
}: ConversationTabsProps) {
  const { organizationSlug, publicId } = Route.useParams();
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const conversation = useConversation();
  const { getDraft } = useDraftMessages();

  const newThreadDraft = getDraft(
    conversation.customerConversationPublicId,
  ).newThread;

  const isNewThreadDraftNotEmpty =
    newThreadDraft.text !== "" ||
    newThreadDraft.subject !== "" ||
    newThreadDraft.email !== "" ||
    newThreadDraft.attachments.length > 0 ||
    newThreadDraft.actions.length > 0;

  const addNewExternalThreadOption =
    (activeTab === "external" && externalTabValue === "new") ||
    isNewThreadDraftNotEmpty;

  const externalThreadOptions = [
    ...conversation.externalConversations.map((externalConversation) => ({
      value: externalConversation.publicId,
      label: externalConversation.subject,
    })),
    ...(addNewExternalThreadOption
      ? [
          {
            value: "new",
            label: "New External Thread (Draft)",
          },
        ]
      : []),
  ];

  const showTabs = externalThreadOptions.length > 0 || activeTab === "external";

  useEffect(() => {
    if (conversation.type === "external") {
      setExternalTabValue(publicId);
    } else if (externalTabValue === "new" || externalTabValue === null) {
      setExternalTabValue(
        conversation.externalConversations[0]?.publicId ?? null,
      );
    }

    setActiveTab(conversation.type);
  }, [publicId]);

  const externalTabLabel =
    externalThreadOptions.find((option) => option.value === externalTabValue)
      ?.label ?? "External Thread";

  const handleTabChange = (value: "customer" | "external") => {
    if (value === "customer") {
      void navigate({
        to: "/organization/$organizationSlug/conversation/$publicId",
        params: {
          organizationSlug,
          publicId: conversation.customerConversationPublicId,
        },
        search,
      });
    } else {
      const externalPublicId =
        externalTabValue ?? externalThreadOptions[0].value;

      void navigate({
        to: "/organization/$organizationSlug/conversation/$publicId",
        params: {
          organizationSlug,
          publicId: externalPublicId,
        },
        search,
      });
    }
    setActiveTab(value);
  };

  const handleExternalThreadChange = (value: string) => {
    if (value !== "new") {
      void navigate({
        to: "/organization/$organizationSlug/conversation/$publicId",
        params: { organizationSlug, publicId: value },
        search,
      });
    }
    setExternalTabValue(value);
  };

  if (!showTabs) {
    return null;
  }

  return (
    <Tabs
      value={activeTab}
      onValueChange={(value) =>
        handleTabChange(value as "customer" | "external")
      }
      defaultValue="customer"
      className="border-b p-2"
    >
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="customer">Customer Thread</TabsTrigger>
        <TabsTrigger value="external">
          {externalThreadOptions.length > 1 ? (
            <Select
              onValueChange={(value) => handleExternalThreadChange(value)}
              value={externalTabValue ?? undefined}
            >
              <SelectTrigger className="h-5 items-center justify-center border-none px-0 py-0 text-sm font-medium shadow-none">
                <SelectValue placeholder="Select external thread" />
              </SelectTrigger>
              <SelectContent>
                {externalThreadOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <>{externalTabLabel}</>
          )}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
