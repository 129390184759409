import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { BotRuleForm, botRuleFormSchema } from "../-utils/botRuleFormSchema";
import { BotRuleFormBody } from "./BotRuleFormBody";
import { useCreateBotRule } from "../-hooks/useCreateBotRule";

export function CreateBotRuleForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<BotRuleForm>({
    resolver: zodResolver(botRuleFormSchema),
  });

  const { onSubmit: onSubmitMutation } = useCreateBotRule({ setOpen });

  const onSubmit = (data: BotRuleForm) => {
    onSubmitMutation(data);

    form.reset();
  };

  return <BotRuleFormBody form={form} onSubmit={onSubmit} />;
}
