import { AttachedFile } from "../../../../-components/FileUpload/AttachedFile";

interface AttachmentListProps {
  attachments: { fileId: string; filename: string }[];
  onRemove: ({ fileId }: { fileId: string }) => void;
}

export function AttachmentList({ attachments, onRemove }: AttachmentListProps) {
  if (attachments.length === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-center justify-start gap-2 pt-2 text-sm">
      {attachments.map((attachment, index) => (
        <AttachedFile
          key={index}
          filename={attachment.filename}
          fileId={attachment.fileId}
          remove={onRemove}
        />
      ))}
    </div>
  );
}
