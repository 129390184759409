import { createFileRoute } from "@tanstack/react-router";
import ConnectContainer from "../connect/-components/ConnectContainer";
import { Button } from "@dashboard/common/ui/button";

export const Route = createFileRoute("/public-routes/welcome")({
  component: Welcome,
});

function Welcome() {
  const inIframe = window.location !== window.parent.location;

  if (!inIframe) {
    window.location.href = "/";
  }

  return (
    <ConnectContainer>
      <h1 className="text-2xl font-bold">Welcome!</h1>
      <p className="text-sm text-gray-500" style={{ marginTop: "30px" }}>
        It looks like you&apos;re trying to access Octocom&apos;s dashboard from
        an external service! If you&apos;d like to open the dashboard, click the
        button below.
      </p>
      <p style={{ marginTop: "30px", marginBottom: "40px" }}>
        <Button
          onClick={() => window.open("/", "_blank")}
          className="w-full"
          style={{ height: "40px" }}
        >
          Open dashboard
        </Button>
      </p>
    </ConnectContainer>
  );
}
