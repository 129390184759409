import { useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import { KeyboardEvent } from "react";

export function IconTextLauncher() {
  const config = useConfig();
  const { displayChatWindow, openChatWindow, closeChatWindow, isMobile } =
    useUiState();

  const text = isMobile ? config.launcherMobileText : config.launcherText;

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (displayChatWindow) {
        closeChatWindow();
      } else {
        openChatWindow();
      }
    }
  };

  return (
    <div
      // aria-label={displayChatWindow ? "Close chat widget" : "Open chat widget"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={() => (displayChatWindow ? closeChatWindow() : openChatWindow())}
      className="flex cursor-pointer items-center justify-center rounded-full border bg-white shadow-lg hover:scale-105"
      style={{
        height: config.launcherSize ?? "48px",
        minWidth: config.launcherSize ?? "48px",

        backgroundColor: config.launcherBackgroundColor ?? undefined,
      }}
    >
      <div className="mx-5 my-3 flex items-center justify-center sm:mx-4">
        {config.launcherIconFileUrl ? (
          <img
            className="rounded"
            style={{
              height: config.launcherIconSize ?? "24px",
              width: config.launcherIconSize ?? "24px",
            }}
            src={config.launcherIconFileUrl}
          />
        ) : (
          <ChatBubbleOvalLeftIcon
            style={{
              height: config.launcherIconSize ?? "24px",
              width: config.launcherIconSize ?? "24px",
              color: config.launcherIconColor ?? undefined,
            }}
          />
        )}
        <span
          className="ml-2 select-none text-sm font-medium"
          style={{
            color: config.launcherTextColor ?? undefined,
          }}
        >
          {text ?? "Need help?"}
        </span>
      </div>
    </div>
  );
}
