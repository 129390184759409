import { User } from "lucide-react";
import { Route } from "../../_conversationSidebar";
import { SelectFilter } from "./SelectFilter";

export function AssigneeFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const options = [
    { label: "Anyone", value: "anyone" },
    ...users.map((user) => ({ label: user.fullName, value: user.id })),
    { label: "Unassigned", value: "unassigned" },
  ];

  return (
    <SelectFilter
      options={options}
      valueIcon={User}
      name="Assignee"
      filterKey="assignee"
    />
  );
}
