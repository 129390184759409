import { useEffect } from "react";
import { minutesToMilliseconds } from "date-fns";
import { useUpdateAgentAvailability } from "./useUpdateAgentAvailability";

export function usePingEffect() {
  const updateAgentAvailability = useUpdateAgentAvailability();

  useEffect(() => {
    function sendPing() {
      const agentIsAvailable = sessionStorage.getItem("agentIsAvailable");
      if (agentIsAvailable && agentIsAvailable !== "true") {
        return;
      }
      updateAgentAvailability();
    }

    const intervalId = setInterval(sendPing, minutesToMilliseconds(1));

    sendPing();

    return () => clearInterval(intervalId);
  }, []);
}
