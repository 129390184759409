import { z } from "zod";

export const signUpFormSchema = z.object({
  firstName: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .trim(),
  lastName: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .trim(),
  username: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .email("Invalid email address")
    .transform((email) => email.toLowerCase()),
  password: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .min(6, { message: "Password must be at least 6 characters" }),
  organization: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .trim(),
  storeUrl: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" })
    .trim()
    .transform((s) => s.replace(/^https?:\/\//, ""))
    .refine((s) => /^[^\s@]+\.[^\s@]+$/.test(s), {
      message: "Invalid URL format",
    }),
  usage: z.enum(["for-my-own-business", "for-my-clients"]),
  requestDemo: z.boolean(),
});

export type SignUpFormSchema = z.infer<typeof signUpFormSchema>;
