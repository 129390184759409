const OrderStatus: Record<string, string> = {
  PENDING: "Pending",
  SCHEDULED: "Scheduled",
  IN_PROGRESS: "In Progress",
  CANCELLED: "Cancelled",
  UNFULFILLED: "Unfulfilled",
  PARTIALLY_FULFILLED: "Partially Fulfilled",
  FULFILLED: "Fulfilled",
  ON_HOLD: "On Hold",
};

export function getOrderStatusString(status: string): string {
  const statusKey = status.toUpperCase();
  return OrderStatus[statusKey] || status;
}

const FinancialStatus: Record<string, string> = {
  AUTHORIZED: "Authorized",
  EXPIRED: "Expired",
  PARTIALLY_PAID: "Partially Paid",
  PAID: "Paid",
  PARTIALLY_REFUNDED: "Partially Refunded",
  REFUNDED: "Refunded",
  PENDING: "Pending",
  VOIDED: "Voided",
};

export function getFinancialStatusString(status: string): string {
  const statusKey = status.toUpperCase();
  return FinancialStatus[statusKey] || status;
}

const FulfillmentStatus: Record<string, string> = {
  OPEN: "Open",
  SCHEDULED: "Scheduled",
  PENDING: "Pending",
  CONFIRMED: "Confirmed",
  SUBMITTED: "Submitted",
  ON_HOLD: "On Hold",
  IN_PROGRESS: "In Progress",
  IN_TRANSIT: "In Transit",
  LABEL_PRINTED: "Label Printed",
  LABEL_PURCHASED: "Label Purchased",
  LABEL_VOIDED: "Label Voided",
  MARKED_AS_FULFILLED: "Marked as Fulfilled",
  OUT_FOR_DELIVERY: "Out for Delivery",
  ATTEMPTED_DELIVERY: "Attempted Delivery",
  READY_FOR_PICKUP: "Ready for Pickup",
  PICKED_UP: "Picked Up",
  DELIVERED: "Delivered",
  FULFILLED: "Fulfilled",
  NOT_DELIVERED: "Not Delivered",
  INCOMPLETE: "Incomplete",
  FAILURE: "Failure",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
  CANCELED: "Cancelled",
};

export function getFulfillmentStatusString(status: string): string {
  const statusKey = status.toUpperCase();
  return FulfillmentStatus[statusKey] || status;
}
