import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { AddressDetailsSection } from "./AddressDetailsSection";

export function BillingAddressSection({ order }: { order: TrackedOrder }) {
  if (!order.billingInfo) return null;

  return (
    <AccordionItem
      value="billing-address"
      className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
    >
      <AccordionTrigger className="py-2 text-xs font-medium">
        Billing Address
      </AccordionTrigger>
      <AccordionContent className="pb-2 text-xs">
        <AddressDetailsSection
          address={order.billingInfo.address}
          name={order.billingInfo.name}
        />
      </AccordionContent>
    </AccordionItem>
  );
}
