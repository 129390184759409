import { MacroAction } from "@be/modules/macros/macros.types";
import { DashboardAction } from "../../../../-components/Macros/DashboardAction";

interface ActionListProps {
  actions: MacroAction[];
  onRemove: (index: number) => void;
}

export function ActionList({ actions, onRemove }: ActionListProps) {
  if (actions.length === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-center justify-start gap-2 pt-2 text-sm">
      {actions.map((action, index) => (
        <DashboardAction
          key={index}
          action={action}
          remove={() => onRemove(index)}
        />
      ))}
    </div>
  );
}
