import { File } from "lucide-react";

interface StaticFileComponentProps {
  file: {
    id: string;
    name: string;
  };
}

export function StaticFileComponent({ file }: StaticFileComponentProps) {
  return (
    <div className="bg-gray-light relative flex items-center gap-2 rounded-lg px-2 py-1.5 text-xs">
      <div className="flex items-center">
        <File className="mr-2 h-4 w-4 shrink-0" strokeWidth={1.5} />
        <span>{file.name}</span>
      </div>
    </div>
  );
}
