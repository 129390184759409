import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import { UseFormReturn } from "react-hook-form";
import { Input } from "@dashboard/common/ui/input";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { ViewForm } from "../-utils/viewFormSchema";
import { Route } from "..";
import { FiltersFormField } from "./Filters/FiltersFormField";

interface ViewFormBodyProps {
  form: UseFormReturn<ViewForm>;
  onSubmit: (data: ViewForm) => void;
  onError: (errors: unknown) => void;
}

export function ViewFormBody({ form, onSubmit, onError }: ViewFormBodyProps) {
  const { user } = Route.useRouteContext();
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = form;

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Enter the View title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FiltersFormField
          control={control}
          watch={watch}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
        />
        <DialogFooter className="lg:space-x-2.5">
          {user.isAdmin && (
            <Button
              variant="secondary"
              type="button"
              onClick={() => console.log(form.getValues())}
            >
              Show Values
            </Button>
          )}
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
