import { sumProp } from "@dashboard/utils/arrays";
import { useData } from "./useData";
import { numberToScale1 } from "@dashboard/utils/numbers";

export function useAvgConversationsPerDay() {
  const { cur, prev, comparedTo } = useData();

  const conversationCountCur = sumProp(cur.conversationsPerDay, "count");
  const conversationCountPrev = sumProp(prev.conversationsPerDay, "count");

  const avgConversationsPerDayCur = conversationCountCur / comparedTo.days;
  const avgConversationsPerDayPrev = conversationCountPrev / comparedTo.days;

  return {
    value: numberToScale1(avgConversationsPerDayCur),
    diff: numberToScale1(
      avgConversationsPerDayCur - avgConversationsPerDayPrev,
    ),
  };
}
