import { useMemo } from "react";
import { useConversation } from "../-hooks/useConversation.hook";
import { Route } from "..";
import { MessageAvatar } from "./MessageAvatar";
import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import { DisplayPassedTimeWithTooltip } from "@dashboard/common/DisplayPassedTimeWithTooltip";
import { differenceInDays } from "date-fns";
import { cn } from "@dashboard/utils/ui";

type Message = ReturnType<typeof useConversation>["messages"][number];

export function Message({ message }: { message: Message }) {
  const { organization, user } = Route.useRouteContext();

  const isCustomer = message.sender === "customer";
  const isExternal = message.sender === "external";

  const filesMd = useMemo(() => {
    const ageInDays = differenceInDays(Date.now(), new Date(message.createdAt));
    if (organization.slug === "gls-pharmaceuticals" && ageInDays > 3) {
      // This is a hacky way to prevent showing images in old messages for GLS.
      // We don't want to actually delete the images, just hide them.
      // We will generalize this later.
      return "";
    }

    return message.files
      .map((file) => {
        if (file.contentType.startsWith("image")) {
          return `![${file.name}](${file.url})`;
        } else {
          return `[${file.name}](${file.url})`;
        }
      })
      .join("\n\n");
  }, [message, organization.slug]);

  const { useTranslation } = Route.useSearch();

  let translation: string | undefined;

  if (useTranslation === undefined || useTranslation) {
    translation = message.translations.find(
      (t) => t.translatedTo === user.translationLanguage,
    )?.translation;
  }

  const text = translation ?? message.text;

  const content = `${text}\n\n${filesMd}`;

  const agentName = message.sender === "agent" ? message.agentName : undefined;

  return (
    <div
      className={cn("flex flex-row-reverse gap-x-2 p-2", {
        "flex-row": isCustomer || isExternal,
      })}
    >
      <div className="flex flex-col-reverse pb-2">
        <MessageAvatar sender={message.sender} agentName={agentName} />
      </div>

      <div
        className={cn(
          "max-w-md rounded-lg px-4 pb-2 pt-3 xl:max-w-lg",
          !isCustomer && !isExternal
            ? "bg-[#DCE1F9] hover:bg-[#CED6F7]"
            : "bg-gray-lightest hover:bg-gray-light",
        )}
      >
        <MarkdownContent content={content} className="break-words text-sm" />
        <div className="mt-1 flex flex-row items-end">
          <span className="text-gray text-[13px]">
            <DisplayPassedTimeWithTooltip
              date={message.createdAt}
              side="bottom"
              extraText={`via ${message.channel}`}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
