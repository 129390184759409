import { z } from "zod";

export const klaviyoFormSchema = z.object({
  apiKey: z
    .string({
      required_error: "API Key is required",
      invalid_type_error: "API Key is required",
    })
    .min(1, "API Key is required"),
  mailingListId: z
    .string({
      required_error: "Mailing List ID is required",
      invalid_type_error: "Mailing List ID is required",
    })
    .min(1, "Mailing List ID is required"),
});

export type KlaviyoForm = z.infer<typeof klaviyoFormSchema>;
