import { Route } from "..";
import { toast } from "sonner";
import { ThreatForm } from "../-utils/threatForm";

export const useCreateThreat = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.threats.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.threats.getAll.invalidate({ businessSlug });

      setOpen(false);
      toast.success("Threat created");
    },
    onError: () => {
      toast.error("Failed to create threat");
    },
  });

  return {
    onSubmit: ({ title, description, sampleStatements }: ThreatForm) => {
      creationMutation.mutate({
        businessSlug,
        title,
        description,
        sampleStatements: sampleStatements.map(({ statement }) => statement),
      });
    },
  };
};
