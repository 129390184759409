import { createFileRoute } from "@tanstack/react-router";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ConnectWithSignupForm,
  connectWithSignupFormSchema,
} from "./-utils/formSchema";
import { Button } from "@dashboard/common/ui/button";
import ConnectWithSignupLabel from "./-components/ConnectWithSignupLabel";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";

export const Route = createFileRoute("/connect/signup/")({
  validateSearch: z.object({
    integrationData: z.unknown(),
    integrationServiceName: integrationServiceNameSchema,
  }),
  component: ConnectWithSignup,
});

function ConnectWithSignup() {
  const defaultValues: ConnectWithSignupForm = {
    email: null,
    password: null,
    fullName: null,
  };

  const formMethods = useForm<ConnectWithSignupForm>({
    resolver: zodResolver(connectWithSignupFormSchema),
    defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm();

  return (
    <div className="flex h-full w-full items-center justify-center">
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => void formMethods.handleSubmit(onSubmit, onError)(e)}
        >
          <div className="border-gray-light flex flex-col border-b px-10 py-4">
            <div className="flex flex-col space-y-1">
              <ConnectWithSignupLabel
                label="Full Name: "
                className="text-gray"
              />
              <Input name="fullName" />
            </div>
            <div className="flex flex-col space-y-1">
              <ConnectWithSignupLabel label="Email: " className="text-gray" />
              <Input name="email" />
            </div>
            <div className="flex flex-col space-y-1">
              <ConnectWithSignupLabel
                label="Password: "
                className="text-gray"
              />
              <Input name="password" />
            </div>
            <div className="mt-4 flex justify-center">
              <Button variant="publish">
                {formMethods.formState.isSubmitting ? "Submitting..." : "Next"}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
