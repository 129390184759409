// Round number to a given scale
// numberToScale(12, 1) -> 12
// numberToScale(12.34, 1) -> 12.3
export function numberToScale(x: number, scale: number) {
  const k = 10 ** scale;
  return Math.floor(x * k) / k;
}

export const numberToScale1 = (x: number) => numberToScale(x, 1);

export const toPercent = (total: number, x: number) =>
  total && Math.round((x * 100) / total);
