import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/email.svg";

import { Separator } from "@dashboard/common/ui/separator";

import { useRemoveIntegration } from "../-hooks/useRemoveIntegration";
import { EmailSettingsForm } from "./EmailSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">Email Integration with Octocom</h2>
      <p className="text-justify text-gray-800">
        Enhance your communication capabilities by connecting your email account
        with Octocom. With this integration, you can seamlessly automate and
        manage your email communications to elevate your customer interactions
        and streamline your business processes.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Seamless Connection:</span> Effortlessly
          link your email account to Octocom, allowing Octocom to receive and
          automatically respond to your customer email queries.
        </li>
        <li>
          <span className="font-medium">Automated Responses:</span> Leverage the
          power of the Octocom bot to handle your email replies. The bot can
          automatically respond to messages, resolve common issues, and provide
          24/7 support, ensuring that your customers receive prompt and accurate
          assistance.
        </li>
        <li>
          <span className="font-medium">Centralized Management:</span> View all
          of your customer support email communications within the Octocom
          dashboard. This centralized approach allows you to monitor emails
          without switching between platforms, improving your customer support
          efficiency.
        </li>
        <li>
          <span className="font-medium">Helpdesk Integration:</span> Utilize
          Octocom&apos;s helpdesk tools to respond to customer inquiries via
          email. Your support team can reply to messages directly from the
          Octocom dashboard, ensuring that customer issues are resolved quickly
          and effectively.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your email communications more
        efficiently, offering both automation and direct management tools to
        ensure a smooth and responsive customer service experience.
      </p>
    </div>
  );
}

export function EmailIntegrationDialog({ installed }: { installed: boolean }) {
  const removeIntegration = useRemoveIntegration();

  return (
    <IntegrationBlock
      integrationName="smtpImap"
      title="Email"
      shortDescription="Automate your email support with Octocom."
      longDescription={<Description />}
      imageSrc={icon}
      installed={installed}
      onRemove={() => removeIntegration({ integrationServiceName: "smtpImap" })}
      settingsForm={<EmailSettingsForm />}
    />
  );
}
