import ModalContainer from "@chatbot/components/ChatWindow/Modal/ModalContainer";
import DisclaimerModalContent from "@chatbot/components/ChatWindow/Disclaimer/DisclaimerModalContent";
import { useUiState } from "@chatbot/hooks/uiState.hooks";

export function DisclaimerModal() {
  const { displayDisclaimer, closeDisclaimer } = useUiState();

  return (
    <ModalContainer show={displayDisclaimer} onClickClose={closeDisclaimer}>
      <DisclaimerModalContent />
    </ModalContainer>
  );
}
