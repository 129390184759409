import { CalendarRange } from "lucide-react";
import { FilterSelect } from "./FilterSelect";

export function TimeRange({
  data,
  setFormValue,
  error,
  clearErrors,
}: {
  data: string | null;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "this-week",
    },
    {
      label: "This Month",
      value: "this-month",
    },
  ];

  return (
    <FilterSelect
      filterName="Time Range"
      valueIcon={CalendarRange}
      options={options}
      data={data}
      setFormValue={setFormValue}
      error={error}
      clearErrors={clearErrors}
    />
  );
}
