import { toast } from "sonner";
import { Route } from "..";

export function useAssignUser(publicId: string) {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.conversations.assignUser.useMutation({
    onSuccess: async (_, args) => {
      await trpcUtils.dashboard.conversations.getCount.invalidate({
        organizationSlug,
      });
      await trpcUtils.dashboard.conversations.get.invalidate({
        organizationSlug,
        publicId,
      });

      if (args.assigneeId === null) {
        toast.success("Conversation moved to Unassigned", {
          position: "bottom-right",
        });
      } else if (args.assigneeId === user.id) {
        toast.success("Conversation moved to Your Inbox", {
          position: "bottom-right",
        });
      } else {
        toast.success("Conversation assigned", {
          position: "bottom-right",
        });
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (value: string | null) => {
    if (value === "unassigned") {
      value = null;
    }

    updateMutation.mutate({
      organizationSlug,
      publicId,
      assigneeId: value,
    });
  };
}
