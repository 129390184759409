import { z } from "zod";

export const macroActionSchema = z.union([
  z.object({ type: z.literal("close"), data: z.null() }),
  z.object({
    type: z.literal("assignMember"),
    data: z.string().uuid(),
  }),
  z.object({ type: z.literal("setTag"), data: z.string().uuid() }),
  z.object({
    type: z.literal("snooze"),
    data: z.union([
      z.literal("1-hour"),
      z.literal("tomorrow"),
      z.literal("next-week"),
      z.literal("next-month"),
      z.string().datetime({ offset: true }),
    ]),
  }),
]);

export const fileSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export const macroSchema = z.object({
  id: z.string().uuid(),
  organizationId: z.string().uuid(),
  businessId: z.string().uuid().nullable(),
  title: z.string(),
  content: z.string().nullable(),
  availableFor: z.string().nullable(),
  actions: z.array(macroActionSchema),
  files: z.array(fileSchema),
});

export const macroUpdateSchema = macroSchema
  .omit({
    organizationId: true,
    files: true,
  })
  .extend({
    fileIds: z.array(z.string().uuid()).nullish(),
  })
  .partial()
  .required({
    id: true,
  });
