import { Transition } from "@headlessui/react";
import { ReactNode } from "react";

interface ModalProps {
  show: boolean;
  onClickClose: () => void;
  children: ReactNode;
}

export default function ModalContainer({
  show,
  onClickClose,
  children,
}: ModalProps) {
  return (
    <Transition show={show}>
      <Transition.Child
        enter="transition-opacity ease-linear duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute bottom-0 top-0 h-full w-full rounded-xl bg-black opacity-20"
          onClick={onClickClose}
        ></div>
      </Transition.Child>

      <Transition.Child
        enter="transition ease-in-out duration-150 transform"
        enterFrom="translate-y-96"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-150 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-96"
      >
        <div className="absolute bottom-0 w-full rounded-b-xl rounded-t-lg bg-white">
          {children}
        </div>
      </Transition.Child>
    </Transition>
  );
}
