import { useFormContext } from "react-hook-form";
import { formDefaults } from "../../../-utils/formDefaults";
import { WebChatConfigForm } from "../../../-utils/formSchema";

function DisclaimerButton() {
  const { watch } = useFormContext<WebChatConfigForm>();

  const disclaimerButtonBackgroundColor =
    watch("disclaimerButtonBackgroundColor") ??
    formDefaults.disclaimerButtonBackgroundColor;
  const disclaimerButtonTextColor =
    watch("disclaimerButtonTextColor") ??
    formDefaults.disclaimerButtonTextColor;

  return (
    <div className="sticky right-0 top-2 ml-auto h-0 w-fit">
      <button
        type="button"
        className="origin-bottom-right -rotate-90 rounded-t-md px-2 py-0.5 text-xs font-light hover:bg-gray-200"
        style={{
          color: disclaimerButtonTextColor,
          backgroundColor: disclaimerButtonBackgroundColor,
        }}
      >
        Disclaimer
      </button>
    </div>
  );
}

export default DisclaimerButton;
