import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/richpanel.png";

import { Separator } from "@dashboard/common/ui/separator";
import { RichpanelSettingsForm } from "./RichpanelSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Richpanel Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Elevate your customer service experience by integrating Richpanel with
        Octocom. This seamless integration allows you to pass unhandled customer
        queries directly to the Richpanel help desk, ensuring that your support
        team can manage and resolve issues efficiently across all channels.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Smooth Query Escalation:</span>{" "}
          Automatically transfer unhandled customer queries from Octocom to
          Richpanel, ensuring that every customer concern is promptly addressed.
        </li>
        <li>
          <span className="font-medium">Centralized Support Management:</span>{" "}
          Handle all your customer interactions within Octocom, with the option
          to escalate unresolved queries to Richpanel for comprehensive
          follow-up and resolution.
        </li>
        <li>
          <span className="font-medium">Enhanced Multi-Channel Support:</span>{" "}
          Leverage Richpanel’s capabilities to manage customer queries across
          various channels, ensuring quick and accurate responses that boost
          customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to deliver exceptional
        customer service by combining Octocom’s centralized management features
        with Richpanel’s multi-channel support tools, leading to more efficient
        issue resolution and higher customer satisfaction.
      </p>
    </div>
  );
}

export function RichpanelIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        integrationName="richpanel"
        title="Richpanel"
        shortDescription="Pass unhandled customer support queries to Richpanel"
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<RichpanelSettingsForm />}
      />
    </>
  );
}
