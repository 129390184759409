import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { UplodDocumentForm } from "./UploadDocumentForm";

export function UploadDocumentDialogContent({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Upload a new document</DialogTitle>
      </DialogHeader>
      <UplodDocumentForm setOpen={setOpen} />
    </>
  );
}
