import { Tooltip } from "@dashboard/common/ui/tooltip";
import { TooltipContentProps } from "@radix-ui/react-tooltip";
import { format, formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";

export function DisplayPassedTimeWithTooltip({
  date,
  side,
  extraText = "",
}: {
  date: string;
  side?: TooltipContentProps["side"];
  extraText?: string;
}) {
  const [, setTick] = useState(0);

  // Effect to update the component every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const content = format(new Date(date), "PPp") + " " + extraText;

  return (
    <Tooltip
      trigger={formatDistanceToNowShort(date)}
      content={content.trim()}
      side={side}
    />
  );
}

function formatDistanceToNowShort(date: string) {
  return formatDistanceToNow(new Date(date))
    .replace(/ years?/, "y")
    .replace(/ months?/, "mon")
    .replace(/ weeks?/, "w")
    .replace(/ days?/, "d")
    .replace(/ hours?/, "h")
    .replace(/ minutes?/, "m")
    .replace("about ", "")
    .replace("less than am", "now");
}
