import { useFormContext } from "react-hook-form";
import AttentionGrabberSuggestion from "./AttentionGrabberSuggestion";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

function AttentionGrabbers() {
  const { watch } = useFormContext<WebChatConfigForm>();

  const positionSide = watch("positionSide") ?? formDefaults.positionSide;
  const selectedSide = watch("selectedSide");
  const launcherSize = watch("launcherSize") ?? formDefaults.launcherSize;

  const attentionGrabberMessage =
    watch("attentionGrabberMessage") ?? formDefaults.attentionGrabberMessage;
  const botIconSrc = watch("botIconSrc") ?? formDefaults.botIconSrc;

  return (
    <div
      style={{
        bottom: `${20 + parseInt(launcherSize)}px`,
        [selectedSide]: positionSide,
      }}
      className="absolute z-[2147483648]"
    >
      <div className="relative flex flex-row">
        <img
          alt="bot icon"
          className="ml-auto mr-3 mt-auto h-7 w-7 rounded"
          src={botIconSrc}
        />
        <div
          className="relative flex min-w-[290px] flex-col rounded-r-xl rounded-tl-xl bg-white px-5 py-[15px] after:absolute after:-left-[5px] after:bottom-0 after:h-0 after:w-0 after:border-b-[13px] after:border-l-[5px] after:border-r-0 after:border-t-0 after:border-solid after:border-x-transparent after:border-b-white after:border-t-transparent"
          style={{ boxShadow: "rgba(35, 47, 53, 0.09) 0px 2px 8px 0px" }}
        >
          <div className="flex flex-row">
            <span className="text-sm text-gray-500">Octo</span>
            <span className="mx-1 text-sm text-gray-500">•</span>
            <span className="text-sm text-gray-500">9m ago</span>
          </div>
          <span className="mt-2 text-sm">{attentionGrabberMessage}</span>
        </div>
      </div>

      <div className="mt-5 flex flex-col items-end space-y-3 overflow-hidden">
        <AttentionGrabberSuggestion index={0} />
        <AttentionGrabberSuggestion index={1} />
        <AttentionGrabberSuggestion index={2} />
      </div>
    </div>
  );
}

export default AttentionGrabbers;
