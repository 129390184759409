import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { ImageLauncher } from "./ImageLauncher";
import { IconTextLauncher } from "./IconTextLauncher";
import { IconLauncher } from "./IconLauncher";

export function Launcher() {
  const config = useConfig();
  const { loadedIntoContainer } = useUiState();

  if (loadedIntoContainer) return null;

  if (config.launcherType === "image" && config.launcherImageFileUrl) {
    return <ImageLauncher />;
  } else if (config.launcherType === "icon_text") {
    return <IconTextLauncher />;
  } else {
    return <IconLauncher />;
  }
}
