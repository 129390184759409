import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { EmailForm, emailFormSchema } from "../-utils/emailFormSchema";
import { useIntegration } from "../-hooks/useIntegration";
import { useSubmitForm } from "../-hooks/useSubmitForm";

export function EmailSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "smtpImap" });

  const defaultValues = {
    fromEmail: "",

    smtpHost: "",
    smtpPort: undefined,
    smtpUsername: "",
    smtpPassword: "",

    imapHost: "",
    imapPort: undefined,
    imapUsername: "",
    imapPassword: "",
  };

  const form = useForm<EmailForm>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "smtpImap",
  });

  function Description() {
    return (
      <div className="flex flex-col items-start gap-4">
        <h1 className="text-2xl font-semibold">Email Integration Settings</h1>

        <p>
          We recommend you create a new email account for this integration,
          specifically for handling customer queries, if you don&apos;t have one
          already. The Octocom bot will be automatically responding to all
          emails sent to the email you setup here.
        </p>

        <p>
          Follow the instructions from your email provider to get the required
          details.
        </p>

        <p>Example Details for FastMail:</p>
        <Button variant="link" className="p-0 font-semibold">
          <a
            href="https://www.fastmail.help/hc/en-us/articles/1500000279921-IMAP-POP-and-SMTP#01H8Q4KWEZNKHYE35XSGMTZ4WX"
            target="_blank"
            rel="noreferrer"
          >
            SMTP & IMAP for Fastmail
          </a>
        </Button>

        <p>Note: for Outlook and Gmail, use the dedicated integrations.</p>
      </div>
    );
  }

  return (
    <>
      <Description />
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div className="pb-4">
            <FormField
              control={form.control}
              name="fromEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Sender Email</FormLabel>
                  <FormControl>
                    <Input placeholder="username@email.com" {...field} />
                  </FormControl>
                  <FormDescription>
                    The email address that will be used to send emails.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex gap-8">
            <div className="flex w-1/2 flex-col gap-2">
              <h2 className="text-lg font-semibold">SMTP</h2>
              <FormField
                control={form.control}
                name="smtpUsername"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input placeholder="smtp@email.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="smtpPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input placeholder="Password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="smtpHost"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Hostname</FormLabel>
                    <FormControl>
                      <Input placeholder="smtp.provider.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="smtpPort"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Port Number</FormLabel>
                    <FormControl>
                      <Input placeholder="465" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex w-1/2 flex-col gap-2">
              <h2 className="text-lg font-semibold">IMAP</h2>
              <FormField
                control={form.control}
                name="imapUsername"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input placeholder="imap@email.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="imapPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input placeholder="Password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="imapHost"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Hostname</FormLabel>
                    <FormControl>
                      <Input placeholder="imap.provider.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="imapPort"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Port Number</FormLabel>
                    <FormControl>
                      <Input placeholder="993" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex w-full justify-end pt-4">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Form>
    </>
  );
}
