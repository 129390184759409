import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { useIntegration } from "../-hooks/useIntegration";
import { BrazeForm, brazeFormSchema } from "../-utils/brazeFormSchema";
import { useSubmitForm } from "../-hooks/useSubmitForm";

export function BrazeSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "braze" });

  const defaultValues = {
    apiKey: "",
    baseUrl: "",
  };

  const form = useForm<BrazeForm>({
    resolver: zodResolver(brazeFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "braze",
  });

  function Description() {
    return (
      <div className="flex flex-col gap-4 leading-normal">
        <h1 className="text-2xl font-semibold">Braze Integration Settings</h1>
        <h2 className="text-xl font-medium">
          Enter your Braze API Key and REST API Endpoint
        </h2>
        <p>
          Your API Key should have the{" "}
          <span className="bg-accent rounded-lg px-4 py-1 font-medium">
            email.status
          </span>{" "}
          permission
        </p>
        <p>
          Instructions on how to get the required details can be found here:
          <div className="py-2">
            <Button variant="link" className="p-0 font-semibold">
              <a
                href="https://www.braze.com/docs/api/basics/?tab=email"
                target="_blank"
                rel="noreferrer"
              >
                Braze API Documentation
              </a>
            </Button>
          </div>
        </p>
      </div>
    );
  }

  return (
    <>
      <Description />
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="apiKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>API Key</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter the API Key" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="baseUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>REST Endpoint</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter the REST API Endpoint"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex w-full justify-end pt-4">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Form>
    </>
  );
}
