import { useConfig } from "@chatbot/hooks/config.hooks";
import { useConversations } from "@chatbot/hooks/conversations.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import clsxm from "@chatbot/utils/clsxm";
import { useState, KeyboardEventHandler, useRef, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Paperclip } from "lucide-react";
import { X } from "lucide-react";
import { trpc } from "@chatbot/utils/trpc";
import { z } from "zod";

export function Input() {
  const {
    isReadyToSendMessage,
    sendMessage,
    isActive,
    conversations,
    email,
    agreedToTermsAndConditions,
    browserSessionId,
  } = useConversations();
  const { requestEmailBeforeChat, requireTermsAndConditionsAgreement } =
    useConfig();
  const { displayChatWindow } = useUiState();

  const [input, setInput] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocusedOnInput, setIsFocusedOnInput] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (inputRef.current) {
        if (isReadyToSendMessage && displayChatWindow) {
          inputRef.current.focus();
        }
      }
    });
  }, [isReadyToSendMessage, displayChatWindow]);

  const [files, setFiles] = useState<{ file: File; id: string }[]>([]);

  const fileUploadMutation = trpc.webChat.getFileUploadUrl.useMutation();
  const cloudinarySignatureMutation =
    trpc.webChat.getCloudinarySignature.useMutation();

  async function handleHeicFile(file: File) {
    const { signature, timestamp, apiKey, cloudName } =
      await cloudinarySignatureMutation.mutateAsync();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("timestamp", timestamp.toString());
    formData.append("signature", signature);
    formData.append("api_key", apiKey);

    const cloudinaryResponse = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: "POST",
        body: formData,
      },
    );

    const cloudinaryResponseSchema = z.object({
      secure_url: z.string().url(),
    });

    const data = (await cloudinaryResponse.json()) as unknown;
    const parsedData = cloudinaryResponseSchema.parse(data);

    const { secure_url: secureUrl } = parsedData;

    const jpgResponse = await fetch(secureUrl.replace(".heic", ".jpg"));

    const jpgFile = new File(
      [await jpgResponse.blob()],
      file.name.replace(".heic", ".jpg"),
      {
        type: "image/jpeg",
      },
    );

    return jpgFile;
  }

  const onClickAttachment = () => {
    console.log("Attachment clicked");
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = true;
    fileInput.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (!files || files.length === 0) {
        return;
      }

      for (const file of files) {
        const processAndUploadFile = async () => {
          let fileToUpload = file;

          if (
            file.type === "image/heic" ||
            file.name.toLowerCase().endsWith(".heic")
          ) {
            try {
              fileToUpload = await handleHeicFile(file);
            } catch (error) {
              console.error("Failed to convert HEIC file:", error);
              return;
            }
          }

          try {
            const { id: fileId, presignedUrl } =
              await fileUploadMutation.mutateAsync({
                browserSessionId,
                filename: fileToUpload.name,
                contentType: fileToUpload.type
                  ? fileToUpload.type
                  : "application/octet-stream",
              });

            const r = await fetch(presignedUrl, {
              method: "PUT",
              body: fileToUpload,
              headers: {
                "Content-Type": fileToUpload.type,
                "x-ms-blob-type": "BlockBlob",
                "x-ms-blob-content-disposition": `attachment; filename="${fileToUpload.name}"`,
              },
            });

            if (!r.ok) {
              throw new Error("Failed to upload file");
            }

            setFiles((prev) => [
              ...prev,
              {
                file: fileToUpload,
                id: fileId,
              },
            ]);
          } catch (error) {
            console.error("Failed to upload file:", error);
            // You might want to show an error message to the user here
          }
        };

        void processAndUploadFile();
      }
    };

    fileInput.click();
  };

  const onSendMessage = () => {
    if (input.trim()) {
      sendMessage({ text: input, fileIds: files.map((f) => f.id) });
      setInput("");
      setFiles([]);
    }
  };

  const handleKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      onSendMessage();
      event.preventDefault();
    }
  };

  const waitingForTermsAndConditionsAgreement =
    requireTermsAndConditionsAgreement &&
    !agreedToTermsAndConditions &&
    !isActive &&
    conversations.length === 1;

  const waitingForEmail =
    requestEmailBeforeChat && !email && !isActive && conversations.length === 1;

  if (waitingForTermsAndConditionsAgreement ?? waitingForEmail) {
    return <div className="h-2"></div>;
  }

  const disabled = !isReadyToSendMessage || waitingForEmail;
  return (
    <div
      className={clsxm(
        "xoverflow-hidden relative border-t border-[#00000014] sm:rounded-b-xl",
        isFocusedOnInput ? "bg-white" : "bg-[#fafafa]",
        disabled && "opacity-50",
      )}
    >
      <TextareaAutosize
        minRows={1}
        maxRows={10}
        aria-label="Write a message..."
        className="w-full flex-1 resize-none border-none bg-inherit pl-[29px] pr-[70px] text-base leading-[1.33] text-[#1a1a1a] outline-none focus:ring-0 sm:text-sm"
        placeholder="Write a message..."
        style={{ paddingTop: "18px", paddingBottom: "18px" }}
        value={input}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onFocus={() => {
          setIsFocusedOnInput(true);
        }}
        onBlur={() => {
          setIsFocusedOnInput(false);
        }}
        ref={inputRef}
      />
      {files.length > 0 && (
        <div
          className="absolute right-[10.25px] top-[-45px] flex flex-col-reverse"
          style={{ right: "10.25px", top: "-45px" }}
        >
          {files.map(({ file, id }, index) => (
            <div
              key={id}
              className="group absolute right-0 h-9 w-9"
              style={{ bottom: `${index * 44 - 36}px`, right: "0px" }}
            >
              <X
                className="absolute -right-2 -top-2 h-4 w-4 cursor-pointer rounded-full border bg-white text-gray-800 opacity-0 shadow group-hover:opacity-100"
                onClick={() =>
                  setFiles((prev) => prev.filter((i) => i.id !== id))
                }
              />
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  className="h-full w-full rounded"
                />
              ) : (
                <div className="flex h-full w-full items-center justify-center rounded border">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {!input && (
        <button
          aria-label="Attach a file"
          disabled={disabled}
          onClick={onClickAttachment}
          className="h-24-px w-24-px absolute right-[20.25px] top-[20.25px] h-4 w-4 text-gray-600"
          style={{ top: "20.25px", right: "20.25px" }}
        >
          <Paperclip className="h-full w-full" />
        </button>
      )}
      {input && (
        <button
          aria-label="Send message"
          disabled={disabled}
          onClick={onSendMessage}
          className="absolute right-[18.25px] top-[18.25px] h-6 w-6 rounded-full bg-gray-800 p-1 text-white"
          style={{ top: "18.25px", right: "18.25px" }}
        >
          <SendArrowIcon />
        </button>
      )}
    </div>
  );
}

function SendArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-full w-full"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
      />
    </svg>
  );
}
