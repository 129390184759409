import { ReactNode } from "react";
import { CardTitle } from "./CardTitle";
import { cn } from "@dashboard/utils/ui";

interface CardProps {
  title: string;
  children?: ReactNode;
  className?: string;
  tooltipText?: string;
  filter?: ReactNode;
}

export function Card({
  title,
  children,
  tooltipText,
  className,
  filter,
}: CardProps) {
  return (
    <div
      className={cn(
        "flex w-full flex-col rounded-lg border border-gray-300 p-6",
        className,
      )}
    >
      <CardTitle title={title} tooltipText={tooltipText} filter={filter} />
      {children}
    </div>
  );
}
