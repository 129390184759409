import { toast } from "sonner";
import { Route } from "..";

export function useResetPassword() {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const resetPasswordMutation = trpc.dashboard.users.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.users.getOrgUsers.invalidate({
        organizationSlug,
      });

      const message = "Password reset successfully.";

      toast(message);
    },

    onError: (error) => {
      const message = "Failed to reset password.";

      toast(message);
      console.error(error);
    },
  });

  return {
    resetPassword: (ids: string[]) => {
      resetPasswordMutation.mutate({
        organizationSlug,
        data: { id: ids[0], hashedPassword: null },
      });
    },
  };
}
