import { useState } from "react";
import { Button } from "./ui/button";
import { Dialog, DialogTrigger, DialogContent } from "./ui/dialog";
import { X } from "lucide-react";

interface ImageDialogProps {
  src: string;
  alt?: string;
  title?: string;
}

export function ImageDialog({ src, alt, title }: ImageDialogProps) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <img
          src={src}
          alt={alt}
          title={title}
          className="max-w-full cursor-pointer rounded-lg transition-opacity hover:opacity-95"
        />
      </DialogTrigger>
      <DialogContent
        className="h-[90vh] max-h-[90vh] w-[90vw] max-w-[90vw] border-none bg-transparent p-0 shadow-none"
        customCloseButton={<CloseButton />}
      >
        <div
          className="flex h-full w-full items-center justify-center"
          onClick={() => setOpen(false)}
        >
          <img
            src={src}
            alt={alt}
            title={title}
            className="h-auto max-h-[80vh] w-auto max-w-[90vw] rounded-lg"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default function CloseButton() {
  return (
    <Button variant="secondary" className="h-9 rounded-lg bg-[#F0F1EF] px-2.5">
      <span className="mr-1 rounded-lg border border-[#E4E5E1] px-1.5 py-0.5 text-xs font-medium text-gray-600">
        Esc
      </span>
      <X className="h-4 w-4" strokeWidth={2} />
    </Button>
  );
}
