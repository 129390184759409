import { proxy, useSnapshot } from "valtio";

const resetStore = proxy({
  resetCount: 0,
});

export function forceReset() {
  resetStore.resetCount++;
}

export function useResetCount() {
  return useSnapshot(resetStore).resetCount;
}
