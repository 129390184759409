import { createFileRoute } from "@tanstack/react-router";
import "@dashboard/styles/markdownContent.css";
import { z } from "zod";
import { useConversation } from "./-hooks/useConversation.hook";
import { ConversationContent } from "./-components/ConversationContent";
import { ConversationDetails } from "./-components/ConversationDetails/ConversationDetails";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId/",
)({
  validateSearch: z.object({
    useTranslation: z.boolean().optional(),
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug, publicId },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
        organizationSlug,
      }),

      trpcClientUtils.dashboard.conversations.tags.getConversationTags.ensureData(
        {
          organizationSlug,
          publicId,
        },
      ),

      trpcClientUtils.dashboard.conversations.tags.getOrganizationTags.ensureData(
        {
          organizationSlug,
        },
      ),

      trpcClientUtils.dashboard.macros.getAvailable.ensureData({
        organizationSlug,
      }),

      trpcClientUtils.dashboard.conversations.get.ensureData({
        organizationSlug,
        publicId,
      }),

      trpcClientUtils.dashboard.conversations.getSubscriptionToken.ensureData({
        organizationSlug,
        publicId,
      }),

      trpcClientUtils.dashboard.conversations.feedback.get.ensureData({
        organizationSlug,
        publicId,
      }),

      trpcClientUtils.dashboard.conversations.subscriptions.hasConfig.ensureData(
        {
          organizationSlug,
          publicId,
        },
      ),

      trpcClientUtils.dashboard.conversations.orders.hasConfig.ensureData({
        organizationSlug,
        publicId,
      }),

      trpcClientUtils.dashboard.conversations.giftCards.hasConfig.ensureData({
        organizationSlug,
        publicId,
      }),
    ]);
  },
  component: ConversationPage,
});

function ConversationPage() {
  const search = Route.useSearch();
  const conversation = useConversation();
  const navigate = Route.useNavigate();
  const { user } = Route.useRouteContext();
  if (
    search.useTranslation &&
    !conversation.messages.some((message) =>
      message.translations.some(
        (translation) => translation.translatedTo === user.translationLanguage,
      ),
    )
  ) {
    void navigate({
      search: { ...search, useTranslation: undefined },
      replace: true,
    });
  }

  return (
    <>
      <ConversationContent />
      <ConversationDetails />
    </>
  );
}
