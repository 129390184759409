import { useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";

export default function DisclaimerButton() {
  const { disclaimerButtonBackgroundColor, disclaimerButtonTextColor } =
    useConfig();
  const { openDisclaimer } = useUiState();

  return (
    <button
      onClick={() => openDisclaimer()}
      className="origin-bottom-right -rotate-90 rounded-t-md bg-[#f5f5f5] px-2 py-0.5 text-xs font-light hover:bg-gray-200"
      style={{
        color: disclaimerButtonTextColor ?? undefined,
        backgroundColor: disclaimerButtonBackgroundColor ?? undefined,
      }}
    >
      Disclaimer
    </button>
  );
}
