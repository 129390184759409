import { HelpCircle } from "lucide-react";

import {
  TooltipRoot,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@dashboard/common/ui/tooltip";
import { ReactNode } from "react";

interface CardTitleProps {
  title: string;
  tooltipText?: string;
  filter?: ReactNode;
}

export function CardTitle({ title, tooltipText, filter }: CardTitleProps) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <h2 className="text-lg font-semibold text-black">{title}</h2>

        {tooltipText && (
          <TooltipProvider delayDuration={0} skipDelayDuration={0}>
            <TooltipRoot>
              <TooltipTrigger asChild>
                <HelpCircle
                  width={16}
                  height={16}
                  className="ml-2 text-black"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-[270px]">{tooltipText}</p>
              </TooltipContent>
            </TooltipRoot>
          </TooltipProvider>
        )}
      </div>
      {filter}
    </div>
  );
}
