import { Button } from "@dashboard/common/ui/button";
import { DialogTrigger } from "@radix-ui/react-dialog";

interface OrderActionButtonProps {
  label: string;
}

export function OrderActionButton({ label }: OrderActionButtonProps) {
  return (
    <DialogTrigger asChild>
      <Button
        variant="secondary"
        className="h-6 flex-grow px-2 py-1 text-xs font-normal"
      >
        {label}
      </Button>
    </DialogTrigger>
  );
}
