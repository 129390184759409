import { TagCommand } from "./TagCommand";
import { Tag } from "./Tag";

import { Route } from "../..";

export function TagSection() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const [tags] =
    trpc.dashboard.conversations.tags.getConversationTags.useSuspenseQuery({
      organizationSlug,
      publicId,
    });

  return (
    <div>
      <div className="pl-7 pt-4 text-xs font-medium uppercase">Tags</div>
      <div className="px-7 pt-5">
        <TagCommand />
      </div>
      {tags.length > 0 && (
        <div className="mt-5 flex max-h-[150px] flex-wrap gap-2 overflow-y-auto px-7">
          {tags.map((tag) => (
            <Tag key={tag.id} title={tag.title} id={tag.id} />
          ))}
        </div>
      )}
    </div>
  );
}
