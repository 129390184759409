import { useCallback, useState, DragEvent, ChangeEvent } from "react";
import { Upload } from "lucide-react";
import { cn } from "@dashboard/utils/ui";

interface FileUploaderProps {
  onFilesSelected: (files: File[]) => Promise<void>;
  acceptedFileTypes?: string[];
  maxFileSize?: number; // in bytes
  multiple?: boolean;
}

export function FileUploader({
  onFilesSelected,
  acceptedFileTypes = [],
  maxFileSize = 5 * 1024 * 1024, // 5MB default
  multiple = false,
}: FileUploaderProps) {
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const validateFile = useCallback(
    (file: File): boolean => {
      if (
        acceptedFileTypes.length > 0 &&
        !acceptedFileTypes.includes(file.type)
      ) {
        setError(`File type ${file.type} is not accepted`);
        return false;
      }
      if (file.size > maxFileSize) {
        setError(`File size exceeds ${maxFileSize / 1024 / 1024}MB limit`);
        return false;
      }
      return true;
    },
    [acceptedFileTypes, maxFileSize],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      setError(null);

      const droppedFiles = Array.from(e.dataTransfer.files);

      if (droppedFiles.length === 0) {
        return;
      }

      const validFiles = droppedFiles.filter(validateFile);

      if (validFiles.length > 0) {
        void onFilesSelected(validFiles);
      }
    },
    [onFilesSelected, validateFile],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setError(null);

      if (!e.target.files) {
        return;
      }

      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles.filter(validateFile);
      if (validFiles.length > 0) {
        void onFilesSelected(validFiles);
      }
    },
    [onFilesSelected, validateFile],
  );

  return (
    <div className="mx-auto w-full">
      <div
        className={cn(
          "relative mt-4 rounded-lg border-2 border-dashed p-6",
          dragActive ? "border-primary" : "border-gray-300",
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple={multiple}
          onChange={handleChange}
          accept={acceptedFileTypes.join(",")}
          className="hidden"
          id="file-upload"
        />
        <label
          htmlFor="file-upload"
          className="flex cursor-pointer flex-col items-center justify-center"
        >
          <Upload className="h-10 w-10 text-gray-400" />
          <p className="mt-2 text-sm text-gray-500">
            Drag & drop files here, or click to select files
          </p>
        </label>
      </div>

      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    </div>
  );
}
