import { Route } from "..";
import { toast } from "sonner";

export function useDeleteFlows() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.flows.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.flows.getAll.invalidate({
        businessSlug,
      });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Flows deleted successfully."
          : "Flow deleted successfully.";

      toast.success(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1 ? "Failed to delete flows." : "Failed to delete flow.";

      toast.error(message);
      console.error(error);
    },
  });

  return {
    deleteFlows: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids });
    },
  };
}
