import { Link } from "@tanstack/react-router";
import logo from "../../../../../../../assets/side-navbar-logo.svg";
import { Route } from "../../_conversationSidebar";
import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";
import { useState } from "react";

function ShopifySubscriptionTutorial() {
  const [dialogOpen, setDialogOpen] = useState(true);

  const { organizationSlug } = Route.useParams();
  const { businessSlug } = Route.useSearch();
  if (!businessSlug) {
    return null;
  }
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent>
        <div className="block">
          <div className="flex w-full justify-center">
            <img alt="Octocom logo" className="h-14 w-14" src={logo} />
          </div>
          <div
            className="flex w-full justify-center"
            style={{ marginTop: "30px" }}
          >
            <div className="text-center">
              <h1 className="text-2xl font-bold">Welcome!</h1>
              <p
                className="text-wrap text-center"
                style={{ marginTop: "30px" }}
              >
                Let&apos;s get you started with Shopify! First thing&apos;s
                first, you&apos;ll need to subscribe to unlock Octocom&apos;s
                features.{" "}
                <Link
                  from="/organization/$organizationSlug"
                  to="/organization/$organizationSlug/settings/$businessSlug/billing"
                  params={{ organizationSlug, businessSlug }}
                  className="text-blue-600 hover:underline"
                >
                  click here
                </Link>{" "}
                to view our pricing plans.
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShopifySubscriptionTutorial;
