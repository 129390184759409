import { z } from "zod";

export const businessConfigFormSchema = z.object({
  name: z.string(),
  website: z.string().url().nullable(),
  oneSentenceDescription: z.string().nullable(),
  about: z.string().nullable(),
  enableFeedback: z.boolean(),
  enableMacroRecommendations: z.boolean(),
  tz: z.string().nullable().optional(),
});

export type BusinessConfigForm = z.infer<typeof businessConfigFormSchema>;
