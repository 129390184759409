import { ReactNode } from "react";

interface MessageHeaderProps {
  icon: ReactNode;
  title: string;
  subtitle?: string;
}

export function MessageHeader({ icon, title, subtitle }: MessageHeaderProps) {
  return (
    <div className="flex flex-row items-center">
      {icon}
      <span className="ml-1 text-sm font-medium">{title}</span>
      {subtitle && (
        <span className="ml-2 text-sm text-gray-600">{subtitle}</span>
      )}
    </div>
  );
}
