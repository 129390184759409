import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import ConnectContainer from "../-components/ConnectContainer";
import { useState, useEffect } from "react";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";
export const Route = createFileRoute("/connect/demo/")({
  validateSearch: z.object({
    integrationData: z.unknown(),
    integrationServiceName: integrationServiceNameSchema,
  }),
  component: BookDemo,
});

function BookDemo() {
  const search = Route.useSearch();

  const navigate = useNavigate();

  const onBookDemo = () => {
    window.location.href = "https://www.octocom.ai/request-demo";
  };

  const onLogin = () => {
    window.location.href = "/"; // Intentional, navigate causes a flashbang for a sec
  };

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("connectIntegrationData")) {
      localStorage.setItem(
        "connectIntegrationData",
        JSON.stringify({ ...search, ...{ onboardType: "demo" } }),
      );
    }

    if (window.self !== window.top && window.top) {
      const currentLocation = window.location.href;
      window.open(currentLocation, "_blank"); // if running in an iframe - step out of the iframe
      void navigate({ to: "/public-routes/welcome" });
    } else {
      setShouldRender(true); // Ensure rendering happens only if not in iframe
    }
  }, []);

  return (
    shouldRender && (
      <ConnectContainer>
        <h1 className="text-2xl font-bold">Welcome!</h1>
        <p className="text-wrap text-center" style={{ marginTop: "30px" }}>
          We&apos;re excited to show you how Octocom can help you manage your
          customer support!
        </p>
        <p style={{ marginTop: "30px", marginBottom: "40px" }}>
          <Button
            onClick={onBookDemo}
            className="w-full"
            style={{ height: "40px" }}
          >
            Book your demo
          </Button>
        </p>
        <div>
          <p>Already have an account?</p>
          <a
            onClick={onLogin}
            className="cursor-pointer text-blue-600 hover:underline"
          >
            <p style={{ marginTop: "3px", fontWeight: 500 }}>
              Continue to dashboard
            </p>
          </a>
        </div>
      </ConnectContainer>
    )
  );
}
