import { Route } from "../_conversationList";

export function useRemoveConversation({
  pageParams,
}: {
  pageParams: (string | null)[];
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, sortBy, status } = Route.useSearch();
  const trpcUtils = trpc.useUtils();

  return (id: { conversationId: string } | { publicId: string }) => {
    trpcUtils.dashboard.conversations.getAll.setInfiniteData(
      { organizationSlug, view, filters, sortBy, status },
      (oldData) => {
        if (oldData === undefined) {
          return oldData;
        }

        const pagesWithoutDeletedConversation = oldData.pages.map((page) => {
          if ("publicId" in id) {
            return {
              ...page,
              conversations: page.conversations.filter(
                (conversation) => conversation.publicId !== id.publicId,
              ),
            };
          } else {
            return {
              ...page,
              conversations: page.conversations.filter(
                (conversation) => conversation.id !== id.conversationId,
              ),
            };
          }
        });
        return {
          pages: pagesWithoutDeletedConversation,
          pageParams,
        };
      },
    );
  };
}
