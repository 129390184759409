import { UseFormSetError } from "react-hook-form";
import { toast } from "sonner";
import { Route } from "..";
import { UserForm } from "../-utils/userFormSchema";

export const useCreateUser = ({
  setError,
  setPassword,
}: {
  setError: UseFormSetError<UserForm>;
  setPassword: (password: string) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const createMutation = trpc.dashboard.users.create.useMutation({
    onSuccess: async (data) => {
      if (data.success) {
        await trpcUtils.dashboard.users.getOrgUsers.invalidate({
          organizationSlug,
        });

        setPassword(data.password);

        toast.success("User created.");
      } else {
        setError("email", {
          type: "manual",
          message: data.error,
        });
      }
    },
    onError: (error) => {
      toast.error("Failed to create user.");
      console.error(error);
    },
  });

  return {
    onSubmit: (data: UserForm) => {
      createMutation.mutate({
        organizationSlug,
        ...data,
      });
    },

    onError: (error: unknown) => {
      console.error(error);
    },
  };
};
