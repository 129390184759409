function MessageInput() {
  return (
    <div className="relative flex flex-row items-center overflow-hidden border-t border-[#00000014] bg-white sm:rounded-b-xl">
      <textarea
        disabled
        rows={1}
        className="w-full flex-1 resize-none border-none bg-inherit py-[16px] pl-[29px] pr-[70px] text-base leading-[1.33] text-[#1a1a1a] outline-none focus:ring-0 sm:text-sm"
        placeholder="Write a message..."
      ></textarea>
      <button
        disabled
        className="absolute right-[18.25px] h-6 w-6 rounded-full bg-gray-800 p-1 text-white opacity-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-full w-full"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
          ></path>
        </svg>
      </button>
    </div>
  );
}

export default MessageInput;
