import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@dashboard/common/ui/dialog";
import { EditUserForm } from "./EditUserForm";

export function EditUserDialogContent({
  id,
  fullName,
  email,
  setOpen,
}: {
  id: string;
  fullName: string;
  email: string;
  setOpen: (open: boolean) => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Edit team member</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Edit the details of the user you want to update.
      </DialogDescription>
      <EditUserForm
        id={id}
        fullName={fullName}
        email={email}
        setOpen={setOpen}
      />
    </>
  );
}
