import { Tooltip } from "@dashboard/common/ui/tooltip";
import { X } from "lucide-react";
import { ActionComponent } from "./ActionComponent";
import { MacroAction } from "@be/modules/macros/macros.types";

export function DashboardAction({
  action,
  remove,
}: {
  action: MacroAction;
  remove: () => void;
}) {
  const handleRemove = () => {
    remove();
  };

  return (
    <div className="bg-gray-light group relative flex items-center justify-between gap-2 rounded-lg px-2 py-1.5 text-xs">
      <div className="group-hover:bg-gray-light absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
      <Tooltip
        triggerAsChild
        content="Remove action"
        side="top"
        trigger={
          <X
            className="group-hover:bg-gray-light absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors group-hover:text-black"
            onClick={handleRemove}
          />
        }
      />
      <ActionComponent action={action} />
    </div>
  );
}
