import { Network } from "lucide-react";
import { SelectFilter } from "./SelectFilter";

export function TypeFilter() {
  const options = [
    {
      label: "External Only",
      value: "external",
    },
    {
      label: "Customer & External",
      value: "all",
    },
  ];

  return (
    <SelectFilter
      options={options}
      valueIcon={Network}
      name="Type"
      filterKey="type"
    />
  );
}
