import { Route } from "..";
import { toast } from "sonner";

export function useDeleteMacros() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.macros.delete.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.macros.getAvailable.invalidate({
        organizationSlug,
      });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Macros deleted successfully."
          : "Macro deleted successfully.";

      toast.success(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1 ? "Failed to delete macros." : "Failed to delete macro.";

      toast.error(message);
      console.error(error);
    },
  });

  return {
    deleteMacros: (ids: string[]) => {
      deleteMutation.mutate({ organizationSlug, ids });
    },
  };
}
