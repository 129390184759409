import { toast } from "sonner";
import { Route } from "../..";

export function useSubscriptionCancel() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const trpcUtils = trpc.useUtils();

  const cancelSubscriptionMutation =
    trpc.dashboard.conversations.subscriptions.cancel.useMutation({
      onError: (error) => {
        toast.error("Failed to cancel subscription.");
        console.error(error);
      },
      onSuccess: async () => {
        toast.success("Subscription cancelled successfully.");
        await trpcUtils.dashboard.conversations.subscriptions.getDetails.invalidate();
        await trpcUtils.dashboard.conversations.subscriptions.getDetails.refetch();
      },
    });

  return {
    cancelSubscription: ({ subscriptionId }: { subscriptionId: string }) => {
      cancelSubscriptionMutation.mutate({
        organizationSlug,
        publicId,
        subscriptionId,
      });
    },
  };
}
