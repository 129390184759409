import { minutesToMilliseconds } from "date-fns";
import { Route } from "../..";

export function useOrders() {
  const { publicId, organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const {
    data: orderDetails,
    isLoading,
    refetch,
  } = trpc.dashboard.conversations.orders.getDetails.useQuery(
    {
      organizationSlug,
      publicId,
    },
    {
      refetchInterval: minutesToMilliseconds(5),
    },
  );

  return {
    isLoading,
    data: orderDetails,
    refetch,
  };
}
