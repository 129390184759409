import { useNavigate } from "@tanstack/react-router";
import { Route } from "..";
import { toast } from "sonner";
import { ConnectWithSignupForm } from "../-utils/formSchema";
import { TRPCClientError } from "@trpc/client";

export function useSubmitForm() {
  const { integrationData, integrationServiceName } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const navigate = useNavigate();

  const registerMutation =
    trpc.dashboard.integrations.installWithSignup.useMutation({
      onSuccess: () => {
        toast(
          "User registered successfully, you will be redirected to our login page in 5 seconds.",
        );
        setTimeout(() => {
          void navigate({ to: "/organization" });
        }, 5000);
      },
      onError: (e: unknown) => {
        if (e instanceof TRPCClientError) {
          toast.error(e.message);
        } else {
          toast.error("An unknown error occurred. Please try again.");
        }
      },
    });

  return {
    onSubmit: (data: ConnectWithSignupForm) => {
      if (!data.email || !data.password || !data.fullName) {
        toast("Please fill out all fields.");
        return;
      }
      registerMutation.mutate({
        integrationServiceName,
        integrationData,
        email: data.email,
        password: data.password,
        fullName: data.fullName,
      });
    },
    onError: (errors: unknown) => {
      console.error(errors);
      toast("Failed to register user.");
    },
  };
}
