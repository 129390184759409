import { z } from "zod";

export const feedbackFormSchema = z.union([
  z.object({
    correctAction: z.literal("no"),
    comment: z.string(),
  }),
  z.object({
    correctAction: z.literal("yes"),
    comment: z.string().optional(),
  }),
]);

export type FeedbackForm = z.infer<typeof feedbackFormSchema>;
