import { toast } from "sonner";
import { Route } from "..";

export function useUpdatePermissions() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const updatePermissionsMutation = trpc.dashboard.users.update.useMutation({
    onError: (error) => {
      const message = "Failed to update permissions.";

      toast(message);
      console.error(error);
    },
  });

  return {
    updatePermissions: ({
      id,
      isManager,
    }: {
      id: string;
      isManager: boolean;
    }) => {
      updatePermissionsMutation.mutate({
        organizationSlug,
        data: { id, isManager },
      });
    },
  };
}
