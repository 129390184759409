import { z } from "zod";

export const hubspotFormSchema = z.object({
  apiKey: z
    .string({
      required_error: "API Key is required",
      invalid_type_error: "API Key is required",
    })
    .min(1, "API Key is required"),
});

export type HubspotForm = z.infer<typeof hubspotFormSchema>;
