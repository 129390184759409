import { Control } from "react-hook-form";
import { FileUploader } from "@dashboard/common/FileUploader";
import { MacroForm } from "../-utils/macroFormSchema";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { File, X } from "lucide-react";
import { useFileUpload } from "../-hooks/useUploadFile";
import { Tooltip } from "@dashboard/common/ui/tooltip";

interface AttachmentsFormFieldProps {
  control: Control<MacroForm>;
}

export function AttachmentsFormField({ control }: AttachmentsFormFieldProps) {
  const { uploadFiles } = useFileUpload();

  return (
    <FormField
      control={control}
      name="files"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Attachments</FormLabel>
          <FormControl>
            <div className="space-y-4">
              <FileUploader
                onFilesSelected={async (files) => {
                  const uploadedFiles = await uploadFiles({
                    files,
                  });

                  const filesToAdd = uploadedFiles.map((file) => ({
                    id: file.fileId,
                    name: file.filename,
                  }));

                  field.onChange([...field.value, ...filesToAdd]);
                }}
                multiple
              />

              <div className="space-y-2">
                {field.value.map((file) => (
                  <div
                    key={file.id}
                    className="bg-gray-light group relative flex items-center justify-between gap-2 rounded-lg px-2 py-2 text-xs"
                  >
                    <div className="group-hover:bg-gray-light absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
                    <Tooltip
                      triggerAsChild
                      content="Remove attachment"
                      side="top"
                      trigger={
                        <X
                          className="group-hover:bg-gray-light absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors group-hover:text-black"
                          onClick={() => {
                            field.onChange(
                              field.value.filter((f) => f.id !== file.id),
                            );
                          }}
                        />
                      }
                    />
                    <div className="flex max-w-full items-center">
                      <File
                        className="mr-2 h-4 w-4 shrink-0"
                        strokeWidth={1.5}
                      />
                      <span>{file.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
