import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateConversationData } from "../../../-hooks/useInvalidateConversationData";
import { MacroAction } from "@be/modules/macros/macros.types";

export function useSendExternalMessage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const invalidate = useInvalidateConversationData({ publicId });

  const sendExternalMessageMutation =
    trpc.dashboard.conversations.external.sendMessage.useMutation({
      onSuccess: async () => {
        await invalidate();
      },
      onError: (error) => {
        console.error("Error sending message", error);
        toast.error("Error sending message");
      },
    });

  return ({
    text,
    actions,
    fileIds,
  }: {
    text: string;
    actions: MacroAction[];
    fileIds?: string[];
  }) =>
    sendExternalMessageMutation.mutate({
      organizationSlug,
      publicId,
      text,
      actions,
      fileIds,
    });
}
