import { Route } from "..";
import { toast } from "sonner";
import { ViewFormPayload } from "../-utils/viewFormSchema";

export function useEditView({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.views.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.views.getAll.invalidate({
        organizationSlug,
      });

      setOpen(false);
      toast.success("View updated");
    },
    onError: () => {
      toast.error("Failed to update view");
    },
  });

  return {
    onSubmit: (data: ViewFormPayload & { id: string }) => {
      updateMutation.mutate({ organizationSlug, ...data });
    },
  };
}
