import { Route } from "..";
import { toast } from "sonner";

import { BotRuleForm } from "../-utils/botRuleFormSchema";

export function useEditBotRule({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.bots.updateRule.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getRules.invalidate({ businessSlug });

      setOpen(false);
      toast.success("Bot rule updated");
    },
    onError: () => {
      toast.error("Failed to update bot rule");
    },
  });

  return {
    onSubmit: ({ id, title, rule }: BotRuleForm & { id: string }) => {
      updateMutation.mutate({ businessSlug, id, title, rule });
    },
  };
}
