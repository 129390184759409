import { Macro } from "@be/modules/macros/macros.types";
import { ActionComponent } from "./ActionComponent";
import { MacroContent } from "./MacroContent";
import { FileComponent } from "./FileComponent";
import { Separator } from "@dashboard/common/ui/separator";

export function MacroPreview({ macro }: { macro: Macro }) {
  return (
    <div className="flex w-full flex-col gap-2 p-4 text-justify">
      <div className="text-lg font-semibold">{macro.title}</div>
      {macro.content && (
        <div className="text-sm">
          <MacroContent input={macro.content} />
        </div>
      )}
      {macro.actions.length > 0 && (
        <>
          <Separator className="my-2" />
          <div className="jusitify-start flex flex-wrap items-center gap-2">
            {macro.actions.map((action, index) => (
              <div
                className="bg-gray-light flex h-8 items-center justify-start gap-2 rounded-lg px-4 py-2 text-sm font-medium"
                key={index}
              >
                <ActionComponent action={action} />
              </div>
            ))}
          </div>
        </>
      )}
      {macro.files.length > 0 && (
        <>
          <Separator className="my-2" />
          <div className="flex flex-wrap items-center gap-2">
            {macro.files.map((file, index) => (
              <div
                className="bg-gray-light flex h-8 items-center justify-start gap-2 rounded-lg px-4 py-2 text-sm font-medium"
                key={index}
              >
                <FileComponent key={index} file={file} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
