import { differenceInDays, format, subDays } from "date-fns";
import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";

export function useData() {
  const { period, businessSlug } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const range = periodToRange(period);
  const days = differenceInDays(range.to, range.from);

  const [cur] = trpc.dashboard.metrics.get.useSuspenseQuery({
    organizationSlug,
    businessSlug,
    from: range.from,
    to: range.to,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const prevRange = {
    from: subDays(range.from, days),
    to: range.from,
  };

  const [prev] = trpc.dashboard.metrics.get.useSuspenseQuery({
    organizationSlug,
    businessSlug,
    from: prevRange.from,
    to: prevRange.to,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return {
    cur,
    prev,
    from: range.from,
    comparedTo: {
      days,
      from: format(prevRange.from, "PPp"),
      // prevRange.to is not inclusive, but better to display inclusive to a user
      to: format(subDays(prevRange.to, 1), "PPp"),
    },
  };
}
