import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@dashboard/utils/ui";
import {
  // TooltipArrow,
  TooltipContentProps,
  TooltipPortal,
  TooltipProps,
} from "@radix-ui/react-tooltip";
import { ReactNode, useState } from "react";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    animate?: false;
  }
>(({ className, sideOffset = 8, animate = true, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      // -m-px is to let tooltip arrow overlay the border
      "bg-popover z-50 -m-px overflow-hidden rounded-md border px-3 py-1.5 text-sm text-black shadow-md",
      animate && "animate-in fade-in-0 zoom-in-95",
      "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
      "data-[side=top]:slide-in-from-bottom-2",
      "data-[side=left]:slide-in-from-right-2",
      "data-[side=right]:slide-in-from-left-2",
      "data-[side=bottom]:slide-in-from-top-2",
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type CustomTooltipProps = Omit<TooltipProps, "children"> & {
  trigger: ReactNode;
  triggerAsChild?: boolean;
  content: ReactNode;
  side?: TooltipContentProps["side"];
  sideOffset?: number;
  animate?: false;
  className?: string;
};

function Tooltip({
  trigger,
  triggerAsChild,
  side,
  sideOffset,
  animate,
  content,
  className,
  ...props
}: CustomTooltipProps) {
  return (
    <TooltipRoot {...props}>
      <TooltipTrigger asChild={triggerAsChild}>{trigger}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          side={side}
          animate={animate}
          sideOffset={sideOffset}
          className={className}
        >
          {content}
          {/* Radix arrow behaves not very well with animation */}
          {/*<TooltipArrow fill="white" />*/}
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  );
}

function TruncatedTooltip({
  className,
  text,
  ...props
}: Omit<CustomTooltipProps, "triggerAsChild" | "trigger" | "content"> & {
  className?: string;
  text: ReactNode;
}) {
  const [needTooltip, setNeedTooltip] = useState(false);

  if (!needTooltip) {
    return (
      <div
        onMouseEnter={(e) => {
          // tooltip might not be needed initially, then user can resize window,
          // and the tooltip will show up on mouse enter
          const el = e.target as HTMLDivElement;
          if (el.scrollWidth > el.offsetWidth) {
            setNeedTooltip(true);
          }
        }}
        className={cn("truncate", className)}
      >
        {text}
      </div>
    );
  }

  return (
    <Tooltip
      triggerAsChild
      trigger={<div className={cn("truncate", className)}>{text}</div>}
      content={text}
      {...props}
    />
  );
}

export {
  Tooltip,
  TruncatedTooltip,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
};
