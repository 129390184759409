import { Select } from "@dashboard/common/ui/select";

import { Route } from "../_conversationSidebar";
import { useState } from "react";

export function BusinessSelect() {
  const [businessSlug, setBusinessSlug] = useState("all");

  const { businesses } = Route.useRouteContext();

  const options = businesses.map((business) => ({
    label: business.name,
    value: business.id,
  }));

  const groups = [
    {
      title: "Businesses",
      options: [
        {
          label: "All Businesses",
          value: "all",
        },
      ],
    },
    {
      title: "",
      options,
    },
  ];

  const search = Route.useSearch();

  const navigate = Route.useNavigate();
  const setSearch = (value: string | undefined) => {
    setBusinessSlug(value ?? "all");

    if (value === "all") value = undefined;
    void navigate({
      search: {
        ...search,
        filters: { ...search.filters, businessId: value },
      },
    });
  };

  return (
    <>
      {businesses.length > 1 && (
        <>
          <Select
            className="mb-4"
            variant="dropdown"
            value={businessSlug}
            defaultValue={options[0].value}
            onChange={(value) => setSearch(value)}
            groups={groups}
          />
        </>
      )}
    </>
  );
}
