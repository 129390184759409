import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@dashboard/utils/ui";
import { useFormContext } from "react-hook-form";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, name, defaultChecked, ...props }, ref) => {
  const formContext = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const setValue = formContext ? formContext.setValue : undefined;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const watch = formContext ? formContext.watch : undefined;

  const value = watch && name ? (watch(name) as boolean) : defaultChecked;

  const handleChange = (checked: boolean | "indeterminate") => {
    if (setValue && name) {
      setValue(name, checked);
    }
  };

  return (
    <>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          "border-primary focus-visible:ring-ring data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground peer h-4 w-4 shrink-0 rounded-sm border shadow focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        onCheckedChange={(checked) => handleChange(checked)}
        defaultChecked={value}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn("flex items-center justify-center text-current")}
        >
          <CheckIcon className="h-4 w-4" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    </>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
