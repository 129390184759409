import { trpc } from "@chatbot/utils/trpc";
import { secondsToMilliseconds } from "date-fns";
import { useEffect, useMemo } from "react";
import { z } from "zod";
import { forceReset } from "./reset.hooks";
import { env } from "@chatbot/env";
import { proxy, useSnapshot } from "valtio";

const browserSessionStore = proxy({
  id: undefined as undefined | string,
});

const disableInputStore = proxy({
  inputDisabled: false,
});

export function useInitializeBrowserSession({
  configId,
}: {
  configId: string | undefined;
}) {
  const persistedBrowserSessionId = useMemo(
    () => (configId ? loadPersistedBrowserSessionId({ configId }) : undefined),
    [configId],
  );

  const browserSessionExists = trpc.webChat.browserSessionExists.useQuery(
    { browserSessionId: persistedBrowserSessionId ?? "" },
    {
      enabled: !!persistedBrowserSessionId,
    },
  );

  const newBrowserSessionMutation =
    trpc.webChat.newBrowserSession.useMutation();

  useEffect(() => {
    if (!persistedBrowserSessionId && configId) {
      void newBrowserSessionMutation
        .mutateAsync({ configId, userAgent: navigator.userAgent })
        .then(({ browserSessionId }) => {
          browserSessionStore.id = browserSessionId;
          persistBrowserSessionId({ configId, browserSessionId });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedBrowserSessionId, configId]);

  useEffect(() => {
    if (configId) {
      if (browserSessionExists.data?.exists === false) {
        console.log("Browser session was deleted, creating a new one");
        void newBrowserSessionMutation
          .mutateAsync({ configId, userAgent: navigator.userAgent })
          .then(({ browserSessionId }) => {
            browserSessionStore.id = browserSessionId;
            persistBrowserSessionId({ configId, browserSessionId });
          });
      } else if (
        browserSessionExists.data?.exists === true &&
        persistedBrowserSessionId
      ) {
        browserSessionStore.id = persistedBrowserSessionId;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSessionExists.data, configId]);
  return useStoredBrowserSession();
}

export function useStoredBrowserSession() {
  return useSnapshot(browserSessionStore).id;
}

export function useDisableInput() {
  return useSnapshot(disableInputStore).inputDisabled;
}

export function usePing() {
  const id = useStoredBrowserSession();

  const notifyBackendMutation = trpc.webChat.disconnectEvent.useMutation();

  const pingMutation = trpc.webChat.pingBrowserSession.useMutation({
    onSuccess(data) {
      if (!id) {
        return;
      }
      if (data !== id) {
        notifyBackendMutation.mutate({ oldSessionId: data, newSessionId: id });
        console.log("resetting octocom state due to session id mismatch");
        disableInputStore.inputDisabled = true;
        forceReset();
      } else {
        disableInputStore.inputDisabled = false;
      }
    },
    onError(error) {
      console.log(
        "attempted to ping browser session",
        id,
        "but failed with error",
        error,
      );
      console.log("resetting state");
      disableInputStore.inputDisabled = true;
      forceReset();
    },
  });

  useEffect(() => {
    async function sendPing() {
      const url = `${env.CHATBOT_BASE_API_URL}/system/is-ok`;
      try {
        await fetch(url);
      } catch {
        console.log("Server is down, cannot ping");
        console.log("resetting state");
        disableInputStore.inputDisabled = true;
        forceReset();
        return;
      }

      if (!id) {
        return;
      }

      pingMutation.mutate({ browserSessionId: id });
    }

    const intervalId = setInterval(
      () => void sendPing(),
      secondsToMilliseconds(6),
    );

    void sendPing();

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
}

function loadPersistedBrowserSessionId({
  configId,
}: {
  configId: string;
}): string | null {
  const value = localStorage.getItem(`octocom-browser-session-id:${configId}`);
  if (!value) return null;

  const parsedUuid = z.string().uuid().safeParse(value);

  if (!parsedUuid.success) {
    return null;
  }

  return parsedUuid.data;
}

function persistBrowserSessionId({
  configId,
  browserSessionId,
}: {
  configId: string;
  browserSessionId: string;
}) {
  localStorage.setItem(
    `octocom-browser-session-id:${configId}`,
    browserSessionId,
  );
}
