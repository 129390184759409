import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import { TrendIndicator } from "./TrendIndicator/TrendIndicator";

interface CustomTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      {/* <line x1={0} y1={-7.5} x2={0} y2={-2} stroke="#c7c5c5" /> */}
      <text
        x={0}
        y={0}
        dy={8}
        fontSize={10}
        fontFamily="Arial"
        fontWeight="light"
        textAnchor="middle"
        fill="#6a6a75"
      >
        {payload?.value}
      </text>
    </g>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    value?: number;
  }[];
  label?: string;
  yTitle?: string; // Add this line
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  yTitle, // Add this line
}) => {
  if (active && payload?.length) {
    const count = payload[0].value;
    return (
      <div className="custom-tooltip rounded-md border border-gray-300 bg-white px-3 py-2.5 shadow-sm">
        <p className="font-medium text-blue-500">{`${count} ${yTitle}`}</p>{" "}
        {/* Change here */}
        <p className="text-gray-700">{format(new Date(label ?? ""), "PPp")}</p>
      </div>
    );
  }
  return null;
};

interface ChartProps {
  value: number;
  trendingIndicator: {
    higherIsBetter: boolean;
    diff: number;
    comparedTo: {
      days: number;
      from: string;
      to: string;
    };
  };
  yTitle: string;
  data: {
    x: string;
    y: number;
  }[];
}

export const Chart: React.FC<ChartProps> = ({
  data,
  yTitle,
  value,
  trendingIndicator,
}) => {
  return (
    <div className="w-full">
      <div className="mt-4 flex flex-row items-center">
        <span className="text-2xl text-black">
          {value} {yTitle}
        </span>
        <TrendIndicator
          showPreviousDays={true}
          value={trendingIndicator.diff}
          higherIsBetter={trendingIndicator.higherIsBetter}
          comparedTo={trendingIndicator.comparedTo}
        />
      </div>
      <div className="mt-10 h-[280px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              stroke="#F2F2F2"
              horizontal={true}
              vertical={false}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{
                dx: -15,
                style: {
                  fontFamily: "Arial",
                  fontSize: 11,
                  fontWeight: "light",
                },
              }}
            />
            <XAxis
              dataKey="x"
              axisLine={false}
              tickLine={false}
              tick={<CustomTick />}
            />
            <Tooltip<number, string>
              content={(props) => <CustomTooltip {...props} yTitle={yTitle} />}
              cursor={{ fill: "transparent" }}
            />

            <Bar dataKey="y" fill="#3482F6" barSize={14} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
