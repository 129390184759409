import { ReactNode } from "react";
import { Route } from "../..";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import {
  ArrowUpRight,
  BriefcaseBusiness,
  CalendarRange,
  InboxIcon,
  Languages,
  MessageSquareDashed,
  NetworkIcon,
  TagIcon,
  User,
} from "lucide-react";

function StaticFilterContainer({ children }: { children: ReactNode }) {
  return (
    <div className="bg-gray-light relative flex h-8 items-center justify-between gap-2 rounded-lg px-4 py-2 text-sm font-medium">
      {children}
    </div>
  );
}

export function StaticFilterComponent({
  filter,
}: {
  filter: { key: FilterKeys; value: string };
}) {
  const { trpc, businesses } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const [languages] =
    trpc.dashboard.conversations.getLanguages.useSuspenseQuery({
      organizationSlug,
    });

  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  switch (filter.key) {
    case "assignee": {
      let message = "Assigned To: User not found";
      if (filter.value === "unassigned") {
        message = "Unassigned";
      } else if (filter.value === "anyone") {
        message = "Anyone";
      } else {
        const user = users.find((user) => user.id === filter.value);
        message = user ? user.fullName : message;
      }

      return (
        <StaticFilterContainer>
          <User className="h-4 w-4" />
          <span>Assignee: {message}</span>
        </StaticFilterContainer>
      );
    }
    case "businessId": {
      const business = businesses.find(
        (business) => business.id === filter.value,
      );

      return (
        <StaticFilterContainer>
          <BriefcaseBusiness className="h-4 w-4" />
          <span>
            Business: {business ? business.name : "Business not found"}
          </span>
        </StaticFilterContainer>
      );
    }
    case "channel": {
      const channels = {
        email: "Email",
        web: "Web",
        instagram: "Instagram",
        instagramComment: "Instagram Comments",
        messenger: "Messenger",
        facebookComment: "Facebook Comments",
      };

      return (
        <StaticFilterContainer>
          <MessageSquareDashed className="h-4 w-4" />
          <span>Channel: {channels[filter.value as "email" | "web"]}</span>
        </StaticFilterContainer>
      );
    }
    case "handedOff": {
      const handedOff = {
        yes: "Yes",
        no: "No",
      };

      return (
        <StaticFilterContainer>
          <ArrowUpRight className="h-4 w-4" />
          <span>Handed Off: {handedOff[filter.value as "yes" | "no"]}</span>
        </StaticFilterContainer>
      );
    }
    case "language": {
      const language = languages.find(
        (language) => language.code === filter.value,
      );

      return (
        <StaticFilterContainer>
          <Languages className="h-4 w-4" />
          <span>
            Language: {language ? language.name : "Language not found"}
          </span>
        </StaticFilterContainer>
      );
    }
    case "timeRange": {
      const timeRanges = {
        today: "Today",
        "this-week": "This Week",
        "this-month": "This Month",
      };

      return (
        <StaticFilterContainer>
          <CalendarRange className="h-4 w-4" />
          <span>
            Time Range:{" "}
            {timeRanges[filter.value as "today" | "this-week" | "this-month"]}
          </span>
        </StaticFilterContainer>
      );
    }
    case "status": {
      const statuses = {
        open: "Open",
        closed: "Closed",
        snoozed: "Snoozed",
      };

      return (
        <StaticFilterContainer>
          <InboxIcon className="h-4 w-4" />
          <span>
            Status: {statuses[filter.value as "open" | "closed" | "snoozed"]}
          </span>
        </StaticFilterContainer>
      );
    }
    case "tag": {
      const tag = tags.find((tag) => tag.id === filter.value);

      return (
        <StaticFilterContainer>
          <TagIcon className="h-4 w-4" />
          <span>Tag: {tag ? tag.title : "Tag not found"}</span>
        </StaticFilterContainer>
      );
    }
    case "type": {
      const types = {
        all: "Customer & External",
        external: "External Only",
      };

      return (
        <StaticFilterContainer>
          <NetworkIcon className="h-4 w-4" />
          <span>Type: {types[filter.value as "all" | "external"]}</span>
        </StaticFilterContainer>
      );
    }
  }
}
