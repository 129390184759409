import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { CreateBotRuleDialogContent } from "./-components/CreateBotRuleDialogContent";
import { useDeleteBotRules } from "./-hooks/useDeleteBotRules";
import { BotRuleDialog } from "./-components/BotRuleDialog";
import { EditBotRuleDialogContent } from "./-components/EditBotRuleDialogContent";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/bot-rules/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.bots.getRules.ensureData({
      businessSlug,
    });
  },
  component: BotRulesPage,
});

function BotRulesPage() {
  const { trpc } = Route.useRouteContext();

  const { businessSlug } = Route.useParams();

  const [botRules] = trpc.dashboard.bots.getRules.useSuspenseQuery({
    businessSlug,
  });

  const { deleteRules } = useDeleteBotRules();

  const records = botRules.map((rule) => ({
    id: rule.id,
    titleDialog: BotRuleDialog({
      title: rule.title,
      rule: rule.rule,
    }),
    title: rule.title,
    rule: rule.rule,
  }));

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col">
        <Table
          title="Bot Rules"
          records={records}
          columns={[
            {
              header: "Title",
              accessor: "titleDialog",
            },
          ]}
          addRecordDialogContent={(setOpen) => (
            <CreateBotRuleDialogContent setOpen={setOpen} />
          )}
          editRecordDialogContent={(record, setOpen) => (
            <EditBotRuleDialogContent {...record} setOpen={setOpen} />
          )}
          deleteRecords={deleteRules}
          recordName={{ singular: "Bot rule", plural: "Bot rules" }}
          noRecordsText="No bot rules available"
        />
      </div>
    </div>
  );
}
