import { ReactNode } from "@tanstack/react-router";

interface SidebarProps {
  header: ReactNode;
  children: ReactNode;
}

export function Sidebar({ header, children }: SidebarProps) {
  return (
    <>
      <div className="w-[230px] shrink-0" />
      <div className="bg-gray-lightest border-r-gray-light x-overflow-auto fixed left-11 top-0 z-50 h-full w-[230px] shrink-0 overflow-y-auto border-r pb-3">
        <div className="bg-gray-lightest sticky top-0 z-50 px-5 pb-4 pt-5">
          {header}
        </div>
        <div className="flex flex-col space-y-0 px-[12px]">{children}</div>
      </div>
    </>
  );
}
