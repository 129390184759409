import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { EditBotRuleForm } from "./EditBotRuleForm";

interface EditBotRuleDialogContentProps {
  id: string;
  title: string;
  rule: string;
  setOpen: (opened: boolean) => void;
}

export function EditBotRuleDialogContent({
  id,
  title,
  rule,
  setOpen,
}: EditBotRuleDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle className="mx-auto">Edit Bot Rule</DialogTitle>
      </DialogHeader>
      <EditBotRuleForm id={id} title={title} rule={rule} setOpen={setOpen} />
    </>
  );
}
