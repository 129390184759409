import { Route } from "..";
import { toast } from "sonner";

export function useRefineExternalMessage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const refineMutation =
    trpc.dashboard.conversations.refineExternalMessage.useMutation();

  return ({
    currentMessage,
    onSuccess,
    onError,
  }: {
    currentMessage: string;
    onSuccess: (refinedMessage: string) => void;
    onError: () => void;
  }) => {
    refineMutation.mutate(
      { organizationSlug, publicId, currentMessage },
      {
        onSuccess: (data) => {
          onSuccess(data.refinedMessage);
        },
        onError: (error) => {
          toast.error("Failed to refine external message", {
            position: "top-center",
          });
          console.error(error);
          onError();
        },
      },
    );
  };
}
