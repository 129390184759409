import { z } from "zod";
import {
  addDays,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { exhaustive } from "@dashboard/utils/other";

export const periodSchema = z
  .union([
    z.literal("today"),
    z.literal("yesterday"),
    z.literal("past-week"),
    z.literal("month-to-date"),
    z.literal("past-4-weeks"),
    z.literal("past-12-weeks"),
  ])
  .catch("past-4-weeks");

export function periodToRange(period: z.infer<typeof periodSchema>) {
  switch (period) {
    case "today": {
      const from = startOfDay(new Date());
      return {
        from,
        to: addDays(from, 1),
      };
    }
    case "yesterday": {
      const to = startOfDay(new Date());
      return {
        from: subDays(to, 1),
        to,
      };
    }
    case "past-week": {
      const to = startOfWeek(new Date());
      return {
        from: subDays(to, 7),
        to: addDays(startOfDay(new Date()), 1),
      };
    }
    case "month-to-date": {
      return {
        from: startOfMonth(new Date()),
        to: addDays(startOfDay(new Date()), 1),
      };
    }
    case "past-4-weeks": {
      const to = startOfWeek(new Date());
      return {
        from: subDays(to, 7 * 4),
        to: addDays(startOfDay(new Date()), 1),
      };
    }
    case "past-12-weeks": {
      const to = startOfWeek(new Date());
      return {
        from: subDays(to, 7 * 12),
        to: addDays(startOfDay(new Date()), 1),
      };
    }
    default:
      throw exhaustive(period);
  }
}
