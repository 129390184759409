import { Route } from "../../onboarding";

export function useUpdateOnboardingStatus({
  onSuccess,
}: {
  onSuccess?: () => Promise<void> | void;
}) {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const { organizationSlug } = Route.useParams();

  const updateMutation = trpc.dashboard.business.updateOrganization.useMutation(
    {
      onSuccess: async () => {
        await trpcUtils.dashboard.business.getOrganizationOrThrow.invalidate({
          organizationSlug,
        });
        if (onSuccess) {
          const result = onSuccess();
          if (result instanceof Promise) {
            await result;
          }
        }
      },
    },
  );

  return {
    update: (onboardingStatus: string) => {
      updateMutation.mutate({ onboardingStatus, organizationSlug });
    },
  };
}
