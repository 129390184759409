import { Link } from "@tanstack/react-router";

export function ProductLink({
  organizationSlug,
  businessSlug,
  productSlug,
}: {
  organizationSlug: string;
  businessSlug: string;
  productSlug: string;
}) {
  return (
    <Link
      className="text-blue-600 hover:underline"
      to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products/$productSlug"
      params={{ organizationSlug, businessSlug, productSlug }}
    >
      View details
    </Link>
  );
}
