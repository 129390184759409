import { useFieldArray, useFormContext } from "react-hook-form";
import { FormField, FormItem, FormLabel } from "@dashboard/common/ui/form";

import { MacroForm } from "../../-utils/macroFormSchema";

import { Action } from "./Action";
import { AddAction } from "./AddAction";
import { useState } from "react";
import { MacroActionType } from "@db";

const actionLabels = {
  close: "Close",
  assignMember: "Assign Member",
  setTag: "Set Tag",
  snooze: "Snooze",
};

const initialOptions = [
  { value: "close", label: "Close" },
  { value: "assignMember", label: "Assign Member" },
  { value: "setTag", label: "Set Tag" },
  { value: "snooze", label: "Snooze" },
];

export function ActionsFormField() {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<MacroForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "actions",
  });

  const actions = watch("actions");
  const filterAvailableActions = () => {
    return initialOptions.filter(
      (option) => !actions.some((action) => action.type === option.value),
    );
  };
  const [options, setOptions] = useState(filterAvailableActions);

  const handleRemove = (index: number, type: MacroActionType) => {
    remove(index);
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: type, label: actionLabels[type] },
    ]);
  };

  const handleAdd = (value: MacroActionType) => {
    //@ts-expect-error - initial action should be invalid, only valid after user adds data
    append({ type: value, data: null });
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== value),
    );
  };

  const setFormValue = (value: string, index: number) => {
    setValue(`actions.${index}.data`, value);
  };

  return (
    <div className="flex min-h-10 flex-col flex-wrap items-start justify-center gap-2.5">
      <FormLabel>Actions</FormLabel>
      {fields.map((field, index) => (
        <FormField
          key={field.id}
          control={control}
          name={`actions.${index}`}
          render={({ field }) => (
            <FormItem>
              <Action
                type={field.value.type}
                data={field.value.data}
                remove={() => handleRemove(index, field.value.type)}
                setFormValue={(value: string) => setFormValue(value, index)}
                error={!!errors.actions?.[index]?.data}
                clearErrors={() => clearErrors(`actions.${index}`)}
              />
            </FormItem>
          )}
        />
      ))}
      <AddAction add={handleAdd} options={options} />
    </div>
  );
}
