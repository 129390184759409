import { toast } from "sonner";
import { Route } from "..";

export function useClose() {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const closeMutation = trpc.dashboard.conversations.close.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.getCount.invalidate({
        organizationSlug,
      });
      await trpcUtils.dashboard.conversations.get.invalidate({
        organizationSlug,
        publicId,
      });

      toast.success("Conversation moved to Closed", {
        position: "bottom-right",
      });
    },
    onError: (error) => {
      console.error("Error closing conversation", error);
      toast.error("Error closing conversation");
    },
  });

  return () =>
    closeMutation.mutate({
      organizationSlug,
      publicId,
      closer: `${user.fullName} (${user.email})`,
    });
}
