import { ArrowRight } from "lucide-react";
import { DatetimeInput } from "./DatetimeInput";
import { Dispatch, SetStateAction } from "react";

interface BottomSectionProps {
  openSection: "custom" | "options";
  setOpenSection: (section: "custom" | "options") => void;
  setMainSection: (section: "calendar" | "time") => void;
  date: string;
  time: string;
  setDate: (date: string) => void;
  setTime: (time: string) => void;
  setCalendarDate: Dispatch<SetStateAction<Date | undefined>>;
  onSelect: (date: Date) => void;
  actionLabel: string;
}

export function BottomSection({
  openSection,
  setOpenSection,
  setMainSection,
  date,
  time,
  setDate,
  setTime,
  setCalendarDate,
  onSelect,
  actionLabel,
}: BottomSectionProps) {
  if (openSection === "options") {
    return (
      <div
        onClick={() => setOpenSection("custom")}
        className="text-gray hover:bg-gray-light flex cursor-pointer items-center justify-between px-3 py-2"
      >
        <span className="text-sm font-medium">Day & Time</span>
        <ArrowRight className="h-4 w-4" />
      </div>
    );
  } else {
    return (
      <DatetimeInput
        setMainSection={setMainSection}
        date={date}
        time={time}
        setDate={setDate}
        setTime={setTime}
        setCalendarDate={setCalendarDate}
        onSelect={onSelect}
        actionLabel={actionLabel}
      />
    );
  }
}
