import { Route } from "../../onboarding";

export const useSyncArticles = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const syncMutation = trpc.dashboard.onboarding.syncArticles.useMutation({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      console.error(error);
      if (onError) {
        onError();
      }
    },
  });

  return {
    syncArticles: (serviceName: "zendesk" | "gorgias") => {
      syncMutation.mutate({
        serviceName,
        businessSlug: organizationSlug,
      });
    },
  };
};
