import { Route } from "../../onboarding";

export const useDeleteArticle = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.articles.deleteMany.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.articles.getAll.invalidate({
        businessSlug: organizationSlug,
      });

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      console.error(error);
      if (onError) {
        onError();
      }
    },
  });

  return {
    deleteArticle: (id: string) => {
      deleteMutation.mutate({
        businessSlug: organizationSlug,
        ids: [id],
      });
    },
  };
};
