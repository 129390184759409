import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

// TODO: add some kind of max height (now looks shitty on smaller browser)

function ChatWindow({ children }: { children?: ReactNode }) {
  const { watch } = useFormContext<WebChatConfigForm>();

  const positionSide = watch("positionSide") ?? formDefaults.positionSide;
  const selectedSide = watch("selectedSide");

  const launcherSize = watch("launcherSize") ?? formDefaults.launcherSize;

  return (
    <div
      style={{
        bottom: `${20 + parseInt(launcherSize)}px`,
        [selectedSide]: positionSide,
      }}
      className="absolute z-[2147483001] flex h-[584px] w-[400px] flex-col overflow-hidden rounded-none bg-white drop-shadow-2xl sm:rounded-xl"
    >
      {children}
    </div>
  );
}

export default ChatWindow;
