import { toast } from "sonner";
import { Route } from "..";

export function useUnarchiveUser() {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const unarchiveMutation = trpc.dashboard.users.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.users.getOrgUsers.invalidate({
        organizationSlug,
      });

      toast("User unarchived successfully.");
    },

    onError: (error) => {
      toast("Failed to unarchive user.");
      console.error(error);
    },
  });

  return {
    unarchiveUser: (ids: string[]) => {
      unarchiveMutation.mutate({
        organizationSlug,
        data: { id: ids[0], archivedAt: null },
      });
    },
  };
}
