import { toast } from "sonner";
import { Route } from "../../onboarding";

export const useCreateEmailExternalTicketConfig = () => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const navigate = Route.useNavigate();

  const trpcUtils = trpc.useUtils();

  const createMutation =
    trpc.dashboard.externalTickets.upsertConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.externalTickets.getConfiguration.invalidate({
          organizationSlug,
        });
        void navigate({
          to: "/organization/$organizationSlug/onboarding/knowledge",
          params: {
            organizationSlug,
          },
        });
      },
      onError: (error) => {
        toast.error("Failed to create external ticket configuration.");
        console.error(error);
      },
    });

  return {
    createConfig: (data: { email: string }) => {
      createMutation.mutate({
        businessSlug: organizationSlug,
        system: "email",
        createOnHandoff: true,
        emailRecipients: [data.email],
      });
    },
  };
};
