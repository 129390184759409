import { Select } from "@dashboard/common/ui/select";
import { MacroActionType } from "@db";
import { useState } from "react";

export function AddAction({
  add,
  options,
}: {
  add: (value: MacroActionType) => void;
  options: { value: string; label: string }[];
}) {
  const [value, setValue] = useState<string>("");

  function handleOnChange(value: MacroActionType) {
    add(value);
    setValue("");
  }

  return (
    <>
      {options.length > 0 && (
        <Select
          variant="dropdown"
          value={value}
          onChange={handleOnChange}
          placeholder="Add Action"
          options={options}
        />
      )}
    </>
  );
}
