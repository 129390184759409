import ReactMarkdown from "react-markdown";
import { cn } from "@dashboard/utils/ui";
import { ImageDialog } from "./ImageDialog";
import "@dashboard/styles/markdownContent.css";
import remarkGfm from "remark-gfm";

interface MarkdownContentProps {
  content: string;
  className?: string;
}

export function MarkdownContent({ content, className }: MarkdownContentProps) {
  return (
    <div className={cn("markdown-content", className)}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ src, alt, title }) => {
            if (!src) return null;
            return <ImageDialog src={src} alt={alt} title={title} />;
          },
          td: ({ children }) => <td className="px-2 py-1">{children}</td>,
          th: ({ children }) => (
            <td className="px-2 py-1 font-semibold">{children}</td>
          ),
          code: ({ children }) => (
            <code className="text-wrap text-sm">{children}</code>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
