import { AlarmClock, MessageSquareX, Tag, UserRoundPlus } from "lucide-react";
import { MacroAction } from "@be/modules/macros/macros.types";
import { Route } from "../..";

export function ActionComponent({ action }: { action: MacroAction }) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  switch (action.type) {
    case "close":
      return (
        <>
          <MessageSquareX className="h-4 w-4" strokeWidth={1.5} />
          <span>Close Conversation</span>
        </>
      );
    case "assignMember": {
      const user = users.find((user) => user.id === action.data);
      return (
        <>
          <UserRoundPlus className="h-4 w-4" strokeWidth={1.5} />
          <span>Assign to: {user?.fullName ?? "Member not found"}</span>
        </>
      );
    }
    case "setTag": {
      const tag = tags.find((tag) => tag.id === action.data);
      return (
        <>
          <Tag className="h-4 w-4" strokeWidth={1.5} />
          <span>Add tag: {tag?.title ?? "Tag not found"}</span>
        </>
      );
    }
    case "snooze": {
      const options = [
        { value: "1-hour", label: "For 1 Hour" },
        { value: "tomorrow", label: "Until Tomorrow" },
        { value: "next-week", label: "Until Next Week" },
        { value: "next-month", label: "Until Next Month" },
      ];

      const label = options.find(
        (option) => option.value === action.data,
      )?.label;

      return (
        <>
          <AlarmClock className="h-4 w-4" strokeWidth={1.5} />
          <span>Snooze: {label ?? "Unexpected Error"}</span>
        </>
      );
    }
  }
}
