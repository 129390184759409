import { toast } from "sonner";
import { Route } from "..";

export const useRemoveExternalTicketConfig = () => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const removeMutation =
    trpc.dashboard.externalTickets.removeConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.externalTickets.getConfiguration.invalidate();
        toast.success("External ticket configuration updated.");
      },
      onError: (error) => {
        toast.error("Failed to update external ticket configuration.");
        console.error(error);
      },
    });

  return {
    removeConfig: () => {
      removeMutation.mutate({ businessSlug });
    },
  };
};
