import { CalendarRange } from "lucide-react";
import { SelectFilter } from "./SelectFilter";

export function TimeRangeFilter() {
  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "this-week",
    },
    {
      label: "This Month",
      value: "this-month",
    },
    {
      label: "Last 90 Days",
      value: "last-90-days",
    },
  ];

  return (
    <SelectFilter
      options={options}
      valueIcon={CalendarRange}
      name="Time Range"
      filterKey="timeRange"
    />
  );
}
