import { toast } from "sonner";
import { Route } from "..";

export function useFileUpload() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const imageUploadMutation =
    trpc.dashboard.conversations.getImageUploadUrl.useMutation();

  async function uploadFile({
    file,
    businessId,
  }: {
    file: File;
    businessId: string;
  }) {
    const { id, presignedUrl } = await imageUploadMutation.mutateAsync({
      organizationSlug,
      businessId,
      filename: file.name,
      contentType: file.type,
    });

    const response = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });

    if (!response.ok) {
      toast.error(`Could not upload file ${file.name}. Please try again.`);
      throw new Error("Could not upload file");
    }

    return { fileId: id, filename: file.name };
  }

  async function uploadFiles({
    files,
    businessId,
  }: {
    files: File[];
    businessId: string;
  }) {
    return Promise.all(files.map((file) => uploadFile({ file, businessId })));
  }

  return { uploadFile, uploadFiles };
}
