import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ItemDetailSection } from "./OrderDetailSection";
import { z } from "zod";

export function CustomFieldsSection({ order }: { order: TrackedOrder }) {
  if (!order.recipient) return null;

  // ! Hacky for orbio. Let's make this section generic in the future.
  const extra = z
    .object({
      csPolicy: z.enum(["No 1", "No 2"]).nullable(),
      isKlarnaPaymentMethod: z.boolean(),
      isStripePaymentMethod: z.boolean(),
      isCashOnDeliveryPaymentMethod: z.boolean(),
      isOtherPaymentMethod: z.boolean(),
      isTestPaymentMethod: z.boolean(),
      wasDispatched: z.boolean(),
      isDelivered: z.boolean(),
      isAustralia: z.boolean(),
      isEuPolicy: z.boolean(),
      isNotEuPolicy: z.boolean(),
      isNPF: z.boolean(),
      isVFulfill: z.boolean(),
      hasNotes: z.boolean(),
      isRefunded: z.boolean(),
    })
    .safeParse(order.extra);

  if (!extra.success) {
    return null;
  }

  const details = Object.entries(extra.data).map(([key, value]) => ({
    label: key,
    value: String(value),
  }));

  return (
    <AccordionItem
      value="custom-fields"
      className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
    >
      <AccordionTrigger className="py-2 text-xs font-medium">
        Custom Fields
      </AccordionTrigger>
      <AccordionContent className="pb-2 text-xs">
        <ItemDetailSection details={details} />
      </AccordionContent>
    </AccordionItem>
  );
}
