import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { Select } from "@dashboard/common/ui/select";
import { useState } from "react";

export function AddFilter({
  add,
  options,
}: {
  add: (key: FilterKeys) => void;
  options: { value: string; label: string }[];
}) {
  const [value, setValue] = useState<string>("");

  function handleOnChange(key: FilterKeys) {
    add(key);
    setValue("");
  }

  return (
    <>
      {options.length > 0 && (
        <Select
          variant="dropdown"
          value={value}
          onChange={handleOnChange}
          placeholder="Add Filter"
          options={options}
        />
      )}
    </>
  );
}
