import { Outlet, createFileRoute } from "@tanstack/react-router";

import { ConversationsFilters } from "./-components/ConversationFilters";
import { ConversationsList } from "./-components/ConversationsList";
import { ConversationTopBar } from "./-components/ConversationTopBar";
import { DraftMessagesProvider } from "./-context/DraftMessagesContext";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList",
)({
  loaderDeps: ({ search: { view, filters, status, sortBy, searchQuery } }) => ({
    view,
    filters,
    status,
    sortBy,
    searchQuery,
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
    deps: { view, filters, status, sortBy, searchQuery },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.conversations.getAll.ensureData({
        organizationSlug,
        view,
        filters,
        status,
        sortBy,
        searchQuery,
      }),

      trpcClientUtils.dashboard.conversations.getCount.ensureData({
        organizationSlug,
        view,
        status,
        filters,
        searchQuery,
      }),

      trpcClientUtils.dashboard.conversations.getSubscriptionToken.ensureData({
        organizationSlug,
        view,
        filters,
        status,
      }),
    ]);
  },
  component: ConversationListLayout,
});

function ConversationListLayout() {
  return (
    <div className="flex h-full w-full">
      <ConversationsListSection />
      <DraftMessagesProvider>
        <Outlet />
      </DraftMessagesProvider>
    </div>
  );
}

function ConversationsListSection() {
  return (
    <div className="bg-background border-r-border flex w-[24%] min-w-[250px] max-w-[460px] flex-col border-r">
      <ConversationTopBar>
        <h2 className="text-xl font-medium text-black">Conversations</h2>
      </ConversationTopBar>

      <ConversationsFilters />

      <ConversationsList />
    </div>
  );
}
