import { XMarkIcon } from "@heroicons/react/24/outline";
import { KeyboardEvent } from "react";

interface ModalTopBarProps {
  title: string;
  titleTooltip?: string;
  onClickClose: () => void;
  closeRef?: React.RefObject<HTMLDivElement>;
}

export default function ModalTopBar({
  title,
  onClickClose,
  titleTooltip,
  closeRef,
}: ModalTopBarProps) {
  const handleKeyCloseSettings = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onClickClose();
    }
  };

  return (
    <div className="relative border-b p-5">
      <p
        className="text-center font-semibold leading-none text-[#1a1a1a]"
        title={titleTooltip}
      >
        {title}
      </p>
      <div
        ref={closeRef}
        tabIndex={0}
        aria-label="Close settings"
        className="absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer rounded-lg p-1 hover:bg-gray-100"
        onClick={onClickClose}
        onKeyDown={handleKeyCloseSettings}
        id="close-settings-modal"
      >
        <XMarkIcon className="h-6 w-6 text-gray-800" />
      </div>
    </div>
  );
}
