import { Tooltip } from "@dashboard/common/ui/tooltip";
import { ElementType } from "react";
import { RenderProps } from "../-utils/renderNavbarLink";

interface NavbarButtonProps {
  tooltipText: string;
  icon: ElementType;
  iconSize?: number;
  render(props: RenderProps): JSX.Element;
}

export function NavbarButton({
  tooltipText,
  icon: Icon,
  iconSize = 18,
  render,
}: NavbarButtonProps) {
  return render({
    className: "group w-full cursor-pointer hover:cursor-pointer",
    activeClassName: "is-active bg-gray-lightest group-hover:bg-gray-light",
    inactiveClassName: "bg-gray-light",
    children: (
      <div className="hover:bg-gray-lightest">
        <Tooltip
          disableHoverableContent
          triggerAsChild
          side="right"
          trigger={
            <div className="relative flex h-[44px] w-full items-center justify-center">
              <div className="bg-blue invisible absolute left-0 h-full w-0.5 group-[.is-active]:visible" />
              <span className="flex h-full w-full items-center justify-center">
                <Icon
                  className="group-[.is-active]:text-blue text-black"
                  color="currentColor"
                  width={iconSize}
                  height={iconSize}
                  strokeWidth={2.1}
                />
              </span>
            </div>
          }
          content={<p className="font-medium text-black">{tooltipText}</p>}
        />
      </div>
    ),
  });
}
