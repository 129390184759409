import clsx from "clsx";

interface TrendIndicatorProps {
  value: number;
  unit?: string;
  positive: boolean;
  showPreviousDays?: boolean;
  className?: string;
  days?: number;
}

export function Trend({
  value,
  unit,
  positive,
  showPreviousDays,
  className,
  days,
}: TrendIndicatorProps) {
  const chevronColor = positive ? "#1bb157" : "#FF0000"; // Green for positive, red for negative
  const chevronRotation = value < 0 ? "rotate(0)" : "rotate(180deg)";

  return (
    <div className={clsx("flex flex-row items-center", className)}>
      {" "}
      {/* Applying clsx to include className */}
      <svg
        className={clsx({
          "text-[#1bb157]": positive,
          "text-[#FF0000]": !positive,
        })}
        fill="currentColor"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: chevronRotation }}
      >
        <path d="M6.79491 10.1807C7.18432 10.6065 7.81568 10.6064 8.20509 10.1807L11.2942 6.80291C11.7339 6.32211 11.4225 5.5 10.8006 5.5L4.1994 5.5C3.57755 5.5 3.26612 6.32211 3.70584 6.80291L6.79491 10.1807Z"></path>
      </svg>
      <span className={`text-sm text-[${chevronColor}]`}>
        {value > 0 ? `+${value}` : value}
        {unit}
      </span>
      {showPreviousDays && days !== undefined && (
        <span className="text-gray ml-1 pt-0.5 text-xs">
          from previous {days} day{days > 1 && "s"}
        </span>
      )}
    </div>
  );
}
