import { Route } from "..";

export function useGetKnowledgeSummary() {
  const { trpc, business } = Route.useRouteContext();

  const [summary] = trpc.dashboard.bots.getKnowledgeSummary.useSuspenseQuery(
    {
      businessSlug: business.slug,
    },
    {
      refetchInterval: 30000,
    },
  );

  const totalQuestionCount =
    summary.summary?.reduce((acc, topic) => acc + topic.questions.length, 0) ??
    0;

  const totalAnsweredQuestionCount =
    summary.summary?.reduce(
      (acc, topic) => acc + topic.questions.filter((q) => q.isAnswered).length,
      0,
    ) ?? 0;

  return {
    ...summary,
    totalQuestionCount,
    totalAnsweredQuestionCount,
  };
}
