import { useInitializeConversations } from "@chatbot/hooks/conversations.hooks";
import { useInitializeUiState } from "@chatbot/hooks/uiState.hooks";
import { ChatWindow } from "./ChatWindow/ChatWindow";
import { Launcher } from "./Launcher";
import AttentionGrabbers from "./AttentionGrabbers";
import clsxm from "@chatbot/utils/clsxm";
import { ReadonlyConfig } from "@chatbot/hooks/config.hooks";
// import { usePing } from "@chatbot/hooks/browserSessions.hooks";
import { currentlyInSupportTimeWindow } from "@chatbot/utils/supportHours";
import { useFurniture1Hack } from "@chatbot/hooks/hacks.hooks";

export function Coordinator({
  browserSessionId,
  config,
}: {
  browserSessionId: string;
  config: ReadonlyConfig;
}) {
  // usePing();

  const ui = useInitializeUiState({ configId: config.id });

  const isConversationsInitialized = useInitializeConversations({
    configId: config.id,
    browserSessionId,
  });

  const isInSupportTimeWindow = currentlyInSupportTimeWindow({
    supportHours: config.supportHours,
  });

  useFurniture1Hack({
    enabled:
      new URL(window.location.href).hostname === "www.arredamento1.it" &&
      isInSupportTimeWindow,
  });

  if (!ui || !isConversationsInitialized) {
    return null;
  }

  if (!isInSupportTimeWindow) {
    return null;
  }

  const { isMobile, loadedIntoContainer } = ui;

  return (
    <div
      className={clsxm(
        "font-sans",
        !loadedIntoContainer ? "absolute h-full" : "h-full w-full",
      )}
    >
      <div
        className={
          !loadedIntoContainer ? "fixed z-[2147483648]" : "h-full w-full"
        }
        style={{
          bottom: !isMobile
            ? config.positionBottom
              ? config.positionBottom
              : "20px"
            : config.mobilePositionBottom
            ? config.mobilePositionBottom
            : "20px",
          right: !isMobile
            ? config.positionRight
              ? config.positionRight
              : config.positionLeft
              ? undefined
              : "20px"
            : config.mobilePositionRight
            ? config.mobilePositionRight
            : config.mobilePositionLeft
            ? undefined
            : "20px",
          left: !isMobile
            ? config.positionLeft
              ? config.positionLeft
              : undefined
            : config.mobilePositionLeft
            ? config.mobilePositionLeft
            : undefined,
        }}
      >
        <ChatWindow />
        {!loadedIntoContainer && (
          <>
            <AttentionGrabbers />
            <div
              className={clsxm(
                "mt-4 flex w-full flex-row justify-end",
                !isMobile
                  ? config.positionLeft
                    ? "justify-start"
                    : "justify-end"
                  : config.mobilePositionLeft
                  ? "justify-start"
                  : "justify-end",
              )}
            >
              <Launcher />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
