import { Button } from "@dashboard/common/ui/button";
import { Select } from "@dashboard/common/ui/select";
import { Separator } from "@dashboard/common/ui/separator";
import { cn } from "@dashboard/utils/ui";
import { MacroAction } from "@be/modules/macros/macros.types";
import { DatetimeForm } from "@dashboard/common/Calendar/DatetimeForm";
import { useState } from "react";
import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";

export function MessageButton({
  onClickSendMessage,
  disabled,
}: {
  onClickSendMessage: ({ action }: { action?: MacroAction }) => void;
  disabled: boolean;
}) {
  const [open, setOpen] = useState(false);

  const handleOnChange = (value: "send" | "sendClose" | "sendSnooze") => {
    switch (value) {
      case "send":
        if (disabled) return;

        onClickSendMessage({});
        break;
      case "sendClose":
        if (disabled) return;

        onClickSendMessage({ action: { type: "close", data: null } });
        break;
      case "sendSnooze":
        setOpen(true);
        break;
    }
  };

  const handleOnClick = () => {
    if (disabled) return;

    onClickSendMessage({});
  };

  const handleSelectDate = (date: Date) => {
    if (disabled) return;

    setOpen(false);

    onClickSendMessage({
      action: { type: "snooze", data: date.toISOString() },
    });
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen} modal={true}>
        <DialogContent className="w-auto overflow-hidden p-0">
          <DatetimeForm onSelect={handleSelectDate} actionLabel="Send" />
        </DialogContent>
      </Dialog>

      <div
        className={cn(
          "flex h-8 items-center rounded-lg bg-black transition duration-100 ease-in-out",
          disabled && "bg-transparent",
        )}
      >
        <Button
          disabled={disabled}
          className="h-full rounded-lg pr-3 font-semibold shadow-none transition duration-100 ease-in-out disabled:bg-transparent disabled:text-gray-500 disabled:shadow-none"
          onClick={handleOnClick}
        >
          Send
        </Button>
        <Separator
          orientation="vertical"
          className={cn(
            "h-5 bg-white transition duration-100 ease-in-out",
            disabled && "bg-gray-400",
          )}
        />
        <Select
          disabled={disabled}
          value=""
          variant="dropdown"
          className="h-8 rounded-lg pl-1 text-white transition duration-100 ease-in-out hover:bg-black hover:text-white disabled:cursor-default disabled:bg-transparent disabled:text-gray-800 data-[state=open]:bg-black data-[state=open]:text-white"
          placeholder=""
          onChange={handleOnChange}
          options={[
            { value: "send", label: "Send" },
            { value: "sendClose", label: "Send & Close" },
            { value: "sendSnooze", label: "Send & Snooze" },
          ]}
        />
      </div>
    </>
  );
}
