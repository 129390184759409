import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import TextareaAutosize from "react-textarea-autosize";
import { Mails } from "lucide-react";
import { Label } from "@dashboard/common/ui/label";
import { Dispatch, SetStateAction, useState } from "react";
import { Separator } from "@dashboard/common/ui/separator";
import { useProcessRecipients } from "../-hooks/useFormatRecipients";
import { Tooltip } from "@dashboard/common/ui/tooltip";

export function BulkSendDialog({
  businessSlug,
  processedEmails,
  setProcessedEmails,
  allowOrderIds,
}: {
  businessSlug: string;
  processedEmails: string[];
  setProcessedEmails: Dispatch<SetStateAction<string[]>>;
  allowOrderIds: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState<string>("");
  const [orderIds, setOrderIds] = useState<string>("");
  const [errors, setErrors] = useState<{ key: string; error: string }[]>([]);

  const processRecipients = useProcessRecipients({
    allowOrderIds,
    businessSlug,
    setErrors,
    setProcessedEmails,
  });

  return (
    <>
      <Tooltip
        triggerAsChild
        trigger={
          <Button
            variant="ghost"
            className="h-8 px-2"
            onClick={() => setOpen(true)}
          >
            <Mails className="h-4 w-4" />
          </Button>
        }
        content="Add Multiple Recipients"
      />

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="w-[800px] max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Add Multiple Recipients</DialogTitle>
            <DialogDescription>
              Create conversation with multiple recipients.{" "}
              {allowOrderIds &&
                "Recipients can be specified by email or order ID."}{" "}
              <br /> Separate multiple values with a comma.
              (email1@email.com,email2@email.com,...)
            </DialogDescription>
          </DialogHeader>

          <Label>Emails</Label>
          <TextareaAutosize
            minRows={4}
            maxRows={10}
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            className="block w-full resize-none rounded-xl border px-3 py-3 text-sm text-black outline-none focus:ring-0"
            placeholder="email1@email.com,email2@email.com,..."
          />

          {allowOrderIds && (
            <>
              <Label>Order IDs</Label>
              <TextareaAutosize
                minRows={4}
                maxRows={10}
                value={orderIds}
                onChange={(e) => setOrderIds(e.target.value)}
                className="block w-full resize-none rounded-xl border px-3 py-3 text-sm text-black outline-none focus:ring-0"
                placeholder="#000001,#000002,..."
              />
            </>
          )}

          {processedEmails.length > 0 && (
            <>
              <Separator />
              <Label>Recipients</Label>
              <div className="flex flex-wrap gap-2">
                {processedEmails.map((email) => (
                  <span
                    key={email}
                    className="bg-gray-light flex items-center justify-center rounded-lg px-4 py-2 text-sm font-semibold"
                  >
                    {email}
                  </span>
                ))}
              </div>
            </>
          )}

          {errors.length > 0 && (
            <>
              <Separator />
              <Label>Errors</Label>
              <div className="flex flex-col flex-wrap gap-2">
                {errors.map((error) => (
                  <span key={error.key} className="text-sm text-red-500">
                    {error.key}: {error.error}
                  </span>
                ))}
              </div>
            </>
          )}

          <DialogFooter>
            <Button
              type="button"
              onClick={() => void processRecipients({ orderIds, emails })}
            >
              Save recipients
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
