import { TrackedOrder } from "@be/modules/orders/orders.types";
import { OrderCancelationForm } from "./OrderCancelationForm";
import { OrderRefundForm } from "./OrderRefundForm";
import { HoldFulfillmentForm } from "./HoldFulfillmentForm";
import { ReleaseHoldFulfillmentDialog } from "./ReleaseHoldFulfillmentDialog";
import { useConversation } from "../../../-hooks/useConversation.hook";

interface OrderActionsProps {
  order: TrackedOrder;
}

export function OrderActions({ order }: OrderActionsProps) {
  const { availableOrderServices } = useConversation();
  const availableActions = [];

  if (availableOrderServices.cancelations && order.canCancel) {
    availableActions.push("cancel");
  }

  if (availableOrderServices.refunds && order.canRefund) {
    availableActions.push("refund");
  }

  if (availableOrderServices.hold && order.canHold) {
    availableActions.push("hold");
  }

  if (availableOrderServices.hold && order.canReleaseHold) {
    availableActions.push("releaseHold");
  }

  if (availableActions.length === 0) {
    return null;
  }

  return (
    <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
      {availableActions.includes("cancel") && (
        <OrderCancelationForm order={order} />
      )}
      {availableActions.includes("refund") && <OrderRefundForm order={order} />}
      {availableActions.includes("hold") && (
        <HoldFulfillmentForm
          fulfillments={order.fulfillments.filter(
            (fulfillment) => fulfillment.canHold,
          )}
        />
      )}
      {availableActions.includes("releaseHold") && (
        <ReleaseHoldFulfillmentDialog
          fulfillments={order.fulfillments.filter(
            (fulfillment) => fulfillment.canReleaseHold,
          )}
        />
      )}
    </div>
  );
}
