import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/metafields/$metafieldKey",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, metafieldKey },
  }) => {
    await trpcClientUtils.dashboard.metafields.getMetafieldKeyValues.ensureData(
      {
        businessSlug,
        key: metafieldKey,
      },
    );
  },
  component: MetafieldKeyValueListPage,
});

export function MetafieldKeyValueListPage() {
  const { businessSlug, metafieldKey } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [keyValues] =
    trpc.dashboard.metafields.getMetafieldKeyValues.useSuspenseQuery({
      businessSlug,
      key: metafieldKey,
    });

  const records = keyValues.map((keyValue) => ({
    id: keyValue.value,
    value: keyValue.value,
    count: keyValue.count,
  }));

  return (
    <div className="flex h-screen flex-col">
      <Table
        title="Metafield Key Values"
        records={records}
        columns={[
          {
            header: "Value",
            accessor: "value",
          },
          {
            header: "Product Count",
            accessor: "count",
          },
        ]}
        recordName={{
          singular: "Metafield Key Value",
          plural: "Metafield Key Values",
        }}
        noRecordsText="No metafield key values available"
      />
    </div>
  );
}
