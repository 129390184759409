import { Route } from "..";
import { toast } from "sonner";

export function useDeleteBotRules() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.bots.deleteRules.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.bots.getRules.invalidate({ businessSlug });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Bot rules deleted successfully."
          : "Bot rule deleted successfully.";

      toast(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to delete bot rules."
          : "Failed to delete bot rule.";

      toast(message);
      console.error(error);
    },
  });

  return {
    deleteRules: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids });
    },
  };
}
