import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@dashboard/common/ui/accordion";
import SettingsSection from "./SettingsSection";
import ColorPicker from "./ColorPicker";
import LogoUploader from "./LogoUploader";
import { Tabs, TabsList, TabsTrigger } from "@dashboard/common/ui/tabs";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Input } from "@dashboard/common/ui/input";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { useFormContext } from "react-hook-form";
import { Button } from "@dashboard/common/ui/button";
import { Select } from "@dashboard/common/ui/select";
import { formDefaults } from "../-utils/formDefaults";
import defaultIcon from "@dashboard/assets/chat-bubble-oval-left.png";
import { WebChatConfigForm } from "../-utils/formSchema";
import SettingsLabel from "../../-components/SettingsLabel";
import SettingsDivider from "../../-components/SettingsDivider";
import { Route } from "..";
import { Switch } from "@dashboard/common/ui/switch";

function SettingsTab() {
  const { setValue, watch } = useFormContext<WebChatConfigForm>();

  const { user } = Route.useRouteContext();

  const selectedSide = watch("selectedSide");
  const launcherType = watch("launcherType");
  const enableDisclaimer = watch("enableDisclaimer");
  const enableAttentionGrabbers = watch("enableAttentionGrabbers");
  const enableLiveChat = watch("enableLiveChat");
  const enableDeployment = watch("enableDeployment");
  const topBarImageSrc = watch("topBarImageSrc");
  const botIconSrc = watch("botIconSrc");
  const launcherIconSrc = watch("launcherIconSrc");

  const removeImage = (
    name: "topBarImage" | "botIcon" | "launcherIcon" | "launcherImage",
  ) => {
    setValue(`${name}Src`, undefined);
    setValue(name, null);
  };

  const handleLauncherTypeChange = (value: "icon" | "icon_text" | "image") => {
    setValue("launcherType", value);
  };

  return (
    <Accordion type="single" collapsible className="w-full space-y-4">
      {/* Top bar section */}
      <AccordionItem value="item-0" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Top Bar</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Logo" />
            <LogoUploader
              name="topBarImage"
              img={formDefaults.topBarImageSrc}
            />
            {topBarImageSrc && (
              <Button
                className="mt-2"
                type="button"
                variant="destructive"
                onClick={() => removeImage("topBarImage")}
              >
                Restore default
              </Button>
            )}

            <SettingsDivider />

            <SettingsLabel label="Title" />
            <Input
              name="topBarTitle"
              className="flex h-8 flex-row text-black"
            />

            <SettingsDivider />

            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Title Color" />
                <ColorPicker
                  defaultValue={formDefaults.topBarTextColor}
                  name="topBarTextColor"
                />
              </div>
              <div className="ml-2 flex-1">
                <SettingsLabel label="Background color" />
                <ColorPicker
                  defaultValue={formDefaults.topBarBackgroundColor}
                  name="topBarBackgroundColor"
                />
              </div>
            </div>

            <SettingsDivider />

            <SettingsLabel label="Icon color" />
            <ColorPicker
              defaultValue={formDefaults.topBarIconColor}
              name="topBarIconColor"
            />

            <SettingsDivider />

            <div className="flex flex-row items-center pb-2">
              <Checkbox name="enableTopBarBorder" />
              <SettingsLabel
                label="Enable top bar border"
                className="ml-2 pb-0"
              />
            </div>
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Bot section */}
      <AccordionItem value="item-1" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Bot Messages</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Bot Icon" />
            <LogoUploader name="botIcon" img={formDefaults.botIconSrc} />
            {botIconSrc && (
              <Button
                className="mt-2"
                type="button"
                variant="destructive"
                onClick={() => removeImage("botIcon")}
              >
                Restore default
              </Button>
            )}

            <SettingsDivider />

            <SettingsLabel label="Message appearance" />
            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Background color" />
                <ColorPicker
                  defaultValue={formDefaults.botMessageBackgroundColor}
                  name="botMessageBackgroundColor"
                />
              </div>
              <div className="ml-2 flex-1">
                <SettingsLabel label="Text color" />
                <ColorPicker
                  defaultValue={formDefaults.botMessageTextColor}
                  name="botMessageTextColor"
                />
              </div>
            </div>

            <SettingsDivider />

            <SettingsLabel className="pb-1.5" label="Intro message" />
            <Textarea
              rows={3}
              placeholder="Type your intro message here"
              defaultValue={formDefaults.greetingMessage}
              className="w-full"
              name="greetingMessage"
              maxLength={500}
            />

            <SettingsDivider />

            <div className="flex flex-row items-center pb-2">
              <Checkbox
                name="requestEmailBeforeChat"
                defaultChecked={watch("requestEmailBeforeChat")}
              />
              <SettingsLabel
                label="Request email before chat"
                className="ml-2 pb-0"
              />
            </div>
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Customer section */}
      <AccordionItem value="item-2" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Customer Messages</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Message appearance" />
            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Background color" />
                <ColorPicker
                  defaultValue={formDefaults.customerMessageBackgroundColor}
                  name="customerMessageBackgroundColor"
                />
              </div>
              <div className="ml-2 flex-1">
                <SettingsLabel label="Text color" />
                <ColorPicker
                  defaultValue={formDefaults.customerMessageTextColor}
                  name="customerMessageTextColor"
                />
              </div>
            </div>
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Disclaimer section */}
      <AccordionItem value="item-3" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Disclaimer</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <div className="flex flex-row items-center pb-4">
              <Checkbox name="requireTermsAndConditionsAgreement" />
              <SettingsLabel
                label="Require T&C agreement"
                className="ml-2 pb-0"
              />
            </div>
            <div className="flex flex-row items-center pb-2">
              <Checkbox name="enableDisclaimer" />
              <SettingsLabel label="Enable disclaimer" className="ml-2 pb-0" />
            </div>

            {enableDisclaimer && (
              <>
                <SettingsDivider />

                <SettingsLabel className="pb-1.5" label="Disclaimer message" />
                <Textarea
                  rows={3}
                  placeholder="Type your disclaimer message here"
                  defaultValue={formDefaults.disclaimerText}
                  className="w-full"
                  name="disclaimerText"
                  maxLength={500}
                />

                <SettingsDivider />

                <div className="flex w-full items-center justify-between">
                  <div className="mr-2 flex-1">
                    <SettingsLabel label="Button background color" />
                    <ColorPicker
                      defaultValue={
                        formDefaults.disclaimerButtonBackgroundColor
                      }
                      name="disclaimerButtonBackgroundColor"
                    />
                  </div>
                  <div className="ml-2 flex-1">
                    <SettingsLabel label="Button text color" />
                    <ColorPicker
                      defaultValue={formDefaults.disclaimerButtonTextColor}
                      name="disclaimerButtonTextColor"
                    />
                  </div>
                </div>
              </>
            )}
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Launcher section */}
      <AccordionItem value="item-4" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Launcher</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Launcher type" />
            <Select
              variant="dropdown"
              value={launcherType}
              onChange={handleLauncherTypeChange}
              renderValue={(valueJsx) => <>{valueJsx}</>}
              options={[
                {
                  label: "Icon",
                  value: "icon",
                },
                {
                  label: "Icon + Text",
                  value: "icon_text",
                },
                {
                  label: "Image",
                  value: "image",
                },
              ]}
            />

            <SettingsDivider />

            {launcherType !== "image" && (
              <>
                <div className="flex w-full flex-wrap items-center justify-between gap-4">
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Launcher background color" />
                    <ColorPicker
                      defaultValue={formDefaults.launcherBackgroundColor}
                      name="launcherBackgroundColor"
                    />
                  </div>
                  {!launcherIconSrc && (
                    <div className="min-w-[180px] flex-1">
                      <SettingsLabel label="Launcher icon color" />
                      <ColorPicker
                        defaultValue={formDefaults.launcherIconColor}
                        name="launcherIconColor"
                      />
                    </div>
                  )}
                </div>

                <SettingsDivider />
              </>
            )}

            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Launcher size" />
                <Input
                  name="launcherSize"
                  defaultValue={formDefaults.launcherSize}
                  className="flex h-8 w-[77px] flex-row text-black"
                />
              </div>

              {launcherType !== "image" && (
                <div className="ml-2 flex-1">
                  <SettingsLabel label="Launcher icon size" />
                  <Input
                    name="launcherIconSize"
                    defaultValue={formDefaults.launcherIconSize}
                    className="flex h-8 w-[77px] flex-row text-black"
                  />
                </div>
              )}
            </div>

            <SettingsDivider />

            {launcherType === "icon_text" && (
              <>
                <SettingsLabel label="Launcher text" />
                <Input
                  name="launcherText"
                  className="flex h-8 flex-row text-black"
                />

                <SettingsDivider />

                <SettingsLabel label="Launcher text color" />
                <ColorPicker
                  defaultValue={formDefaults.launcherTextColor}
                  name="launcherTextColor"
                />

                <SettingsDivider />
              </>
            )}

            {launcherType === "image" ? (
              <>
                <SettingsLabel label="Launcher image" />
                <LogoUploader name="launcherImage" />
              </>
            ) : (
              <>
                <SettingsLabel label="Launcher icon" />
                <LogoUploader name="launcherIcon" img={defaultIcon} />
                {launcherIconSrc && (
                  <Button
                    className="mt-2"
                    type="button"
                    variant="destructive"
                    onClick={() => removeImage("launcherIcon")}
                  >
                    Restore default
                  </Button>
                )}
              </>
            )}
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Position section */}
      <AccordionItem value="item-5" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Position</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Adjust your launcher's positioning" />
            <Tabs
              className="w-full"
              defaultValue={selectedSide}
              onValueChange={(value) => {
                setValue("selectedSide", value as "left" | "right");
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="left">Left</TabsTrigger>
                <TabsTrigger value="right">Right</TabsTrigger>
              </TabsList>
            </Tabs>

            <SettingsDivider />

            <div className="flex w-full items-center justify-between">
              <div className="mr-2 flex-1">
                <SettingsLabel label="Side spacing" />
                <Input
                  name="positionSide"
                  defaultValue={formDefaults.positionSide}
                  className="flex h-8 w-[77px] flex-row text-black"
                />
              </div>

              <div className="ml-2 flex-1">
                <SettingsLabel label="Bottom spacing" />
                <Input
                  name="positionBottom"
                  defaultValue={formDefaults.positionBottom}
                  className="flex h-8 w-[77px] flex-row text-black"
                />
              </div>
            </div>
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Attention grabbers section */}
      <AccordionItem value="item-6" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Attention Grabbers</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <div className="flex flex-row items-center pb-2">
              <Checkbox name="enableAttentionGrabbers" />
              <SettingsLabel
                label="Enable attention grabbers"
                className="ml-2 pb-0"
              />
            </div>

            {enableAttentionGrabbers && (
              <>
                <SettingsDivider />

                <SettingsLabel
                  className="pb-1.5"
                  label="Attention grabber message"
                />
                <Textarea
                  rows={3}
                  placeholder="Type your message here"
                  defaultValue={formDefaults.attentionGrabberMessage}
                  className="w-full"
                  name="attentionGrabberMessage"
                  maxLength={120}
                />

                <SettingsDivider />

                <SettingsLabel
                  className="pb-1.5"
                  label="Attention grabber suggestion 1"
                />

                <SettingsLabel className="pb-1.5" label="Display" />
                <Input
                  name="attentionGrabberSuggestions[0].displayText"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[0].displayText
                  }
                  className="mb-2 flex h-8 flex-row text-black"
                />

                <SettingsLabel className="pb-1.5" label="Message" />
                <Textarea
                  rows={3}
                  placeholder="Type your message here"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[0].message
                  }
                  className="w-full"
                  name="attentionGrabberSuggestions[0].message"
                  maxLength={120}
                />

                <SettingsDivider />

                <SettingsLabel
                  className="pb-1.5"
                  label="Attention grabber suggestion 2"
                />

                <SettingsLabel className="pb-1.5" label="Display" />
                <Input
                  name="attentionGrabberSuggestions[1].displayText"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[1].displayText
                  }
                  className="mb-2 flex h-8 flex-row text-black"
                />

                <SettingsLabel className="pb-1.5" label="Message" />
                <Textarea
                  rows={3}
                  placeholder="Type your message here"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[1].message
                  }
                  className="w-full"
                  name="attentionGrabberSuggestions[1].message"
                  maxLength={120}
                />

                <SettingsDivider />

                <SettingsLabel
                  className="pb-1.5"
                  label="Attention grabber suggestion 3"
                />

                <SettingsLabel className="pb-1.5" label="Display" />
                <Input
                  name="attentionGrabberSuggestions[2].displayText"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[2].displayText
                  }
                  className="mb-2 flex h-8 flex-row text-black"
                />

                <SettingsLabel className="pb-1.5" label="Message" />
                <Textarea
                  rows={3}
                  placeholder="Type your message here"
                  defaultValue={
                    formDefaults.attentionGrabberSuggestions[2].message
                  }
                  className="w-full"
                  name="attentionGrabberSuggestions[2].message"
                  maxLength={120}
                />

                <SettingsDivider />

                <div className="flex w-full flex-wrap items-center justify-between gap-4">
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Background color" />
                    <ColorPicker
                      defaultValue={
                        formDefaults.attentionGrabberSuggestionBubbleBackgroundColor
                      }
                      name="attentionGrabberSuggestionBubbleBackgroundColor"
                    />
                  </div>
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Text color" />
                    <ColorPicker
                      defaultValue={
                        formDefaults.attentionGrabberSuggestionBubbleTextColor
                      }
                      name="attentionGrabberSuggestionBubbleTextColor"
                    />
                  </div>
                </div>

                <SettingsDivider />

                <div className="flex w-full flex-wrap items-center justify-between gap-4">
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Hover background color" />
                    <ColorPicker
                      defaultValue={
                        formDefaults.attentionGrabberSuggestionBubbleHoverBackgroundColor
                      }
                      name="attentionGrabberSuggestionBubbleHoverBackgroundColor"
                    />
                  </div>
                  <div className="min-w-[180px] flex-1">
                    <SettingsLabel label="Hover text color" />
                    <ColorPicker
                      defaultValue={
                        formDefaults.attentionGrabberSuggestionBubbleHoverTextColor
                      }
                      name="attentionGrabberSuggestionBubbleHoverTextColor"
                    />
                  </div>
                </div>

                <SettingsDivider />

                <SettingsLabel label="Delay" />
                <Input
                  type="number"
                  name="attentionGrabberDelay"
                  defaultValue={formDefaults.attentionGrabberDelay}
                  className="flex h-8 w-[77px] flex-row text-black"
                />
              </>
            )}
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Deployments section */}
      <AccordionItem value="item-7" variant="tabs">
        <AccordionTrigger>
          <h2 className="text-base font-medium">Deployment</h2>
        </AccordionTrigger>
        <AccordionContent>
          <SettingsSection>
            <SettingsLabel label="Domain" />
            <Input
              name="deploymentHostname"
              defaultValue={formDefaults.deploymentHostname}
              className="flex h-8 flex-row text-black"
            />
            <div className="mt-4 flex flex-row items-center pb-2">
              <Switch
                onCheckedChange={(value) => {
                  setValue("enableDeployment", value);
                }}
                defaultChecked={enableDeployment}
              />
              <SettingsLabel label="Enable Deployment" className="ml-2 pb-0" />
            </div>
          </SettingsSection>
        </AccordionContent>
      </AccordionItem>
      {/* Live chat section */}
      {enableLiveChat && (
        <AccordionItem value="item-8" variant="tabs">
          <AccordionTrigger>
            <h2 className="text-base font-medium">Live Chat</h2>
          </AccordionTrigger>
          <AccordionContent>
            <SettingsSection>
              <SettingsLabel
                className="pb-1.5"
                label="Expected response time"
              />
              <div className="flex w-full items-center justify-start gap-2">
                <Input
                  name="liveChatResponseTime"
                  defaultValue={formDefaults.liveChatResponseTime}
                  type="number"
                  className="h-8 w-[77px] text-black"
                />
                <span className="text-muted-foreground text-sm">minutes</span>
              </div>

              <SettingsDivider />

              <SettingsLabel
                className="pb-1.5"
                label="Live chat response timeout"
              />

              <div className="flex w-full items-center justify-start gap-2">
                <Input
                  name="liveChatTimeout"
                  defaultValue={formDefaults.liveChatTimeout}
                  type="number"
                  className="h-8 w-[77px] text-black"
                />
                <span className="text-muted-foreground text-sm">minutes</span>
              </div>
            </SettingsSection>
          </AccordionContent>
        </AccordionItem>
      )}
      {/* Admin section */}
      {user.isAdmin && (
        <AccordionItem value="item-9" variant="tabs">
          <AccordionTrigger>
            <h2 className="text-base font-medium">Admin</h2>
          </AccordionTrigger>
          <AccordionContent>
            <SettingsSection>
              <div className="flex flex-row items-center pb-2">
                <Checkbox name="enableOctocomBranding" />
                <SettingsLabel
                  label="Enable Octocom Branding"
                  className="ml-2 pb-0"
                />
              </div>

              <div className="flex flex-row items-center pb-2">
                <Checkbox name="enableLiveChat" />
                <SettingsLabel label="Enable Live Chat" className="ml-2 pb-0" />
              </div>
            </SettingsSection>
          </AccordionContent>
        </AccordionItem>
      )}
    </Accordion>
  );
}

export default SettingsTab;
