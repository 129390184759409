import ModalContainer from "@chatbot/components/ChatWindow/Modal/ModalContainer";
import SettingsModalContent from "@chatbot/components/ChatWindow/Settings/SettingsModalContent";
import { useUiState } from "@chatbot/hooks/uiState.hooks";
import { useRef, KeyboardEvent } from "react";

export function SettingsModal() {
  const { displaySettings, closeSettings } = useUiState();

  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      closeSettings();
    }
  };

  if (!displaySettings) {
    return null;
  }

  return (
    <div aria-modal aria-label="settings">
      <ModalContainer show={displaySettings} onClickClose={closeSettings}>
        <div ref={modalRef} onKeyDown={handleKeyDown}>
          <SettingsModalContent />
        </div>
      </ModalContainer>
    </div>
  );
}
