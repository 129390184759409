import { ChannelType } from "@db";

type DetermineReplyChannelResult =
  | {
      showInput: false;
      replyChannel: null;
    }
  | {
      showInput: true;
      replyChannel: ChannelType;
    };

interface DetermineReplyChannelParams {
  activeTab: "external" | "customer";
  hasExternalTicketConfig: boolean;
  isClosed: boolean;
  isHandedOff: boolean;
  replyChannel: ChannelType | null;
  latestChannel: ChannelType;
  enableLiveChat: boolean;
  customerEmailAvailable: boolean;
  emailIntegrationAvailable: boolean;
}

export function determineReplyChannel({
  activeTab,
  hasExternalTicketConfig,
  isClosed,
  isHandedOff,
  replyChannel,
  latestChannel,
  enableLiveChat,
  customerEmailAvailable,
  emailIntegrationAvailable,
}: DetermineReplyChannelParams): DetermineReplyChannelResult {
  console.log("Determining reply channel", {
    activeTab,
    hasExternalTicketConfig,
    isClosed,
    isHandedOff,
    replyChannel,
    latestChannel,
    enableLiveChat,
    customerEmailAvailable,
    emailIntegrationAvailable,
  });

  if (
    isClosed ||
    !isHandedOff ||
    hasExternalTicketConfig ||
    latestChannel === "zoom-transcript"
  ) {
    return { showInput: false, replyChannel: null };
  }

  function validateChannel(
    channel: ChannelType | null,
  ): DetermineReplyChannelResult {
    let result: DetermineReplyChannelResult = {
      showInput: false,
      replyChannel: null,
    };

    console.log("Validating channel", {
      channel,
      enableLiveChat,
      customerEmailAvailable,
      emailIntegrationAvailable,
      isClosed,
      isHandedOff,
      hasExternalTicketConfig,
    });

    switch (channel) {
      case "web":
        if (enableLiveChat) {
          result = { showInput: true, replyChannel: "web" };
        } else {
          result = validateChannel("email");
        }
        break;
      case "email":
        if (customerEmailAvailable && emailIntegrationAvailable) {
          result = { showInput: true, replyChannel: "email" };
        }
        break;
      case "messenger": {
        result = { showInput: true, replyChannel: "messenger" };
        break;
      }
      case "instagram": {
        result = { showInput: true, replyChannel: "instagram" };
        break;
      }
      case "whatsapp": {
        result = { showInput: true, replyChannel: "whatsapp" };
        break;
      }
      case null: {
        result = validateChannel(latestChannel);
        break;
      }
      default:
        break;
    }

    console.log("Validated channel", {
      result,
    });

    return result;
  }

  const result = validateChannel(replyChannel);

  return result;
}
