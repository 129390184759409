import { cn } from "@dashboard/utils/ui";
import { Link } from "@tanstack/react-router";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useUpdateOnboardingStatus } from "../-hooks/useUpdateOnboardingStatus";
import { Route } from "../../onboarding";

interface OnboardingNavigationProps {
  backPath?: string;
  nextPath?: string;
  isNextEnabled: boolean;
  finalStep?: boolean;
}

export function OnboardingNavigation({
  backPath,
  nextPath,
  isNextEnabled,
  finalStep,
}: OnboardingNavigationProps) {
  const { organizationSlug } = Route.useParams();

  const { update } = useUpdateOnboardingStatus({
    onSuccess: () => {
      window.location.href = "/organization";
    },
  });

  const handleFinishOnboarding = () => {
    update("SETUP");
  };

  return (
    <div
      className={cn("mt-4 flex w-full items-center", {
        "justify-between": backPath,
        "justify-end": !backPath,
      })}
    >
      {backPath && (
        <Link
          to={backPath}
          params={{ organizationSlug }}
          className="flex items-center px-4 py-2 text-sm font-normal text-[#041627] hover:underline"
        >
          <ArrowLeft className="mr-2 h-5 w-5" strokeWidth={1.5} />
          Go Back
        </Link>
      )}
      {finalStep ? (
        <button
          className="flex h-10 items-center px-4 py-2 text-sm font-normal text-[#041627] hover:underline"
          onClick={handleFinishOnboarding}
        >
          Finish Onboarding
          <ArrowRight className="ml-2 h-5 w-5" strokeWidth={1.5} />
        </button>
      ) : (
        nextPath &&
        isNextEnabled && (
          <Link
            to={nextPath}
            params={{ organizationSlug }}
            className="flex items-center px-4 py-2 text-sm font-normal text-[#041627] hover:underline"
          >
            Continue to Next Step
            <ArrowRight className="ml-2 h-5 w-5" strokeWidth={1.5} />
          </Link>
        )
      )}
    </div>
  );
}
