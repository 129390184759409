import { CalendarDays } from "lucide-react";
import { Route } from "..";
import { Select } from "@dashboard/common/ui/select";

export default function RangeSelect() {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const setSearch = (period: typeof search.period) => {
    void navigate({
      search: {
        ...search,
        period,
      },
    });
  };

  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "Past week",
      value: "past-week",
    },
    {
      label: "Month to date",
      value: "month-to-date",
    },
    {
      label: "Past 4 weeks",
      value: "past-4-weeks",
    },
    {
      label: "Past 12 weeks",
      value: "past-12-weeks",
    },
  ];

  return (
    <Select
      variant="dropdown"
      value={search.period}
      valueIcon={CalendarDays}
      className="gap-2"
      defaultValue={options[0].value}
      onChange={(value) => setSearch(value as typeof search.period)}
      options={options}
    />
  );
}
