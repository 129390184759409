import { useState, useEffect, ReactNode } from "react";

function CustomErrorBoundary({ children }: { children: ReactNode }) {
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const errorHandler = (error: unknown, errorInfo?: string) => {
      console.error("Uncaught error:", error, errorInfo);
      setHasError(true);
    };

    const errorBoundaryWrapper =
      (fn: (...args: unknown[]) => unknown) =>
      (...args: unknown[]) => {
        try {
          return fn(...args);
        } catch (error) {
          errorHandler(error);
        }
      };

    const originalConsoleError = console.error;
    console.error = errorBoundaryWrapper(originalConsoleError) as (
      ...args: unknown[]
    ) => void;

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  if (hasError) {
    return null;
  }

  return <>{children}</>;
}

export default CustomErrorBoundary;
