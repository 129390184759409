import { Input } from "@dashboard/common/ui/input";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

interface SearchBarProps {
  search: {
    searchQuery: string | undefined;
    setSearch: (value: string | undefined) => void;
  };
}

export function SearchBar({ search }: SearchBarProps) {
  const [inputValue, setInputValue] = useState(search.searchQuery ?? "");

  const debounced = useDebouncedCallback((value: string) => {
    search.setSearch(value);
  }, 500);

  return (
    <div className="flex w-full shrink-0 flex-row items-start justify-start py-4">
      <Input
        type="text"
        placeholder="Search"
        value={inputValue}
        icon={true}
        className="!w-full"
        onChange={(input) => {
          setInputValue(input.target.value);
          debounced(input.target.value);
        }}
      />
    </div>
  );
}
