import { toast } from "sonner";
import { Route } from "..";

export function useGetEmails() {
  const { trpc } = Route.useRouteContext();

  const mutation = trpc.dashboard.orders.getEmails.useMutation({
    onError: () => {
      toast.error("Error retrieving order emails. Please try again.");
    },
  });

  return async ({
    businessSlug,
    orderIds,
  }: {
    businessSlug: string;
    orderIds: string;
  }) => {
    return await mutation.mutateAsync({
      businessSlug,
      orderIds,
    });
  };
}
