import { Route } from "..";

export function useGetProductStatus() {
  const { trpc, business } = Route.useRouteContext();

  const [config] = trpc.dashboard.products.getConfig.useSuspenseQuery({
    businessSlug: business.slug,
  });

  const [count] = trpc.dashboard.products.getCount.useSuspenseQuery({
    businessSlug: business.slug,
  });

  return {
    totalProducts: count,
    syncedAt: config?.lastSyncedAt ? new Date(config.lastSyncedAt) : null,
  };
}
