import { useInitializeConfig } from "@chatbot/hooks/config.hooks";
import { useInitializeBrowserSession } from "@chatbot/hooks/browserSessions.hooks";
import { useSuntouchedHack, useZendeskHack } from "@chatbot/hooks/hacks.hooks";
import { Coordinator } from "@chatbot/components/Coordinator";

export function BrowserSessionProvider({
  presetConfigId,
}: {
  presetConfigId?: string;
}) {
  const config = useInitializeConfig({ presetConfigId });

  useSuntouchedHack({
    enabled: new URL(window.location.href).hostname === "suntouched.co",
  });

  useZendeskHack({
    enabled: new URL(window.location.href).hostname === "drinklemonkind.com",
  });

  const browserSessionId = useInitializeBrowserSession({
    configId: config?.id,
  });
  if (!config || !browserSessionId) {
    return null;
  }

  return <Coordinator browserSessionId={browserSessionId} config={config} />;
}
