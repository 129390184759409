import { sumProp } from "@dashboard/utils/arrays";
import { useData } from "./useData";
import { addDays, format, startOfDay } from "date-fns";

export function useHandedOffChartData() {
  const { cur, prev, comparedTo, from } = useData();

  const data = cur.handoffs;
  const days = comparedTo.days;

  let dataIndex = 0;
  const handedOffsPerDay = Array.from({ length: days }, (_, i) => {
    const dataTime =
      dataIndex < data.length
        ? startOfDay(new Date(data[dataIndex].date)).getTime()
        : undefined;
    const targetDate = startOfDay(addDays(from, i));

    let count = 0;
    if (targetDate.getTime() === dataTime) {
      count = data[dataIndex].count;
      dataIndex += 1;
    }

    return {
      x: format(targetDate, "PPp"),
      y: count,
    };
  });

  const handedOffCountCur = sumProp(cur.handoffs, "count");
  const handedOffCountPrev = sumProp(prev.handoffs, "count");

  return {
    handedOffsCount: handedOffCountCur,
    handedOffsCountDiff: handedOffCountCur - handedOffCountPrev,
    handedOffsPerDay,
  };
}
