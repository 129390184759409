import { TrackedOrder } from "@be/modules/orders/orders.types";
import { formatCurrency } from "@dashboard/utils/currency";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ItemDetailSection } from "./OrderDetailSection";

interface ShippingCostSectionProps {
  order: TrackedOrder;
}

export function ShippingCostSection({ order }: ShippingCostSectionProps) {
  return (
    <AccordionItem
      value="shipping-cost"
      className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
    >
      <AccordionTrigger className="py-2 text-xs font-medium">
        Shipping Cost:{" "}
        {order.shippingCost
          ? formatCurrency({
              amount: parseFloat(order.shippingCost.amount),
              currency: order.shippingCost.currencyCode,
            })
          : "N/A"}
      </AccordionTrigger>
      <AccordionContent className="pb-2 text-xs">
        <ItemDetailSection
          details={[
            {
              label: "Shipping Code:",
              value: order.shippingMethod ?? "N/A",
            },
          ]}
        />
      </AccordionContent>
    </AccordionItem>
  );
}
