import { Button } from "@dashboard/common/ui/button";
import { RadioGroup, RadioGroupItem } from "@dashboard/common/ui/radio-group";
import { Separator } from "@dashboard/common/ui/separator";
import { cn } from "@dashboard/utils/ui";
import { Star } from "lucide-react";
import { KeyboardEventHandler, useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { FeedbackForm, feedbackFormSchema } from "../-utils/feedbackFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { toast } from "sonner";
import { useCreateFeedback } from "../-hooks/useCreateFeedback";

export function FeedbackFormInput({
  showMessageInput,
}: {
  showMessageInput: boolean;
}) {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const form = useForm<FeedbackForm>({
    resolver: zodResolver(feedbackFormSchema),
  });

  const onSubmitMutation = useCreateFeedback();

  const createNullFeedback = () => {
    onSubmitMutation({});
  };

  const onSubmit = (data: FeedbackForm) => {
    const payload = {
      correctAction: data.correctAction === "yes",
      comment: data.comment,
    };

    onSubmitMutation(payload);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to submit feedback");
  };

  const handleKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      void form.handleSubmit(onSubmit, onError)(event);
      event.preventDefault();
    }
  };

  const correctAction = form.watch("correctAction");

  let placeholder = "";

  switch (correctAction) {
    case "yes":
      placeholder = "Great! Any feedback? (Optional)";
      break;
    case "no":
      placeholder = "What was wrong? (Required)";
      break;
  }

  return (
    <div className="mb-3 w-full px-7">
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div
            className={cn(
              "flex flex-col gap-4 overflow-x-hidden break-words rounded-2xl border border-gray-100 px-4 pb-2 pt-3 shadow-md",
              inputIsFocused && "shadow-lg",
            )}
          >
            <div className="flex flex-row items-center gap-2">
              <Star className="h-4" />

              <span className="text-sm font-medium">
                Leave feedback for the bot
              </span>
            </div>

            <div className="flex flex-col items-start justify-center gap-4">
              <FormField
                control={form.control}
                name="correctAction"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Did the bot handle the conversation correctly?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="px-2"
                      >
                        <FormItem className="flex items-center gap-2">
                          <FormControl>
                            <RadioGroupItem value="yes" />
                          </FormControl>
                          <FormLabel className="h-full">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center gap-2">
                          <FormControl>
                            <RadioGroupItem value="no" />
                          </FormControl>
                          <FormLabel className="h-full">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {
              //eslint-disable-next-line
              correctAction && (
                <FormField
                  control={form.control}
                  name="comment"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <TextareaAutosize
                          minRows={2}
                          maxRows={10}
                          className="block w-full resize-none border-0 px-0 py-2 text-sm text-black outline-none focus:ring-0"
                          placeholder={placeholder}
                          {...field}
                          onKeyDown={handleKeyDown}
                          onFocus={() => setInputIsFocused(true)}
                          onBlur={() => setInputIsFocused(false)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )
            }

            <div className="flex h-8 items-center justify-end gap-4">
              {showMessageInput && (
                <>
                  <Button
                    type="button"
                    className="hover:bg-gray-light h-full"
                    variant="ghost"
                    onClick={createNullFeedback}
                  >
                    Skip
                  </Button>
                  <Separator
                    orientation="vertical"
                    className="h-5 bg-black transition duration-100 ease-in-out"
                  />
                </>
              )}
              <Button
                type="submit"
                disabled={!form.formState.isValid}
                className="h-full rounded-lg pr-3 font-semibold shadow-none transition duration-100 ease-in-out disabled:bg-transparent disabled:text-gray-500 disabled:shadow-none"
              >
                Submit feedback
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
