import { Route } from "..";
import { toast } from "sonner";

export function useRemoveTag() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.conversations.tags.remove.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.tags.getConversationTags.invalidate(
        {
          organizationSlug,
          publicId,
        },
      );
    },

    onError: (error) => {
      console.error(error);
      toast.error("Failed to remove tag");
    },
  });

  return (tagId: string) => {
    deleteMutation.mutate({
      organizationSlug,
      publicId,
      tagId,
    });
  };
}
