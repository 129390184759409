import { useEffect } from "react";
import { useRemoveConversation } from "./useRemoveConversation";
import { useParams } from "@tanstack/react-router";

export function useMovedConversationEffect({
  pageParams,
  movedConversation,
  setMovedConversation,
}: {
  movedConversation: string | null;
  setMovedConversation: (value: string | null) => void;
  pageParams: (string | null)[];
}) {
  const removeConversation = useRemoveConversation({
    pageParams,
  });

  const { publicId: activePublicId } = useParams({ strict: false });

  useEffect(() => {
    setMovedConversation(null);
    if (movedConversation) {
      removeConversation({ publicId: movedConversation });
    }
  }, [activePublicId]);
}
