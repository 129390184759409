import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";

import { Route } from "../..";

import { useState } from "react";
import { UserRoundPlus, UserRoundPlusIcon } from "lucide-react";
import { ActionButton } from "./ActionButton";

export function AssignUser({
  setFormValue,
  data,
  error,
  clearErrors,
}: {
  setFormValue: (value: string) => void;
  data: string | null;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  let fullName;
  if (data) {
    fullName = users.find((user) => user.id === data)?.fullName;
  }

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(fullName ?? "");

  const handleSelectTag = (id: string, fullName: string) => {
    setValue(fullName);
    setFormValue(id);
    setOpen(false);
    clearErrors();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <ActionButton
          value={value}
          label="Assign to"
          error={error}
          Icon={UserRoundPlusIcon}
        />
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Member..."
            icon={UserRoundPlus}
            value={value}
            onValueChange={(search) => setValue(search)}
          />
          <CommandList>
            <CommandEmpty>
              <span>No members found.</span>
            </CommandEmpty>
            <CommandGroup>
              {users.map((user) => (
                <CommandItem
                  key={user.id}
                  value={user.fullName}
                  onSelect={() => handleSelectTag(user.id, user.fullName)}
                >
                  {user.fullName}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
