import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import { OrderActionButton } from "./OrderActionButton";
import { Fulfillment } from "@be/modules/orders/orders.types";
import { Box } from "lucide-react";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Separator } from "@dashboard/common/ui/separator";
import { formatCurrency } from "@dashboard/utils/currency";
import { useFulfillmentsReleaseHold } from "../../../-hooks/orders/useFulfillmentsReleaseHold";

interface ReleaseHoldFulfillmentDialogProps {
  fulfillments: Fulfillment[];
}

export function ReleaseHoldFulfillmentDialog({
  fulfillments,
}: ReleaseHoldFulfillmentDialogProps) {
  const [open, setOpen] = useState(false);
  const { releaseFulfillmentsHold } = useFulfillmentsReleaseHold();

  function onConfirm() {
    releaseFulfillmentsHold({
      fulfillmentIds: fulfillments.map(
        (fulfillment) => fulfillment.externalId ?? "UNKNOWN_ID",
      ),
      onSuccess: () => {
        setOpen(false);
      },
    });
  }

  const releasableHoldFulfillments = fulfillments.filter(
    (fulfillment) => fulfillment.canReleaseHold,
  );
  const name =
    releasableHoldFulfillments.length === 1 ? "Fulfillment" : "Fulfillments";

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <OrderActionButton label="Release Hold" />
      <DialogContent className="w-[1000px] max-w-full p-0 text-sm">
        <DialogHeader className="border-b p-4">
          <DialogTitle>Release Hold on {name}</DialogTitle>
        </DialogHeader>
        {releasableHoldFulfillments.length > 0 && (
          <div className="max-w-[999px] border-b pb-4 pt-0">
            <div className="flex max-h-[300px] flex-col overflow-y-auto">
              {releasableHoldFulfillments.map(
                (fulfillment, fulfillmentIndex) => (
                  <>
                    <div key={fulfillmentIndex} className="px-6">
                      <h3 className="mb-2 font-semibold">
                        Fulfillment {fulfillmentIndex + 1}
                      </h3>
                      {fulfillment.items?.map((item, itemIndex) => (
                        <div key={itemIndex}>
                          <div className="flex items-center justify-between">
                            <div className="flex max-w-[60%] items-center gap-4">
                              <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-xl">
                                {item.image ? (
                                  <img
                                    src={item.image}
                                    alt={item.name}
                                    className="h-full w-full object-cover"
                                    onError={(e) => {
                                      e.currentTarget.style.display = "none";
                                      const nextElement = e.currentTarget
                                        .nextElementSibling as HTMLElement;
                                      nextElement.style.display = "block";
                                    }}
                                  />
                                ) : null}
                                <Box
                                  className="h-full w-full"
                                  strokeWidth={1}
                                  style={{
                                    display: item.image ? "none" : "block",
                                  }}
                                />
                              </div>
                              <TruncatedTooltip text={item.name} />
                            </div>

                            <div className="flex items-center gap-4">
                              <div>
                                {item.discountedUnitPrice &&
                                parseFloat(item.discountedUnitPrice.amount) <
                                  parseFloat(
                                    item.orginalUnitPrice?.amount ?? "0",
                                  ) ? (
                                  <>
                                    <span className="text-gray-400 line-through">
                                      {formatCurrency({
                                        amount: parseFloat(
                                          item.orginalUnitPrice?.amount ?? "0",
                                        ),
                                        currency:
                                          item.orginalUnitPrice?.currencyCode ??
                                          "USD",
                                      })}
                                    </span>{" "}
                                    <span>
                                      {formatCurrency({
                                        amount: parseFloat(
                                          item.discountedUnitPrice.amount,
                                        ),
                                        currency:
                                          item.discountedUnitPrice.currencyCode,
                                      })}
                                    </span>
                                  </>
                                ) : (
                                  formatCurrency({
                                    amount: parseFloat(
                                      item.orginalUnitPrice?.amount ?? "0",
                                    ),
                                    currency:
                                      item.orginalUnitPrice?.currencyCode ??
                                      "USD",
                                  })
                                )}{" "}
                                x {item.quantity}
                              </div>
                              <div className="text-right">
                                <p className="font-medium">
                                  Total:{" "}
                                  {formatCurrency({
                                    amount: parseFloat(
                                      item.totalPrice?.amount ?? "0",
                                    ),
                                    currency:
                                      item.totalPrice?.currencyCode ?? "USD",
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                          {itemIndex < (fulfillment.items?.length ?? 0) - 1 && (
                            <Separator className="my-2" />
                          )}
                        </div>
                      ))}
                    </div>
                    {fulfillmentIndex <
                      releasableHoldFulfillments.length - 1 && (
                      <Separator className="my-4" />
                    )}
                  </>
                ),
              )}
            </div>
          </div>
        )}
        <div className="p-4 pt-0">
          <DialogFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={onConfirm}>Release Hold</Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
