export const getUnitAndDivisor = (seconds: number) => {
  if (seconds < 60) {
    return { unit: "Seconds", divisor: 1 };
  } else if (seconds < 3600) {
    return { unit: "Minutes", divisor: 60 };
  } else if (seconds < 86400) {
    return { unit: "Hours", divisor: 3600 };
  } else {
    return { unit: "Days", divisor: 86400 };
  }
};
