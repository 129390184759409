import { env } from "@dashboard/env";
import { Route } from "..";
import { WoocommerceForm } from "../-utils/woocommerceFormSchema";

export function useSubmitForm() {
  const { business } = Route.useRouteContext();
  const baseUrl = `${env.DASHBOARD_BASE_API_URL}/woocommerce/install`;

  return {
    onSubmit: (data: WoocommerceForm) => {
      const website = data.website.trim();

      const params = new URLSearchParams({
        businessId: business.id,
        website,
      });

      const url = `${baseUrl}?${params.toString()}`;

      console.log("Redirecting to", url);
      window.location.href = url;
    },
  };
}
