import logo from "@dashboard/assets/bot.svg";

export const formDefaults = {
  // Top bar
  topBarTitle: "Chat with us",
  topBarImage: undefined,
  topBarImageSrc: logo,
  topBarBackgroundColor: "#ffffff",
  topBarTextColor: "#1a1a1a",
  topBarIconColor: "#1f2937",
  enableTopBarBorder: true,

  // Bot
  botName: "Octo",
  botIcon: undefined,
  botIconSrc: logo,
  botMessageBackgroundColor: "#f4f4f4",
  botMessageTextColor: "#000000",
  greetingMessage: "Hi! How can I help you today?",
  requestEmailBeforeChat: false,

  // Customer
  customerMessageBackgroundColor: "#000000",
  customerMessageTextColor: "#ffffff",

  // Disclaimer
  disclaimerText: undefined,
  disclaimerButtonBackgroundColor: "#F5F5F5",
  disclaimerButtonTextColor: "#020817",
  enableDisclaimer: false,

  // Position
  positionBottom: "20px",
  positionSide: "20px",
  selectedSide: "right",

  // Launcher
  launcherText: "Need help?",
  launcherBackgroundColor: "#ffffff",
  launcherTextColor: "#000000",
  launcherIconColor: "#000000",
  launcherSize: "48px",
  launcherIconSize: "24px",
  launcherIcon: undefined,
  launcherIconSrc: undefined,
  launcherIconFileId: undefined,
  launcherImage: undefined,
  launcherImageSrc: undefined,
  launcherImageFileId: undefined,

  // Attention grabbers
  enableAttentionGrabbers: false,
  attentionGrabberMessage: "Hi, how can I help you?",
  attentionGrabberSuggestionBubbleBackgroundColor: "#e5eeff",
  attentionGrabberSuggestionBubbleTextColor: "#003499",
  attentionGrabberSuggestionBubbleHoverBackgroundColor: "#0057ff",
  attentionGrabberSuggestionBubbleHoverTextColor: "#ffffff",
  attentionGrabberDelay: undefined,
  attentionGrabberSuggestions: [
    { displayText: "", message: "" },
    { displayText: "", message: "" },
    { displayText: "", message: "" },
  ],

  // Deployments
  deploymentHostname: undefined,
  enableDeployment: false,

  // Live chat
  enableLiveChat: false,
  liveChatResponseTime: undefined,
  liveChatTimeout: undefined,
};
