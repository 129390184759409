import { useState, useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "@dashboard/common/ui/input";
import { Route } from "../_conversationSidebar";
import { SearchFilters } from "./SearchFilters/SearchFilters";

export function SidebarSearch() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const [inputValue, setInputValue] = useState(search.searchQuery ?? "");
  const inputRef = useRef<HTMLInputElement>(null);

  const setSearch = (key: "searchQuery", value: string | undefined) => {
    if (value === "") {
      value = undefined;
    }
    void navigate({ search: { ...search, [key]: value } });
  };

  const debounced = useDebouncedCallback((value: string) => {
    setSearch("searchQuery", value);
  }, 500);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-start gap-4">
      <div className="px-2 pt-1">
        <Input
          type="text"
          placeholder="Search"
          value={inputValue}
          icon={true}
          ref={inputRef}
          onChange={(input) => {
            setInputValue(input.target.value);
            debounced(input.target.value);
          }}
        />
      </div>
      <SearchFilters />
    </div>
  );
}
