import { filterKeysSchema } from "@be/modules/dashboard/views/views.schemas";
import { CustomView } from "@be/modules/dashboard/views/views.types";
import { z } from "zod";

export const viewFormSchema = z.object({
  title: z
    .string({
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  filters: z.array(
    z.object({
      key: filterKeysSchema,
      value: z.string({
        invalid_type_error: "Value is missing",
      }),
    }),
  ),
});

export type ViewForm = z.infer<typeof viewFormSchema>;
export type ViewFormPayload = Omit<CustomView, "id" | "organizationId">;
