import { Route } from "..";

export function useLogMacroRecommendationUsed() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();

  const mutation =
    trpc.dashboard.macros.logMacroRecommendationUsed.useMutation();

  return ({ macroTitle }: { macroTitle: string }) => {
    mutation.mutate({
      macroTitle,
      publicId,
      organizationSlug,
    });
  };
}
