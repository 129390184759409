import { createFileRoute } from "@tanstack/react-router";
import { ConversationTopBar } from "../-components/ConversationTopBar";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/",
)({
  loaderDeps: ({ search: { view, filters, status, sortBy, searchQuery } }) => ({
    view,
    filters,
    status,
    sortBy,
    searchQuery,
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
    deps: { view, filters, status, sortBy, searchQuery },
  }) => {
    await trpcClientUtils.dashboard.conversations.getAll.ensureData({
      organizationSlug,
      view,
      filters,
      status,
      sortBy,
      searchQuery,
    });
  },
  component: SelectConversationPage,
});

function SelectConversationPage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const { view, filters, status, sortBy, searchQuery } = Route.useSearch();

  const [{ conversations }] =
    trpc.dashboard.conversations.getAll.useSuspenseQuery({
      organizationSlug,
      view,
      filters,
      status,
      sortBy,
      searchQuery,
    });

  return (
    <>
      <div className="flex h-full flex-grow flex-col">
        <ConversationTopBar />
        <div className="flex w-full flex-col items-center">
          <h1 className="mt-48 text-lg font-medium">
            {conversations.length === 0 ? (
              <span>No conversations found</span>
            ) : (
              <span>Please select a conversation</span>
            )}
          </h1>
        </div>
      </div>
      <div className="flex h-full w-[22%] shrink-0 flex-col border-l 2xl:w-[25%]">
        <ConversationTopBar />
      </div>
    </>
  );
}
