import { Route } from "../../onboarding";

export function useExternalTicketConfig() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [config] =
    trpc.dashboard.externalTickets.getConfiguration.useSuspenseQuery({
      organizationSlug,
    });

  return config;
}
