import ShopifySubscriptionTutorial from "./Tutorials/shopifySubscription";
import ShopifyUsageTutorial from "./Tutorials/shopifyUsage";
interface TutorialProps {
  tutorialType?: "shopifySubscription" | "shopifyUsage";
}

function Tutorial({ tutorialType }: TutorialProps) {
  switch (tutorialType) {
    case "shopifySubscription":
      return <ShopifySubscriptionTutorial />;
    case "shopifyUsage":
      return <ShopifyUsageTutorial />;
    default:
      return null;
  }
}

export default Tutorial;
