import { Route } from "../_conversationList";

export const useInvalidateConversationData = ({
  publicId,
}: {
  publicId?: string;
}) => {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  return async () => {
    await trpcUtils.dashboard.conversations.getAll.invalidate({
      organizationSlug,
    });
    await trpcUtils.dashboard.conversations.getCount.invalidate({
      organizationSlug,
    });
    await trpcUtils.dashboard.conversations.get.invalidate({
      organizationSlug,
      publicId,
    });
    await trpcUtils.dashboard.conversations.tags.getConversationTags.invalidate(
      {
        organizationSlug,
        publicId,
      },
    );
  };
};
