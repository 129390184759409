import { useState } from "react";
import logo from "../../../../../../../assets/side-navbar-logo.svg";
import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";

function ShopifyUsageTutorial() {
  const [dialogOpen, setDialogOpen] = useState(true);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent>
        <div className="block">
          <div className="flex w-full justify-center">
            <img alt="Octocom logo" className="h-14 w-14" src={logo} />
          </div>
          <div
            className="flex w-full justify-center"
            style={{ marginTop: "30px" }}
          >
            <div className="text-center">
              <h1 className="text-2xl font-bold">Thank you!</h1>
              <p
                className="text-wrap text-center"
                style={{ marginTop: "30px" }}
              >
                Now that you have subscribed, we&apos;ll sync your product data
                with the AI. In the mean time, let us show you how to use
                Shopify with Octocom. to get started{" "}
                <a
                  className="text-blue"
                  href="https://octocom.gitbook.io/octocom-1/octocom-chat-embed-tutorial"
                >
                  Click here
                </a>{" "}
                to view our usage tutorials.
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShopifyUsageTutorial;
