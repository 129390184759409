import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ArticleForm, articleFormSchema } from "../-utils/articleForm";
import { ArticleFormBody } from "./ArticleFormBody";
import { useEditArticle } from "../-hooks/useEditArticle";

interface EditArticleFormProps {
  id: string;
  title: string;
  content: string;
  categoryId?: string;
  isPublic?: boolean;
  setOpen: (opened: boolean) => void;
}

export function EditArticleForm({
  id,
  title,
  content,
  categoryId,
  isPublic,
  setOpen,
}: EditArticleFormProps) {
  const form = useForm<ArticleForm>({
    resolver: zodResolver(articleFormSchema),
    defaultValues: {
      title,
      content,
      categoryId,
      isPublic,
    },
  });

  const { onSubmit: onSubmitMutation } = useEditArticle({ setOpen });

  function onSubmit(data: ArticleForm) {
    onSubmitMutation({ ...data, id });

    form.reset();
  }

  return <ArticleFormBody form={form} onSubmit={onSubmit} />;
}
