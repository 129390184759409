import { RouterOutput, trpc } from "@chatbot/utils/trpc";
import { useEffect } from "react";
import { proxy, useSnapshot, snapshot } from "valtio";

type Config = RouterOutput["webChat"]["getConfig"];

const configStore = proxy({
  config: undefined as Config | undefined,
});

type ConfigStore = ReturnType<typeof snapshot<typeof configStore>>;

export type ReadonlyConfig = NonNullable<ConfigStore["config"]>;
export type SupportHours = ReadonlyConfig["supportHours"];

export function useConfigOptional() {
  const snapshot = useSnapshot(configStore);

  return snapshot.config;
}

export function useConfig() {
  const snapshot = useSnapshot(configStore);

  if (!snapshot.config) {
    throw new Error("Config is undefined");
  }

  return snapshot.config;
}

export function useInitializeConfig({
  presetConfigId,
}: {
  presetConfigId: string | undefined;
}) {
  const { config } = useSnapshot(configStore);

  console.log("presetConfigId", presetConfigId);
  console.log("getting config for host", window.location.href);

  const configQuery = trpc.webChat.getConfig.useQuery(
    presetConfigId ? { id: presetConfigId } : { url: window.location.href },
  );

  useEffect(() => {
    configStore.config = configQuery.data;
  }, [configQuery.data]);

  return config;
}
