import { toast } from "sonner";
import { Route } from "..";

export function useTranslateText({
  onData,
}: {
  onData: (data: string) => void;
}) {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const translationMutation =
    trpc.dashboard.conversations.translateText.useMutation({
      onSuccess: (data) => {
        console.log("Translation result", data);
        if (!data) {
          toast.error(
            "Could not translate message because the target language is not supported",
          );
          return;
        }
        onData(data);
      },
      onError: () => {
        toast.error("Failed to translate message");
      },
    });

  const translate = ({
    text,
    targetLang,
  }: {
    text: string;
    targetLang: string;
  }) => {
    translationMutation.mutate({
      organizationSlug,
      text,
      publicId,
      targetLang,
    });
  };

  return {
    translate,
  };
}
