import { z, ZodTypeAny } from "zod";

const injectedEnv = process.env;
const CI = injectedEnv.CI === "true";

function devOptional<T extends ZodTypeAny>(t: T): T {
  return CI ? t : (t.optional() as unknown as T);
}

export const env = z
  .object({
    NODE_ENV: z
      .union([
        z.literal("production"),
        z.literal("development"),
        z.literal("test"),
      ])
      .default("development"),
    DASHBOARD_BASE_API_URL: z.string().url(),
    DASHBOARD_TRPC_WS_URL: z.string().url(),
    DASHBOARD_TRPC_HTTP_URL: z.string().url(),
    DASHBOARD_SENTRY_DSN: z
      .string()
      .default(
        "https://52b9da581ab2a1fe3ad9932c25061c29@o4506240732889088.ingest.sentry.io/4506258316197888",
      ),
    DASHBOARD_META_APP_ID: devOptional(z.string()),
    DASHBOARD_META_REDIRECT_URI: devOptional(z.string()),
    DASHBOARD_WHATSAPP_CONFIGURATION_ID: devOptional(z.string()),
    DASHBOARD_MESSENGER_CONFIGURATION_ID: devOptional(z.string()),
    DASHBOARD_INSTAGRAM_CONFIGURATION_ID: devOptional(z.string()),
    DASHBOARD_GOOGLE_CLIENT_ID: devOptional(z.string()),
    DASHBOARD_BASE_URL: z.string().url(),
    DASHBOARD_LANDING_URL: z.string().url(),
    DASHBOARD_STATIC_BASE_URL: z
      .string()
      .url()
      .default("https://black-tree-0bada2f03.4.azurestaticapps.net/chatbot"),

    DASHBOARD_VAPID_PUBLIC_KEY: z.string(),
  })
  .parse(injectedEnv);
