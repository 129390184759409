import { toast } from "sonner";
import { Route } from "..";

export function useDeleteCategory() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const deleteCategoryMutation =
    trpc.dashboard.articles.deleteCategory.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.articles.getCategories.invalidate({
          businessSlug,
        });
        toast.success("Category deleted successfully");
      },
      onError: (error) => {
        console.error("Failed to delete category:", error);
        toast.error("Failed to delete category");
      },
    });

  return ({ id }: { id: string }) => {
    deleteCategoryMutation.mutate({
      businessSlug,
      id,
    });
  };
}
