import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";

import { Button } from "@dashboard/common/ui/button";
import { Plus } from "lucide-react";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";

interface AddRecordDialogProps {
  recordNameSingular: string;
  children: (setOpen: Dispatch<SetStateAction<boolean>>) => ReactNode;
  initialOpen?: boolean;
}

export function AddRecordDialog({
  recordNameSingular,
  children,
  initialOpen = false,
}: AddRecordDialogProps) {
  const [open, setOpen] = useState(initialOpen);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" strokeWidth={3} />
          Add {recordNameSingular}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] w-[50vw] max-w-[50vw] overflow-scroll">
        {children(setOpen)}
      </DialogContent>
    </Dialog>
  );
}
