import { useData } from "./useData";
import { toPercent } from "@dashboard/utils/numbers";
import { sumProp } from "@dashboard/utils/arrays";

export function useConversationsByTopics(): {
  topic: string;
  count: number;
  percentage: number;
  diff: number;
}[] {
  const { cur, prev } = useData();

  const conversationCountCur = sumProp(cur.conversationsPerDay, "count");
  const conversationCountPrev = sumProp(prev.conversationsPerDay, "count");

  const prevTopics = prev.topics;

  const conversationsByTopics = cur.topics
    .map(({ topic, count }) => {
      const percentage = toPercent(conversationCountCur, count);

      const prev = prevTopics.find((x) => x.topic === topic);
      const prevPercentage =
        prev && toPercent(conversationCountPrev, prev.count);

      if (topic === "unclassified") {
        topic = "informational-queries";
      }

      return {
        topic,
        count,
        percentage,
        diff: percentage - (prevPercentage ?? 0),
      };
    })
    .sort((a, b) => b.count - a.count)
    .filter(
      ({ topic }) =>
        !["asked-to-talk-to-a-human", "asked-to-talk-to-a-supervisor"].includes(
          topic,
        ),
    );

  return conversationsByTopics;
}
