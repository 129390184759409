import { MarkdownContent } from "@dashboard/common/MarkdownContent";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Badge } from "@dashboard/common/ui/badge";
import { format } from "date-fns";
import { Separator } from "@dashboard/common/ui/separator";
import { Button } from "@dashboard/common/ui/button";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";

interface ArticleDialogProps {
  title: string;
  content: string;
  category?: string;
  createdAt: string;
  updatedAt: string;
  isPublic: boolean;
}

export function ArticleDialog({
  title,
  content,
  category,
  createdAt,
  updatedAt,
  isPublic,
}: ArticleDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="max-w-96">
          <Button variant="link" className="w-full justify-start truncate pl-0">
            <TruncatedTooltip text={title} />
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] w-[1000px] max-w-[50vw] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">{title}</DialogTitle>
        </DialogHeader>
        <Separator className="my-2" />
        <div className="flex flex-col gap-1 text-sm text-gray-600">
          <div className="flex justify-start">
            <span className="w-32">Created:</span>
            <span>{format(new Date(createdAt), "PPpp")}</span>
          </div>
          <div className="flex justify-start">
            <span className="w-32">Last Edited:</span>
            <span>{format(new Date(updatedAt), "PPpp")}</span>
          </div>
          <div className="flex justify-start">
            <span className="w-32">Category:</span>
            <Badge variant="outline">{category ?? "No Category"}</Badge>
          </div>
          <div className="flex justify-start">
            <span className="w-32">Visibility:</span>
            <Badge variant="secondary">{isPublic ? "Public" : "Private"}</Badge>
          </div>
        </div>
        <Separator className="my-2" />
        <MarkdownContent content={content} />
      </DialogContent>
    </Dialog>
  );
}
