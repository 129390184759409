import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";

import { Dispatch, SetStateAction } from "react";

interface DeleteRecordDialogContentProps {
  ids: string[];
  recordName: { singular: string; plural: string };
  deleteRecords: (ids: string[]) => void;
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
}

export function DeleteRecordDialogContent({
  ids,
  recordName,
  deleteRecords,
  setSelectedIds,
}: DeleteRecordDialogContentProps) {
  const handleDelete = () => {
    deleteRecords(ids);
    setSelectedIds([]);
  };

  const title =
    ids.length > 1
      ? `Are you sure you want to remove these ${recordName.plural}?`
      : `Are you sure you want to remove this ${recordName.singular}?`;

  return (
    <DialogContent className="max-h-[80vh] overflow-scroll pt-12 sm:max-w-md">
      <DialogHeader className="pb-4">
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <Button variant="destructive" onClick={handleDelete}>
        Yes
      </Button>
    </DialogContent>
  );
}
