import { Route } from "..";
import { toast } from "sonner";

export const useMergeConversation = ({
  onOpenChange,
}: {
  onOpenChange: (value: boolean) => void;
}) => {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const navigate = Route.useNavigate();

  const mergeMutation = trpc.dashboard.conversations.merge.useMutation({
    onSuccess: async (_, { targetId }) => {
      await trpcUtils.dashboard.conversations.getAll.invalidate();
      await trpcUtils.dashboard.conversations.get.invalidate({
        publicId: targetId,
      });

      onOpenChange(false);

      await navigate({
        to: "/organization/$organizationSlug/conversation/$publicId",
        params: { organizationSlug, publicId: targetId },
      });

      toast.success("Conversations merged successfully");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to merge conversations");
    },
  });

  return {
    merge: ({ sourceId, targetId }: { sourceId: string; targetId: string }) => {
      mergeMutation.mutate({ organizationSlug, sourceId, targetId });
    },
    isLoading: mergeMutation.isPending,
  };
};
