import { addDays, format, startOfDay } from "date-fns";
import { useData } from "./useData";
import { sumProp } from "@dashboard/utils/arrays";

export function useConversationChartData() {
  const { cur, prev, comparedTo, from } = useData();

  const data = cur.conversationsPerDay;
  const days = comparedTo.days;

  let dataIndex = 0;
  const conversationsPerDay = Array.from({ length: days }, (_, i) => {
    const dataTime =
      dataIndex < data.length
        ? startOfDay(new Date(data[dataIndex].date)).getTime()
        : undefined;
    const targetDate = startOfDay(addDays(from, i));

    let count = 0;
    if (targetDate.getTime() === dataTime) {
      count = data[dataIndex].count;
      dataIndex += 1;
    }

    return {
      x: format(targetDate, "PPp"),
      y: count,
    };
  });

  const conversationCountCur = sumProp(cur.conversationsPerDay, "count");
  const conversationCountPrev = sumProp(prev.conversationsPerDay, "count");

  return {
    conversationsCount: conversationCountCur,
    conversationsCountDiff: conversationCountCur - conversationCountPrev,
    conversationsPerDay,
  };
}
