import { createFileRoute } from "@tanstack/react-router";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";

import { Button } from "@dashboard/common/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { businessConfigFormSchema } from "./-utils/formSchema";
import { BusinessConfigForm } from "./-utils/formSchema";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import timezones from "timezones-list";
import { useEffect } from "react";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Switch } from "@dashboard/common/ui/switch";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/business/",
)({
  component: BusinessSettingsPage,
});

// TODO: Fix the issue of nulls causing type errors
function BusinessSettingsPage() {
  const { business } = Route.useRouteContext();

  const timezoneSelectOptions = timezones.map((timezone) => ({
    label: timezone.label,
    value: timezone.tzCode,
  }));

  const defaultValues: BusinessConfigForm = { ...business };

  const form = useForm<BusinessConfigForm>({
    resolver: zodResolver(businessConfigFormSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [business]);

  const { onSubmit } = useSubmitForm();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const businessName = form.watch("name") ?? "Your Business";

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              General Settings
              <span className="text-gray"> {`for ${businessName}`}</span>
            </h1>
            <Button variant="publish" className="ml-auto" type="submit">
              {form.formState.isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name of your business</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="website"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Store URL</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="oneSentenceDescription"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Short description of your business</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                      <Textarea
                        placeholder="Type your short description here"
                        maxLength={100}
                        rows={5}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="about"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Longer description of your business</FormLabel>
                    <FormControl>
                      {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                      <Textarea
                        placeholder="Type your longer description here"
                        maxLength={1000}
                        rows={5}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="enableFeedback"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Enable Feedback</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="enableMacroRecommendations"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Enable Macro Recommendations</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tz"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Time zone</FormLabel>
                    {/* @ts-expect-error this works, but type errors need tobe fixed ASAP */}
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a Time Zone" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {timezoneSelectOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      Defaults to the location of the teammate who set up this
                      workspace. Changing it will affect time-dependent features
                      across Octocom.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
