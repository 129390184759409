import { useUiState } from "@chatbot/hooks/uiState.hooks";
import clsxm from "@chatbot/utils/clsxm";
import { TopBar } from "./TopBar";
import { Input } from "./Input";
import { SettingsModal } from "./Settings/SettingsModal";
import { DisclaimerModal } from "./Disclaimer/DisclaimerModal";
import Container from "./Container";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { KeyboardEvent } from "react";

export function ChatWindow() {
  const config = useConfig();
  const {
    loadedIntoContainer,
    isMobile,
    windowHeight,
    displayChatWindow,
    closeChatWindow,
    displaySettings,
  } = useUiState();

  const isLhs =
    (isMobile && !!config.mobilePositionLeft) ||
    (!isMobile && !!config.positionLeft);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape" && !displaySettings) {
      closeChatWindow();
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-label="Customer support chat window"
      style={{
        height: loadedIntoContainer
          ? "100%"
          : isMobile
          ? windowHeight
          : "704px",
        maxHeight: isMobile || loadedIntoContainer ? undefined : "80vh",
        width: isMobile || loadedIntoContainer ? "100%" : "400px",
      }}
      className={clsxm(
        "flex flex-col overflow-hidden rounded-none bg-white drop-shadow-2xl sm:rounded-xl",
        // Mobile is full screen, above the launcher and attention grabbers, or above all the content if loaded into container
        isMobile ? "fixed bottom-0 right-0 z-10" : "",
        displayChatWindow === null
          ? "hidden"
          : loadedIntoContainer
          ? ""
          : displayChatWindow
          ? isLhs
            ? "animate-open-chat-lhs"
            : "animate-open-chat"
          : isLhs
          ? "animate-close-chat-lhs"
          : "animate-close-chat",
      )}
    >
      <TopBar />
      <Container />
      <Input />
      <SettingsModal />
      {config.disclaimerText && <DisclaimerModal />}
    </div>
  );
}
