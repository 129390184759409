import { Route } from "..";

export function useAddTag() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const createMutation = trpc.dashboard.conversations.tags.add.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.tags.getConversationTags.invalidate(
        {
          organizationSlug,
          publicId,
        },
      );
    },

    onError: (error) => {
      console.error(error);
    },
  });

  return (tagId: string) => {
    createMutation.mutate({
      organizationSlug,
      publicId,
      tagId,
    });
  };
}
