import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { format } from "date-fns";
import { z } from "zod";
import { ContextElementDialog } from "./-components/ContextElementDialog";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/websites/context-elements",
)({
  validateSearch: z.object({
    webpageId: z.string().uuid(),
  }),
  beforeLoad: async ({
    context: { trpcClientUtils },
    search: { webpageId },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.scraper.getContextElements.ensureData({
      businessSlug,
      sourceId: webpageId,
    });
  },
  component: ContextElementsTable,
});

function ContextElementsTable() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const { webpageId } = Route.useSearch();

  const [contextElements] =
    trpc.dashboard.scraper.getContextElements.useSuspenseQuery({
      businessSlug,
      sourceId: webpageId,
    });

  const records = contextElements.map((element) => ({
    id: element.id,
    content: element.content.substring(0, 100) + "...",
    createdAt: format(new Date(element.createdAt), "yyyy-MM-dd HH:mm:ss"),
    titleDialog: ContextElementDialog({
      title: element.id,
      content: element.content,
    }),
  }));

  return (
    <Table
      title="Context Elements"
      records={records}
      columns={[
        {
          header: "id",
          accessor: "titleDialog",
        },
        {
          header: "Created At",
          accessor: "createdAt",
        },
      ]}
      recordName={{ singular: "Context Element", plural: "Context Elements" }}
      noRecordsText="No context elements available"
    />
  );
}
