import {
  addDays,
  differenceInDays,
  format,
  startOfDay,
  subDays,
} from "date-fns";
import { Route } from "..";
import { periodToRange } from "../-utils/periodToRange";
import { sumProp } from "@dashboard/utils/arrays";
import { getUnitAndDivisor } from "../-utils/getUnitAndDivisor";

export function useAvgTimeToClose() {
  const {
    period,
    businessSlug,
    avgCloseAssignee: assignee,
  } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const range = periodToRange(period);
  const days = differenceInDays(range.to, range.from);

  const [cur] = trpc.dashboard.metrics.getAvgTimeToClose.useSuspenseQuery({
    organizationSlug,
    businessSlug,
    assignee,
    from: range.from,
    to: range.to,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const prevRange = {
    from: subDays(range.from, days),
    to: range.from,
  };

  const [prev] = trpc.dashboard.metrics.getAvgTimeToClose.useSuspenseQuery({
    organizationSlug,
    businessSlug,
    assignee,
    from: prevRange.from,
    to: prevRange.to,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const avgTimeToCloseCur =
    cur.length > 0 ? sumProp(cur, "value") / cur.length : 0;
  const avgTimeToClosePrev =
    prev.length > 0 ? sumProp(prev, "value") / prev.length : 0;

  const { unit, divisor } = getUnitAndDivisor(avgTimeToCloseCur);

  let dataIndex = 0;
  const avgTimeToClosePerDay = Array.from({ length: days }, (_, i) => {
    const dataTime =
      dataIndex < cur.length
        ? startOfDay(new Date(cur[dataIndex].date)).getTime()
        : undefined;
    const targetDate = startOfDay(addDays(range.from, i));

    let value = 0;
    if (targetDate.getTime() === dataTime) {
      value = Number((cur[dataIndex].value / divisor).toFixed(2));
      dataIndex += 1;
    }

    return {
      x: format(targetDate, "PPp"),
      y: value,
    };
  });

  return {
    value: Number((avgTimeToCloseCur / divisor).toFixed(2)),
    diff: avgTimeToClosePrev
      ? Number(((avgTimeToCloseCur - avgTimeToClosePrev) / divisor).toFixed(2))
      : 0,
    perDay: avgTimeToClosePerDay,
    yTitle: unit,
  };
}
