import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/organization/")({
  beforeLoad: ({
    context: {
      user: { organizations },
    },
  }) => {
    if (organizations.length > 0) {
      throw redirect({
        to: "/organization/$organizationSlug/conversation",
        params: { organizationSlug: organizations[0].slug },
        search: { view: "bot" },
      });
    }
  },
  component: () => (
    <h1>There are no organizations assigned to this account.</h1>
  ),
});
