import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { EditArticleForm } from "./EditArticleForm";

interface EditArticleDialogContentProps {
  id: string;
  title: string;
  content: string;
  categoryId?: string;
  isPublic?: boolean;
  setOpen: (opened: boolean) => void;
}

export function EditArticleDialogContent({
  id,
  title,
  content,
  categoryId,
  isPublic,
  setOpen,
}: EditArticleDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle className="mx-auto">Edit Article</DialogTitle>
      </DialogHeader>
      <EditArticleForm
        id={id}
        title={title}
        content={content}
        categoryId={categoryId}
        isPublic={isPublic}
        setOpen={setOpen}
      />
    </>
  );
}
