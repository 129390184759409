import { Route } from "..";

export function useInvalidateInstalledIntegrations() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  async function invalidate() {
    await trpcUtils.dashboard.integrations.getInstalledIntegrations.invalidate({
      businessSlug,
    });
  }

  return invalidate;
}
