import { Calendar } from "../ui/calendar";
import { TimeOptions } from "./TimeOptions";
import { TimeSelect } from "./TimeSelect";

interface MainSectionProps {
  openSection: "custom" | "options";
  mainSection: "calendar" | "time";
  onSelect: (date: Date) => void;
  calendarDate: Date | undefined;
  setTime: (time: string) => void;
  handleCalendarSelect: (date: Date | undefined) => void;
}

export function MainSection({
  openSection,
  mainSection,
  onSelect,
  calendarDate,
  setTime,
  handleCalendarSelect,
}: MainSectionProps) {
  switch (openSection) {
    case "options":
      return <TimeOptions onSelect={onSelect} />;
    case "custom":
      if (mainSection === "calendar") {
        return (
          <Calendar
            mode="single"
            selected={calendarDate}
            onSelect={handleCalendarSelect}
          />
        );
      } else {
        return <TimeSelect onSelect={setTime} />;
      }
  }
}
