import { cn } from "@dashboard/utils/ui";
import { ChannelType } from "@db";
import { formatDistanceToNow } from "date-fns";

interface ConversationCardProps {
  isClosed: boolean;
  createdAt: Date;
  channel: ChannelType;
  orderId: string | null;
  className?: string;
}

export function ConversationCard({
  isClosed,
  createdAt,
  channel,
  orderId,
  className,
}: ConversationCardProps) {
  return (
    <div
      className={cn(
        "hover:bg-offWhite flex flex-col items-start justify-between gap-1 rounded-xl px-3 py-2",
        className,
      )}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <span className="text-gray text-xs font-medium">
          Started {formatDistanceToNow(createdAt)} ago
        </span>
        <span
          className={cn(
            "ml-auto rounded-full px-3 py-0.5 text-[10px] font-semibold",
            isClosed
              ? "bg-gray-200 text-gray-800"
              : "bg-[#fff9cd] text-[#f9a600]",
          )}
        >
          {isClosed ? "Closed" : "Open"}
        </span>
      </div>
      <div className="flex w-full items-center justify-between gap-1">
        <span className="text-gray text-[12px]">Sent via {channel}</span>
        {orderId && (
          <span className="bg-blue-light text-blue ml-auto rounded-full px-3 py-0.5 text-[10px] font-semibold">
            #{orderId.replace(/^#/, "")}
          </span>
        )}
      </div>
    </div>
  );
}
