import { format } from "date-fns";
import { z } from "zod";

export function Event({
  event,
  createdAt,
  metadata,
}: {
  event: string;
  createdAt: Date | string;
  metadata?: unknown;
}) {
  let content = null;

  const date = format(new Date(createdAt), "MMM d, yyyy");
  const time = format(new Date(createdAt), "HH:mm");

  switch (event) {
    case "webChatTimeout": {
      content = `Live chat timed out, conversation moved to email on ${date} at ${time}`;
      break;
    }
    case "customerLeftWebChat": {
      content = `The customer left the chat, conversation moved to email on ${date} at ${time}`;
      break;
    }
    case "reopen": {
      const opener = z.string().optional().nullable().parse(metadata);

      content = `Conversation was reopend ${
        opener ? `by ${opener}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "close": {
      const closer = z.string().optional().nullable().parse(metadata);

      content = `Conversation was closed ${
        closer ? `by ${closer}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "handOff": {
      const handoffer = z.string().optional().nullable().parse(metadata);

      content = `Conversation was handed off ${
        handoffer ? `by ${handoffer}` : "automatically"
      } on ${date} at ${time}`;
      break;
    }
    case "snooze": {
      content = `Conversation was snoozed on ${date} at ${time}`;
      break;
    }
    case "unsnooze": {
      content = `Conversation was unsnoozed on ${date} at ${time}`;
      break;
    }
    case "threatDetected": {
      const threatSlug = z.string().parse(metadata);
      content = `Threat "${threatSlug}" detected on ${date} at ${time}`;
      break;
    }
    case "raw": {
      const note = z.string().parse(metadata);
      content = note;
      break;
    }
    case "merge": {
      const { mergedBy, sourceId } = z
        .object({ mergedBy: z.string(), sourceId: z.string() })
        .parse(metadata);

      content = `Conversation was merged with ${sourceId} by ${mergedBy} on ${date} at ${time}`;
      break;
    }
    case "orderMarkedAsUrgent": {
      const { orderId } = z.object({ orderId: z.string() }).parse(metadata);
      content = `Order ${orderId} marked as urgent on ${date} at ${time}`;
      break;
    }
    default: {
      break;
    }
  }

  if (!content) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center px-10 py-2">
      <span className="rounded-lg px-3 text-center text-xs italic text-gray-500">
        {content}
      </span>
    </div>
  );
}
