import { Button } from "@dashboard/common/ui/button";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Copy } from "lucide-react";
import { ReactNode, useState } from "react";

export function ItemDetailSection({
  details,
}: {
  details: { label: ReactNode; value: ReactNode | null; copy?: boolean }[];
}) {
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const filtered = details.filter((x) => x.value !== null);
  if (!filtered.length) return null;

  const onClickCopy = async (value: string, index: number) => {
    await navigator.clipboard.writeText(value);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 1000);
  };

  return (
    <div className="flex flex-col text-xs">
      {filtered.map((detail, index) => (
        <div key={index} className="flex items-center">
          <TruncatedTooltip
            className="text-gray w-[45%] shrink-0 pr-2.5"
            text={detail.label}
          />
          {detail.copy ? (
            <Button
              variant="ghost"
              className="group relative flex h-auto w-[55%] items-center justify-start rounded-lg p-1 text-sm font-normal"
              onClick={() => void onClickCopy(detail.value as string, index)}
            >
              {copiedIndex === index ? (
                <span className="text-gray w-full text-center">Copied!</span>
              ) : (
                <>
                  <div className="group-hover:bg-accent absolute right-2 z-10 h-full w-7 bg-transparent blur-sm" />
                  <Copy className="group-hover:bg-accent group-hover:text-gray absolute right-2 z-20 h-4 w-4 bg-transparent text-transparent transition-colors" />

                  <TruncatedTooltip
                    className="w-full text-left text-black"
                    text={detail.value}
                  />
                </>
              )}
            </Button>
          ) : (
            <TruncatedTooltip
              className="w-full p-1 text-left text-black"
              text={detail.value}
            />
          )}
        </div>
      ))}
    </div>
  );
}
