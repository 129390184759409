import {
  TooltipRoot,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@dashboard/common/ui/tooltip";
import { Trend } from "./Trend";

interface TrendIndicatorProps {
  value: number;
  unit?: string;
  higherIsBetter: boolean;
  showPreviousDays?: boolean;
  comparedTo: {
    days: number;
    from: string;
    to: string;
  };
}

export function TrendIndicator({
  value,
  unit,
  higherIsBetter,
  showPreviousDays,
  comparedTo,
}: TrendIndicatorProps) {
  return (
    <div className="ml-3 flex flex-row items-center">
      <TooltipProvider
        delayDuration={0}
        skipDelayDuration={0}
        disableHoverableContent
      >
        <TooltipRoot>
          <TooltipTrigger asChild>
            <div>
              <Trend
                value={value}
                unit={unit}
                positive={higherIsBetter ? value > 0 : value <= 0}
                showPreviousDays={showPreviousDays}
                days={comparedTo.days}
              ></Trend>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-[270px]">
              Compared to {comparedTo.from}
              {comparedTo.to !== comparedTo.from && ` - ${comparedTo.to}`}
            </p>
          </TooltipContent>
        </TooltipRoot>
      </TooltipProvider>
    </div>
  );
}
