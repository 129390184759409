import { useEffect } from "react";
import { useSubscriptionToken } from "./useSubscriptionToken";
import { wsTrpcClient } from "@dashboard/utils/wsTrpcClient";
import { Route } from "..";
import { toast } from "sonner";

export function useSubscription() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const subscriptionToken = useSubscriptionToken();

  useEffect(() => {
    const subscription =
      wsTrpcClient.dashboard.conversations.onConversationUpdate.subscribe(
        { token: subscriptionToken },
        {
          onStarted: () => {
            console.log("Conversation subscription started");
          },
          onStopped: () => {
            console.log("Conversation subscription stopped");
          },
          onData: (data) => {
            console.log("Updated conversation", data.conversation);
            trpcUtils.dashboard.conversations.get.setData(
              { organizationSlug, publicId },
              data.conversation,
            );
          },
          onError: (error) => {
            console.error("Error in subscription", error);
            // TODO: Improve UX
            toast.error("Network error. Please refresh your browser.");
          },
        },
      );

    return () => {
      subscription.unsubscribe();
    };
    // ! Investigate if this is correct
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionToken, publicId]);
}
