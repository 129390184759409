import { ElementType } from "react";

interface SidebarButtonExternalProps {
  text: string;
  to: string;
  icon?: ElementType;
}

export function SidebarButtonExternal({
  text,
  to,
  icon: Icon,
}: SidebarButtonExternalProps) {
  return (
    <a
      className="hover:bg-gray-light flex w-full flex-row items-center rounded-lg px-[12px] py-2 text-sm text-black"
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {Icon && <Icon className="mr-2 h-4 w-4 text-black" />}
      {text}
    </a>
  );
}
