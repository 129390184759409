import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/woo.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        WooCommerce Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Streamline your eCommerce operations by integrating your WooCommerce
        store with Octocom. This seamless integration enables you to automate
        and manage your WooCommerce orders, customer interactions, and store
        analytics, providing a more efficient and cohesive shopping experience
        for your customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Integration:</span> Easily
          connect your WooCommerce store to Octocom, allowing you to manage all
          aspects of your eCommerce business from a single, unified platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Management:</span>{" "}
          Simplify your order processing by automating key tasks such as order
          confirmations, shipment tracking, and inventory updates. This
          automation reduces manual effort and enhances the accuracy of your
          order fulfillment process.
        </li>
        <li>
          <span className="font-medium">Centralized Dashboard:</span> Monitor
          and manage all your WooCommerce store activities from one intuitive
          dashboard. Track orders, customer inquiries, and store performance
          metrics without needing to switch between multiple tools.
        </li>
        <li>
          <span className="font-medium">Integrated Customer Support:</span>{" "}
          Utilize Octocom&apos;s support tools to handle customer inquiries
          related to your WooCommerce store. Respond to questions, resolve
          issues, and manage returns directly from the Octocom dashboard,
          ensuring a smooth and responsive customer service experience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your WooCommerce store more
        effectively, offering both automation and direct management tools to
        enhance your eCommerce operations and improve customer satisfaction.
      </p>
    </div>
  );
}

export function WooCommerceIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        integrationName="woocommerce"
        title="WooCommerce"
        shortDescription="Automate the customer support for your WooCommerce store with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
