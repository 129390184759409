// import "./sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
// import { QueryClientProvider } from "@tanstack/react-query";
// import { queryClient } from "@dashboard/utils/queryClient";
// import { env } from "@dashboard/env";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { ThemeProvider } from "./providers/theme-provider";
// import { Router } from "@dashboard/Router";
// import { AuthGuard } from "@dashboard/common/AuthGuard/AuthGuard";
// import { AppContextProvider } from "@dashboard/AppContext";

{
  /* <Toaster />
<QueryClientProvider client={queryClient}>
  <Auth0Provider
    domain={env.DASHBOARD_AUTH0_DOMAIN}
    clientId={env.DASHBOARD_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: env.DASHBOARD_AUTH0_AUDIENCE,
    }}
    cacheLocation="localstorage"
    onRedirectCallback={(appState) => {
      // redirect back to the location where user was before login
      // not using tanstack router because it requires precise pathnames and search params
      if (appState?.returnTo) {
        window.location.href = appState.returnTo;
      }
    }}
  >
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <div className="flex h-screen w-screen items-center justify-center text-lg font-medium sm:hidden">
        <h1>Octocom dashboard is not available on mobile</h1>
      </div>
      <div className="hidden h-full sm:block">
        <AuthGuard>
          <AppContextProvider>
            <TooltipProvider delayDuration={0}>
              <Router />
            </TooltipProvider>
          </AppContextProvider>
        </AuthGuard>
      </div>
    </ThemeProvider>
  </Auth0Provider>
</QueryClientProvider> */
}
// import { TooltipProvider } from "@dashboard/common/ui/tooltip";

const root = document.getElementById("root");
if (!root) throw new Error("No root element found");

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
