import { cn } from "@dashboard/utils/ui";

interface ConnectWithSingupLabelProps {
  label: string;
  description?: string;
  className?: string;
}

function ConnectWithSignupLabel({
  label,
  description,
  className,
}: ConnectWithSingupLabelProps) {
  return (
    <div className={cn("flex flex-col pb-1", className)}>
      <span className="text-sm font-medium">{label}</span>
      {description && <span className="text-xs">{description}</span>}
    </div>
  );
}

export default ConnectWithSignupLabel;
