import { toast } from "sonner";
import { RefundData } from "@be/modules/orders/orders.types";
import { Route } from "../..";
import { useOrders } from "./useOrders";

export function useOrderRefund() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const { refetch } = useOrders();

  const refundMutation = trpc.dashboard.conversations.orders.refund.useMutation(
    {
      onError: (error) => {
        toast.error("Failed to refund order.");
        console.error(error);
      },
    },
  );

  return {
    refundOrder: ({
      externalOrderId,
      refundData,
      onSuccess,
    }: {
      externalOrderId: string;
      refundData: RefundData;
      onSuccess: () => void;
    }) => {
      const formattedAmount = parseFloat(refundData.amount).toFixed(2);
      refundData.amount = formattedAmount;

      refundMutation.mutate(
        {
          organizationSlug,
          publicId,
          externalOrderId,
          refundData,
        },
        {
          onSuccess: () => {
            onSuccess();
            void refetch();
            toast.success("Order refunded successfully.");
          },
        },
      );
    },
  };
}
