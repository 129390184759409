import { Separator } from "@dashboard/common/ui/separator";
import { Loader2 } from "lucide-react";
import { useGiftCards } from "../../../-hooks/giftCards/useGiftCards";
import { Route } from "../../..";
import {
  Accordion,
  AccordionTrigger,
  AccordionItem,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { GiftCardDetails } from "./GiftCardDetails";

export function GiftCards() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();
  const { data: giftCardDetails, isLoading } = useGiftCards();
  const [hasGiftCardConfig] =
    trpc.dashboard.conversations.giftCards.hasConfig.useSuspenseQuery({
      publicId,
      organizationSlug,
    });

  if (!hasGiftCardConfig) {
    return null;
  }

  return (
    <Accordion type="multiple" defaultValue={["gift-cards"]} className="w-full">
      <AccordionItem value="gift-cards" className="border-none !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Gift Cards {!isLoading && `(${giftCardDetails?.length ?? 0})`}
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {isLoading ? (
            <div className="flex items-center justify-center p-4 pb-0">
              <Loader2
                className="h-8 w-8 animate-spin text-gray-500"
                strokeWidth={1.5}
              />
              <span className="ml-2 text-sm text-gray-500">
                Loading gift card details...
              </span>
            </div>
          ) : !giftCardDetails ? (
            <div className="px-7 pt-4 text-sm text-gray-500">
              No gift card details found for this customer.
            </div>
          ) : (
            <Accordion
              type="single"
              collapsible
              className="max-h-160 mt-5 flex flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4"
            >
              {giftCardDetails.map((giftCard, index) => (
                <div key={giftCard.externalId}>
                  <GiftCardDetails giftCard={giftCard} index={index} />
                  {index !== giftCardDetails.length - 1 && (
                    <Separator className="bg-offWhite mx-3 w-auto" />
                  )}
                </div>
              ))}
            </Accordion>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
