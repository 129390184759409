import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import {
  WoocommerceForm,
  woocommerceFormSchema,
} from "./-utils/woocommerceFormSchema";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Button } from "@dashboard/common/ui/button";
import { Input } from "@dashboard/common/ui/input";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/woocommerce/",
)({
  loader: async ({ context: { trpcClientUtils }, params: { businessSlug } }) =>
    await trpcClientUtils.dashboard.woocommerce.getIntegration.ensureData({
      businessSlug,
    }),
  component: WoocommerceIntegrationPage,
});

function WoocommerceIntegrationPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [integration] =
    trpc.dashboard.woocommerce.getIntegration.useSuspenseQuery({
      businessSlug,
    });

  const defaultValues: WoocommerceForm = { website: integration?.domain ?? "" };

  const form = useForm<WoocommerceForm>({
    resolver: zodResolver(woocommerceFormSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [businessSlug]);

  const { onSubmit } = useSubmitForm();

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              WooCommerce Integration
            </h1>
            <Button variant="publish" className="ml-auto" type="submit">
              Install
            </Button>
          </div>
          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="website"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Woocommerce Store URL</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="www.store.com" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
