import { ChannelName } from "@be/modules/conversations/conversations.types";
import { Route } from "..";
import { toast } from "sonner";

export function useRefineMessage() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const refineMutation =
    trpc.dashboard.conversations.refineMessage.useMutation();

  return ({
    currentMessage,
    replyChannel,
    onSuccess,
    onError,
  }: {
    currentMessage: string;
    replyChannel: ChannelName;
    onSuccess: (refinedMessage: string) => void;
    onError: () => void;
  }) => {
    refineMutation.mutate(
      { organizationSlug, publicId, currentMessage, replyChannel },
      {
        onSuccess: (data) => {
          onSuccess(data.refinedMessage);
        },
        onError: (error) => {
          toast.error("Failed to refine message", {
            position: "top-center",
          });
          console.error(error);
          onError();
        },
      },
    );
  };
}
