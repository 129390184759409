import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toast } from "sonner";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { ViewForm, viewFormSchema } from "../-utils/viewFormSchema";
import { formatPayload } from "../-utils/formatPayload";
import { ViewFormBody } from "./ViewFormBody";
import { useEditView } from "../-hooks/useEditView";

interface EditViewFormProps {
  id: string;
  title: string;
  filters: { key: FilterKeys; value: string }[];
  setOpen: (opened: boolean) => void;
}

export function EditViewForm({
  id,
  title,
  filters,
  setOpen,
}: EditViewFormProps) {
  const form = useForm<ViewForm>({
    resolver: zodResolver(viewFormSchema),
    defaultValues: {
      title,
      filters,
    },
  });

  const { onSubmit: onSubmitMutation } = useEditView({ setOpen });

  const onSubmit = (data: ViewForm) => {
    const payload = formatPayload(data);

    onSubmitMutation({ ...payload, id });
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to update view");
  };

  return <ViewFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
