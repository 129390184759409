import { createFileRoute } from "@tanstack/react-router";
import ConnectContainer from "./-components/ConnectContainer";

export const Route = createFileRoute("/connect/fail")({
  component: ConnectFailure,
});

function ConnectFailure() {
  return (
    <ConnectContainer>
      <div>
        <h1 className="text-2xl font-bold">Something went wrong.</h1>
        <p className="text-wrap text-center" style={{ marginTop: "30px" }}>
          Please try again later. <br /> If the problem persists,{" "}
          <a
            href="mailto:info@octocom.ai"
            className="text-blue-600 hover:underline"
          >
            please contact us
          </a>
        </p>
      </div>
    </ConnectContainer>
  );
}
