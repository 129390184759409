import { toast } from "sonner";
import { Route } from "../..";

export function useSkipSubscriptionMonth() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const trpcUtils = trpc.useUtils();

  const skipSubscriptionMonthMutation =
    trpc.dashboard.conversations.subscriptions.setNextChargeDate.useMutation({
      onError: (error) => {
        toast.error("Failed to skip subscription month.");
        console.error(error);
      },
      onSuccess: async () => {
        toast.success("Subscription month skipped successfully.");
        await trpcUtils.dashboard.conversations.subscriptions.getDetails.invalidate();
        await trpcUtils.dashboard.conversations.subscriptions.getDetails.refetch();
      },
    });

  return {
    skipSubscriptionMonth: ({
      subscriptionId,
      nextChargeDate,
    }: {
      subscriptionId: string;
      nextChargeDate: string;
    }) => {
      skipSubscriptionMonthMutation.mutate({
        organizationSlug,
        publicId,
        subscriptionId,
        date: new Date(
          new Date(nextChargeDate).setMonth(
            new Date(nextChargeDate).getMonth() + 1,
          ),
        ).toISOString(),
      });
    },
  };
}
