import { Button } from "@dashboard/common/ui/button";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { ThreatForm } from "../-utils/threatForm";
import { TrashIcon } from "lucide-react";

interface ThreatFormBodyProps {
  form: UseFormReturn<ThreatForm>;
  onSubmit: (data: ThreatForm) => void;
}

export function ThreatFormBody({ form, onSubmit }: ThreatFormBodyProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "sampleStatements",
  });

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Chargebacks" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  rows={8}
                  placeholder="Customer indicates that they will initiate a chargeback if their request is not fulfilled."
                  {...field}
                />
              </FormControl>
              <FormDescription>
                The description can be in any language.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex items-center space-x-2">
              <FormField
                control={form.control}
                name={`sampleStatements.${index}.statement`}
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>Sample Statement {index + 1}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter a sample statement"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="button"
                variant="outline"
                size="icon"
                className="mt-8"
                onClick={() => remove(index)}
              >
                <TrashIcon className="h-4 w-4" />
              </Button>
            </div>
          ))}
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="mt-2"
            onClick={() => append({ statement: "" })}
          >
            Add Sample Statement
          </Button>
        </div>

        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
