import { TrendIndicator } from "./TrendIndicator/TrendIndicator";

interface StatsCardProps {
  higherIsBetter: boolean;
  value: string | number;
  diff: number;
  unit?: string;
  comparedTo: {
    days: number;
    from: string;
    to: string;
  };
}

export default function StatsCard({
  value,
  higherIsBetter,
  diff,
  unit,
  comparedTo,
}: StatsCardProps) {
  return (
    <div className="mt-5 flex flex-row items-center">
      {/* Card Value */}
      <span className="text-4xl font-normal text-black">{value}</span>
      <TrendIndicator
        showPreviousDays={true}
        higherIsBetter={higherIsBetter}
        value={diff}
        unit={unit}
        comparedTo={comparedTo}
      />
    </div>
  );
}
