import { Route } from "..";
import { toast } from "sonner";

export function useUpdateVisibility() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const updateVisibilityMutation = trpc.dashboard.articles.update.useMutation({
    onError: (e) => {
      toast.error("Failed to update article visibility");
      console.error(e);
    },
  });

  const updateVisibility = async ({
    id,
    isPublic,
  }: {
    id: string;
    isPublic: boolean;
  }) => {
    await updateVisibilityMutation.mutateAsync({
      id,
      businessSlug,
      isPublic,
    });
  };

  return { updateVisibility };
}
