import { z } from "zod";
import { redirect } from "@tanstack/react-router";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";

export function integrateRedirect() {
  const connectDataString = localStorage.getItem("connectIntegrationData");

  if (!connectDataString) {
    return;
  }

  const { integrationData, integrationServiceName, onboardType } = z
    .object({
      integrationData: z.unknown(),
      integrationServiceName: integrationServiceNameSchema,
      onboardType: z.union([z.literal("signup"), z.literal("demo")]),
    })
    .parse(JSON.parse(connectDataString));

  throw redirect({
    to: `/connect`,
    search: { integrationData, integrationServiceName, onboardType },
    replace: true,
  });
}
