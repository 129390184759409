import { toast } from "sonner";
import { Route } from "..";

export function useCreateFeedback() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const createMutation =
    trpc.dashboard.conversations.feedback.create.useMutation({
      onSuccess: async (_, data) => {
        await trpcUtils.dashboard.conversations.feedback.get.invalidate({
          organizationSlug,
          publicId,
        });

        if (data.correctAction !== undefined) {
          toast.success("Thanks for the feedback!");
        }
      },
      onError: (error) => {
        console.error("Error submitting feedback", error);
        toast.error("Error submitting feedback.");
      },
    });

  return (data: { correctAction?: boolean; comment?: string }) => {
    createMutation.mutate({ organizationSlug, publicId, ...data });
  };
}
