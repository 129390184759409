import { z } from "zod";

export const addressFormSchema = z.object({
  firstName: z
    .string({
      required_error: "First name is required",
      invalid_type_error: "First name is required",
    })
    .min(1, "First name is required"),
  lastName: z
    .string({
      required_error: "Last name is required",
      invalid_type_error: "Last name is required",
    })
    .min(1, "Last name is required"),
  address1: z
    .string({
      required_error: "Address is required",
      invalid_type_error: "Address is required",
    })
    .min(1, "Address is required"),
  address2: z.string().nullish(),
  city: z
    .string({
      required_error: "City is required",
      invalid_type_error: "City is required",
    })
    .min(1, "City is required"),
  countryCode: z
    .string({
      required_error: "Country is required",
      invalid_type_error: "Country is required",
    })
    .min(1, "Country is required"),
  zip: z
    .string({
      required_error: "ZIP/Postal code is required",
      invalid_type_error: "ZIP/Postal code is required",
    })
    .min(1, "ZIP/Postal code is required"),
});

export type AddressFormData = z.infer<typeof addressFormSchema>;
