import { createFileRoute } from "@tanstack/react-router";
import ConnectContainer from "./-components/ConnectContainer";
import { useEffect } from "react";
import { z } from "zod";

export const Route = createFileRoute("/connect/success")({
  validateSearch: z.object({
    successRedirect: z.string().url().optional(),
  }),
  component: ConnectSuccess,
});

function ConnectSuccess() {
  const { successRedirect } = Route.useSearch();
  useEffect(() => {
    setTimeout(() => {
      window.location.href = successRedirect ?? "/"; //The page must be reloaded to prevent errors here.
    }, 5000);
  }, []);

  return (
    <ConnectContainer>
      <h1 className="text-2xl font-bold">Integration Complete!</h1>
      <p className="text-wrap text-center" style={{ marginTop: "30px" }}>
        You will be redirected back to your dashboard in a few seconds. if this
        doesn&apos;t occur automatically{" "}
        <a
          href={successRedirect ?? "/"}
          className="text-blue-600 hover:underline"
        >
          click here
        </a>
      </p>
    </ConnectContainer>
  );
}
