import { toast } from "sonner";
import { Route } from "..";

export function useCreateCategory() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const createCategoryMutation =
    trpc.dashboard.articles.createCategory.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.articles.getCategories.invalidate({
          businessSlug,
        });
      },
      onError: (error) => {
        console.error("Failed to create category:", error);
        toast.error("Failed to create category");
      },
    });

  return async ({ name }: { name: string }) => {
    const result = await createCategoryMutation.mutateAsync({
      businessSlug,
      name,
    });

    return result;
  };
}
