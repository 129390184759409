import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";

import { Route } from "../..";

import { useState } from "react";
import { Tag, TagIcon } from "lucide-react";
import { ActionButton } from "./ActionButton";

export function SetTag({
  setFormValue,
  data,
  error,
  clearErrors,
}: {
  setFormValue: (value: string) => void;
  data: string | null;
  error: boolean;
  clearErrors: () => void;
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [tags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  let tagTitle;
  if (data) {
    tagTitle = tags.find((tag) => tag.id === data)?.title;
  }

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(tagTitle ?? "");

  const handleSelectTag = (id: string, title: string) => {
    setValue(title);
    setFormValue(id);
    setOpen(false);
    clearErrors();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <ActionButton
          value={value}
          label="Add tag"
          error={error}
          Icon={TagIcon}
        />
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Tag..."
            icon={Tag}
            value={value}
            onValueChange={(search) => setValue(search)}
          />
          <CommandList>
            <CommandEmpty>
              <span>No tags found.</span>
            </CommandEmpty>
            <CommandGroup>
              {tags.map((tag) => (
                <CommandItem
                  key={tag.id}
                  value={tag.title}
                  onSelect={() => handleSelectTag(tag.id, tag.title)}
                >
                  {tag.title}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
