import { Languages } from "lucide-react";
import { Route } from "../../_conversationSidebar";
import { SelectFilter } from "./SelectFilter";

export function LanguageFilter() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [languages] =
    trpc.dashboard.conversations.getLanguages.useSuspenseQuery({
      organizationSlug,
    });

  const options = languages.map((language) => ({
    label: language.name,
    value: language.code,
  }));

  if (options.length <= 1) {
    return null;
  }

  return (
    <SelectFilter
      options={options}
      valueIcon={Languages}
      name="Language"
      filterKey="language"
    />
  );
}
