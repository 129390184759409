/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from "react";
import { use100vh } from "react-div-100vh";
import { proxy, useSnapshot } from "valtio";
import type {} from "@redux-devtools/extension";
import { devtools } from "valtio/utils";
import { z } from "zod";
import { recordGtmEvent } from "@chatbot/utils/ga4";
import { useConfigOptional } from "./config.hooks";
import { differenceInWeeks } from "date-fns";

const uiStore = proxy({
  ui: undefined as
    | undefined
    | {
        loadedIntoContainer: boolean;

        windowHeight: number;
        windowWidth: number;
        isMobile: boolean;

        // TODO: Fix null issue (related to animations)
        displayChatWindow: boolean | null;
        // ? How was the chat window opened? (e.g. via attention grabber, via settings)
        openChatWindow(): void;
        closeChatWindow(): void;

        displayAttentionGrabbers: boolean;
        closeAttentionGrabbers(): void;

        displaySettings: boolean;
        openSettings(): void;
        closeSettings(): void;

        displayDisclaimer: boolean;
        openDisclaimer(): void;
        closeDisclaimer(): void;

        reset(): void;
      },
});

devtools(uiStore, {
  name: "UI Store",
  enabled: process.env.NODE_ENV === "development",
});

export function useUiState() {
  const { ui } = useSnapshot(uiStore);

  if (!ui) {
    throw new Error("UI state not found");
  }

  return ui;
}

export function useInitializeUiState({
  configId,
}: {
  configId: string | undefined;
}) {
  // TODO: This should be an argument to Coordinator
  const loadedIntoContainer = useMemo(
    () => document.getElementById("octocom-container") !== null,
    [],
  );

  const config = useConfigOptional();

  const windowHeight = use100vh();

  useEffect(() => {
    if (!configId) {
      return;
    }

    const hasOpenOctocomSearchParam =
      new URL(window.location.href).searchParams.get("open-octocom") !== null;

    const hasOpenOctocomDataAttribute =
      document.getElementById("octocom-script")?.dataset.openWindow === "true";

    const path = window.location.pathname;
    const isOnAutoOpenPath =
      config?.autoOpenPaths?.some((autoOpenPath) =>
        new RegExp(autoOpenPath).test(path),
      ) ?? false;
    console.log(
      "isOnAutoOpenPath",
      isOnAutoOpenPath,
      config?.autoOpenPaths,
      path,
    );

    console.log("hasOpenOctocomSearchParam", hasOpenOctocomSearchParam);

    const isMobileInitially =
      window.innerWidth < 640 || (windowHeight ?? window.innerHeight) < 440;

    uiStore.ui = {
      loadedIntoContainer,

      windowHeight: windowHeight ?? window.innerHeight,
      windowWidth: window.innerWidth,
      get isMobile(): boolean {
        return uiStore.ui!.windowWidth < 640 || uiStore.ui!.windowHeight < 440;
      },

      displayChatWindow:
        (!isMobileInitially && loadedIntoContainer) ||
        isOnAutoOpenPath ||
        hasOpenOctocomDataAttribute ||
        hasOpenOctocomSearchParam ||
        getPersistedChatWindowOpenState({ configId })
          ? true
          : null,
      openChatWindow() {
        uiStore.ui!.displayChatWindow = true;
        uiStore.ui!.displayAttentionGrabbers = false;
        persistInteractionTimestamp({ configId });
        recordGtmEvent({ eventAction: "Open" });
        if (!uiStore.ui!.isMobile) {
          persistChatWindowOpenState({
            open: true,
            configId,
          });
        }
      },
      closeChatWindow() {
        uiStore.ui!.displayChatWindow = null;
        recordGtmEvent({ eventAction: "Close" });
        persistChatWindowOpenState({
          open: false,
          configId,
        });
      },

      displaySettings: false,
      openSettings() {
        uiStore.ui!.displaySettings = true;
      },
      closeSettings() {
        uiStore.ui!.displaySettings = false;
      },

      displayAttentionGrabbers: false,
      closeAttentionGrabbers() {
        uiStore.ui!.displayAttentionGrabbers = false;
        persistInteractionTimestamp({ configId });
      },

      displayDisclaimer: false,
      openDisclaimer() {
        uiStore.ui!.displayDisclaimer = true;
      },
      closeDisclaimer() {
        uiStore.ui!.displayDisclaimer = false;
      },

      reset() {
        uiStore.ui!.displayChatWindow = false;
        uiStore.ui!.displaySettings = false;
        uiStore.ui!.displayAttentionGrabbers = false;
        deleteInteractionTimestamp({ configId });
        if (config?.enableAttentionGrabbers) {
          setTimeout(() => {
            if (!uiStore.ui!.isMobile && !uiStore.ui!.displayChatWindow) {
              uiStore.ui!.displayAttentionGrabbers = true;
            }
          }, (config.attentionGrabberDelay ?? 15) * 1000);
        }
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId]);

  useEffect(() => {
    if (uiStore.ui) {
      uiStore.ui.loadedIntoContainer = loadedIntoContainer;
    }
  }, [loadedIntoContainer]);

  useEffect(() => {
    if (uiStore.ui) {
      if (windowHeight !== null) {
        uiStore.ui.windowHeight = windowHeight;
      }
    }
  }, [windowHeight]);

  useEffect(() => {
    const listener = () => {
      if (uiStore.ui) {
        uiStore.ui.windowWidth = window.innerWidth;
      }
    };

    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  useEffect(() => {
    const handleCustomEvent = (event: CustomEvent) => {
      const parsedDetail = z
        .object({ type: z.literal("OPEN_OCTOCOM") })
        .safeParse(event.detail);

      if (parsedDetail.success) {
        if (uiStore.ui) {
          uiStore.ui.openChatWindow();
        }
      }
    };

    document.addEventListener(
      "openChatEvent",
      handleCustomEvent as EventListener,
    );

    return () => {
      document.removeEventListener(
        "openChatEvent",
        handleCustomEvent as EventListener,
      );
    };
  }, []);

  useEffect(() => {
    if (
      config?.id &&
      config.openChatAfter &&
      !isLastInteractionRecent({ configId: config.id })
    ) {
      // open chat after a delay
      setTimeout(() => {
        if (uiStore.ui) {
          uiStore.ui.openChatWindow();
        }
      }, config.openChatAfter * 1000);
    }
    if (
      config?.id &&
      config.enableAttentionGrabbers &&
      !isLastInteractionRecent({ configId: config.id })
    ) {
      setTimeout(() => {
        if (
          uiStore.ui &&
          !uiStore.ui.displayChatWindow &&
          !uiStore.ui.isMobile
        ) {
          uiStore.ui.displayAttentionGrabbers = true;
        }
      }, (config.attentionGrabberDelay ?? 10) * 1000);
    }
  }, [config]);

  const { ui } = useSnapshot(uiStore);

  return ui;
}

function persistInteractionTimestamp({ configId }: { configId: string }) {
  localStorage.setItem(
    `octocom-last-interaction-at:${configId}`,
    new Date().toISOString(),
  );
}

function deleteInteractionTimestamp({ configId }: { configId: string }) {
  localStorage.removeItem(`octocom-last-interaction-at:${configId}`);
}

function persistChatWindowOpenState({
  open,
  configId,
}: {
  open: boolean;
  configId: string;
}) {
  localStorage.setItem(`octocom-chat-window-open:${configId}`, open.toString());
}

function getPersistedChatWindowOpenState({
  configId,
}: {
  configId: string;
}): boolean {
  const value = localStorage.getItem(`octocom-chat-window-open:${configId}`);

  if (!value) {
    return false;
  }

  return z.boolean().parse(JSON.parse(value));
}

function isLastInteractionRecent({ configId }: { configId: string }): boolean {
  const value = localStorage.getItem(`octocom-last-interaction-at:${configId}`);

  if (!value) {
    return false;
  }

  const lastUsed = new Date(value);

  return differenceInWeeks(new Date(), lastUsed) < 1;
}
