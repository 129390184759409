import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { integrationServiceNameSchema } from "@be/modules/integrations/integrations.schemas";
import { RouterOutput } from "@be/exports";
import { useConnect } from "./-hooks/useConnect";
import { useEffect } from "react";

export type User = RouterOutput["dashboard"]["users"]["getCurrent"];

export const Route = createFileRoute("/connect/")({
  validateSearch: z.object({
    integrationData: z.unknown(),
    integrationServiceName: integrationServiceNameSchema,
    onboardType: z.union([z.literal("signup"), z.literal("demo")]),
  }),
  beforeLoad: async ({ search, context: { trpcClientUtils } }) => {
    const { integrationData, integrationServiceName } = search;

    localStorage.setItem("connectIntegrationData", JSON.stringify(search));

    let user: User;
    try {
      user = await trpcClientUtils.dashboard.users.getCurrent.ensureData();
    } catch (error) {
      throw redirect({
        to: "/login",
      });
    }

    localStorage.removeItem("connectIntegrationData");

    const businesses =
      await trpcClientUtils.dashboard.business.getAll.ensureData({
        organizationSlug: user.organizations[0].slug,
      });

    if (!user.isAdmin && businesses.length === 1) {
      return { singleBusiness: businesses[0] };
    }

    throw redirect({
      to: "/connect/selection",
      search: { integrationData, integrationServiceName },
    });
  },
  component: Connect,
});

function Connect() {
  const { singleBusiness } = Route.useRouteContext();

  const connect = useConnect({
    onDataSuccess: () => {
      window.location.href = "/";
    },
  });

  useEffect(() => {
    connect({ selectedBusinessSlug: singleBusiness.slug });
  }, []);

  return null;
}
