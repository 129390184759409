import { Tooltip } from "@dashboard/common/ui/tooltip.tsx";
import { File, X } from "lucide-react";

export function AttachedFile({
  filename,
  fileId,
  remove,
}: {
  filename: string;
  fileId: string;
  remove: ({ fileId }: { fileId: string }) => void;
}) {
  const handleRemove = () => {
    remove({ fileId });
  };

  return (
    <div className="bg-gray-light group relative flex items-center justify-between gap-2 rounded-lg px-2 py-1.5 text-xs">
      <div className="group-hover:bg-gray-light absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm" />
      <Tooltip
        triggerAsChild
        content="Remove attachment"
        side="top"
        trigger={
          <X
            className="group-hover:bg-gray-light absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors group-hover:text-black"
            onClick={handleRemove}
          />
        }
      />
      <div className="flex items-center">
        <File className="mr-2 h-4 w-4" strokeWidth={1.5} />
        <span>{filename}</span>
      </div>
    </div>
  );
}
