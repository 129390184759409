import { toast } from "sonner";
import { AddressData } from "@be/modules/orders/orders.types";
import { Route } from "../..";
import { useOrders } from "./useOrders";

export function useOrderUpdateShippingAddress() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const { refetch } = useOrders();

  const updateShippingAddressMutation =
    trpc.dashboard.conversations.orders.updateShippingAddress.useMutation({
      onError: (error) => {
        toast.error("Failed to update shipping address.");
        console.error(error);
      },
    });

  return {
    updateShippingAddress: ({
      externalOrderId,
      addressData,
      onSuccess,
    }: {
      externalOrderId: string;
      addressData: AddressData;
      onSuccess: () => void;
    }) => {
      updateShippingAddressMutation.mutate(
        {
          organizationSlug,
          publicId,
          externalOrderId,
          addressData,
        },
        {
          onSuccess: () => {
            onSuccess();
            void refetch();
            toast.success("Shipping address updated successfully.");
          },
        },
      );
    },
  };
}
