import { Button } from "@dashboard/common/ui/button";
import { cn } from "@dashboard/utils/ui";
import { ElementType } from "react";

export function ActionButton({
  value,
  label,
  error,
  Icon,
}: {
  value: string;
  label: string;
  error: boolean;
  Icon: ElementType;
}) {
  const buttonClass = error
    ? "bg-red-300 group-hover:bg-red-200 group-hover:text-red-700 data-[state=open]:bg-red-200 data-[state=open]:text-red-700"
    : "";

  const valueClass = error
    ? "text-red-600 group-hover:text-red-600 data-[state=open]:text-red-600"
    : "";

  return (
    <Button
      type="button"
      variant="secondary"
      className={cn(
        "group-hover:text-blue group-hover:bg-blue-light bg-gray-light gap-2",
        buttonClass,
      )}
    >
      <Icon className="h-4 w-4 font-bold" strokeWidth={2.4} />
      <div className="font-medium">
        {label}:{" "}
        <span className={valueClass}>
          {value !== "" ? value : error ? "Value is Missing*" : "Choose Value"}
        </span>
      </div>
    </Button>
  );
}
