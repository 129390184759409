import { z } from "zod";

export const loginFormSchema = z.object({
  username: z
    .string({
      required_error: "Email is required",
      invalid_type_error: "Email is required",
    })
    .min(1, { message: "Email is required" })
    .email("Invalid email address")
    .transform((email) => email.toLowerCase()),
  password: z
    .string({
      required_error: "Password is required",
      invalid_type_error: "Password is required",
    })
    .min(1, { message: "Password is required" }),
});

export type LoginFormSchema = z.infer<typeof loginFormSchema>;
