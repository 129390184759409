import { createFileRoute } from "@tanstack/react-router";
import { format } from "date-fns";

import { Table } from "@dashboard/common/Table/Table";

import { ThreatDialog } from "./-components/ThreatDialog";
import { CreateThreatDialogContent } from "./-components/CreateThreatDialogContent";

import { useDeleteThreats } from "./-hooks/useDeleteThreats";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/threats/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.threats.getAll.ensureData({
      businessSlug,
    });
  },
  component: ThreatsPage,
});

function ThreatsPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [threats] = trpc.dashboard.threats.getAll.useSuspenseQuery({
    businessSlug,
  });

  const { deleteThreats } = useDeleteThreats();

  const records = threats.map((threat) => ({
    id: threat.id,
    titleDialog: ThreatDialog({
      title: threat.title,
      description:
        threat.description +
        (threat.sampleStatements.length > 0
          ? "\n\n**Sample Statements**\n\n" +
            threat.sampleStatements.join("\n\n---\n\n")
          : ""),
    }),
    title: threat.title,
    content: threat.description,
    createdAt: format(new Date(threat.createdAt), "Pp"),
  }));

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col">
        <Table
          title="Threats"
          records={records}
          columns={[
            {
              header: "Title",
              accessor: "titleDialog",
            },
            {
              header: "Created",
              accessor: "createdAt",
            },
          ]}
          addRecordDialogContent={(setOpen) => (
            <CreateThreatDialogContent setOpen={setOpen} />
          )}
          deleteRecords={deleteThreats}
          recordName={{ singular: "Threat", plural: "Threats" }}
          noRecordsText="No threats available"
        />
      </div>
    </div>
  );
}
