import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { BotRuleForm, botRuleFormSchema } from "../-utils/botRuleFormSchema";
import { BotRuleFormBody } from "./BotRuleFormBody";

import { useEditBotRule } from "../-hooks/useEditBotRule";

interface EditBotRuleFormProps {
  id: string;
  title: string;
  rule: string;
  setOpen: (opened: boolean) => void;
}

export function EditBotRuleForm({
  id,
  title,
  rule,
  setOpen,
}: EditBotRuleFormProps) {
  const form = useForm<BotRuleForm>({
    resolver: zodResolver(botRuleFormSchema),
    defaultValues: {
      title,
      rule,
    },
  });

  const { onSubmit: onSubmitMutation } = useEditBotRule({ setOpen });

  function onSubmit(data: BotRuleForm) {
    onSubmitMutation({ ...data, id });

    form.reset();
  }

  return <BotRuleFormBody form={form} onSubmit={onSubmit} />;
}
