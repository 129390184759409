import { Route } from "../_navbar";

export function useLogout() {
  const { trpc } = Route.useRouteContext();

  const logoutMutation = trpc.dashboard.auth.logout.useMutation({
    onSuccess: () => {
      window.location.href = "/login";
    },
    onError: (error) => {
      console.error("Logout failed:", error);
    },
  });

  return () => {
    void logoutMutation.mutateAsync();
  };
}
