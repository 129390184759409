import { ChannelName } from "@be/modules/conversations/conversations.types";
import { useData } from "./useData";
import { toPercent } from "@dashboard/utils/numbers";
import { sumProp } from "@dashboard/utils/arrays";

export function useConversationsByChannels(): {
  channel: ChannelName;
  count: number;
  percentage: number;
  diff: number;
}[] {
  const { cur, prev } = useData();

  const conversationCountCur = sumProp(cur.conversationsPerDay, "count");
  const conversationCountPrev = sumProp(prev.conversationsPerDay, "count");

  const prevChannelConversations = prev.channelsConversations;
  const conversationsByChannels = cur.channelsConversations
    .map(({ channel, count }) => {
      const percentage = toPercent(conversationCountCur, count);

      const prev = prevChannelConversations.find((x) => x.channel === channel);
      const prevPercentage =
        prev && toPercent(conversationCountPrev, prev.count);

      return {
        channel,
        count,
        percentage,
        diff: percentage - (prevPercentage ?? 0),
      };
    })
    .sort((a, b) => b.count - a.count);

  return conversationsByChannels;
}
