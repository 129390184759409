import ModalFooter from "@chatbot/components/ChatWindow/Modal/ModalFooter";
import ModalTopBar from "@chatbot/components/ChatWindow/Modal/ModalTopBar";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { useUiState } from "@chatbot/hooks/uiState.hooks";

export default function DisclaimerModalContent() {
  const { disclaimerText } = useConfig();
  const { closeDisclaimer } = useUiState();

  if (!disclaimerText) {
    throw new Error("Disclaimer text is missing");
  }

  return (
    <>
      <ModalTopBar title="Disclaimer" onClickClose={closeDisclaimer} />
      <div className="px-6 py-4 text-sm font-light text-gray-700">
        <p>{disclaimerText}</p>
      </div>
      <ModalFooter />
    </>
  );
}
