import { Button } from "@dashboard/common/ui/button";
import { Plus } from "lucide-react";

import { useCreateTag } from "../../-hooks/useCreateTag";

interface CreateTagButtonProps {
  value: string;
  setValue: (value: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateTagButton({
  value,
  setValue,
  open,
  setOpen,
}: CreateTagButtonProps) {
  const createTag = useCreateTag();

  const handleClick = () => {
    createTag(value.trim());
    setTimeout(() => {
      setValue("");
    }, 100);
    setOpen(false);
  };

  return (
    <Button
      variant="outline"
      role="combobox"
      aria-expanded={open}
      className="flex w-[95%] justify-start gap-2"
      onClick={handleClick}
    >
      <Plus />
      {`Add "${value.trim()}"`}
    </Button>
  );
}
