import { ArrowLeft } from "lucide-react";

import { useState } from "react";
import { Button } from "../ui/button";
import { format } from "date-fns";
import { MainSection } from "./MainSection";
import { BottomSection } from "./BottomSection";

interface DatetimeFormProps {
  onSelect: (date: Date) => void;
  actionLabel: string;
}

export function DatetimeForm({ onSelect, actionLabel }: DatetimeFormProps) {
  const [calendarDate, setCalendarDate] = useState<Date>();
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");

  const [openSection, setOpenSection] = useState<"custom" | "options">(
    "options",
  );
  const [mainSection, setMainSection] = useState<"calendar" | "time">(
    "calendar",
  );

  const handleCalendarSelect = (date: Date | undefined) => {
    setCalendarDate(date);
    if (date) {
      setDate(format(date, "yyyy/MM/dd"));
    }
    setMainSection("time");
  };

  return (
    <div className="flex flex-col items-center">
      <div className="border-gray-light relative flex w-full items-center justify-center border-b px-3 py-2 font-medium">
        {openSection !== "options" && (
          <Button
            className="absolute left-1 top-1 !px-2 !py-0"
            variant="select"
            onClick={() => setOpenSection("options")}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
        )}
        <span>Select Time</span>
      </div>
      <MainSection
        openSection={openSection}
        mainSection={mainSection}
        onSelect={onSelect}
        calendarDate={calendarDate}
        setTime={setTime}
        handleCalendarSelect={handleCalendarSelect}
      />
      <div className="border-gray-light w-full border-t">
        <BottomSection
          openSection={openSection}
          setOpenSection={setOpenSection}
          setMainSection={setMainSection}
          date={date}
          time={time}
          setDate={setDate}
          setTime={setTime}
          setCalendarDate={setCalendarDate}
          onSelect={onSelect}
          actionLabel={actionLabel}
        />
      </div>
    </div>
  );
}
