import { externalTicketSystemSchema } from "@be/modules/externalTickets/externalTickets.schemas";
import { z } from "zod";

const extendedExternalTicketSystemSchema = z.union([
  externalTicketSystemSchema,
  z.literal("none"),
]);

export const externalTicketConfigFormSchema = z.object({
  system: extendedExternalTicketSystemSchema,
  createOnHandoff: z.boolean().optional(),
  createOnClose: z.boolean().optional(),
  botSenderName: z.string().optional(),
  emailRecipients: z
    .array(
      z.object({
        email: z
          .string({
            required_error: "Email is required",
            invalid_type_error: "Email is required",
          })
          .email({ message: "Email is invalid" }),
      }),
    )
    .optional(),
});

export type ExternalTicketConfigForm = z.infer<
  typeof externalTicketConfigFormSchema
>;
