import { ClipboardEvent, KeyboardEvent } from "react";
import TextareaAutosize from "react-textarea-autosize";

interface MessageTextAreaProps {
  value: string;
  onChange: (value: string) => void;
  onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
  disabled?: boolean;
  minRows?: number;
}

export function MessageTextArea({
  value,
  onChange,
  onPaste,
  onKeyDown,
  onFocus,
  onBlur,
  disabled = false,
  minRows = 2,
}: MessageTextAreaProps) {
  return (
    <TextareaAutosize
      minRows={minRows}
      maxRows={10}
      className="block w-full resize-none border-0 px-0 py-3 text-sm text-black outline-none focus:ring-0 disabled:bg-transparent"
      placeholder="Type here..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onPaste={onPaste}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}
