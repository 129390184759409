import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ThreatForm, threatFormSchema } from "../-utils/threatForm";
import { useCreateThreat } from "../-hooks/useCreateThreat";
import { ThreatFormBody } from "./ThreatFormBody";

export function CreateThreatForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<ThreatForm>({
    resolver: zodResolver(threatFormSchema),
    defaultValues: {
      sampleStatements: [],
    },
  });

  const { onSubmit: onSubmitMutation } = useCreateThreat({ setOpen });

  const onSubmit = (data: ThreatForm) => {
    onSubmitMutation(data);

    form.reset();
  };

  return <ThreatFormBody form={form} onSubmit={onSubmit} />;
}
