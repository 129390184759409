import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  RichpanelForm,
  richpanelFormSchema,
} from "../-utils/richpanelFormSchema";
import { useIntegration } from "../-hooks/useIntegration";
import { useSubmitForm } from "../-hooks/useSubmitForm";

export function RichpanelSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "richpanel" });

  const defaultValues = {
    apiKey: "",
  };

  const form = useForm<RichpanelForm>({
    resolver: zodResolver(richpanelFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "richpanel",
  });

  function Description() {
    return (
      <div className="flex flex-col gap-4 leading-normal">
        <h1 className="text-2xl font-semibold">
          Richpanel Integration Settings
        </h1>
        <p>
          Instructions on how to get your API key can be found here:
          <div className="py-2">
            <Button variant="link" className="p-0 font-semibold">
              <a
                href="https://developer.richpanel.com/reference/authentication"
                target="_blank"
                rel="noreferrer"
              >
                Richpanel Authentication
              </a>
            </Button>
          </div>
        </p>
      </div>
    );
  }

  return (
    <Form {...form}>
      <Description />
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <FormField
          control={form.control}
          name="apiKey"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Enter the API Key" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex w-full justify-end pt-4">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Form>
  );
}
