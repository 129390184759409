import { Button } from "@dashboard/common/ui/button";
import { Route } from "../../_conversationSidebar";

export function ClearFilters() {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const clearFilters = () => {
    void navigate({
      search: { ...search, filters: undefined },
    });
  };

  if (!search.filters) return null;

  return (
    <Button
      onClick={clearFilters}
      variant="ghost"
      className="hover:text-blue hover:bg-blue-light mt-4 flex w-full items-center justify-start gap-2 rounded-lg px-3 py-2 text-sm text-black"
    >
      Clear Filters
    </Button>
  );
}
