import { createFileRoute } from "@tanstack/react-router";
import { LoginForm } from "./-components/LoginForm";

export const Route = createFileRoute("/_auth/login/")({
  component: LoginPage,
});

function LoginPage() {
  return (
    <>
      <h1 className="pb-8 text-2xl font-medium lg:text-3xl">
        Log in to your account
      </h1>
      <LoginForm />
    </>
  );
}
