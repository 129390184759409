import { ChannelType } from "@db";
import { useConversation } from "../../-hooks/useConversation.hook";

import { CustomerMessageInput } from "./CustomerMessageInput";
import { ExternalMessageInput } from "./ExternalMessageInput";
import { NewThreadMessageInput } from "./NewThreadMessageInput";

interface MessageInputProps {
  replyChannel: ChannelType;
  isNewExternalOpen: boolean;
}

export function MessageInput({
  isNewExternalOpen,
  replyChannel,
}: MessageInputProps) {
  const conversation = useConversation();

  if (isNewExternalOpen) {
    return <NewThreadMessageInput conversation={conversation} />;
  }

  if (conversation.type === "external") {
    return <ExternalMessageInput conversation={conversation} />;
  } else {
    return (
      <CustomerMessageInput
        replyChannel={replyChannel}
        conversation={conversation}
      />
    );
  }
}
