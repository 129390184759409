import { Sender } from "@be/modules/conversations/conversations.types";
import { useConfig } from "@chatbot/hooks/config.hooks";
import { baseUrl } from "@chatbot/utils/baseUrl";
import clsxm from "@chatbot/utils/clsxm";

export function Avatar({
  sender,
  invisible,
  agentName,
}: {
  sender: Sender;
  agentName: string;
  invisible?: boolean;
}) {
  if (sender === "bot") {
    return <BotAvatar invisible={invisible} />;
  } else {
    return <AgentAvatar name={agentName} />;
  }
}

export function BotAvatar({ invisible = false }: { invisible?: boolean }) {
  const { botIconFileUrl } = useConfig();

  return (
    <div className="mb-3 mr-3 mt-auto flex-none">
      <img
        className={clsxm(
          "mx-auto my-auto h-8 w-8",
          invisible ? "opacity-0" : "",
        )}
        src={botIconFileUrl ?? `${baseUrl}/bot.svg`}
      />
    </div>
  );
}

function AgentAvatar({ name }: { name: string }) {
  const initials = name
    .split(" ")
    .map((s) => s[0].toUpperCase())
    .join("")
    .slice(0, 2);

  return (
    <div className="mb-3 mr-3 mt-auto flex-none">
      <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black">
        <span className="font-medium text-white">{initials}</span>
      </div>
    </div>
  );
}
