import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { WebsiteForm, websiteFormSchema } from "../-utils/websiteFormSchema";
import { WebsiteFormBody } from "./WebsiteFormBody";
import { useCreateWebsite } from "../-hooks/useCreateWebsite";

export function CreateWebsiteForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<WebsiteForm>({
    resolver: zodResolver(websiteFormSchema),
  });

  const { onSubmit: onSubmitMutation } = useCreateWebsite({ setOpen });

  const onSubmit = (data: WebsiteForm) => {
    onSubmitMutation(data);

    form.reset();
  };

  return <WebsiteFormBody form={form} onSubmit={onSubmit} />;
}
