import { ArrowUpRight } from "lucide-react";
import { SelectFilter } from "./SelectFilter";

export function HandedOffFilter() {
  const options = [
    {
      label: "Handed Off",
      value: "yes",
    },
    {
      label: "Not Handed Off",
      value: "no",
    },
  ];

  return (
    <SelectFilter
      options={options}
      valueIcon={ArrowUpRight}
      name="Handed Off"
      filterKey="handedOff"
    />
  );
}
