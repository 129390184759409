import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";
import { formDefaults } from "../../../-utils/formDefaults";
import { WebChatConfigForm } from "../../../-utils/formSchema";

function DisclaimerModal() {
  const { watch } = useFormContext<WebChatConfigForm>();

  const disclaimerText = watch("disclaimerText") ?? formDefaults.disclaimerText;

  return (
    <Transition show={false}>
      <Transition.Child
        enter="transition-opacity ease-linear duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute bottom-0 top-0 h-full w-full rounded-xl bg-black opacity-20" />
      </Transition.Child>

      <Transition.Child
        enter="transition ease-in-out duration-150 transform"
        enterFrom="translate-y-96"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-150 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-96"
      >
        <div className="absolute bottom-0 w-full rounded-b-xl rounded-t-lg bg-white">
          <div className="relative border-b p-5">
            <p className="text-center font-semibold leading-none text-[#1a1a1a]">
              Disclaimer
            </p>
            <div className="absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer rounded-lg p-1 hover:bg-gray-100">
              <XMarkIcon className="h-6 w-6 text-gray-800" />
            </div>
          </div>

          <div className="px-6 py-4 text-sm font-light text-gray-700">
            <p>{disclaimerText}</p>
          </div>
          <div className="border-t p-5">
            <p className="text-center text-xs font-extralight">
              &copy; {new Date().getFullYear()} Octocom LTD &bull; Privacy
              &bull; Terms
            </p>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
}

export default DisclaimerModal;
