import { ActionButton } from "./ActionButton";
import { useState } from "react";
import { AlarmClockIcon } from "lucide-react";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
} from "@dashboard/common/ui/select";
import { SelectTrigger } from "@radix-ui/react-select";

export function Snooze({
  setFormValue,
  data,
  error,
  clearErrors,
}: {
  setFormValue: (value: string) => void;
  data: string | null;
  error: boolean;
  clearErrors: () => void;
}) {
  const options = [
    { value: "1-hour", label: "For 1 Hour" },
    { value: "tomorrow", label: "Until Tomorrow" },
    { value: "next-week", label: "Until Next Week" },
    { value: "next-month", label: "Until Next Month" },
  ];

  let currentLabel;
  if (data) {
    currentLabel = options.find((option) => option.value === data)?.label;
  }

  const [label, setLabel] = useState<string>(currentLabel ?? "");

  const handleSelectValue = (value: string) => {
    setLabel(options.find((option) => option.value === value)?.label ?? "");
    setFormValue(value);
    clearErrors();
  };

  return (
    <SelectRoot onValueChange={handleSelectValue}>
      <SelectTrigger>
        <ActionButton
          value={label}
          label="Snooze"
          error={error}
          Icon={AlarmClockIcon}
        />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
}
