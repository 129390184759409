import { Route } from "..";
import { toast } from "sonner";

export type ShopifySubscription = "Basic" | "Advanced" | "Enterprise";

export function useSubscribe() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const subscribeMutation = trpc.dashboard.shopify.subscribe.useMutation({
    onSuccess: (data) => {
      if (data) {
        window.location.href = data;
      } else {
        toast.error(
          "Failed to create subscription. Please try again or contact support if the issue persists.",
        );
      }
    },
    onError: (error: unknown) => {
      console.error("Error subscribing", error);
      toast.error(
        "Failed to create subscription. Please try again or contact support if the issue persists.",
      );
    },
  });

  const subscribe = ({
    subscriptionType,
  }: {
    subscriptionType: ShopifySubscription;
  }) => {
    if (subscribeMutation.isPending) {
      return;
    }

    subscribeMutation.mutate({
      businessSlug: organizationSlug,
      subscriptionType,
    });
  };

  return { subscribe, isLoading: subscribeMutation.isPending };
}
