import { z } from "zod";

export const userFormSchema = z.object({
  fullName: z
    .string({ invalid_type_error: "Full name is required" })
    .min(1, { message: "Full name is required" }),
  email: z
    .string({
      invalid_type_error: "Email is required",
    })
    .email({ message: "Invalid email" }),
});

export type UserForm = z.infer<typeof userFormSchema>;
