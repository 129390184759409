import { createFileRoute } from "@tanstack/react-router";
import {
  HelpDeskConfigForm,
  helpDeskConfigFormSchema,
} from "./-utils/formSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSaveHelpDeskConfig } from "./-hooks/useSave";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Button } from "@dashboard/common/ui/button";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import { useEffect } from "react";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/help-desk/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.helpDesk.getConfiguration.ensureData({
      businessSlug,
    });
  },
  component: HelpDeskConfigPage,
});

function HelpDeskConfigPage() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [config] = trpc.dashboard.helpDesk.getConfiguration.useSuspenseQuery({
    businessSlug,
  });

  const defaultValues: HelpDeskConfigForm = {
    autoAssignmentPolicy: config.autoAssignmentPolicy,
  };

  const form = useForm<HelpDeskConfigForm>({
    resolver: zodResolver(helpDeskConfigFormSchema),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [businessSlug]);

  const { saveConfig } = useSaveHelpDeskConfig();

  const onSubmit = (values: HelpDeskConfigForm) => {
    saveConfig(values);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-8"
      >
        <div className="relative py-0">
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Help Desk Configuration
            </h1>
            <Button
              variant="publish"
              className="ml-auto"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="autoAssignmentPolicy"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Auto Assignment Policy</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a policy" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="noAutoAssignment">
                          No auto assignment
                        </SelectItem>
                        <SelectItem value="roundRobin">Round Robin</SelectItem>
                        <SelectItem value="balanced">Balanced</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
