import { useRouter } from "@tanstack/react-router";
import { Route } from "..";
import { toast } from "sonner";
import { WebsiteForm } from "../-utils/websiteFormSchema";

export const useCreateWebsite = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const router = useRouter();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.scraper.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.scraper.getAll.invalidate({ businessSlug });
      await router.invalidate();

      setOpen(false);
      toast.success("Website added");
    },
    onError: () => {
      toast.error("Failed to add website");
    },
  });

  return {
    onSubmit: (data: WebsiteForm) => {
      creationMutation.mutate({ businessSlug, ...data });
    },
  };
};
