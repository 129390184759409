import { useConfig } from "@chatbot/hooks/config.hooks";

interface ModalFooterProps {
  octocomLtdLinkRef?: React.RefObject<HTMLAnchorElement>;
  privacyPolicyLinkRef?: React.RefObject<HTMLAnchorElement>;
  toSLinkRef?: React.RefObject<HTMLAnchorElement>;
}

export default function ModalFooter({
  octocomLtdLinkRef,
  privacyPolicyLinkRef,
  toSLinkRef,
}: ModalFooterProps) {
  const { enableOctocomBranding } = useConfig();

  // TODO: We should make the legal bit more configurable
  return (
    <div className="border-t p-5">
      {enableOctocomBranding && (
        <p className="text-center text-xs font-extralight">
          <a
            ref={octocomLtdLinkRef}
            aria-label="Octocom LTD URL"
            tabIndex={0}
            href={`https://www.octocom.ai/?ref=web-chat`}
            target="_blank"
            rel="noreferrer"
          >
            &copy; {new Date().getFullYear()} Octocom LTD
          </a>{" "}
          &bull;{" "}
          <a
            aria-label="Privacy Policy URL"
            ref={privacyPolicyLinkRef}
            tabIndex={0}
            href="https://www.octocom.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>{" "}
          &bull;{" "}
          <a
            aria-label="Terms of Service URL"
            ref={toSLinkRef}
            tabIndex={0}
            href="https://www.octocom.ai/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </p>
      )}
    </div>
  );
}
