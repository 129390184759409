import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ItemDetailSection } from "./OrderDetailSection";
import { FulfillmentItemsSection } from "./FulfillmentItemsSection";
import { HoldFulfillmentForm } from "./HoldFulfillmentForm";
import { ReleaseHoldFulfillmentDialog } from "./ReleaseHoldFulfillmentDialog";
import { getFulfillmentStatusString } from "../../../-utils/orderStatusUtils";
import { useConversation } from "../../../-hooks/useConversation.hook";

export function FulfillmentsSection({ order }: { order: TrackedOrder }) {
  const { availableOrderServices } = useConversation();

  const holdAvailable = availableOrderServices.hold;

  return (
    <AccordionItem
      value="fulfillments"
      className="my-1 border-none !shadow-none"
    >
      <AccordionTrigger className="py-2 text-xs font-medium">
        Fulfillments ({order.fulfillments.length})
      </AccordionTrigger>
      <AccordionContent className="pb-2 text-xs">
        {order.fulfillments.map((fulfillment, index) => (
          <div
            key={index}
            className={index > 0 ? "mt-2 border-t border-gray-200 pt-2" : ""}
          >
            <h3 className="mb-2 font-semibold">Fulfillment {index + 1}</h3>
            {fulfillment.canHold && holdAvailable && (
              <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
                <HoldFulfillmentForm fulfillments={[fulfillment]} />
              </div>
            )}
            {fulfillment.canReleaseHold && holdAvailable && (
              <div className="mb-2 flex items-center justify-center gap-2 rounded-lg bg-gray-200 p-1">
                <ReleaseHoldFulfillmentDialog fulfillments={[fulfillment]} />
              </div>
            )}
            <ItemDetailSection
              details={[
                {
                  label: "Status:",
                  value: getFulfillmentStatusString(fulfillment.status),
                },
                {
                  label: "Tracking:",
                  value: fulfillment.trackingUrl ? (
                    <a
                      href={fulfillment.trackingUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Track package
                    </a>
                  ) : (
                    "N/A"
                  ),
                },
              ]}
            />
            <FulfillmentItemsSection items={fulfillment.items} />
          </div>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
}
