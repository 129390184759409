import { z } from "zod";

export const dixaFormSchema = z.object({
  apiKey: z
    .string({
      required_error: "API Key is required",
      invalid_type_error: "API Key is required",
    })
    .min(1, "API Key is required"),
  requesterId: z
    .string({
      required_error: "Requester ID is required",
      invalid_type_error: "Requester ID is required",
    })
    .min(1, "Requester ID is required"),
  emailIntegrationId: z
    .string({
      required_error: "Email Integration ID is required",
      invalid_type_error: "Email Integration ID is required",
    })
    .min(1, "Email Integration ID is required"),
});

export type DixaForm = z.infer<typeof dixaFormSchema>;
