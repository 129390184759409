import { z } from "zod";

export const brazeFormSchema = z.object({
  apiKey: z
    .string({
      required_error: "API Key is required",
      invalid_type_error: "API Key is required",
    })
    .min(1, "API Key is required"),
  baseUrl: z
    .string({
      required_error: "REST Endpoint is required",
      invalid_type_error: "REST Endpoint is required",
    })
    .min(1, "REST Endpoint is required"),
});

export type BrazeForm = z.infer<typeof brazeFormSchema>;
