import { ArrowUpLeft } from "lucide-react";
import { CONVERSATION_CARD_HEIGHT } from "./ConversationCard";

export function ConversationMovedCard({ start }: { start: number }) {
  return (
    <>
      <div
        style={{
          height: `${CONVERSATION_CARD_HEIGHT - 1}px`,
          transform: `translateY(${start}px)`,
        }}
        className="bg-accent absolute left-0 top-0 flex w-full cursor-default items-center justify-start gap-x-3 rounded-xl px-3 py-3 text-sm"
      >
        <ArrowUpLeft className="h-6 w-6" />
        <span>Conversation has been moved to another view</span>
      </div>
      <div className="bg-gray-lightest absolute bottom-0 left-3 right-3 h-px group-last:invisible group-hover:invisible" />
    </>
  );
}
