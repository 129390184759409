import { Route } from "..";
import { useConversation } from "./useConversation.hook";

export function useMacroData() {
  const { user } = Route.useRouteContext();
  const conversation = useConversation();

  return {
    agentName: user.fullName,
    customerName: conversation.customer?.name ?? "",
    email: conversation.customer?.email ?? "",
    phone: conversation.customer?.phone ?? "",
  };
}
