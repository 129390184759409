import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/liveagent.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        LiveAgent Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Improve your customer support efficiency by integrating LiveAgent with
        Octocom. This seamless integration allows you to pass unhandled customer
        queries directly to the LiveAgent help desk, ensuring that your support
        team can manage and resolve issues across multiple communication
        channels with ease.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Query Escalation:</span>{" "}
          Automatically transfer unhandled customer queries from Octocom to
          LiveAgent, ensuring that every customer concern is promptly addressed
          and resolved.
        </li>
        <li>
          <span className="font-medium">
            Centralized Interaction Management:
          </span>{" "}
          Handle all customer interactions within Octocom, with the ability to
          escalate unresolved queries to LiveAgent’s help desk for comprehensive
          follow-up.
        </li>
        <li>
          <span className="font-medium">Enhanced Multi-Channel Support:</span>{" "}
          Utilize LiveAgent’s robust multi-channel support features to manage
          customer queries across various platforms, ensuring quick and accurate
          responses that boost customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to deliver top-notch
        customer service by combining Octocom’s centralized management tools
        with LiveAgent’s comprehensive multi-channel support capabilities,
        resulting in faster issue resolution and improved customer experiences.
      </p>
    </div>
  );
}

export function LiveAgentIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        integrationName="liveAgent"
        title="LiveAgent"
        shortDescription="Pass unhandled customer support queries to LiveAgent"
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
