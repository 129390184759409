import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@dashboard/common/ui/button";
import { Check, Split, Package, Database, Loader2 } from "lucide-react";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useBookDemo } from "./-hooks/useBookDemo";
import { cn } from "@dashboard/utils/ui";

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/features/",
)({
  component: FeaturesPage,
});

function FeaturesPage() {
  const { bookDemo, isLoading } = useBookDemo();

  return (
    <div className="text-blue-dark flex h-full w-full flex-col items-center justify-center">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        You&apos;re all set! Here&apos;s what you can do with Octocom
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Your core features are ready to use right away. For larger catalogs and
        advanced features, we&apos;re here to help.
      </h2>

      <div className="flex w-full max-w-3xl flex-col gap-4 py-8">
        <div className="rounded-lg border p-6">
          <h3 className="mb-4 font-medium">Available now:</h3>
          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <span>Automated Pre-sale Support</span>
            </div>
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Smart Product Recommendations</span>
                <p className="text-sm text-gray-500">Up to 100 products</p>
              </div>
            </div>
            <div className="flex items-center">
              <Check className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>AI-Powered Order Support</span>
                <p className="text-sm text-gray-500">
                  Automatic order tracking and status updates
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-lg border p-6">
          <h3 className="mb-4 font-medium">
            Enterprise Features (Available upon request):
          </h3>
          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              <Package className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Advanced AI Order Support</span>
                <p className="text-sm text-gray-500">
                  Order modifications, cancellations, returns and refunds
                  processing
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Split className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Custom Automation Workflows</span>
                <p className="text-sm text-gray-500">
                  Build custom workflows to automate your business processes
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Database className="text-blue-dark mr-3 h-5 w-5" />
              <div>
                <span>Large Catalog Product Recommendations</span>
                <p className="text-sm text-gray-500">
                  For catalogs with more than 100 products, and custom
                  recommendation requirements
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <Button
            variant="outline"
            className={cn("h-10 w-full justify-center font-semibold", {
              "border-blue-dark": !isLoading,
            })}
            onClick={bookDemo}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Booking your demo...</span>
              </div>
            ) : (
              "Book a demo to explore enterprise features"
            )}
          </Button>

          <p className="text-center text-sm text-gray-600">
            Our team will contact you to discuss your needs and provide a
            personalized demo of our enterprise features
          </p>
        </div>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/knowledge"
          nextPath="/organization/$organizationSlug/onboarding/plan"
          isNextEnabled={true}
        />
      </div>
    </div>
  );
}
