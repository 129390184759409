import {
  Accordion,
  AccordionTrigger,
  AccordionItem,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { Separator } from "@dashboard/common/ui/separator";
import { Loader2 } from "lucide-react";
import { useSubscriptions } from "../../../-hooks/subscriptions/useSubscriptions";
import { SubscriptionDetails } from "./SubscriptionDetails";
import { Subscription } from "@be/modules/subscriptions/subscriptions.types";
import { Route } from "../../..";
export function Subscriptions() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();
  const { data: subscriptionDetails, isLoading } = useSubscriptions();
  const [hasSubscriptionConfig] =
    trpc.dashboard.conversations.subscriptions.hasConfig.useSuspenseQuery({
      publicId,
      organizationSlug,
    });

  if (!hasSubscriptionConfig) {
    return null;
  }

  return (
    <Accordion
      type="multiple"
      defaultValue={["subscription-details"]}
      className="w-full"
    >
      <AccordionItem
        value="subscription-details"
        className="border-none !shadow-none"
      >
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Subscriptions {!isLoading && `(${subscriptionDetails?.length ?? 0})`}
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {isLoading ? (
            <div className="flex items-center justify-center p-4 pb-0">
              <Loader2
                className="h-8 w-8 animate-spin text-gray-500"
                strokeWidth={1.5}
              />
              <span className="ml-2 text-sm text-gray-500">
                Loading order details...
              </span>
            </div>
          ) : !subscriptionDetails || subscriptionDetails.length === 0 ? (
            <div className="px-7 pt-4 text-sm text-gray-500">
              No subscription details found for this customer.
            </div>
          ) : (
            <Accordion
              type="single"
              collapsible
              className="max-h-160 mt-5 flex flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4"
            >
              {subscriptionDetails.map((subscription, index) => (
                <div key={subscription.subscriptionId}>
                  <SubscriptionDetails
                    subscription={subscription as Subscription}
                  />
                  {index !== subscriptionDetails.length - 1 && (
                    <Separator className="bg-offWhite mx-3 w-auto" />
                  )}
                </div>
              ))}
            </Accordion>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
