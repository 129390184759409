import { numberToScale1 } from "@dashboard/utils/numbers";
import { useData } from "./useData";

export function useAvgMessagesPerConversation() {
  const { cur, prev } = useData();
  return {
    value: numberToScale1(cur.avgMessagesPerConversation),
    diff: numberToScale1(
      cur.avgMessagesPerConversation - prev.avgMessagesPerConversation,
    ),
  };
}
