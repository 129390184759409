import { Route } from "..";
import { toast } from "sonner";

export function useScrapeWebsites() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const scrapeMutation = trpc.dashboard.scraper.scrape.useMutation({
    onSuccess: (_, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1 ? "Scraping websites..." : "Scraping website...";

      toast.success(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to initiate scraping websites."
          : "Failed to initiate scraping website";

      toast.error(message);
      console.error(error);
    },
  });

  return {
    scrapeWebsites: (ids: string[]) => {
      scrapeMutation.mutate({ businessSlug, ids: ids });
    },
  };
}
