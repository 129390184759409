import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TelegramForm, telegramFormSchema } from "../-utils/telegramFormSchema";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import { useSubmitForm } from "../-hooks/useSubmitForm";
import { useIntegration } from "../-hooks/useIntegration";

export function TelegramSettingsForm() {
  const integration = useIntegration({ integrationServiceName: "telegram" });

  const defaultValues = {
    botToken: "",
  };

  const form = useForm<TelegramForm>({
    resolver: zodResolver(telegramFormSchema),
    defaultValues: integration ?? defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({
    integrationServiceName: "telegram",
  });

  function Description() {
    return (
      <div className="flex flex-col gap-4 leading-normal">
        <h1 className="text-2xl font-semibold">
          Telegram Integration Settings
        </h1>
        <p>Enter the bot token of the Telegram bot you want to integrate.</p>
        <h2 className="text-xl font-medium">Setup a Telegram Bot</h2>
        <p>
          If you don&apos;t have a bot setup, you can create one by messaging{" "}
          <Button variant="link" className="h-auto p-0 font-semibold">
            <a href="https://t.me/botfather" target="_blank" rel="noreferrer">
              @BotFather
            </a>
          </Button>{" "}
          on Telegram with the{" "}
          <span className="bg-accent rounded-lg px-4 py-1 font-medium">
            /newbot
          </span>{" "}
          command. Fill in the required details and generate the bot token. The
          token will look something like this:
          <br />
        </p>

        <span className="bg-accent w-full rounded-lg p-2 text-center font-medium">
          110201543:AAHdqTcvCH1vGWJxfSeofSAs0K5PALDsaw
        </span>

        <h2 className="text-xl font-medium">Lost your Bot Token?</h2>
        <p>
          If you already have a bot set up, and have lost your token you can
          generate a new one by messaging{" "}
          <Button variant="link" className="h-auto p-0 font-semibold">
            <a href="https://t.me/botfather" target="_blank" rel="noreferrer">
              @BotFather
            </a>
          </Button>{" "}
          with the{" "}
          <span className="bg-accent rounded-lg px-4 py-1 font-medium">
            /token
          </span>{" "}
          command.
        </p>

        <p>
          Detailed instructions can be found here:
          <ul className="py-2">
            <li>
              <Button variant="link" className="p-0 font-semibold">
                <a
                  href="https://core.telegram.org/bots/features#creating-a-new-bot"
                  target="_blank"
                  rel="noreferrer"
                >
                  Creating a new bot
                </a>
              </Button>
            </li>
            <li>
              <Button variant="link" className="p-0 font-semibold">
                <a
                  href="https://core.telegram.org/bots/features#generating-an-authentication-token"
                  target="_blank"
                  rel="noreferrer"
                >
                  Generating an authentication token
                </a>
              </Button>
            </li>
          </ul>
        </p>
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <FormField
          control={form.control}
          name="botToken"
          render={({ field }) => (
            <FormItem>
              <Description />
              <FormControl>
                <Input placeholder="Enter the Bot Token" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex w-full justify-end pt-4">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Form>
  );
}
