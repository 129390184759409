import { Select } from "@dashboard/common/ui/select";
import { Route } from "..";
import { useState } from "react";

export function AssigneeSelect({
  chart,
}: {
  chart: "assigned" | "closed" | "avgReply" | "avgClose";
}) {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  let chartAssignee:
    | "assignedAssignee"
    | "closedAssignee"
    | "avgReplyAssignee"
    | "avgCloseAssignee";
  switch (chart) {
    case "assigned":
      chartAssignee = "assignedAssignee";
      break;
    case "closed":
      chartAssignee = "closedAssignee";
      break;
    case "avgReply":
      chartAssignee = "avgReplyAssignee";
      break;
    case "avgClose":
      chartAssignee = "avgCloseAssignee";
      break;
  }

  const [assignee, setAssignee] = useState(search[chartAssignee] ?? "all");

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const options = users.map((user) => ({
    label: user.fullName,
    value: user.id,
  }));

  const generalOptions = [
    {
      label: "All Assignees",
      value: "all",
    },
  ];

  if (chart === "closed" || chart === "avgClose") {
    generalOptions.push({
      label: "Unassigned",
      value: "unassigned",
    });
  }

  const groups = [
    {
      title: "Assignees",
      options: generalOptions,
    },
    {
      title: "",
      options,
    },
  ];

  const setSearch = (value: string | undefined) => {
    setAssignee(value ?? "all");

    if (value === "all") value = undefined;
    void navigate({ search: { ...search, [chartAssignee]: value } });
  };

  return (
    <Select
      variant="dropdown"
      value={assignee}
      onChange={(value) => setSearch(value)}
      groups={groups}
    />
  );
}
