import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Textarea } from "@dashboard/common/ui/textarea";
import { OrderActionButton } from "./OrderActionButton";
import { Fulfillment, HoldData } from "@be/modules/orders/orders.types";
import { holdDataSchema } from "@be/modules/orders/orders.schemas";
import {
  SelectRoot as Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import { Checkbox } from "@dashboard/common/ui/checkbox";
import { Box } from "lucide-react";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Separator } from "@dashboard/common/ui/separator";
import { formatCurrency } from "@dashboard/utils/currency";
import { useFulfillmentsHold } from "../../../-hooks/orders/useFulfillmentsHold";

interface HoldFulfillmentFormProps {
  fulfillments: Fulfillment[];
}

export function HoldFulfillmentForm({
  fulfillments,
}: HoldFulfillmentFormProps) {
  const [open, setOpen] = useState(false);
  const form = useForm<HoldData>({
    resolver: zodResolver(holdDataSchema),
    defaultValues: {
      reason: "OTHER",
      notifyMerchant: true,
      staffNote: "",
    },
  });

  const { holdFulfillments } = useFulfillmentsHold();

  function onSubmit(values: HoldData) {
    holdFulfillments({
      fulfillmentIds: fulfillments.map(
        (fulfillment) => fulfillment.externalId ?? "UNKNOWN_ID",
      ),
      holdData: values,
      onSuccess: () => {
        setOpen(false);
      },
    });
  }

  const holdableFulfillments = fulfillments.filter(
    (fulfillment) => fulfillment.canHold,
  );
  const name =
    holdableFulfillments.length === 1 ? "Fulfillment" : "Fulfillments";

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <OrderActionButton label="Hold" />
      <DialogContent className="w-[1000px] max-w-full p-0 text-sm">
        <DialogHeader className="border-b p-4">
          <DialogTitle>Hold {name}</DialogTitle>
        </DialogHeader>
        {holdableFulfillments.length > 0 && (
          <div className="max-w-[999px] border-b pb-4 pt-0">
            <div className="flex max-h-[300px] flex-col overflow-y-auto">
              {holdableFulfillments.map((fulfillment, fulfillmentIndex) => (
                <>
                  <div key={fulfillmentIndex} className="px-6">
                    <h3 className="mb-2 font-semibold">
                      Fulfillment {fulfillmentIndex + 1}
                    </h3>
                    {fulfillment.items?.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <div className="flex items-center justify-between">
                          <div className="flex max-w-[60%] items-center gap-4">
                            <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-xl">
                              {item.image ? (
                                <img
                                  src={item.image}
                                  alt={item.name}
                                  className="h-full w-full object-cover"
                                  onError={(e) => {
                                    e.currentTarget.style.display = "none";
                                    const nextElement = e.currentTarget
                                      .nextElementSibling as HTMLElement;
                                    nextElement.style.display = "block";
                                  }}
                                />
                              ) : null}
                              <Box
                                className="h-full w-full"
                                strokeWidth={1}
                                style={{
                                  display: item.image ? "none" : "block",
                                }}
                              />
                            </div>
                            <TruncatedTooltip text={item.name} />
                          </div>

                          <div className="flex items-center gap-4">
                            <div>
                              {item.discountedUnitPrice &&
                              parseFloat(item.discountedUnitPrice.amount) <
                                parseFloat(
                                  item.orginalUnitPrice?.amount ?? "0",
                                ) ? (
                                <>
                                  <span className="text-gray-400 line-through">
                                    {formatCurrency({
                                      amount: parseFloat(
                                        item.orginalUnitPrice?.amount ?? "0",
                                      ),
                                      currency:
                                        item.orginalUnitPrice?.currencyCode ??
                                        "USD",
                                    })}
                                  </span>{" "}
                                  <span>
                                    {formatCurrency({
                                      amount: parseFloat(
                                        item.discountedUnitPrice.amount,
                                      ),
                                      currency:
                                        item.discountedUnitPrice.currencyCode,
                                    })}
                                  </span>
                                </>
                              ) : (
                                formatCurrency({
                                  amount: parseFloat(
                                    item.orginalUnitPrice?.amount ?? "0",
                                  ),
                                  currency:
                                    item.orginalUnitPrice?.currencyCode ??
                                    "USD",
                                })
                              )}{" "}
                              x {item.quantity}
                            </div>
                            <div className="text-right">
                              <p className="font-medium">
                                Total:{" "}
                                {formatCurrency({
                                  amount: parseFloat(
                                    item.totalPrice?.amount ?? "0",
                                  ),
                                  currency:
                                    item.totalPrice?.currencyCode ?? "USD",
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                        {itemIndex < (fulfillment.items?.length ?? 0) - 1 && (
                          <Separator className="my-2" />
                        )}
                      </div>
                    ))}
                  </div>
                  {fulfillmentIndex < holdableFulfillments.length - 1 && (
                    <Separator className="my-4" />
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="space-y-4 p-4"
          >
            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reason for holding {name.toLowerCase()}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a reason" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="AWAITING_PAYMENT">
                        Awaiting Payment
                      </SelectItem>
                      <SelectItem value="AWAITING_RETURN_ITEMS">
                        Awaiting Return Items
                      </SelectItem>
                      <SelectItem value="HIGH_RISK_OF_FRAUD">
                        High Risk of Fraud
                      </SelectItem>
                      <SelectItem value="INCORRECT_ADDRESS">
                        Incorrect Address
                      </SelectItem>
                      <SelectItem value="INVENTORY_OUT_OF_STOCK">
                        Inventory Out of Stock
                      </SelectItem>
                      <SelectItem value="ONLINE_STORE_POST_PURCHASE_CROSS_SELL">
                        Post Purchase Upsell Offer
                      </SelectItem>
                      <SelectItem value="UNKNOWN_DELIVERY_DATE">
                        Unknown Delivery Date
                      </SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="staffNote"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Staff Note</FormLabel>
                  <FormControl>
                    {/* @ts-expect-error this works, but type errors need to be fixed */}
                    <Textarea
                      placeholder="Enter any additional notes (optional)"
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="notifyMerchant"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Notify Merchant</FormLabel>
                  </div>
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit">Hold Fulfillment</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
