import * as React from "react";
import { cn } from "@dashboard/utils/ui";
import { useFormContext } from "react-hook-form";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  maxLength?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, name, maxLength, ...props }) => {
    const formContext = useFormContext();

    let textareaProps = props;
    let remainingCharacters: number | null = null;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (name && formContext) {
      const { register, watch } = formContext;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = watch(name);
      if (typeof value !== "string" && value !== null && value !== undefined) {
        throw new Error("Textarea value must be a string, undefined or null");
      }
      remainingCharacters =
        maxLength && typeof value === "string"
          ? maxLength - value.length
          : null;

      textareaProps = {
        ...register(name, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          setValueAs: (v) => (v === "" ? null : v),
        }),
        ...props,
      };
    }

    return (
      <>
        <textarea
          className={cn(
            "border-input placeholder:text-muted-foreground focus-visible:ring-ring flex min-h-[60px] w-full rounded-md border bg-transparent px-3 py-2 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          maxLength={maxLength}
          {...textareaProps}
        />
        {maxLength && (
          <span className="text-xs text-gray-400">
            {`${remainingCharacters ?? maxLength} characters remaining`}
          </span>
        )}
      </>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
