export function Template({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="border-blue-light hover:bg-blue-light hover:border-blue flex h-full min-h-40 cursor-pointer flex-col items-start justify-start gap-2 overflow-hidden rounded-lg border bg-white p-4 transition-all duration-200 ease-in-out hover:shadow-lg">
      <h3 className="text-lg font-medium text-gray-800">{title}</h3>
      <p className="text-left text-gray-600">{description}</p>
    </div>
  );
}
