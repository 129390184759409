import { Tooltip } from "@dashboard/common/ui/tooltip.tsx";
import { Button } from "@dashboard/common/ui/button.tsx";
import { Paperclip } from "lucide-react";
import { ChangeEvent, useRef } from "react";
import { useFileUpload } from "../../-hooks/useFileUpload";

export function AttachmentButton({
  onUpload,
  businessId,
  disabled = false,
}: {
  onUpload: (file: { fileId: string; filename: string }) => void;
  businessId: string;
  disabled?: boolean;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const { uploadFile } = useFileUpload();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const selectedFile = event.target.files[0];
    const { fileId } = await uploadFile({ file: selectedFile, businessId });
    onUpload({ fileId, filename: selectedFile.name });
  };

  return (
    <>
      <Tooltip
        triggerAsChild
        trigger={
          <Button
            variant="select"
            onClick={handleButtonClick}
            disabled={disabled}
            className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
          >
            <Paperclip className="h-5 w-5" />
          </Button>
        }
        content="Attach file"
      />
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={(args) => void handleFileChange(args)}
      />
    </>
  );
}
