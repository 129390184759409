import { MessageSquareDashed } from "lucide-react";
import { SelectFilter } from "./SelectFilter";

export function ChannelFilter() {
  const options = [
    {
      label: "Web",
      value: "web",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Instagram",
      value: "instagram",
    },
    {
      label: "Instagram Comments",
      value: "instagramComment",
    },
    {
      label: "Messenger",
      value: "messenger",
    },
    {
      label: "Facebook Comments",
      value: "facebookComment",
    },
  ];

  return (
    <SelectFilter
      options={options}
      valueIcon={MessageSquareDashed}
      name="Channel"
      filterKey="channel"
    />
  );
}
