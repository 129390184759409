import React, { ElementType } from "react";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { Check } from "lucide-react";
import { cn } from "@dashboard/utils/ui";

interface AccordionItemComponentProps {
  value: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  icon: ElementType;
  completed: boolean;
}

export function AccordionItemComponent({
  value,
  title,
  subtitle,
  children,
  icon: Icon,
  completed,
}: AccordionItemComponentProps) {
  return (
    <AccordionItem
      value={value}
      className="border-blue-dark rounded-lg border hover:bg-gray-50"
    >
      <AccordionTrigger className="flex items-center justify-between px-4 py-3">
        <div className="flex flex-1 items-center gap-4">
          <div
            className={cn(
              "flex h-12 w-12 items-center justify-center rounded-lg",
              completed ? "bg-black" : "border border-gray-400",
            )}
          >
            {completed ? (
              <Check className="h-8 w-8 text-white" strokeWidth={2} />
            ) : (
              <Icon className="h-8 w-8 text-black" strokeWidth={1.5} />
            )}
          </div>
          <div className="flex flex-col items-start gap-1">
            <span className="text-lg font-medium text-black">{title}</span>
            <span className="text-justify text-sm text-gray-500">
              {subtitle}
            </span>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="p-4">{children}</AccordionContent>
    </AccordionItem>
  );
}
