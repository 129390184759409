import { useConversation } from "../-hooks/useConversation.hook";
import { Route } from "..";
import { toast } from "sonner";
import { useEffect, useState } from "react";

export function useTranslation() {
  const conversation = useConversation();
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc, user } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const translationMutation =
    trpc.dashboard.conversations.translateMessage.useMutation({
      onSuccess: () => {
        toast.success("Conversation translated successfully");
        void trpcUtils.dashboard.conversations.get
          .invalidate({
            organizationSlug,
            publicId,
          })
          .then(() =>
            navigate({ search: { ...search, useTranslation: true } }),
          );
      },
      onError: (error) => {
        console.error("Error translating message", error);
        toast.error("Something went wrong while translating the conversation");
      },
    });

  const [translationIsEnabled, setIsTranslationEnabled] = useState(
    search.useTranslation ??
      conversation.messages.some((message) =>
        message.translations.some(
          (translation) =>
            translation.translatedTo === user.translationLanguage,
        ),
      ),
  );

  useEffect(() => {
    const requestedLanguageExists = conversation.messages.some((message) =>
      message.translations.some(
        (translation) => translation.translatedTo === user.translationLanguage,
      ),
    );

    if (search.useTranslation === undefined) {
      setIsTranslationEnabled(requestedLanguageExists);
    } else {
      setIsTranslationEnabled(search.useTranslation);
    }
  }, [conversation.messages, user.translationLanguage, search.useTranslation]);

  const translate = (translate: boolean) => {
    if (translate) {
      if (
        conversation.messages.every((message) =>
          message.translations.some(
            (translation) =>
              translation.translatedTo === user.translationLanguage,
          ),
        )
      ) {
        void navigate({ search: { ...search, useTranslation: true } });
        return;
      }
      translationMutation.mutate({
        organizationSlug,
        publicId,
        translationLanguage: user.translationLanguage,
      });
    } else {
      void navigate({ search: { ...search, useTranslation: false } });
    }
  };

  return {
    translationIsEnabled,
    translate,
  };
}
