import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useCreateView } from "../-hooks/useCreateView";
import { ViewForm, viewFormSchema } from "../-utils/viewFormSchema";
import { ViewFormBody } from "./ViewFormBody";
import { formatPayload } from "../-utils/formatPayload";

export function CreateViewForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<ViewForm>({
    resolver: zodResolver(viewFormSchema),
    defaultValues: {
      title: "",
      filters: [],
    },
  });

  const { onSubmit: onSubmitMutation } = useCreateView({ setOpen });

  const onSubmit = (data: ViewForm) => {
    const payload = formatPayload(data);

    onSubmitMutation(payload);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to create view");
  };

  return <ViewFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
