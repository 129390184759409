import { Route } from "..";
import { toast } from "sonner";

export function useDeleteThreats() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const deleteMutation = trpc.dashboard.threats.deleteMany.useMutation({
    onSuccess: async (_, variables) => {
      await trpcUtils.dashboard.threats.getAll.invalidate({ businessSlug });

      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Threats deleted successfully."
          : "Threat deleted successfully.";

      toast(message);
    },
    onError: (error, variables) => {
      const { ids } = variables;
      const message =
        ids.length > 1
          ? "Failed to delete threats."
          : "Failed to delete threat.";

      toast(message);
      console.error(error);
    },
  });

  return {
    deleteThreats: (ids: string[]) => {
      deleteMutation.mutate({ businessSlug, ids: ids });
    },
  };
}
