import { Headset } from "lucide-react";
import { Route } from "../_navbar";
import { Tooltip } from "@dashboard/common/ui/tooltip";

export function AvailableAgentsIndicator() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [availableAgents] =
    trpc.dashboard.users.getAvailableAgents.useSuspenseQuery({
      organizationSlug,
    });

  function TooltipContent() {
    return (
      <div className="flex flex-col gap-3 p-1">
        <h2 className="font-semibold text-black">Available agents</h2>
        {availableAgents.map((agent) => (
          <span key={agent.id} className="text-gray-800">
            {agent.email}
          </span>
        ))}
      </div>
    );
  }

  return (
    <Tooltip
      disableHoverableContent
      content={<TooltipContent />}
      triggerAsChild
      side="right"
      trigger={
        <div className="flex flex-col items-center space-y-1 rounded-full bg-gray-300 px-1 py-2">
          <Headset className="h-5 w-5 text-black" />
          <span className="text-black">{availableAgents.length}</span>
        </div>
      }
    />
  );
}
