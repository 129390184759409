import { Route } from "../../onboarding";

export function useRemoveExternalTicketConfig() {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { organizationSlug } = Route.useParams();

  const removeMutation =
    trpc.dashboard.externalTickets.removeConfiguration.useMutation({
      onSuccess: async () => {
        await trpcUtils.dashboard.externalTickets.getConfiguration.invalidate({
          organizationSlug,
        });
      },
    });

  return async () => {
    await removeMutation.mutateAsync({ businessSlug: organizationSlug });
  };
}
