import { Route } from "..";
import { toast } from "sonner";
import { BotRuleForm } from "../-utils/botRuleFormSchema";

export const useCreateBotRule = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.bots.createRule.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.bots.getRules.invalidate({ businessSlug });

      setOpen(false);
      toast.success("Bot rule created");
    },
    onError: () => {
      toast.error("Failed to create bot rule");
    },
  });

  return {
    onSubmit: (data: BotRuleForm) => {
      creationMutation.mutate({ businessSlug, ...data });
    },
  };
};
