import { env } from "@chatbot/env";

const isNgrok =
  typeof document !== "undefined" &&
  document.location.hostname.split(".").slice(-2).join(".") ===
    "ngrok-free.app";

// base URL for static files: images, css
const url = isNgrok ? document.location.origin : env.CHATBOT_FRONTEND_URL;
export const baseUrl = `${url}/chatbot`;
