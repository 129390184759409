import { Button } from "@dashboard/common/ui/button";
import { MessageSquareX } from "lucide-react";

export function CloseConversation() {
  return (
    <Button
      type="button"
      className="group-hover:text-blue group-hover:bg-blue-light bg-gray-light gap-2"
      variant="secondary"
    >
      <MessageSquareX className="h-4 w-4 font-bold" strokeWidth={2.4} />
      Close Conversation
    </Button>
  );
}
