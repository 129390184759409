import { Button } from "@dashboard/common/ui/button";
import { Dialog, DialogTrigger } from "@dashboard/common/ui/dialog";
import { Trash2 } from "lucide-react";
import { DeleteRecordDialogContent } from "./DeleteRecordDialogContent";

interface DeleteRecordDialogProps {
  ids: string[];
  recordName: { singular: string; plural: string };
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  deleteRecords: (ids: string[]) => void;
}

export function DeleteRecordDialog({
  ids,
  recordName,
  setSelectedIds,
  deleteRecords,
}: DeleteRecordDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive">
          <Trash2 strokeWidth={3} className="mr-2 h-4 w-4" />
          Delete
        </Button>
      </DialogTrigger>
      <DeleteRecordDialogContent
        ids={ids}
        recordName={recordName}
        setSelectedIds={setSelectedIds}
        deleteRecords={deleteRecords}
      />
    </Dialog>
  );
}
