import { Button } from "@dashboard/common/ui/button";
import { Tooltip } from "@dashboard/common/ui/tooltip";
import { Languages, Undo, Wand2, Loader2 } from "lucide-react";
import { MacroCommand } from "../../../../-components/Macros/MacroCommand";
import { AttachmentButton } from "../../../../-components/FileUpload/AttachmentButton";
import { MacroAction } from "@be/modules/macros/macros.types";
import { useMacroData } from "../../../-hooks/useMacroData";
import { MessageButton } from "./MessageButton";

interface MessageToolbarProps {
  // Macro Selection
  setInput: (input: string) => void;
  setActions: (actions: MacroAction[]) => void;
  setFiles: (files: { fileId: string; filename: string }[]) => void;

  // File Upload
  onAttachmentUpload: (file: { fileId: string; filename: string }) => void;

  // Translation
  showTranslate: boolean;
  isTranslated?: boolean;
  onTranslate?: () => void;
  onUndo?: () => void;

  // Refinement
  onRefine?: () => void;
  isRefining?: boolean;

  // Send message
  onClickSendMessage: ({ action }: { action?: MacroAction }) => void;
  sendMessageDisabled: boolean;

  // Misc
  businessId: string;
}

export function MessageToolbar({
  setInput,
  setActions,
  setFiles,
  onAttachmentUpload,
  isTranslated,
  onTranslate,
  onUndo,
  showTranslate,
  onRefine,
  isRefining,
  onClickSendMessage,
  sendMessageDisabled,
  businessId,
}: MessageToolbarProps) {
  const macroData = useMacroData();

  return (
    <div className="mt-1 flex h-8 flex-row items-center justify-between">
      <div className="flex items-center gap-2">
        <MacroCommand
          setInput={setInput}
          setActions={setActions}
          setFiles={setFiles}
          disabled={isRefining}
          macroData={macroData}
        />
        <AttachmentButton
          onUpload={onAttachmentUpload}
          businessId={businessId}
          disabled={isRefining}
        />
        {showTranslate && (
          <Tooltip
            triggerAsChild
            trigger={
              <Button
                variant="select"
                onClick={isTranslated ? onUndo : onTranslate}
                disabled={isRefining}
                className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
              >
                {isTranslated ? (
                  <Undo className="h-5 w-5" />
                ) : (
                  <Languages className="h-5 w-5" />
                )}
              </Button>
            }
            content={isTranslated ? "Undo translation" : "Translate message"}
          />
        )}
        {onRefine && (
          <Tooltip
            triggerAsChild
            trigger={
              <Button
                variant="select"
                onClick={onRefine}
                disabled={isRefining}
                className="disabled:bg-transparent disabled:text-gray-400 disabled:shadow-none"
              >
                {isRefining ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Wand2 className="h-5 w-5" />
                )}
              </Button>
            }
            content="Refine message"
          />
        )}
      </div>
      <div>
        <MessageButton
          onClickSendMessage={onClickSendMessage}
          disabled={sendMessageDisabled}
        />
      </div>
    </div>
  );
}
