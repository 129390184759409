interface TimeSelectProps {
  onSelect: (time: string) => void;
}

export function TimeSelect({ onSelect }: TimeSelectProps) {
  const numbers = Array.from({ length: 24 }, (_, i) => i.toString());
  const times = numbers.map((number) => {
    return number.padStart(2, "0") + ":00";
  });

  return (
    <div className="flex max-h-[272px] w-full flex-col overflow-y-scroll">
      {times.map((time) => (
        <Option key={time} time={time} onSelect={onSelect} />
      ))}
    </div>
  );
}

interface OptionProps {
  time: string;
  onSelect: (time: string) => void;
}

function Option({ time, onSelect }: OptionProps) {
  return (
    <div
      onClick={() => onSelect(time)}
      className="hover:bg-accent flex w-full cursor-pointer items-center justify-center px-3 py-2 text-sm"
    >
      <div className="font-medium text-gray-700">{time}</div>
    </div>
  );
}
