import { MouseEventHandler } from "react";
import { Button } from "../ui/button";
import { Tooltip } from "../ui/tooltip";
import { ReactNode } from "@tanstack/react-router";

interface TooltipButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  tooltipContent: ReactNode;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | "publish"
    | "select";
  size?: "default" | "sm" | "lg" | "icon" | "toggle";
  tooltipSide?: "top" | "right" | "bottom" | "left";
  buttonClassName?: string;
  tooltipClassName?: string;
  disableHoverableContent?: boolean;
}

export function TooltipButton({
  onClick,
  children,
  tooltipContent,
  variant = "default",
  size = "default",
  tooltipSide = "bottom",
  buttonClassName = "",
  tooltipClassName = "",
  disableHoverableContent = true,
}: TooltipButtonProps) {
  return (
    <Tooltip
      disableHoverableContent={disableHoverableContent}
      triggerAsChild
      side={tooltipSide}
      trigger={
        <Button
          type="button"
          variant={variant}
          size={size}
          onClick={onClick}
          className={buttonClassName}
        >
          {children}
        </Button>
      }
      content={
        <div className={`font-medium text-black ${tooltipClassName}`}>
          {tooltipContent}
        </div>
      }
    />
  );
}
