import { Route } from "..";

export function useCategories() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const [categories] = trpc.dashboard.articles.getCategories.useSuspenseQuery({
    businessSlug,
  });

  return categories;
}
