import { toast } from "sonner";
import { Route } from "..";

export function useHide() {
  const { trpc } = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const { organizationSlug, publicId } = Route.useParams();
  const search = Route.useSearch();

  const hideMutation = trpc.dashboard.conversations.hide.useMutation({
    onSuccess: () => {
      toast.success(`Conversation ${publicId} was hidden`);
      void navigate({
        to: "/organization/$organizationSlug/conversation",
        params: { organizationSlug },
        search,
      });
    },
    onError: (error) => {
      console.error("Error hiding conversation", error);
      toast.error("Error hiding conversation");
    },
  });

  return () => hideMutation.mutate({ organizationSlug, publicId });
}
