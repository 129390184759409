import { ReactNode } from "react";

interface SettingsSectionProps {
  children: ReactNode;
}

function SettingsSection({ children }: SettingsSectionProps) {
  return <div className="px-2 pb-3">{children}</div>;
}

export default SettingsSection;
