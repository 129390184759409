import { z } from "zod";

export const pushSubscriptionSchema = z.object({
  endpoint: z.string().url(),
  expirationTime: z.date().nullable(),
  keys: z.object({
    p256dh: z.string(),
    auth: z.string(),
  }),
});
