import { cn } from "@dashboard/utils/ui";
import { Upload } from "lucide-react";
import { DragEvent, ReactNode } from "react";

interface MessageInputContainerProps {
  children: ReactNode;
  isFocused: boolean;
  isDragging: boolean;
  handleDrag: (e: DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
}

export function MessageInputContainer({
  children,
  isFocused,
  isDragging,
  handleDrag,
  handleDrop,
}: MessageInputContainerProps) {
  return (
    <div className="mb-3 w-full px-7">
      <div
        className={cn(
          "relative flex flex-col overflow-x-hidden break-words rounded-2xl border border-gray-100 px-4 pb-2 pt-3",
          isFocused ? "shadow-xl" : "shadow-md",
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {isDragging && (
          <div className="absolute inset-0 z-50 flex flex-col items-center justify-center rounded-2xl border border-gray-400 bg-white/80 backdrop-blur-sm">
            <Upload className="h-10 w-10 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">
              Drop files here to attach
            </p>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
