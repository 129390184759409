import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@dashboard/common/ui/button";
import { Upload } from "lucide-react";
import logo from "@dashboard/assets/side-navbar-logo.svg";
import { FormImageKey, WebChatConfigForm } from "../-utils/formSchema";

interface LogoUploaderProps {
  name: FormImageKey;
  img?: string;
}

function LogoUploader({ name, img }: LogoUploaderProps) {
  const { setValue, watch } = useFormContext<WebChatConfigForm>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const image = watch(`${name}Src`) ?? img ?? logo;

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setValue(`${name}Src`, imageUrl);
      setValue(name, file);
    }
  };

  return (
    <div className="border-input flex flex-row items-center rounded-md border border-dashed px-5 py-6 shadow-sm">
      <div>
        <img alt="Logo" className="max-w-200 h-16 rounded" src={image} />
      </div>
      <Button
        type="button"
        className="ml-10"
        variant="secondary"
        onClick={handleButtonClick}
      >
        <Upload className="mr-2 h-4 w-4" />
        Upload Image
      </Button>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        hidden
      />
    </div>
  );
}

export default LogoUploader;
