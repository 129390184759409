import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { CreateWebsiteForm } from "./CreateWebsiteForm";

export function CreateWebsiteDialogContent({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Add a new website</DialogTitle>
      </DialogHeader>
      <CreateWebsiteForm setOpen={setOpen} />
    </>
  );
}
