import { Select } from "@dashboard/common/ui/select";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Route } from "../..";
import { useAssignUser } from "../../-hooks/useAssignUser";

export function AssigneeSelect() {
  const { publicId, assigneeId } = useConversation();
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const handleOnChange = useAssignUser(publicId);

  const options = [
    ...users.map((user) => ({ label: user.fullName, value: user.id })),
    { label: "Unassigned", value: "unassigned" },
  ];

  // TODO: update to a Command popover
  return (
    <Select
      className="w-full"
      variant="dropdown"
      placeholder="Unassigned"
      value={assigneeId ?? "unassigned"}
      onChange={handleOnChange}
      renderValue={(valueJsx) => <>{valueJsx}</>}
      options={options}
    />
  );
}
