import { createFileRoute } from "@tanstack/react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@dashboard/common/ui/input";
import { Button } from "@dashboard/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@dashboard/common/ui/form";
import { OnboardingNavigation } from "../-components/OnboardingNavigation";
import { useCreateWebsites } from "../-hooks/useCreateWebsites";
import { Check, Loader2, PlusIcon, Trash2, X } from "lucide-react";
import { useEffect, useState } from "react";

const websiteSchema = z.object({
  url: z
    .string({
      required_error: "Please enter a valid URL",
      invalid_type_error: "Please enter a valid URL",
    })
    .url("Please enter a valid URL"),
});

const formSchema = z.object({
  websites: z.array(websiteSchema),
});

export const Route = createFileRoute(
  "/organization/$organizationSlug/onboarding/knowledge/websites",
)({
  component: WebsitesPage,
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.scraper.getAll.ensureData({
      businessSlug: organizationSlug,
    });
  },
});

function WebsitesPage() {
  const { organizationSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const [syncStatus, setSyncStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const [defaultWebsites] = trpc.dashboard.scraper.getAll.useSuspenseQuery({
    businessSlug: organizationSlug,
  });

  const { createWebsites } = useCreateWebsites({
    onSuccess: () => {
      setSyncStatus("loading");
      setTimeout(() => {
        setSyncStatus("success");
      }, 5000);
    },
    onError: () => {
      setSyncStatus("error");
    },
  });

  const SyncStatus = () => {
    switch (syncStatus) {
      case "idle":
        return <span>Sync Websites</span>;
      case "loading":
        return (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            <span>Syncing Websites</span>
          </>
        );
      case "success":
        return (
          <>
            <Check className="mr-2 h-4 w-4" />
            <span>Websites Synced</span>
          </>
        );
      case "error":
        return (
          <>
            <X className="mr-2 h-4 w-4" />
            <span>Error Syncing Websites</span>
          </>
        );
    }
  };

  const defaultValues = {
    websites:
      defaultWebsites.length === 0
        ? [{ url: "" }]
        : defaultWebsites.map((website) => ({
            url: website.url,
          })),
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "websites",
  });

  const websites = form.watch("websites");

  useEffect(() => {
    setSyncStatus("idle");
  }, [websites]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    createWebsites({ websites: values.websites.map((website) => website.url) });
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-[#041627]">
      <h1 className="pb-3 text-center text-2xl font-medium leading-8">
        Add Public Websites
      </h1>
      <h2 className="text-center leading-6 text-gray-800">
        Enter the URLs of the public websites you want to add. Octocom will
        retrieve any textual information to use when answering customer queries.
      </h2>
      <div className="w-full max-w-3xl py-8">
        <Form {...form}>
          <form
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
            className="flex flex-col gap-4"
          >
            <div className="flex max-h-96 flex-col gap-2 overflow-y-auto p-2">
              {fields.map((field, index) => (
                <FormField
                  key={field.id}
                  control={form.control}
                  name={`websites.${index}.url`}
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center gap-2">
                        <FormControl>
                          <Input
                            className="h-10 w-full"
                            placeholder="https://example.com"
                            disabled={syncStatus === "loading"}
                            {...field}
                          />
                        </FormControl>
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => remove(index)}
                          className="h-10 w-10 px-2 py-2"
                          disabled={syncStatus === "loading"}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </div>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => append({ url: "" })}
                className="mt-2 h-10 w-full justify-center"
                disabled={syncStatus === "loading"}
              >
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Another Website
              </Button>
              <Button
                className="mt-2 h-10 w-full justify-center bg-[#041627] text-white"
                type="submit"
                disabled={syncStatus !== "idle"}
              >
                <SyncStatus />
              </Button>
            </div>
          </form>
        </Form>
        <OnboardingNavigation
          backPath="/organization/$organizationSlug/onboarding/knowledge"
          isNextEnabled={false}
        />
      </div>
    </div>
  );
}
