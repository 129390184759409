import { toast } from "sonner";
import { Route } from "..";
import { HelpDeskConfigForm } from "../-utils/formSchema";

export const useSaveHelpDeskConfig = () => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const upsertMutation = trpc.dashboard.helpDesk.saveConfiguration.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.helpDesk.getConfiguration.invalidate();
      toast.success("Help desk configuration updated.");
    },
    onError: (error) => {
      toast.error("Failed to update help desk configuration.");
      console.error(error);
    },
  });

  return {
    saveConfig: (data: HelpDeskConfigForm) => {
      const { autoAssignmentPolicy } = data;

      upsertMutation.mutate({
        businessSlug,
        autoAssignmentPolicy,
      });
    },
  };
};
