import { Input } from "@dashboard/common/ui/input";
import { Separator } from "@dashboard/common/ui/separator";

interface EmailMetadataProps {
  from: string;
  to: string | { value: string; onChange: (value: string) => void };
  subject: string | { value: string; onChange: (value: string) => void };
  isEditable: boolean;
}

export function EmailMetadata({
  from,
  to,
  subject,
  isEditable,
}: EmailMetadataProps) {
  return (
    <div>
      <div className="flex items-center">
        <div className="w-16 text-sm text-gray-500">From</div>
        <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
          {from}
        </span>
      </div>
      <Separator />
      <div className="flex items-center">
        <div className="w-16 text-sm text-gray-500">To</div>
        {isEditable && typeof to === "object" ? (
          <Input
            value={to.value}
            onChange={(e) => to.onChange(e.target.value)}
            className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
          />
        ) : (
          <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
            {typeof to === "string" ? to : to.value}
          </span>
        )}
      </div>
      <Separator />
      <div className="flex items-center">
        <div className="w-16 text-sm text-gray-500">Subject</div>
        {isEditable && typeof subject === "object" ? (
          <Input
            value={subject.value}
            onChange={(e) => subject.onChange(e.target.value)}
            className="border-0 text-sm text-black shadow-none focus-visible:ring-0"
          />
        ) : (
          <span className="flex h-9 items-center px-1 py-1 text-sm text-black">
            {typeof subject === "string" ? subject : subject.value}
          </span>
        )}
      </div>
      <Separator />
    </div>
  );
}
