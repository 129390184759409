import { Route } from "..";
import { toast } from "sonner";
import { EmailSettingsForm } from "../-utils/emailSettingsSchema";

export function useSubmitForm() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const upsertMutation = trpc.dashboard.emails.upsertConfig.useMutation({
    onSuccess: () => {
      toast.success("Successfully updated email settings.");
    },
    onError: () => {
      toast.error("Failed to update the email settings. Please try again.");
    },
  });

  return {
    onSubmit: (data: EmailSettingsForm) => {
      upsertMutation.mutate({
        businessSlug,
        ...data,
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.success("Failed to update email settings.");
    },
  };
}
