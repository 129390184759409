import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";

import { Inbox, Settings, BarChart4, Circle } from "lucide-react";
import logo from "../../../assets/side-navbar-logo.svg";

import { NavbarButton } from "./-components/NavbarButton";
import { AvailableAgentsIndicator } from "./-components/AvailableAgentsIndicator";
import { UserButton } from "./-components/UserButton";
import { ChooseOrganizationDialog } from "./-components/ChooseOrganizationDialog";

import { useNotificationEffect } from "./-hooks/useNotificationEffect";

import { getInitialsComponent } from "./-utils/getInitialsComponent";
import { renderNavbarLink } from "./-utils/renderNavbarLink";
import { integrateRedirect } from "@dashboard/routes/connect/demo/-utils/redirect";

export const Route = createFileRoute("/organization/$organizationSlug/_navbar")(
  {
    beforeLoad: async ({
      params: { organizationSlug },
      context: { trpcClientUtils, user },
    }) => {
      const { organizations } = user;
      const organization = organizations.find(
        (organization) => organization.slug === organizationSlug,
      );

      if (!organization) {
        throw redirect({
          to: "/organization/$organizationSlug/conversation",
          params: {
            organizationSlug: organizations[0].slug,
          },
          replace: true,
        });
      }

      integrateRedirect();

      if (
        organization.onboardingStatus !== "COMPLETED" &&
        organization.onboardingStatus !== "SETUP"
      ) {
        const url = `/organization/${organizationSlug}/onboarding/${organization.onboardingStatus.toLowerCase()}`;
        window.location.href = url;
      }

      const [, businesses, externalTicketConfig] = await Promise.all([
        trpcClientUtils.dashboard.users.getAvailableAgents.ensureData({
          organizationSlug,
        }),

        trpcClientUtils.dashboard.business.getAll.ensureData({
          organizationSlug,
        }),

        trpcClientUtils.dashboard.externalTickets.getConfiguration.ensureData({
          organizationSlug,
        }),
      ]);

      return {
        organization,
        // TODO: Find out why we're returning ensuredData
        businesses,
        hasExternalTicketConfig: !!externalTicketConfig,
      };
    },
    component: NavbarLayout,
  },
);

function NavbarLayout() {
  return (
    <div className="flex h-full overscroll-none">
      <Navbar />
      <div className="h-full flex-grow">
        <Outlet />
      </div>
    </div>
  );
}

function Navbar() {
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const { organizationSlug } = Route.useParams();

  const { user, hasExternalTicketConfig } = Route.useRouteContext();

  useNotificationEffect({});

  return (
    <>
      <div>
        <div className="w-11" />
        <div className="bg-gray-light fixed left-0 top-0 z-50 h-full w-11">
          <div className="flex h-full flex-col items-center pb-2 pt-5">
            <img alt="Octocom logo" className="h-7 w-7" src={logo} />

            <div className="group mt-5 flex w-full flex-grow flex-col">
              {user.isAdmin && (
                <NavbarButton
                  render={renderNavbarLink({
                    to: "/organization/$organizationSlug/setup",
                  })}
                  tooltipText="Setup"
                  icon={Circle}
                />
              )}

              <NavbarButton
                render={renderNavbarLink({
                  to: "/organization/$organizationSlug/conversation",
                })}
                tooltipText="Help Desk"
                icon={Inbox}
              />

              <NavbarButton
                render={renderNavbarLink({
                  to: "/organization/$organizationSlug/metrics",
                })}
                tooltipText="Metrics"
                icon={BarChart4}
              />

              {(user.isManager || user.isAdmin) && (
                <NavbarButton
                  render={renderNavbarLink({
                    to: "/organization/$organizationSlug/settings",
                  })}
                  tooltipText="Settings"
                  icon={Settings}
                />
              )}
            </div>

            {!hasExternalTicketConfig && <AvailableAgentsIndicator />}

            {user.organizations.length > 1 && (
              <NavbarButton
                tooltipText="Select Organization"
                icon={getInitialsComponent(organizationSlug)}
                render={({ className, inactiveClassName, children }) => (
                  <button
                    type="button"
                    className={`${className} ${inactiveClassName}`}
                    onClick={() => setOpenChooseOrganization(true)}
                  >
                    {children}
                  </button>
                )}
              />
            )}

            <UserButton />
          </div>
        </div>
      </div>

      <ChooseOrganizationDialog
        open={openChooseOrganization}
        onOpenChange={setOpenChooseOrganization}
      />
    </>
  );
}
