import { minutesToMilliseconds } from "date-fns";
import { Route } from "../..";

export function useGiftCards() {
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();

  const {
    data: giftCardDetails,
    isLoading,
    refetch,
  } = trpc.dashboard.conversations.giftCards.getDetails.useQuery(
    {
      publicId,
      organizationSlug,
    },
    {
      refetchInterval: minutesToMilliseconds(5),
    },
  );

  return {
    isLoading,
    data: giftCardDetails,
    refetch,
  };
}
