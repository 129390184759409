import {
  RoutePaths,
  RouterProvider,
  createRouter,
} from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
// import { BarLoader } from "react-spinners";

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultNotFoundComponent: () => <></>,
  // defaultPendingComponent: () => (
  //   <div className="xpt-40 flex h-full w-full items-center justify-center">
  //     <div className="pb-[15vh]">
  //       <BarLoader color="#334afa" height={6} width={200} />
  //     </div>
  //   </div>
  // ),
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export type Routepath = RoutePaths<typeof routeTree>;

export function App() {
  return <RouterProvider router={router} />;
}
