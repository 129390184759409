import { HelpCircle } from "lucide-react";
import { Trend } from "./TrendIndicator/Trend";

import {
  TooltipRoot,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@dashboard/common/ui/tooltip";

interface BreakdownProps {
  title?: string;
  data: {
    name: string;
    percentage: number;
    tag?: string;
    tooltipContent?: string;
    trendingIndicator?: {
      value: number;
      unit?: string;
      higherIsBetter: boolean;
    };
  }[];
}

export default function Breakdown({ data, title }: BreakdownProps) {
  // Define an array of color classes
  const colors = [
    "bg-green-700", // Color for data[0]
    "bg-green-600", // Color for data[1]
    "bg-green-500", // Color for data[2]
    "bg-green-400", // Color for data[3]
    "bg-green-300", // Color for data[4]
    "bg-green-200", // Color for data[4]
  ];

  return (
    <div className="mt-4 max-h-[400px] w-full overflow-scroll">
      {title && <h4 className="text-sm font-semibold text-black">{title}</h4>}

      <div className="mt-3">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-row items-center border-b py-3 pr-4"
          >
            <div
              className={`h-2.5 w-2.5 rounded-full ${
                colors[index % colors.length]
              }`}
            ></div>
            <span className="ml-2 text-sm text-black">{item.name}</span>
            <TooltipProvider delayDuration={0} skipDelayDuration={0}>
              <TooltipRoot>
                <TooltipTrigger asChild>
                  <HelpCircle
                    width={16}
                    height={16}
                    className="ml-2 text-black"
                  />
                </TooltipTrigger>
                <TooltipContent className="max-w-[400px]">
                  {item.tooltipContent}
                </TooltipContent>
              </TooltipRoot>
            </TooltipProvider>
            {item.tag && (
              <div className="ml-3 rounded-full bg-blue-100 px-2 py-1 text-xs text-[#3482F6]">
                {item.tag}
              </div>
            )}
            <div className="ml-auto flex flex-row items-center">
              <span className="text-sm">{item.percentage}%</span>
            </div>
            <div className="ml-3 flex w-10 flex-row  items-center justify-start">
              {item.trendingIndicator && item.trendingIndicator.value !== 0 ? (
                <Trend
                  value={item.trendingIndicator.value}
                  unit={item.trendingIndicator.unit}
                  positive={item.trendingIndicator.higherIsBetter}
                  showPreviousDays={false}
                />
              ) : (
                <div className="pl-6 text-xs">—</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
