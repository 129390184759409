import { toast } from "sonner";
import { Route } from "..";
import { OnboardingStep } from "@be/modules/businesses/businesses.types";

export function useCompleteOnboardingStep() {
  const { trpc, business } = Route.useRouteContext();
  const utils = trpc.useUtils();

  const mutation = trpc.dashboard.business.completeOnboardingStep.useMutation({
    onSuccess: async () => {
      await utils.dashboard.business.getOrThrow.invalidate({
        businessSlug: business.slug,
      });
    },
    onError: () => {
      toast.error("Failed to mark step as completed");
    },
  });

  return ({ step }: { step: OnboardingStep }) => {
    mutation.mutate({ businessSlug: business.slug, step });
  };
}
