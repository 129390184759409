import { createFileRoute, Link } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { ProductLink } from "./-components/ProductLink";
import { useDeleteProducts } from "./-hooks/useDeleteProducts";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.products.getAll.ensureData({
      businessSlug,
    });
    await trpcClientUtils.dashboard.products.getCount.ensureData({
      businessSlug,
    });
  },
  component: ProductListPage,
});

export function ProductListPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [products] = trpc.dashboard.products.getAll.useSuspenseQuery({
    businessSlug,
  });

  const records = products.map((product) => ({
    id: product.id,
    name: product.contextual.name,
    minPrice: product.contextual.minPrice,
    maxPrice: product.contextual.maxPrice,
    link: ProductLink({
      organizationSlug,
      businessSlug,
      productSlug: product.slug,
    }),
  }));

  const { deleteProducts } = useDeleteProducts();

  return (
    <div className="flex h-screen flex-col">
      <Link
        to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products/collections"
        params={{ organizationSlug, businessSlug }}
        className="text-blue-500 hover:underline"
      >
        Collections
      </Link>
      <Link
        to="/organization/$organizationSlug/settings/$businessSlug/knowledge/products/metafields"
        params={{ organizationSlug, businessSlug }}
        className="text-blue-500 hover:underline"
      >
        Metafields
      </Link>
      <Table
        title="Products"
        records={records}
        columns={[
          {
            header: "Name",
            accessor: "name",
          },
          {
            header: "Price from",
            accessor: "minPrice",
          },
          {
            header: "Price to",
            accessor: "maxPrice",
          },
          {
            header: "Details",
            accessor: "link",
          },
        ]}
        recordName={{ singular: "Product", plural: "Products" }}
        noRecordsText="No products available"
        deleteRecords={deleteProducts}
      />
    </div>
  );
}
