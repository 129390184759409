export const flowTemplates = {
  delivery_time: {
    title: "Delivery Time",
    description:
      "Provide customers with information regarding the estimated delivery time of their orders.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and acknowledge their inquiry about delivery time.\n" +
      "2. Request the order number or relevant details to locate the order.\n" +
      "3. Check the order status in the system to find the estimated delivery time.\n" +
      "4. Inform the customer of the current estimated delivery time.\n" +
      "5. Offer assistance with any other questions or concerns.\n" +
      "6. Thank the customer for their patience and understanding.",
    orderIdRequired: true,
    subscriptionIdRequired: false,
  },
  order_tracking: {
    title: "Order Tracking",
    description: "Assist customers in tracking the status of their order.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and ask for their order number or relevant details.\n" +
      "2. Use the provided information to look up the order in the tracking system.\n" +
      "3. Provide the customer with the current status of their order, including any tracking numbers if available.\n" +
      "4. Explain any delays or issues, if applicable.\n" +
      "5. Offer to send the tracking information via email or SMS.\n" +
      "6. Ask if the customer needs further assistance and thank them for reaching out.",
    orderIdRequired: true,
    subscriptionIdRequired: false,
  },
  quality_complaints: {
    title: "Quality Complaints",
    description:
      "Handle customer complaints about the quality of the product they received.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and express understanding for their concern.\n" +
      "2. Ask for specific details about the issue, including the order number, product involved, and the nature of the complaint.\n" +
      "3. Apologize for the inconvenience and assure the customer that their complaint is taken seriously.\n" +
      "4. Offer solutions such as a replacement, refund, or discount on future purchases.\n" +
      "5. Arrange for the return or disposal of the defective product, if necessary.\n" +
      "6. Confirm with the customer that the proposed solution is satisfactory.\n" +
      "7. Thank the customer for bringing the issue to your attention and assure them of your commitment to quality.",
    orderIdRequired: true,
    subscriptionIdRequired: false,
  },
  discounts: {
    title: "Discounts",
    description:
      "Provide customers with information about available discounts or assist with applying discounts to their orders.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and inquire about the discount they are interested in.\n" +
      "2. Verify if the customer is eligible for the discount or promotion.\n" +
      "3. Provide details about the discount, including any terms and conditions.\n" +
      "4. Assist the customer in applying the discount to their order, if needed.\n" +
      "5. Confirm the discount has been successfully applied.\n" +
      "6. Thank the customer and offer help with any other questions.",
    orderIdRequired: false,
    subscriptionIdRequired: false,
  },
  order_updates: {
    title: "Order Updates",
    description:
      "Update customers on the status of their orders, including any changes or delays.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and ask for their order number or details.\n" +
      "2. Look up the order in the system to check its current status.\n" +
      "3. Inform the customer of any updates, such as processing, shipping, or delays.\n" +
      "4. If there is a delay, explain the reason and provide a new estimated delivery time.\n" +
      "5. Offer alternatives, such as cancellation or replacement, if applicable.\n" +
      "6. Thank the customer for their understanding and patience.",
    orderIdRequired: true,
    subscriptionIdRequired: false,
  },
  subscription_management: {
    title: "Subscription Management",
    description:
      "Assist customers with managing their subscriptions, including changes, cancellations, or renewals.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and ask how you can assist with their subscription.\n" +
      "2. Confirm the subscription details and identify the customer's request (e.g., change, cancellation, renewal).\n" +
      "3. Follow the necessary steps to make the requested changes in the subscription system.\n" +
      "4. Confirm the changes with the customer and explain any impacts, such as billing or access.\n" +
      "5. Provide information about upcoming billing cycles or renewal dates, if applicable.\n" +
      "6. Thank the customer and offer further assistance.",
    orderIdRequired: false,
    subscriptionIdRequired: true,
  },
  newsletter_management: {
    title: "Newsletter Management",
    description:
      "Handle customer requests regarding their subscription to newsletters, including opting in or out.",
    whenToFollow: "temp",
    flow:
      "1. Greet the customer and inquire about their request concerning the newsletter.\n" +
      "2. Verify the customer's details to locate their subscription status.\n" +
      "3. Process the request to either subscribe, unsubscribe, or update newsletter preferences.\n" +
      "4. Confirm the changes with the customer.\n" +
      "5. Inform the customer about when the changes will take effect.\n" +
      "6. Thank the customer and ensure they know how to make future changes if needed.",
    orderIdRequired: false,
    subscriptionIdRequired: true,
  },
};
