import { toast } from "sonner";
import { Route } from "..";

export function useSnooze() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const trpcUtils = trpc.useUtils();

  const snoozeMutation = trpc.dashboard.conversations.snooze.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.conversations.getCount.invalidate({
        organizationSlug,
      });
      await trpcUtils.dashboard.conversations.get.invalidate({
        organizationSlug,
        publicId,
      });

      toast.success("Conversation snoozed", {
        position: "bottom-right",
      });
    },
    onError: (error) => {
      console.error("Error snoozing conversation", error);
      toast.error("Error snoozing conversation");
    },
  });

  return (until: Date) =>
    snoozeMutation.mutate({
      organizationSlug,
      publicId,
      until: until.toISOString(),
    });
}
