import { Button } from "@dashboard/common/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@dashboard/common/ui/command";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@dashboard/common/ui/popover";

import { Route } from "../..";

import { useState } from "react";
import { useAddTag } from "../../-hooks/useAddTag";
import { CreateTagButton } from "./CreateTagButton";
import { Plus, Tag } from "lucide-react";

export function TagCommand() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const [organizationTags] =
    trpc.dashboard.conversations.tags.getOrganizationTags.useSuspenseQuery({
      organizationSlug,
    });

  const [conversationTags] =
    trpc.dashboard.conversations.tags.getConversationTags.useSuspenseQuery({
      organizationSlug,
      publicId,
    });

  const tags = organizationTags.filter(
    (tag) =>
      !conversationTags
        .map((conversationTag) => conversationTag.title)
        .includes(tag.title),
  );

  const addTag = useAddTag();

  const showCreateTag =
    value.trim() !== "" &&
    !organizationTags.map((tag) => tag.title).includes(value.trim());

  const handleSelectTag = (tagId: string) => {
    addTag(tagId);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          role="combobox"
          aria-expanded={open}
          className="h-7 w-7 rounded-full"
        >
          <Plus className="h-4 w-4 font-bold" strokeWidth={2.4} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Tag..."
            icon={Tag}
            value={value}
            onValueChange={(search) => setValue(search)}
          />
          <CommandList>
            <CommandEmpty>
              <div className="flex w-full flex-col justify-start gap-2">
                {showCreateTag && (
                  <CreateTagButton
                    value={value}
                    setValue={setValue}
                    open={open}
                    setOpen={setOpen}
                  />
                )}
                <span>No tags found.</span>
              </div>
            </CommandEmpty>
            <CommandGroup>
              {showCreateTag && (
                <CreateTagButton
                  value={value}
                  setValue={setValue}
                  open={open}
                  setOpen={setOpen}
                />
              )}
              {tags.map((tag) => (
                <CommandItem
                  key={tag.id}
                  value={tag.title}
                  onSelect={() => handleSelectTag(tag.id)}
                >
                  {tag.title}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
