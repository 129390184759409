import { toast } from "sonner";
import { Route } from "../..";
import { useOrders } from "./useOrders";

export function useFulfillmentsReleaseHold() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const { refetch } = useOrders();

  const releaseMutation =
    trpc.dashboard.conversations.orders.releaseFulfillmentHold.useMutation({
      onError: (error) => {
        toast.error("Failed to release hold on fulfillment(s).");
        console.error(error);
      },
    });

  return {
    releaseFulfillmentsHold: ({
      fulfillmentIds,
      onSuccess,
    }: {
      fulfillmentIds: string[];
      onSuccess: () => void;
    }) => {
      releaseMutation.mutate(
        {
          organizationSlug,
          publicId,
          fulfillmentIds,
        },
        {
          onSuccess: () => {
            onSuccess();
            void refetch();
            toast.success("Hold released on fulfillment(s) successfully.");
          },
        },
      );
    },
  };
}
