import { Route } from "..";

export function useSubscriptionToken() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();
  const [subscriptionToken] =
    trpc.dashboard.conversations.getSubscriptionToken.useSuspenseQuery({
      organizationSlug,
      publicId,
    });
  return subscriptionToken;
}
