import { useFormContext } from "react-hook-form";
import DisclaimerButton from "./Disclaimer/DisclaimerButton";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

function Body() {
  const { watch } = useFormContext<WebChatConfigForm>();

  const botIconSrc = watch("botIconSrc") ?? formDefaults.botIconSrc;

  const botMessageBackgroundColor =
    watch("botMessageBackgroundColor") ??
    formDefaults.botMessageBackgroundColor;

  const botMessageTextColor =
    watch("botMessageTextColor") ?? formDefaults.botMessageTextColor;

  const customerMessageBackgroundColor =
    watch("customerMessageBackgroundColor") ??
    formDefaults.customerMessageBackgroundColor;

  const customerMessageTextColor =
    watch("customerMessageTextColor") ?? formDefaults.customerMessageTextColor;

  const enableDisclaimer = watch("enableDisclaimer");

  const greetingMessage =
    watch("greetingMessage") ?? formDefaults.greetingMessage;

  const enableOctocomBranding = watch("enableOctocomBranding");

  return (
    <div className="relative flex w-full flex-1 flex-col overflow-y-scroll">
      {enableDisclaimer && <DisclaimerButton />}
      <div className="flex-1 px-6 pt-5">
        <div>
          <div className="mb-4 flex items-center">
            <div className="mb-3 mr-3 mt-auto flex-none">
              <img
                className="mx-auto my-auto h-8 w-8 rounded"
                src={botIconSrc}
              />
            </div>
            <div
              style={{
                backgroundColor: botMessageBackgroundColor,
                color: botMessageTextColor,
              }}
              className="group relative mr-auto max-w-[75%] break-words rounded-lg bg-[#f5f5f5] px-[20px] py-[17px] text-sm leading-[1.4] text-black"
            >
              <p>{greetingMessage}</p>
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <div
              style={{
                backgroundColor: customerMessageBackgroundColor,
                color: customerMessageTextColor,
              }}
              className="chatbot-message-container group relative ml-auto max-w-[75%] break-words rounded-lg bg-[#FDE8D3] px-[20px] py-[17px] text-sm leading-[1.4] text-black"
            >
              <p>Hi, could you help me with my order?</p>
            </div>
          </div>
        </div>
      </div>
      {enableOctocomBranding && (
        <div className="pb-2 text-center">
          <a
            href="https://www.octocom.ai/?ref=griffin-remedy-bot"
            target="blank"
          >
            <p className="text-xs font-light text-gray-700 hover:text-black">
              Powered by Octocom
            </p>
          </a>
        </div>
      )}
    </div>
  );
}

export default Body;
