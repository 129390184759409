import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { UseFormReturn } from "react-hook-form";
import { BotRuleForm } from "../-utils/botRuleFormSchema";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import { Button } from "@dashboard/common/ui/button";

interface BotRuleFormBodyProps {
  form: UseFormReturn<BotRuleForm>;
  onSubmit: (data: BotRuleForm) => void;
}

export function BotRuleFormBody({ form, onSubmit }: BotRuleFormBodyProps) {
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter the title of the bot rule"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="rule"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Rule</FormLabel>
              <FormControl>
                <Textarea
                  rows={8}
                  placeholder="Enter the content of the bot rule"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                The rule and title can be in any language.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
